import * as priceutils from '~/services/Insider/utils/valuePriceTractative'
import insiderEnum from '~/services/Insider/InsiderEnum'

export default function financialInformation(params, type = insiderEnum.GET_TOTAL_VALUE) {
  if (priceutils.setOfValues().includes(type)) {
    return priceutils.amountValueOptions(params, type)
  }

  if (priceutils.setOfCurrencies().includes(type)) {
    return priceutils.currencyOptions(params, type)
  }

  if (priceutils.setOfCurrenciesSymbol().includes(type)) {
    return priceutils.currencySymbol(params, type)
  }

  if (priceutils.setOfFormattedValue().includes(type)) {
    return priceutils.totalFormattedAmountValue(params)
  }

  if (type === insiderEnum.TOTAL_VLW_WITHOUT_RENTALCOVER) {
    return priceutils.subTotalInds(params)
  }

  if (type === insiderEnum.PREVIEW_CURRENCY_VALUE) {
    return priceutils.previewCurrencyValue(params)
  }

  return false
}
