import Vue from 'vue'
import Moment from 'moment-timezone'
import VeeValidate from 'vee-validate'
import creditCardValidation from 'card-validator'
import { validatePhone } from '~/filters/phone'
import getRecaptchaByFeatureFlag from '~/helpers/getRecaptchaByFeatureFlag'

Vue.use(VeeValidate)

const OFFSET = 104
export const verifyErrorsFields = () => setTimeout(() => {
  const input = document.querySelector('.invalid:first-of-type')
  if (input) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.querySelector('.invalid:first-of-type').getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        OFFSET
    })
  }
}, 100)

export default ({ app }) => {
  Moment.tz.setDefault('America/Sao_Paulo')

  VeeValidate.Validator.extend('min_date_hour', {
    getMessage: () => 'Your pick-up date or time are before today',
    validate: (value, ref) => {
      const format = 'YYYY-MM-DD HH:mm'
      let currentDate = new Moment()
      const selectedDate = new Moment(value + ' ' + ref[0], format, true)

      if (Moment.tz.zone(ref[1])) {
        currentDate = new Moment(currentDate.tz(ref[1]).format(format), format, true)
      }

      return !currentDate.isAfter(selectedDate)
    }
  })

  VeeValidate.Validator.extend('cpf', {
    getMessage: () => 'Invalid CPF',
    validate: (value) => {
      return app.$helpers.cpfValidate(value)
    }
  })

  VeeValidate.Validator.extend('url_rentcars', {
    getMessage: () => 'The url can not be from Rentcars',
    validate: (value) => {
      const re = /[a-z]*.rentcars.*/g
      return !re.test(value)
    }
  })

  VeeValidate.Validator.extend('special_character', {
    getMessage: () => 'Invalid CPF',
    validate: (value) => {
      // eslint-disable-next-line prefer-regex-literals
      const reg = new RegExp(/^[a-zA-Z0-9 \\º\\ª\\Á\\á\\À\\à\\Ă\\ă\\Â\\â\\Å\\å\\Ä\\ä\\Ã\\ã\\Ą\\ą\\Ḃ\\ḃ\\Ć\\ć\\Ĉ\\ĉ\\Č\\č\\Ċ\\ċ\\Ç\\ç\\Ď\\ď\\Ḋ\\É\\é\\È\\è\\Ĕ\\ĕ\\Ê\\ê\\Ě\\ě\\Ë\\ë\\Ė\\ė\\Ę\\ę\\Ē\\ē\\Ḟ\\Ğ\\ğ\\Ĝ\\ĝ\\Ġ\\ġ\\Ģ\\ģ\\Ĥ\\ĥ\\Í\\í\\Ì\\ì\\Ĭ\\ĭ\\Î\\î\\Ï\\ï\\Ĩ\\ĩ\\İ\\i\\Į\\į\\Ī\\ī\\I\\ı\\Ĵ\\ĵ\\J\\Ķ\\ķ\\Ĺ\\ĺ\\Ľ\\ľ\\Ļ\\ļ\\Ŀ\\ŀ\\Ṁ\\ṁ\\Ń\\ń\\Ň\\ň\\Ñ\\ñ\\Ņ\\ņ\\Ó\\ó\\Ò\\ò\\Ŏ\\ŏ\\Ô\\ô\\Ö\\ö\\Ő\\ő\\Õ\\õ\\Ō\\ō\\Ṗ\\ṗ\\Ŕ\\ŕ\\Ř\\ř\\Ŗ\\ŗ\\Ś\\ś\\Ŝ\\ŝ\\Š\\š\\Ṡ\\ṡ\\Ş\\ş\\Ș\\ș\\Ť\\ť\\T\\Ṫ\\ṫ\\Ţ\\ţ\\Ț\\ț\\Ú\\ú\\Ù\\ù\\Ŭ\\ŭ\\Û\\û\\Ů\\ů\\Ü\\ü\\Ű\\ű\\Ũ\\ũ\\Ų\\ų\\Ū\\ū\\Ẃ\\ẃ\\Ẁ\\ẁ\\Ŵ\\ŵ\\Ẅ\\ẅ\\Ý\\ý\\Ỳ\\ỳ\\Ŷ\\ŷ\\Y\\Ÿ\\ÿ\\Ź\\ź\\Ž\\ž\\Ż\\ż\\'\\-]+$/)

      return reg.test(value)
    }
  })

  VeeValidate.Validator.extend('valid_input', {
    getMessage: () => '',
    validate: (value, args) => {
      const isValidInput = document.getElementById(args[0])
      if (isValidInput != null) {
        isValidInput.classList.add('validated_input')
        verifyErrorsFields()
        return true
      } else if (value === null && isValidInput != null && isValidInput.classList.contains('validated_input')) {
        isValidInput.classList.remove('validated_input')
        verifyErrorsFields()
      }
      return false
    }
  })

  VeeValidate.Validator.extend('confirmed_email', {
    getMessage: () => 'Emails do not match',
    validate: (value, args) => {
      const field = document.querySelector(`[name="${args[0]}"]`)
      return !!(field && String(value) === field.value)
    }
  })

  VeeValidate.Validator.extend('valid_name', {
    getMessage: () => 'Invalid name',
    validate: value => /^[A-Za-zÀ-ÖØ-öø-ÿ' ]+$/.test(value)
  })

  VeeValidate.Validator.extend('registered_email', {
    getMessage: () => 'Email already registered',
    validate: async (value) => {
      try {
        const { data: { exist } } = await app.$rentcarsApi.get('/v1/user/check-email', { email: value })
        return !exist
      } catch (e) {
        return true
      }
    }
  })

  VeeValidate.Validator.extend('registered_email_recaptcha', {
    getMessage: () => 'Email already registered',
    validate: async (value) => {
      let response = true
      try {
        const bodyRequest = { email: value }

        await getRecaptchaByFeatureFlag(
          bodyRequest,
          app.$featureFlagApi
        )

        const { data: { exist } } = await app.$rentcarsApi.get(
          '/v1/user/check-email',
          bodyRequest
        )

        response = !exist
      } catch (e) {
        window.console.error(e)
      }

      return response
    }
  })

  VeeValidate.Validator.extend('confirmed_password', {
    getMessage: () => 'The passwords do not match',
    validate: (value, args) => {
      const field = document.querySelector(`[name="${args[0]}"]`)
      return !!(field && String(value) === field.value)
    }
  })

  VeeValidate.Validator.extend('valid_intl_phone', {
    getMessage: () => 'Invalid telephone number',
    validate: (value, ref) => {
      if (ref[0] && ref[0].toLowerCase() === 'br') {
        value = value.replace(/\D/g, '')
        if (value.length < 11) {
          return false
        }
      }

      return validatePhone(value, ref[0])
    }
  })

  VeeValidate.Validator.extend('latin_roman', {
    getMessage: () => 'Invalid Latin Roman',
    validate: (value, ref) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /[^a-zA-Z0-9 \'\-\º\ª\Á\á\À\à\Ă\ă\Â\â\Å\å\Ä\ä\Ã\ã\Ą\ą\Ā\ā\Ǽ\ǽ\Ḃ\ḃ\Ć\ć\Ĉ\ĉ\Č\č\Ċ\ċ\Ç\ç\Ď\ď\Ḋ\ḋ\Đ\đ\ð\É\é\È\è\Ĕ\ĕ\Ê\ê\Ě\ě\Ë\ë\Ė\ė\Ę\ę\Ē\ē\Ḟ\ḟ\ƒ\Ğ\ğ\Ĝ\ĝ\Ġ\ġ\Ģ\ģ\Ĥ\ĥ\H\Ħ\ħ\Í\í\Ì\ì\Ĭ\ĭ\Î\î\Ï\ï\Ĩ\ĩ\İ\i\Į\į\Ī\ī\I\ı\Ɨ\ɨ\Ĵ\ĵ\J\Ķ\ķ\Ĺ\ĺ\Ľ\ľ\Ļ\ļ\Ł\ł\Ł\ł\Ŀ\ŀ\Ṁ\ṁ\Ń\ń\Ň\ň\Ñ\ñ\Ņ\ņ\Ó\ó\Ò\ò\Ŏ\ŏ\Ô\ô\Ö\ö\Ő\ő\Õ\õ\Ø\ø\Ǿ\ǿ\Ō\ō\Ṗ\ṗ\Ŕ\ŕ\Ř\ř\Ŗ\ŗ\ß\Ś\ś\Ŝ\ŝ\Š\š\Ṡ\ṡ\Ş\ş\Ș\ș\Þ\þ\Ť\ť\T\Ṫ\ṫ\Ţ\ţ\Ț\ț\Ŧ\ŧ\Ú\ú\Ù\ù\Ŭ\ŭ\Û\û\Ů\ů\Ü\ü\Ű\ű\Ũ\ũ\Ų\ų\Ū\ū\Ẃ\ẃ\Ẁ\ẁ\Ŵ\ŵ\Ẅ\ẅ\Ý\ý\Ỳ\ỳ\Ŷ\ŷ\Y\Ÿ\ÿ\Ź\ź\Ž\ž\Ż\ż]+$/
      return !regex.test(value)
    }
  })

  VeeValidate.Validator.extend('b_date', {
    getMessage: () => 'Invalid date',
    validate: (value, ref) => {
      return Moment(ref[0]).isBefore(Moment(ref[1]))
    }
  })

  VeeValidate.Validator.extend('a_date', {
    getMessage: () => 'Invalid date',
    validate: (value, ref) => {
      return Moment(ref[0]).isAfter(Moment(ref[1]))
    }
  })

  VeeValidate.Validator.extend('max_date', {
    getMessage: () => 'Invalid date',
    validate: (value) => {
      const DATE_FORMAT = app.i18n.messages[app.i18n.locale].birthday_format
      return Moment(value, DATE_FORMAT).isBefore(Moment())
    }
  })

  VeeValidate.Validator.extend('valid_book_code', {
    getMessage: () => 'Invalid code',
    validate: async (value) => {
      const book = await app.$rentcarsApi.post('/v1/booking/view', { code: value }).catch((e) => { })
      return book !== undefined && book.data.hasOwnProperty('Success')
    }
  })

  VeeValidate.Validator.extend('validate_creditcard', {
    getMessage: () => 'Invalid card number',
    validate: (value, args) => {
      let valid = false
      const ParseCard = creditCardValidation.number(value)

      if (ParseCard && ParseCard.card && ParseCard.card.hasOwnProperty('type') && args.indexOf(ParseCard.card.type) >= 0) {
        valid = ParseCard.isPotentiallyValid && ParseCard.isValid
      }

      return valid
    }
  })

  VeeValidate.Validator.extend('validate_name', {
    getMessage: () => 'O nome não pode conter números e/ou caracteres especiais',
    validate: (value, args) => {
      return (/^[-‘, a-zA-ZÀ-ÖØ-öø-ÿ]+$/.test(value))
    }
  })

  VeeValidate.Validator.extend('validate_debitcard', {
    getMessage: () => 'Invalid debit number',
    validate: (value, args) => {
      let valid = false
      const ParseCard = creditCardValidation.number(value)

      if (ParseCard && ParseCard.card && ParseCard.card.hasOwnProperty('type') && args.indexOf(ParseCard.card.type) >= 0) {
        valid = ParseCard.isPotentiallyValid && ParseCard.isValid
      }

      return valid
    }
  })

  VeeValidate.Validator.extend('validate_tenant_years', {
    getMessage: () => 'Age less than 18 years',
    validate: (value, ref) => {
      return Moment.tz(Moment.tz('GMT').subtract(18, 'years').format(), 'GMT').isAfter(Moment(value, ref[0]).tz('GMT'))
    }
  })

  VeeValidate.Validator.extend('max_birth_year', {
    getMessage: () => 'Age more than 90 years',
    validate: (value, ref) => {
      return Moment.tz(Moment.tz('GMT').subtract(90, 'years').format(), 'GMT').isBefore(Moment(value, ref[0]).tz('GMT'))
    }
  })

  VeeValidate.Validator.extend('expired_card', {
    getMessage: () => 'Expired card',
    validate: (value) => {
      const currentDate = new Moment(`01/${value}`, 'DD/MM/YY')

      if (currentDate.year() < Moment().year() || currentDate.year() > Moment().year() + 11) {
        return false
      }

      if (currentDate.year() === Moment().year() && currentDate.month() < Moment().month()) {
        return false
      }

      return true
    }
  })

  VeeValidate.Validator.extend('valid_date_card', {
    getMessage: () => 'Invalid date',
    validate: (value) => {
      if (value.includes('/')) {
        const datePart = value.split('/')
        if (datePart[0] > 12 || datePart[0] <= 0) {
          return false
        }
      }
      return true
    }
  })

  VeeValidate.Validator.extend('date_format', {
    getMessage: () => 'Invalid date',
    validate: (value, ref) => {
      return Moment(value, ref[0]).isValid()
    }
  })
}
