import redirectConfig from '~/../config/redirect'

/**
 * Middleware para as rotas de locadoras (interpretadas como grupos).
 *
 * Permite fazer o redirecionamento de locadoras que tiveram o slug alterado.
 *
 * É para ser consderado como solução temporária, afinal, esta solução
 * se tornará cada vez mais inviável.
 */
export default function ({ app: { $routeResolver }, store, redirect, route }) {
  for (const ruleName in redirectConfig.rules) {
    if (ruleName === route.params.companySlug) {
      route.params.companySlug = redirectConfig.rules[ruleName].slug

      redirect(redirectConfig.rules[ruleName].code, $routeResolver(route.name.replace(`.${store.getters.getLocale}`, ''), store.getters.getLocale, true, route.params))
    }
  }
}
