<template>
  <div :class="getClassList()" :style="{ width: `${size}px`, height: `${size}px` }">
    <img :src="imagePath" :alt="imageAlt" :style="{ width: `${imageSize}px`, height: `${imageSize}px` }">
    <div v-if="outlineCircles >= 1" class="c1" />
    <div v-if="outlineCircles >= 2" class="c2" />
    <div v-if="outlineCircles >= 3" class="c3" />
  </div>
</template>
<script>
export default {
  name: 'CircleIcon',
  props: {
    imagePath: {
      type: String,
      default: '',
      required: false
    },
    imageSize: {
      type: Number,
      required: false,
      default: 24
    },
    imageAlt: {
      type: String,
      default: 'Star icon',
      required: false
    },
    outlineCircles: {
      type: Number,
      required: false,
      default: 3
    },
    size: {
      type: Number,
      required: false,
      default: 56
    },
    variant: {
      type: String,
      required: false,
      default: 'green'
    }
  },
  methods: {
    getClassList () {
      const cssClasses = ['icon', this.variant]
      return cssClasses.join(' ')
    }
  }
}
</script>
<style lang="scss" scoped>
$outside-circle-color: #F8F9FA;

.icon {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  mix-blend-mode: multiply;

  &.green {
    background: #F3FCF5 !important;
  }

  &.red {
    background: #FCEEEF !important;
  }

  &.orange {
    background: #FFF8EB !important;
  }

  .material-icons,
  img {
    color: $green-400;
    font-size: 25px;
    width: 25px;
    height: 25px;
    fill: $green-400;
  }

  .c1,
  .c2,
  .c3 {
    position: absolute;
    border: 1px solid $outside-circle-color;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 50%;
  }

  .c1 {
    width: calc(100% + 28px);
    height: calc(100% + 28px);
  }

  .c2 {
    width: calc(100% + 60px);
    height: calc(100% + 60px);
  }

  .c3 {
    width: calc(100% + 90px);
    height: calc(100% + 90px);
  }
}
</style>
