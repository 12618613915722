import insiderEnum from './InsiderEnum'
import insiderUtils from './InsiderUtils'

export default class ActionOnInsiderObject {
  static execute (insiderObject, typeOfObject, route) {
    if (ActionOnInsiderObject.objectsOperationUtmAndUrl().includes(typeOfObject)) {
      insiderObject.custom = {
        ...insiderObject.custom,
        url: insiderUtils.start().getUrl()
      }
    }
  }

  static objectsOperationUtmAndUrl () {
    return [
      insiderEnum.LOCATIONS,
      insiderEnum.RENTAL_COMPANY,
      insiderEnum.PROMOTTIONS,
      insiderEnum.CAMPAIGN_LP
    ]
  }
}
