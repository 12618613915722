export default {
  METRIC_KEY_NEW_PAYMENT: 'acesspage',
  METRIC_KEY_BOOKING_CONVERSION: 'bookingConversionRevenue',
  METRIC_KEY_ACCESS_PAGE: 'acesspage',
  METRIC_KEY_VIEW_CONFIGURE_PAGE: 'viewConfigurePage',
  METRIC_KEY_VIEW_PAYMENT_PAGE: 'viewPaymentPage',
  METRIC_KEY_RENTAL_COVER_CONVERSION: 'rentalcoverConversionRevenue2',
  METRIC_KEY_VIEW_NO_RESULTS: 'viewNoResults',
  METRIC_KEY_OPTIONALS_CONVERSION: 'optionalsIncludedConversion',
  METRIC_KEY_BOOKING_CONVERSION_PP: 'bookingConversionPp',
  METRIC_KEY_BOOKING_CONVERSION_ALL: 'bookingConversionAll',
  METRIC_KEY_VIEW_LOGIN_PAGE: 'viewLoginPage',
  METRIC_KEY_LOGIN_TO_PAYMENT: 'loginToPayment',
  METRIC_KEY_RENTPROTECTION_GENERAL_CONVERSION: 'nrBookingConversionPwa',
  METRIC_KEY_RENTPROTECTION_SERVICE_CONVERSION: 'nrProtectionIncludedConversionPwa',
  METRIC_KEY_RENTPROTECTION_GENERAL_PAYMENT: 'nrPaymentProtectionGeneralPwa',
  METRIC_KEY_RENTPROTECTION_SERVICE_PAYMENT: 'nrPaymentProtectionIncludedPwa',
  METRIC_KEY_RENTPROTECTION_GENERAL_PAYMENT_LOCAL: 'nrPaymentProtectionGeneralLocal',
  METRIC_KEY_RENTPROTECTION_SERVICE_PAYMENT_LOCAL: 'nrPaymentProtectionIncludedLocal',
  METRIC_KEY_RENTPROTECTION_GENERAL_CONVERSION_LOCAL: 'nrBookingConversionLocal',
  METRIC_KEY_RENTPROTECTION_SERVICE_CONVERSION_LOCAL: 'nrProtectionIncludedConversionLocal'
}
