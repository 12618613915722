import localforage from 'localforage'

const NAMESPACE = 'Search-'
const LastLocationsKey = 'LastLocations-'
const SearchDataKey = 'SearchData'

export const GetLastLocations = locale => localforage.getItem(NAMESPACE + LastLocationsKey + locale)

export const SetLastLocations = async (data, locale) => {
  await localforage.setItem(NAMESPACE + LastLocationsKey + locale, data).then((value) => {
    return value
  }).catch(() => {})
}

export const GetSearchData = locale => localforage.getItem(`${NAMESPACE}${SearchDataKey}-${locale}`)

export const SetSearchData = async (data) => {
  await localforage.setItem(`${NAMESPACE}${SearchDataKey}-${data.LOCALE}`, data).then((value) => {
    return value
  }).catch(() => {})
}

export default {
  GetLastLocations,
  SetLastLocations,
  GetSearchData,
  SetSearchData
}
