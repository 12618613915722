import VwoABTest from './dynamicTestPlataform/vwoABTest'
import vwoCampaignKeyEnum from './dynamicTestPlataform/vwoCampaignKeyEnum'
import vwoTrackKeyEnum from './dynamicTestPlataform/vwoTrackKeyEnum'
import vwoVariantEnum from './dynamicTestPlataform/vwoVariantEnum'
import generalConfigAbTest from '../helpers/vwo/generalConfigAbTest'

export default {
  mixins: [generalConfigAbTest],
  methods: {
    abTestPayment () {
      return true
    },
    async abAsyncConfirmation () {
      try {
        const user = await this.$store.getters['user/getLoggedUser']
        const userId = user?.id ?? 'LOGGED_OUT_ID'

        const vwoAbTest = new VwoABTest()
        const variantName = await vwoAbTest.getVwoVariationName(userId, vwoCampaignKeyEnum.ASYNC_CONFIRMATION)
        await vwoAbTest.setVwoTrackABTest(userId, vwoCampaignKeyEnum.ASYNC_CONFIRMATION, vwoTrackKeyEnum.METRIC_KEY_NEW_PAYMENT)

        return variantName === vwoVariantEnum.ASYNC_VARIANT
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    async abForceInteractionRentalCover () {
      try {
        return await this.isVariationAbTest(vwoCampaignKeyEnum.INTERACTION_WITH_RENTAL_COVER, vwoVariantEnum.FORCE_INTERACTION)
      } catch (e) {
        this.$bugsnag.notify(e)
        return false
      }
    }
  }
}
