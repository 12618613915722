import DOMPurify from 'dompurify'

export default {
  inserted (el, binding) {
    // Sanitize the HTML content
    const sanitizedHtml = DOMPurify.sanitize(binding.value, {
      ADD_ATTR: ['target']
    })

    // Set the element's innerHTML using sanitized content
    el.innerHTML = sanitizedHtml
  },
  update (el, binding) {
    // Perform sanitization again when the value updates
    const sanitizedHtml = DOMPurify.sanitize(binding.value, {
      ADD_ATTR: ['target']
    })

    el.innerHTML = sanitizedHtml
  }
}
