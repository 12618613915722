import AppModal from 'components/Modal/Default/Default.vue'

export default {
  name: 'BottomSheetV2',
  extends: AppModal,

  props: {
    id: {
      required: true,
      type: String
    },
    title: {
      required: false,
      type: String
    },
    description: {
      required: false,
      type: String
    },
    closeAction: {
      required: false,
      type: Function
    },
    canShowOverlay: {
      required: false,
      type: Boolean,
      default: false
    },
    showCloseButton: {
      required: false,
      type: Boolean,
      default: true
    },
    defaultPadding: {
      required: false,
      type: Boolean,
      default: false
    },
    hasFooter: {
      required: false,
      type: Boolean,
      default: false
    },
    blockClose: {
      required: false,
      type: Boolean,
      default: false
    },
    hiddenHeader: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  watch: {
    canShowOverlay (value) {
      setTimeout(() => {
        const bodyElement = document.querySelector('body')
        if (value) {
          bodyElement.style.overflow = 'hidden'
          bodyElement.style.height = '100vh'
        } else {
          bodyElement.removeAttribute('style')
        }
      }, 100)
    }
  }
}
