import InsiderObject from '../../services/Insider/InsiderObject'
import insiderEnum from '../../services/Insider/InsiderEnum'
import buildUserObject from '../../services/Insider/utils/buildUserObject'

export default {
  data () {
    return {
      insiderObjs: InsiderObject.start()
    }
  },

  methods: {
    switchCaseObjects (insiderObjectElement, typeOfObject) {
      const nameOfFunction = this._fromToObject(typeOfObject.toLowerCase())
      this.insiderObjs[nameOfFunction](insiderObjectElement)
    },

    _fromToObject (functionName) {
      const relation = {}

      relation[insiderEnum.HOME] = 'setHomeObject'
      relation[insiderEnum.RENTAL_COMPANY] = 'setDefaultObject'
      relation[insiderEnum.CAMPAIGN_LP] = 'setDefaultObject'
      relation[insiderEnum.PROMOTTIONS] = 'setDefaultObject'
      relation[insiderEnum.LOCATIONS] = 'setDefaultObject'
      relation[insiderEnum.SEARCH] = 'setListingPageObject'
      relation[insiderEnum.BASKET] = 'basketObject'
      relation[insiderEnum.UPDATE_USER_INFORMATION] = 'updateUserInformation'
      relation[insiderEnum.PRODUCT] = 'productObject'
      relation[insiderEnum.TRANSACTION_OBJECT] = 'transactionObject'

      return relation[functionName]
    },

    ifHasToUpdateUserInsiderOption (insiderObject, typeOfObject) {
      if (!window.insider_object) {
        window.insider_object = {}
      }

      if (this._isSearchObject(typeOfObject)) {
        if (window.insider_object && !window.insider_object.input) {
          window.insider_object = {
            ...window.insider_object,
            ...insiderObject
          }
        }
      }

      if (this._conditionToNotExecUserInsiderOption(typeOfObject)) {
        return buildUserObject({
          loggedUser: this.$store.getters['user/getLoggedUser'],
          typeOfObject,
          pageInsert: true,
          insiderObject: window.insider_object
        })
      }

      let returnOfUserObject = buildUserObject({
        loggedUser: this.$store.getters['user/getLoggedUser'],
        typeOfObject,
        insiderObject,
        pageInsert: true
      })

      if (!returnOfUserObject) {
        returnOfUserObject = {
          user: {
            custom: {
              request_device: insiderEnum.MOBILE,
              requestor_id: this.$store.getters.getMobicarRequestorID
            }
          }
        }
      }

      window.insider_object = {
        ...window.insider_object,
        ...returnOfUserObject
      }
    },

    _conditionToNotExecUserInsiderOption (typeOfObject) {
      return [
        insiderEnum.HOME,
        insiderEnum.SEARCH,
        insiderEnum.UPDATE_USER_INFORMATION,
        insiderEnum.PRODUCT,
        insiderEnum.BASKET
      ].includes(typeOfObject)
    },

    _isSearchObject (typeOfObject) {
      return [
        insiderEnum.SEARCH
      ].includes(typeOfObject.toLowerCase())
    }
  }
}
