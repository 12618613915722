import DOMPurify from 'dompurify'

export default {
  inserted (el, binding) {
    // Sanitize the HTML content
    const sanitizedHref = DOMPurify.sanitize(binding.value)
    // Set the element's href using sanitized content
    el.href = sanitizedHref
  },
  update (el, binding) {
    // Perform sanitization again when the value updates
    const sanitizedHref = DOMPurify.sanitize(binding.value)

    el.href = sanitizedHref
  }
}
