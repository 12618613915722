export const state = () => ({
  configureAirlineCode: null,
  configureAirlineName: null,
  configureFlightNumber: null,
  configureUrlFromChangeStore: null,
  configureUnavailableLocations: [],
  configureUnavailablePlans: [],
  configureUnavailableCoverages: [],
  configureParamsFromRoute: null,
  configV6: false,
  bookingDuplicity: null,
  bookingDuplicityOverride: false,
  bookingPaymentOptions: [],
  bookingClientNumber: null,
  isPartnerTAP: false,
  priceLock: null,
  asyncFoco: false,
  isPartnerTravelClub: false
})

export const actions = {
  SET_CONFIGURE_AIRLINE_CODE: ({ commit }, data) => {
    commit('SET_CONFIGURE_AIRLINE_CODE', data)
  },
  SET_CONFIGURE_AIRLINE_NAME: ({ commit }, data) => {
    commit('SET_CONFIGURE_AIRLINE_NAME', data)
  },
  SET_CONFIGURE_FLIGHT_NUMBER: ({ commit }, data) => {
    commit('SET_CONFIGURE_FLIGHT_NUMBER', data)
  },
  SET_URL_FROM_CHANGE_STORE: ({ commit }, data) => {
    commit('SET_URL_FROM_CHANGE_STORE', data)
  },
  SET_PARAMS_FROM_CONFIGURE_ROUTE: ({ commit }, data) => {
    commit('SET_PARAMS_FROM_CONFIGURE_ROUTE', data)
  },
  SET_BOOKING_DUPLICITY: ({ commit }, data) => {
    commit('SET_BOOKING_DUPLICITY', data)
  },
  SET_BOOKING_DUPLICITY_OVERRIDE: ({ commit }, data) => {
    commit('SET_BOOKING_DUPLICITY_OVERRIDE', data)
  },
  SET_BOOKING_PAYMENT_OPTIONS: ({ commit }, data) => {
    commit('SET_BOOKING_PAYMENT_OPTIONS', data)
  },
  ADD_UNAVAILABLE_LOCATION: ({ commit }, data) => {
    commit('ADD_UNAVAILABLE_LOCATION', data)
  },
  ADD_UNAVAILABLE_PLAN: ({ commit }, data) => {
    commit('ADD_UNAVAILABLE_PLAN', data)
  },
  ADD_UNAVAILABLE_COVERAGE: ({ commit }, data) => {
    commit('ADD_UNAVAILABLE_COVERAGE', data)
  },
  CLEAR_CONFIGURE_STORAGE: ({ commit }) => {
    commit('CLEAR_CONFIGURE_STORAGE')
  },
  SET_BOOKING_CLIENT_NUMBER: ({ commit }, data) => {
    commit('SET_BOOKING_CLIENT_NUMBER', data)
  },
  SET_BOOKING_PARTNER_TAP: ({ commit }, data) => {
    commit('SET_BOOKING_PARTNER_TAP', data)
  },
  SET_PRICE_LOCK: ({ commit }, data) => {
    commit('SET_PRICE_LOCK', data)
  },
  SET_IS_ASYNC_FOCO: ({ commit }, data) => {
    commit('SET_IS_ASYNC_FOCO', data)
  },
  SET_BOOKING_PARTNER_TRAVEL_CLUB: ({ commit }, data) => {
    commit('SET_BOOKING_PARTNER_TRAVEL_CLUB', data)
  }
}

export const mutations = {
  SET_CONFIGURE_AIRLINE_CODE: (state, data) => {
    state.configureAirlineCode = data
  },
  SET_CONFIGURE_AIRLINE_NAME: (state, data) => {
    state.configureAirlineName = data
  },
  SET_CONFIGURE_FLIGHT_NUMBER: (state, data) => {
    state.configureFlightNumber = data
  },
  SET_URL_FROM_CHANGE_STORE: (state, data) => {
    state.configureUrlFromChangeStore = data
  },
  SET_PARAMS_FROM_CONFIGURE_ROUTE: (state, data) => {
    state.configureParamsFromRoute = data
  },
  SET_BOOKING_DUPLICITY: (state, data) => {
    state.bookingDuplicity = data
  },
  SET_BOOKING_DUPLICITY_OVERRIDE: (state, data) => {
    state.bookingDuplicityOverride = data
  },
  SET_BOOKING_PAYMENT_OPTIONS: (state, data) => {
    state.bookingPaymentOptions = data
  },
  ADD_UNAVAILABLE_LOCATION: (state, data) => {
    state.configureUnavailableLocations.push(data)
  },
  ADD_UNAVAILABLE_PLAN: (state, data) => {
    state.configureUnavailablePlans.push(data)
  },
  ADD_UNAVAILABLE_COVERAGE: (state, data) => {
    state.configureUnavailableCoverages.push(data)
  },
  CLEAR_CONFIGURE_STORAGE: (state) => {
    state.configureAirlineCode = null
    state.configureFlightNumber = null
    state.configureUrlFromChangeStore = null
    state.configureUnavailableLocations = []
    state.configureUnavailablePlans = []
    state.configureUnavailableCoverages = []
    state.configureParamsFromRoute = null
    state.bookingDuplicity = null
    state.bookingDuplicityOverride = false
    state.bookingPaymentOptions = []
  },
  SET_BOOKING_CLIENT_NUMBER: (state, data) => {
    state.bookingClientNumber = data
  },
  SET_BOOKING_PARTNER_TAP: (state, data) => {
    state.isPartnerTAP = data
  },
  SET_PRICE_LOCK: (state, data) => {
    state.priceLock = data
  },

  SET_IS_ASYNC_FOCO: (state, data) => {
    state.asyncFoco = data
  },

  SET_BOOKING_PARTNER_TRAVEL_CLUB: (state, data) => {
    state.isPartnerTravelClub = data
  }
}

export const getters = {
  getConfigureAirlineCode: state => state.configureAirlineCode,

  getConfigureAirlineName: state => state.configureAirlineName,

  getConfigureFlightNumber: state => state.configureFlightNumber,

  getConfigureUrlChangeStore: state => state.configureUrlFromChangeStore,

  getUnavailableLocations: state => state.configureUnavailableLocations,

  getUnavailablePlans: state => state.configureUnavailablePlans,

  getUnavailableCoverages: state => state.configureUnavailableCoverages,

  getParamsFromConfigureRoute: state => state.configureParamsFromRoute,

  getBookingDuplicity: state => state.bookingDuplicity,

  getBookingDuplicityOverride: state => state.bookingDuplicityOverride,

  getBookingPaymentOptions: state => state.bookingPaymentOptions,

  getBookingClientNumber: state => state.bookingClientNumber,

  getPartnerTap: state => state.isPartnerTAP,

  getPriceLock: state => state.priceLock,

  getIsAsyncFoco: state => state.asyncFoco,

  getPartnerTravelClub: state => state.isPartnerTravelClub
}
