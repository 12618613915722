import { format } from 'currency-formatter'

export default class OptionalsFactory {
  /**
   * @desc Represents a OptionalsFactory
   * @constructor
   */
  constructor(optionals) {
    return this.legacyOPtionals(optionals)
  }

  /**
   * @desc normalize optionals
   * @param {Array} optionals
   * @return {Array}
   */
  legacyOPtionals(optionals) {
    if (optionals == null) {
      return []
    }

    const newOptionals = optionals.map((obj) => {
      return {
        Warning: obj.Aviso,
        Calculation: obj.Calculo,
        Code: parseInt(obj.Codigo, 10),
        Description: obj.Descricao,
        Included: obj.Incluso.toLowerCase() === 'true',
        IncludedPlan: obj.InclusoPlano.toLowerCase() === 'true',
        Currency: obj.Moeda,
        CurrencyCode: obj.SimboloMoeda,
        OriginalCurrency: obj.MoedaOriginal || null,
        Name: obj.Nome,
        Required: obj.Obrigatorio.toLowerCase() === 'true',
        Quantity: obj.InclusoPlano.toLowerCase() === 'true' ? 1 : parseInt(obj.Quantidade),
        MaxQuantity: obj.QuantidadeMaxima != null && obj.InclusoPlano.toLowerCase() !== 'true' ? obj.QuantidadeMaxima : 1,
        MinQuantity: obj.InclusoPlano.toLowerCase() === 'true' ? 1 : 0,
        Reference: obj.Referencia,
        Amount: parseFloat(obj.Valor),
        DailyAmount: parseFloat(obj.ValorDia),
        OriginalAmount: parseFloat(obj.ValorOriginal) || null,
        TotalAmount: parseFloat(obj.ValorTotal),

        FormattedDailyAmount: format(parseFloat(obj.ValorDia), {
          code: obj.Moeda,
          format: '%v'
        }),

        FormattedTotalAmount: format(parseFloat(obj.ValorTotal), {
          code: obj.Moeda,
          format: '%v'
        })
      }
    })

    return newOptionals
  }
}
