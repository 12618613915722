import { mapGetters, mapActions } from 'vuex'
import BottomSheet from 'components/Modal/BottomSheetV2/BottomSheetV2.vue'
import CircleIcon from 'components/Icons/CircleIcon.vue'
import { AUTH_MODAL_STEPS } from './AuthEnum'

export const AuthModalProperties = {
  ...mapGetters({
    authModalVisible: 'user/isAuthModalVisible',
    authModalStep: 'user/getAuthModalStep',
    authModalStepHistory: 'user/getAuthModalStepHistory',
    authModalFrom: 'user/getAuthModalFrom',
    authModalVerifiedEmail: 'user/getAuthModalVerifiedEmail'
  })
}

export const AuthModalMethods = {
  ...mapActions({
    closeAuthModal: 'user/CLOSE_AUTH_MODAL',
    backAuthModal: 'user/BACK_AUTH_MODAL_STEP',
    openAuthModal: 'user/OPEN_AUTH_MODAL',
    changeStepAuthModal: 'user/SET_AUTH_MODAL_STEP',
    updateVerifiedEmail: 'user/SET_AUTH_MODAL_EMAIL'
  })
}

export default {
  name: 'Auth',
  components: {
    BottomSheet,
    CircleIcon,
    login: () => import('./Steps/Login/Login.vue'),
    register: () => import('./Steps/Register/Register.vue'),
    greetings: () => import('./Steps/Greetings/Greetings.vue'),
    recoverPassword: () => import('./Steps/RecoverPassword/RecoverPassword.vue'),
    agreeTerms: () => import('./Forms/AgreeTerms/AgreeTerms.vue')
  },
  computed: {
    ...AuthModalProperties
  },

  methods: {
    ...AuthModalMethods,

    onEmailVerifyNext ({ email, exist }) {
      this.email = email
      this.changeStepAuthModal(exist ? 'login' : 'register')
    },

    handleQueryChanges (searchParams) {
      const hasTokenOnUrl = (searchParams.get('s') === 'recover-password' && searchParams.get('t')) ||
        searchParams.get('Token')

      if (hasTokenOnUrl) {
        this.openAuthModal({ step: 'recover-password', from: 'default' })
      }

      if (searchParams.get('auth')) {
        this.openAuthModal()
      }
    },

    stepCanShowAgreeTerms () {
      const steps = [
        AUTH_MODAL_STEPS.GREETINGS
      ]

      return steps.includes(this.authModalStep)
    }
  },
  created () {
    if (process.browser) {
      const searchParams = new URLSearchParams(window.location.search)
      this.handleQueryChanges(searchParams)
    }
  }
}
