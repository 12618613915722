export const state = () => ({
  config: {},
  showPhoneNumber: true
})

export const actions = {
  SET_CONFIG ({ commit }, value) {
    commit('SET_CONFIG', value)
  },
  SET_SHOW_PHONE_NUMBER ({ commit }, value) {
    commit('SET_SHOW_PHONE_NUMBER', value)
  }
}

export const mutations = {
  SET_CONFIG (state, value) {
    state.config = value
  },
  SET_SHOW_PHONE_NUMBER: (state, value) => {
    state.showPhoneNumber = value
  }
}

export const getters = {
  /**
   * @desc return an object with footer config
   * @returns {Object}
   */
  getConfig: state => state.config,
  getShowPhoneNumber: state => state.showPhoneNumber
}
