import moment from 'moment-timezone'
import remove from 'lodash/remove'

export default class SearchUrlService {
  constructor (context, url, plan) {
    this.store = context.$store || context.store

    url = (url == null) ? [] : url.split('-')

    this.pickupCode = url[0] || '0'
    this.timestampPickup = url[1] || '0'
    this.returnCode = url[2] || '0'
    this.timestampReturn = url[3] || '0'
    this.rentalAgencyCode = url[4] || '0'
    this.categoryCode = url[5] || '0'
    this.groupCode = url[6] || '0'
    this.protectionCode = url[7] || '0'
    this.pickupStoreCode = url[8] || '0'
    this.returnStoreCode = url[9] || '0'
    this.optionalsCodes = url[10] ? url[10].split('|') : ['0']
    this.rentalCover = url[11] || '0'

    this.plan = plan || '0'
    this.currency = this.store.getters.getCurrency
    this.additionalServices = []

    this.setAdditionalServicesFromUrl()
  }

  toRest () {
    const PickupMoment = moment.unix(this.timestampPickup)
    const DropoffMoment = moment.unix(this.timestampReturn)

    return {
      CodigoLocalidadeRetirada: this.pickupCode,
      DataRetirada: PickupMoment.format('YYYY-MM-DD'),
      HoraRetirada: PickupMoment.format('HH:mm'),

      CodigoLocalidadeDevolucao: this.returnCode,
      DataDevolucao: DropoffMoment.format('YYYY-MM-DD'),
      HoraDevolucao: DropoffMoment.format('HH:mm'),

      CodigoLocadora: this.rentalAgencyCode,
      CodigoCategoria: this.categoryCode,
      CodigoGrupo: this.groupCode,
      CodigoProtecao: this.protectionCode,
      CodigoLojaRetirada: this.pickupStoreCode,
      CodigoLojaDevolucao: this.returnStoreCode,
      Opcional: this.treatOptionals() || '0',
      RentalCover: this.rentalCover,

      CodigoPlano: this.plan,
      Moeda: this.currency,
      MobicarRequestorIDReferrer: this.store.getters.getMobicarRequestorIDReferrer,
      NewResultsMethod: true
    }
  }

  setAdditionalServicesFromUrl () {
    try {
      const windowUrl = window.location.href

      const url = new URL(windowUrl)
      url.searchParams
        .getAll('additionalServices[]')
        .map(item => ({ service: item, value: true }))
        .forEach((item) => {
          this.setAdditionalService(item)
        })
    } catch (error) {
      this.additionalServices = []
    }
  }

  setAdditionalService (data) {
    try {
      let additionalServices = this.additionalServices

      if (additionalServices?.includes(data.service) && !data.value) {
        additionalServices = additionalServices.filter(item => item !== data.service)
      }

      if (data.value) {
        additionalServices.push(data.service)
      }

      this.additionalServices = additionalServices
    } catch (error) {
      this.additionalServices = []
    }
  }

  getQueryParams () {
    return { 'additionalServices[]': this.additionalServices }
  }

  toString () {
    const urlParams = [
      this.pickupCode,
      this.timestampPickup,
      this.returnCode,
      this.timestampReturn,
      this.rentalAgencyCode,
      this.categoryCode,
      this.groupCode,
      this.protectionCode,
      this.pickupStoreCode,
      this.returnStoreCode,
      this.treatOptionals(),
      this.rentalCover
    ]

    const finalUrl = urlParams.join('-')

    return finalUrl
  }

  treatOptionals () {
    return remove(this.optionalsCodes, value => parseInt(value, 10) !== 0).join('|') || 0
  }

  makeSearchListRoute () {
    this.optionalsCodes = ['0']
    this.categoryCode = '0'
    this.groupCode = '0'
    this.protectionCode = '0'
    this.rentalAgencyCode = '0'
    this.pickupStoreCode = '0'
    this.returnStoreCode = '0'
  }
}
