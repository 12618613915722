import { format } from 'currency-formatter'

export default class CoverageFactory {
  /**
   * @desc Represents a CoverageFactory
   * @constructor
   */
  constructor(CoverageNode) {
    if (Array.isArray(CoverageNode)) {
      return CoverageNode.map(value => this.legacyCoverageNormalization(value))
    } else {
      return this.legacyCoverageNormalization(CoverageNode)
    }
  }

  /**
   * @desc normalize coverages
   * @param {Object} CoverageNode
   * @return {Object}
   */
  legacyCoverageNormalization(CoverageNode) {
    const Items = CoverageNode.hasOwnProperty('Items') ? CoverageNode.Items : CoverageNode.Itens

    return {
      Code: CoverageNode.Codigo,
      Name: CoverageNode.Nome,
      Currency: CoverageNode.Moeda,
      CurrencyCode: CoverageNode.SimboloMoeda,
      Amount: parseFloat(CoverageNode.Valor),
      DailyAmount: parseFloat(CoverageNode.ValorDia),
      FormattedDailyAmount: format(parseFloat(CoverageNode.ValorDia), {
        code: CoverageNode.Moeda,
        format: '%v'
      }),
      TotalAmount: parseFloat(CoverageNode.ValorTotal),
      FormattedTotalAmount: format(parseFloat(CoverageNode.ValorTotal), {
        code: CoverageNode.Moeda,
        format: '%v'
      }),
      Calculation: CoverageNode.Calculo,
      Required: CoverageNode.Obrigatorio.toLowerCase() === 'true',
      Included: CoverageNode.Incluso.toLowerCase() === 'true',
      IncludedPlan: CoverageNode.InclusoPlano.toLowerCase() === 'true',
      SecurityDepositAmount: CoverageNode.ValorCaucao,
      SecurityDeposit: CoverageNode.Caucao,
      Excess: CoverageNode.Franquia,
      ExcessAmount: CoverageNode.ValorFranquia,
      Items: this.treatItems(Items)
    }
  }

  /**
   * @desc treats items
   * @param {Array} items
   * @return {Array}
   */
  treatItems(items) {
    const newItems = items.map((value) => {
      const item = {
        Symbol: value.Sigla,
        Title: value.Titulo,
        Description: value.Descricao,
        DescriptionAdm: value.DescricaoAdmin || null,
        Type: null
      }

      if (value.Tipo) {
        item.Type = {
          Name: value.Tipo.Nome,
          Symbol: value.Tipo.Sigla,
          Description: value.Tipo.Descricao,
          Type: value.Tipo.Tipo,
          Insurance: value.Tipo.Franquia.toLowerCase() === 'true'
        }
      }

      return item
    })

    return newItems
  }
}
