import insiderEnum from './InsiderEnum'
import StoreDataInteractionInputDTO from './dto/StoreDataInteractionInputDTO'

export default class StoreDataInteractions {
  static instance = false

  eventSession

  constructor(config = null) {
    if ((!config) || (config !== 'start') || StoreDataInteractions.instance) {
      throw new Error(insiderEnum.ERROR_CONFIG_MSG)
    }
  }

  static start() {
    if (!StoreDataInteractions.instance) {
      StoreDataInteractions.instance = new StoreDataInteractions('start')
    }

    return StoreDataInteractions.instance
  }

  verifyIfHasToSet(params) {
    return this.setOfElementsThatHasSet().includes(params.insiderEventName) &&
                params.totalAmountWithoutAdditionalServices
  }

  verifyIfHasToGetInput(params) {
    return this.setOfElementsThatHasGetInput().includes(params.insiderEventName)
  }

  inputDataToSet(params) {
    const storeDataInteraction = StoreDataInteractionInputDTO.factoryMySelf()
    storeDataInteraction.totalAmountWithoutAddServices = params.totalAmountWithoutAdditionalServices

    return storeDataInteraction
  }

  insertDataToParams(store, params) {
    params.storeData = store
  }

  setOfElementsThatHasSet() {
    return [
      insiderEnum.PRODUCT_DETAIL_PAGE_VIEW,
      insiderEnum.VIEWED_PAYMENT_STEP
    ]
  }

  setOfElementsThatHasGetInput() {
    return [insiderEnum.PURCHASE]
  }
}
