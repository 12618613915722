export default {
  VARIANT: 'variante',
  VARIANT2: 'variante2',
  DEFAULT: 'default',
  NEW_FLOW: 'NewListConfigureFlow',
  ASYNC_VARIANT: 'AsyncVariant',
  FORCE_INTERACTION: 'forceInteraction',
  CONTROL: 'Control',
  UPGRADE: 'Upgrade',
  FORCED_SELECTED: 'forcedSelected',
  RENTPROTECTION_CONTROL: 'Control',
  RENTPROTECTION_VARIANT: 'RentProtectionPWA',
  RENTPROTECTION_VARIANT_LOCAL: 'RentProtectionLOCAl'
}
