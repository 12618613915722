export default class SupplierFactory {
  /**
   * @desc Represents a SupplierFactory
   * @constructor
   */
  constructor(suppliers) {
    const newSuppliers = suppliers.map((value) => {
      return this.legacyConfigurationNormalization(value)
    })

    return newSuppliers
  }

  /**
   * @desc normalize configuration
   * @param {object} supplier
   * @return {object}
   */
  legacyConfigurationNormalization(supplier) {
    return {
      LocationCode: supplier.Localidade.Codigo,
      Flag: this.treatFlag(supplier.Bandeira),
      Address: this.treatAddress(supplier.Endereco),
      Store: this.treatStore(supplier.Loja),
      Service: this.treatService(supplier.Servico),
      Phones: this.treatPhones(supplier.Telefone),
      Time: this.treatTime(supplier.Horario)
    }
  }

  /**
   * @desc treat flag
   * @param {object} flag
   * @return {object}
   */
  treatFlag(flag) {
    return {
      Ccode: flag.Codigo,
      Image: flag.Imagem,
      Logo: flag.LogoMarca,
      Name: flag.Nome,
      Recommended: flag.Recomendacao.toLowerCase() === 'true',
      Reference: flag.Referencia
    }
  }

  /**
   * @desc treats address
   * @param {object} address
   * @return {object}
   */
  treatAddress(address) {
    return {
      ZipCode: address.Cep,
      District: address.Bairro,
      City: address.Cidade,
      Complement: address.Complemento,
      Address: address.Endereco,
      State: {
        Code: address.UF,
        Name: address.Estado
      },
      Country: {
        Code: address.Codigo,
        Name: address.Pais
      },
      Latitude: address.Lat,
      Longitude: address.Lon
    }
  }

  /**
   * @desc treats store
   * @param {object} store
   * @return {object}
   */
  treatStore(store) {
    return {
      Code: store.Codigo,
      Name: store.Nome,
      IATA: store.IATA,
      IsAirport: store.Aeroporto.toLowerCase() === 'true',
      Included: store.Incluso.toLowerCase() === 'true',
      I18nPlace: store.LocalidadeI18n
    }
  }

  /**
   * @desc treats service
   * @param {object} service
   * @return {object}
   */
  treatService(service) {
    return {
      Code: service.Codigo,
      Name: service.Nome,
      Pickup: service.Retirada,
      Dropoff: service.Devolucao,
      IsShuttle: service.Shuttle.toLowerCase() === 'true'
    }
  }

  /**
   * @desc treats phones
   * @param {Array} phones
   * @return {Array}
   */
  treatPhones(phones) {
    return phones ? phones.map((value) => {
      return {
        Number: value.Numero
      }
    }) : []
  }

  /**
   * @desc treats times
   * @param {Array} times
   * @return {Array}
   */
  treatTime(times) {
    return times ? times.map((value) => {
      return {
        Day: value.Dia,
        Init: value.Inicio,
        End: value.Fim
      }
    }) : []
  }
}
