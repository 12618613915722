import AppModal from 'components/Modal/Default/Default.vue'

export default {
  name: 'BottomSheet',
  extends: AppModal,

  props: {
    id: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    },
    description: {
      required: false,
      type: String
    },
    closeAction: {
      required: false,
      type: Function
    }
  }
}
