export default class ItineraryFactory {
  /**
   * @desc Represents a ItineraryFactory
   * @constructor
   */
  constructor(itinerary) {
    this.itinerary = itinerary
    return this.treatItinerary()
  }

  /**
   * @desc treats itinerary
   * @return {object}
   */
  treatItinerary() {
    const itinerary = {}

    itinerary.Pickup = this.treatPickup()
    itinerary.Return = this.treatReturn()
    itinerary.Daily = parseInt(this.itinerary.Diarias, 10)
    itinerary.ExtraHours = parseInt(this.itinerary.HorasExcedentes, 10)
    itinerary.DaysAdvance = parseInt(this.itinerary.DiasAntecedencia, 10)
    itinerary.MinimumAge = this.itinerary.IdadeMinima
    itinerary.IsDomestic = this.itinerary.Retirada.Pais.Codigo === 1

    return itinerary
  }

  /**
   * @desc treats pickup data
   * @return {object}
   */
  treatPickup() {
    const pickupData = {}

    pickupData.IATA = this.itinerary.Retirada.Aeroporto.IATA

    pickupData.Place = {
      Code: this.itinerary.Retirada.Localidade.Codigo,
      Name: this.itinerary.Retirada.Localidade.Nome,
      Type: this.itinerary.Retirada.Localidade.Tipo,
      CanonicalNameWithPreposition: this.itinerary.Retirada.Localidade.NomeCanonicoComPreposicao
    }

    pickupData.City = {
      Code: this.itinerary.Retirada.Cidade.Codigo,
      Name: this.itinerary.Retirada.Cidade.Nome,
      NamePtBr: this.itinerary.Retirada.Cidade.NomePtBr
    }

    pickupData.State = {
      Code: this.itinerary.Retirada.Estado.Codigo,
      Name: this.itinerary.Retirada.Estado.Nome,
      UF: this.itinerary.Retirada.Estado.UF,
      NamePtBr: this.itinerary.Retirada.Estado.NomePtBr
    }

    pickupData.Country = {
      Code: this.itinerary.Retirada.Pais.Codigo,
      Name: this.itinerary.Retirada.Pais.Nome,
      NamePtBr: this.itinerary.Retirada.Pais.NomePtBr,
      ISO: this.itinerary.Retirada.Pais.ISO,
      initials: this.itinerary.Retirada.Pais.Sigla
    }

    pickupData.Continent = {
      Code: this.itinerary.Retirada.Continente.Codigo,
      Name: this.itinerary.Retirada.Continente.Nome
    }

    pickupData.Date = this.itinerary.Retirada.Data
    pickupData.Time = this.itinerary.Retirada.Horario

    return pickupData
  }

  /**
   * @desc treats return data
   * @return {object}
   */
  treatReturn() {
    const returnData = {}

    returnData.IATA = this.itinerary.Devolucao.Aeroporto.IATA

    returnData.Place = {
      Code: this.itinerary.Devolucao.Localidade.Codigo,
      Name: this.itinerary.Devolucao.Localidade.Nome,
      Type: this.itinerary.Devolucao.Localidade.Tipo
    }

    returnData.City = {
      Code: this.itinerary.Devolucao.Cidade.Codigo,
      Name: this.itinerary.Devolucao.Cidade.Nome,
      NamePtBr: this.itinerary.Devolucao.Cidade.NomePtBr
    }

    returnData.State = {
      Code: this.itinerary.Devolucao.Estado.Codigo,
      Name: this.itinerary.Devolucao.Estado.Nome,
      UF: this.itinerary.Devolucao.Estado.UF
    }

    returnData.Country = {
      Code: this.itinerary.Devolucao.Pais.Codigo,
      Name: this.itinerary.Devolucao.Pais.Nome,
      NamePtBr: this.itinerary.Devolucao.Pais.NomePtBr,
      initials: this.itinerary.Devolucao.Pais.Sigla
    }

    returnData.Continent = {
      Code: this.itinerary.Devolucao.Continente.Codigo,
      Name: this.itinerary.Devolucao.Continente.Nome
    }

    returnData.Date = this.itinerary.Devolucao.Data
    returnData.Time = this.itinerary.Devolucao.Horario

    return returnData
  }
}
