import slugifyFunction from 'slugify'

function cpfFormatter(value) {
  // Remove tudo o que não é dígito
  value = value.replace(/\D/g, '')

  // Coloca um ponto entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{3})(\d)/, '$1.$2')

  // Coloca um ponto entre o terceiro e o quarto dígitos de novo (para o segundo bloco de números)
  value = value.replace(/(\d{3})(\d)/, '$1.$2')

  // Coloca um hífen entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

  return value
}

function zipCodeFormatter(value) {
  // Remove tudo o que não é dígito
  value = value.replace(/\D/g, '')

  // Coloca um ponto entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{2})(\d)/, '$1.$2')

  // Coloca um hífen entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{3})(\d{1,3})$/, '$1-$2')

  return value
}

export default ({ store, route, app }, inject) => {
  const helpers = Object.assign({}, app.$helpers, {
    slugify: (text) => {
      return slugifyFunction(text)
    },

    cpfFormatter: value => cpfFormatter(value),
    zipCodeFormatter: value => zipCodeFormatter(value),

    treatName: (names, suffix = '') => {
      const newNames = {}
      let tagEs = 'es_ar'
      if (names.hasOwnProperty('es')) {
        tagEs = 'es'
      }
      if (suffix === '_slug') {
        newNames.es = names[tagEs + suffix]
        newNames['es-mx'] = names['es_mx' + suffix]
        newNames['es-cl'] = names['es_cl' + suffix]
        newNames['es-ar'] = names['es_ar' + suffix]
        newNames['es-co'] = names['es_co' + suffix]
        newNames.en = names['en_us' + suffix]
        newNames['pt-br'] = names['pt_br' + suffix]
        newNames['pt-pt'] = names['pt_pt' + suffix]
        newNames['it-it'] = names['it_it' + suffix]
        newNames['fr-fr'] = names['fr_fr' + suffix]
        newNames['en-gb'] = names['en_gb' + suffix]
        newNames['nl-nl'] = names['nl_nl' + suffix]
        newNames['de-de'] = names['de_de' + suffix]
        newNames['fr-ca'] = names['fr_ca' + suffix]
      } else {
        newNames.es = names[tagEs + suffix]
        newNames['es-mx'] = names['es_mx' + suffix]
        newNames['es-cl'] = names['es_cl' + suffix]
        newNames['es-ar'] = names['es_ar' + suffix]
        newNames['es-co'] = names['es_co' + suffix]
        newNames.en = names['en_us' + suffix]
        newNames['pt-br'] = names['pt_br' + suffix]
        newNames['pt-pt'] = names['pt_pt' + suffix]
        newNames['it-it'] = names['it_it' + suffix]
        newNames['fr-fr'] = names['fr_fr' + suffix]
        newNames['en-gb'] = names['en_gb' + suffix]
        newNames['nl-nl'] = names['nl_nl' + suffix]
        newNames['de-de'] = names['de_de' + suffix]
        newNames['fr-ca'] = names['fr_ca' + suffix]
      }
      return newNames
    },

    localeForRequest: (localeParam = null) => {
      const locale = localeParam || store.getters.getLocale

      if (locale === 'pt-br') {
        return 'pt_BR'
      } else if (locale === 'es-mx') {
        return 'es_MX'
      } else if (locale === 'es-cl') {
        return 'es_CL'
      } else if (locale === 'es-ar') {
        return 'es_AR'
      } else if (locale === 'es-co') {
        return 'es_CO'
      } else if (locale === 'es') {
        return 'es_ES'
      } else if (locale === 'de-de') {
        return 'de_DE'
      } else if (locale === 'pt-pt') {
        return 'pt_PT'
      } else if (locale === 'en-gb') {
        return 'en_GB'
      } else if (locale === 'nl-nl') {
        return 'nl_NL'
      } else if (locale === 'it-it') {
        return 'it_IT'
      } else if (locale === 'fr-fr') {
        return 'fr_FR'
      } else if (locale === 'fr-ca') {
        return 'fr_CA'
      }
      return 'en_US'
    },

    localeForTranslation: () => {
      const locale = store.getters.getLocale
      if (locale === 'es-mx') {
        return 'es'
      }
      return locale
    },

    localeForStaticPages: () => store.getters.getLocale,

    getLocaleFromPath: () => {
      const LocaleInPath = route.fullPath.split('/')[1]
      // eslint-disable-next-line prefer-regex-literals
      const AvailableLocaleExpr = new RegExp(/(en|es|es-mx|es-cl|es-ar|es-co|pt-br|pt-pt|en-gb|nl-nl|it-it|fr-fr|de-de|fr-ca)/)

      return AvailableLocaleExpr.test(LocaleInPath) ? LocaleInPath : 'en'
    },

    replaceSpecialChars: (value) => {
      return value.replace(/[^a-zA-Z0-9 ºªÁáÀàĂăÂâÅåÄäÃãĄąĀāǼǽḂḃĆćĈĉČčĊċÇçĎďḊḋĐđðÉéÈèĔĕÊêĚěËëĖėĘęĒēḞḟƒĞğĜĝĠġĢģĤĥHĦħÍíÌìĬĭÎîÏïĨĩİiĮįĪīIıƗɨĴĵJĶķĹĺĽľĻļŁłŁłĿŀṀṁŃńŇňÑñŅņÓóÒòŎŏÔôÖöŐőÕõØøǾǿŌōṖṗŔŕŘřŖŗßŚśŜŝŠšṠṡŞşȘșÞþŤťTṪṫŢţȚțŦŧÚúÙùŬŭÛûŮůÜüŰűŨũŲųŪūẂẃẀẁŴŵẄẅÝýỲỳŶŷYŸÿŹźŽžŻż]+$/, '')
    },

    onlyNumbers: value => value.toString().replace(/[^0-9]/g, '')
  })

  inject('helpers', helpers)
}
