
export default {
  methods: {
    eventSelectItem (item) {
      try {
        const eventItem = new SelectItem(item)
        this.eventSend(eventItem, 'select_item')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventViewListItem (item) {
      try {
        const eventItem = new ViewItemList(item)
        this.eventSend(eventItem, 'view_item_list')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventAddToCart (item) {
      try {
        const eventItem = new AddtoCartItem(item)
        this.eventSend(eventItem, 'add_to_cart')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventRemoveToCart (item) {
      try {
        const eventItem = new AddtoCartItem(item)
        this.eventSend(eventItem, 'remove_to_cart')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventBeginCheckout (item) {
      try {
        const eventItem = new AddtoCartItem(item)
        this.eventSend(eventItem, 'begin_checkout')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventPurchase (item) {
      try {
        const eventItem = new Purchase(item)
        this.eventSend(eventItem, 'purchase')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventLogin (item) {
      try {
        const firstLetter = item.charAt(0).toUpperCase()
        const restOfEmail = item.slice(1)
        const LoginMethod = firstLetter + restOfEmail

        const eventItem = new Login({
          method: LoginMethod
        })
        this.eventSend(eventItem, 'login')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventSearch (item) {
      try {
        const eventItem = new Search(item)
        this.eventSend(eventItem, 'search')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventSignUp (item) {
      try {
        const eventItem = new SignUp(item)
        this.eventSend(eventItem, 'sign_up')
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    eventViewPromotionUpgrade (item) {
      const eventItem = new PromotionUpgrade(item)
      this.eventSend(eventItem, 'view_promotion')
    },
    eventSelectPromotionUpgrade (item) {
      const eventItem = new PromotionUpgrade(item)
      this.eventSend(eventItem, 'select_promotion')
    },
    eventPaymentInfo (item) {
      const eventItem = new PaymentInfo(item)
      this.eventSend(eventItem, 'add_payment_info')
    },
    eventVwoPdRemoval (item) {
      const eventItem = new VwoInfo(item)
      this.eventSend(eventItem, 'vwo_info')
    },
    sendEventClickCancelBooking(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id
          },
          'click_cancel_booking'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventSelectCancelReason(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id,
            booking_cancel_reason: data.booking_cancel_reason
          },
          'select_cancel_reason'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventEnterCancelDescription(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id,
            booking_cancel_description: data.booking_cancel_description
          },
          'enter_cancel_description'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventConfirmCancelBooking(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id,
            booking_cancel_confirm: data.booking_cancel_confirm
          },
          'confirm_cancel_booking'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventBookingView(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id
          },
          'view_booking'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventClickEditBooking(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id
          },
          'click_edit_booking'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventSelectEditType(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id,
            booking_change_type: data.booking_change_type
          },
          'select_edit_type'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventEditBookingDate(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id,
            booking_datetime_changed: data.booking_datetime_changed
          },
          'edit_booking_date'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventViewEditSimulator(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id,
            booking_change_automated: data.booking_change_automated
          },
          'view_edit_simulator'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    sendEventPurchaseEdit(data) {
      try {
        this.eventSend(
          {
            customer_id: data.customer_id,
            booking_code: data.booking_id
          },
          'purchase_edit'
        )
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    validateHasGA4Values (item) {
      return item &&
              item.GA4 &&
              item.GA4.items &&
              item.GA4.items.length > 0
    },
    createEventGA4PaymentEvent ({
      payment,
      booking,
      paymentType,
      gateway
    }) {
      try {
        let bookingPayment = {}

        payment = this.validateObjectMap(payment)

        bookingPayment = this.filterPaymentBooking(
          payment,
          paymentType,
          gateway
        )

        if (this.validateHasGA4Values(booking)) {
          this.eventPaymentInfo({
            ...bookingPayment,
            item: booking.GA4.items
          })
        }
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },
    filterPaymentBooking (payment, paymentType, gateway) {
      try {
        if (gateway === 'cielo_worldpay') {
          gateway = 'cielo'
        }
        if (Array.isArray(payment)) {
          return payment.find(option =>
            option.GA4.payment_type.toLowerCase() === `${paymentType} - ${gateway}`
          )?.GA4
        }
        return payment.GA4
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },
    createEventGA4PaymentEventPD ({
      payment,
      booking
    }) {
      try {
        if (this.validateHasGA4Values(booking)) {
          this.eventPaymentInfo({
            ...payment.GA4,
            item: booking.GA4.items
          })
        }
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },
    validateObjectMap (paymentArray) {
      try {
        const array = []
        if (typeof paymentArray === 'object' && paymentArray.length === 1) {
          return [...array, paymentArray]
        }

        return paymentArray
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },
    eventSend (eventObject, event) {
      try {
        window.gtag('event', event, { ...eventObject }) // global
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    }
  }
}
class SignUp {
  constructor ({
    method
  }) {
    this.method = method
  }
}

class PaymentInfo {
  constructor ({
    currency,
    value,
    coupon,
    // eslint-disable-next-line camelcase
    payment_type,
    item
  }) {
    this.currency = currency
    this.value = value
    this.coupon = coupon
    // eslint-disable-next-line camelcase
    this.payment_type = payment_type
    this.items = item
  }
}
class PromotionUpgrade {
  constructor ({
    creativeName,
    creativeSlot,
    promotionId,
    promotionName,
    item
  }) {
    this.creative_name = creativeName
    this.creative_slot = creativeSlot
    this.promotion_id = promotionId
    this.promotion_name = promotionName
    this.items = [item]
  }
}
class Search {
  constructor ({
    term
  }) {
    this.search_term = term
  }
}
class Login {
  constructor ({
    method
  }) {
    this.method = method
  }
}
class Purchase {
  constructor ({
    transactionId,
    value,
    tax,
    currency,
    coupon,
    totalRevenue,
    grossPurchaseRevenue,
    purchaseRevenue,
    item,
    upgradeStatus,
    bookingPaymentType
  }) {
    this.transaction_id = transactionId
    this.value = value
    this.tax = tax
    this.currency = currency
    this.coupon = coupon
    this.total_revenue = totalRevenue
    this.gross_purchase_revenue = grossPurchaseRevenue
    this.purchase_revenue = purchaseRevenue
    this.items = item
    this.upgrade_status = upgradeStatus
    this.booking_payment_type = bookingPaymentType
  }
}

class AddtoCartItem {
  constructor ({
    currency,
    value,
    item
  }) {
    this.currency = currency
    this.value = value
    this.items = item // object of event
  }
}
class SelectItem {
  constructor ({
    itemListId,
    itemListName,
    item
  }) {
    this.item_list_id = itemListId
    this.item_list_name = itemListName
    this.items = item // object of event
  }
}
class ViewItemList {
  constructor ({
    itemListId,
    itemListName,
    item
  }) {
    this.item_list_id = itemListId
    this.item_list_name = itemListName
    this.items = item.map((i) => { return i[0] }) // object of event
  }
}

class VwoInfo {
  constructor ({
    campaignName,
    variableName,
    uuid,
    country
  }) {
    this.vwo_camp = campaignName
    this.variable_name = variableName
    this.uuid = uuid
    this.country = country
    this.user_device = 'Mobile'
  }
}
