export const state = () => ({
  lastRoute: false
})

export const actions = {
  SET_LAST_ROUTE ({ commit }, lastRoute) {
    commit('SET_ROUTE', lastRoute)
  }
}

export const mutations = {
  SET_ROUTE (state, lastRoute) {
    state.lastRoute = lastRoute
  }
}

export const getters = {
  getLastRoute: state => state.lastRoute
}
