export default {
  name: 'locale-helper',

  methods: {
    getFirstPartOfLocale () {
      return this.$store.getters.getLocale.split('-')[0]
    },

    isAllowedLanguages () {
      const allowedLanguages = ['pt-br', 'pt-pt', 'es', 'es-cl', 'es-co', 'es-mx', 'es-ar']

      return allowedLanguages.includes(this.$store.getters.getLocale)
    }
  }
}
