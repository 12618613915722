import indexOf from 'lodash/indexOf'
import Moment from 'moment-timezone'
import UserRepository from 'repositories/UserRepository'

const DEFAULT_STATE_AUTH_MODAL = {
  step: null,
  isVisible: false,
  from: 'default',
  stepHistory: [],
  verifiedEmail: ''
}

export const state = () => ({
  login: null,
  valid_login: false,
  interested_cities: [],
  interested_cities_update_needed: false,
  offersUpdate: false,
  privacyUpdate: false,
  bookingsCount: 0,
  facebook35: {
    error: false,
    message: null
  },
  routeFromLogin: null,
  bookingCompare: null,
  chargeBack: null,
  googleUserData: null,
  loyalty: false,
  authModal: { ...DEFAULT_STATE_AUTH_MODAL }
})

export const mutations = {
  SET_GOOGLE_USER_DATA (state, data) {
    state.googleUserData = data
  },
  SET_USER_LOGIN(state, login) {
    this.$appCookie.set('auth.token', login.token, { expires: '3M' })
    if (login.id) {
      sessionStorage.setItem('userId', login.id)
    }
    state.login = login
  },

  SET_VALID_LOGIN(state, valid = false) {
    state.valid_login = valid
  },

  SET_LOYALTY(state, loyalty = false) {
    state.loyalty = loyalty
  },

  SET_MGM_URL(state, userMgmUrl = '') {
    state.userMgmUrl = userMgmUrl
  },

  SET_MGM_VALUE(state, mgmAvailableValue = false) {
    state.mgmAvailableValue = mgmAvailableValue
  },

  SET_BOOKINGS_COUNT(state, count = 0) {
    state.bookingsCount = count
  },

  UPDATE_USER_DATA(state, data) {
    state.login = data
  },

  CLEAR_USER_STATE(state) {
    state.login = null
    state.valid_login = false
    this.$appCookie.delete('auth.token')
  },

  APPEND_CUSTOMER_INTEREST_CITY(state, data) {
    const index = indexOf(state.interested_cities, data)

    if (index === -1) {
      state.interested_cities.push(data)
    }
  },
  SET_INTERESTED_CITIES_UPDATED(state, status) {
    state.interested_cities_update_needed = status
  },

  REMOVE_INTERESTED_CITY(state, location) {
    const index = indexOf(state.interested_cities, location)

    if (index > -1) {
      state.interested_cities.splice(index, 1)
      state.interested_cities_update_needed = true
    }
  },

  SET_OFFERS_UPDATE(state, status) {
    state.offersUpdate = status
  },

  SET_PRIVACY_UPDATE(state, status) {
    state.privacyUpdate = status
  },

  SET_FACEBOOK_ERROR_35: (state, value) => {
    state.facebook35.error = value.ERROR

    if (value.ERROR) {
      state.facebook35.message = value.MESSAGE
    } else {
      state.facebook35.message = null
    }
  },

  SET_ROUTE_FROM_LOGIN: (state, routePath) => {
    state.routeFromLogin = routePath
  },

  SET_BOOK_COMPARE: (state, book) => {
    state.bookingCompare = book
  },

  SET_AUTH_MODAL: (state, data) => {
    state.authModal = data
  },

  SET_CHARGE_BACK: (state, chargeBack) => {
    state.chargeBack = chargeBack
  }
}

export const actions = {
  async INIT_MODULE({ commit }) {
    if (process.browser) {
      // Old login method using IndexedDB
      const USER = await UserRepository.GetUserData()

      if (USER != null) {
        this.$appCookie.set('auth.token', USER.token)
        UserRepository.DeleteUserData()
      }
      // New login method using JWT cookie
      if (this.$appCookie.get('auth.token')) {
        await this.$rentcarsApi.get('/v1/user/me', null, false, 10000, this.$appCookie.get('auth.token'))
          .then((response) => {
            commit('SET_USER_LOGIN', response.data)
            commit('SET_VALID_LOGIN', !!response.data)
          })
          .catch(() => {
            this.$appCookie.delete('auth.token')
          })
      }
    }
  },

  SET_GOOGLE_USER_DATA ({ commit }, data) {
    commit('SET_GOOGLE_USER_DATA', data)
  },

  SET_USER_LOGIN({ commit, dispatch }, login) {
    commit('SET_USER_LOGIN', login)
    commit('SET_VALID_LOGIN', !!login)
  },

  SET_VALID_LOGIN({ commit }, valid = false) {
    commit('SET_VALID_LOGIN', valid)
  },

  SET_BOOKINGS_COUNT({ commit }, count = 0) {
    commit('SET_BOOKINGS_COUNT', count)
  },

  SET_LOYALTY({ commit }, loyalty = false) {
    commit('SET_LOYALTY', loyalty)
  },

  SET_MGM_URL({ commit }, userMgmUrl = '') {
    commit('SET_MGM_URL', userMgmUrl)
  },

  SET_MGM_VALUE({ commit }, mgmAvailableValue = false) {
    commit('SET_MGM_VALUE', mgmAvailableValue)
  },

  CLEAR_USER_STATE({ commit }) {
    commit('CLEAR_USER_STATE')
  },

  APPEND_CUSTOMER_INTEREST_CITY({ commit }, city) {
    commit('APPEND_CUSTOMER_INTEREST_CITY', city)
  },

  SET_INTERESTED_CITIES_UPDATED({ commit }, status = false) {
    commit('SET_INTERESTED_CITIES_UPDATED', status)
  },

  REMOVE_INTERESTED_CITY({ commit }, location) {
    commit('REMOVE_INTERESTED_CITY', location)
  },

  UPDATE_USER_DATA({ commit }, data) {
    commit('UPDATE_USER_DATA', data)
  },

  SET_OFFERS_UPDATE({ commit }, status = false) {
    commit('SET_OFFERS_UPDATE', status)
  },

  SET_PRIVACY_UPDATE({ commit }, status = false) {
    commit('SET_PRIVACY_UPDATE', status)
  },

  SET_FACEBOOK_ERROR_35: ({ commit }, { ERROR, MESSAGE }) => {
    commit('SET_FACEBOOK_ERROR_35', { ERROR, MESSAGE })
  },

  SET_ROUTE_FROM_LOGIN: ({ commit }, routePath) => {
    commit('SET_ROUTE_FROM_LOGIN', routePath)
  },

  SET_BOOK_COMPARE: ({ commit }, book) => {
    commit('SET_BOOK_COMPARE', book)
  },

  SET_CHARGE_BACK: ({ commit }, chargeBack) => {
    commit('SET_CHARGE_BACK', chargeBack)
  },

  OPEN_AUTH_MODAL: ({ commit, state }, settings) => {
    if (state.valid_login) return

    const step = settings && settings.step ? settings.step : 'greetings'
    const from = settings && settings.from ? settings.from : 'default'

    commit('SET_AUTH_MODAL', {
      ...state.authModal,
      step,
      from,
      isVisible: true,
      stepHistory: [step]
    })
  },

  SET_AUTH_MODAL_STEP: ({ commit, state }, step) => {
    commit('SET_AUTH_MODAL', {
      ...state.authModal,
      step,
      stepHistory: [
        ...state.authModal.stepHistory,
        step
      ]
    })
  },

  SET_AUTH_MODAL_EMAIL: ({ commit, state }, email) => {
    commit('SET_AUTH_MODAL', {
      ...state.authModal,
      verifiedEmail: email
    })
  },

  BACK_AUTH_MODAL_STEP: ({ commit, state }) => {
    const { stepHistory } = state.authModal

    if (stepHistory.length <= 1) {
      return
    }

    commit('SET_AUTH_MODAL', {
      ...state.authModal,
      step: stepHistory[stepHistory.length - 2],
      stepHistory: state.authModal.stepHistory.slice(0, -1)
    })
  },

  CLOSE_AUTH_MODAL: ({ commit }) => {
    commit('SET_AUTH_MODAL', { ...DEFAULT_STATE_AUTH_MODAL })
  }
}

export const getters = {
  getGoogleUserData: state => state.googleUserData,

  getLoggedUser: state => state.login,

  getUserEmail: state => state.login ? state.login.email : null,

  getUserDocumentType: state => state.login ? state.login.documentType : null,

  getUserDocumentNumber: state => state.login ? state.login.document : null,

  getUserName: state => state.login ? state.login.name : null,

  getUserLastName: state => state.login ? state.login.surname : null,

  getUserFullName: state => state.login ? `${state.login.name} ${state.login.surname}` : null,

  getUserCellPhone: state => state.login ? state.login.phone : null,

  getUserToken: state => state.login ? state.login.token : false,

  getUserId: state => state.login ? state.login.id : null,

  getUserResidenceCountry: (state) => {
    if (state.login && state.login.country) {
      return state.login.country.alpha2
    }
    return null
  },

  getValidLogin: state => state.valid_login,

  getBookingsCount: state => state.bookingsCount,

  getLoyalty: state => state.loyalty,

  getUserMgmUrl: state => state.userMgmUrl,

  getUserMgmValue: state => state.mgmAvailableValue,

  getCustomerInterestCities: (state) => {
    return state.interested_cities
  },

  interestedCitiesUpdateNeeded: (state) => {
    return state.interested_cities_update_needed
  },

  offersUpdate: state => state.offersUpdate,

  privacyUpdate: state => state.privacyUpdate,

  /**
   * @desc checks if user need to update profile
   * @returns {Boolean}
   */
  needCompleteProfile: (state, getters) => {
    try {
      const user = getters.getLoggedUser
      return user && (
        user.phone == null ||
        user.birthdate == null ||
        user.phone.trim().length === 0 ||
        Moment(user.birthdate.trim()).isValid() === false
      )
    } catch (e) {
      return true
    }
  },

  getFacebook35: state => state.facebook35,

  getRouteFromLogin: state => state.routeFromLogin,

  getBookCompare: state => state.bookingCompare,

  getChargeBack: state => state.chargeBack,

  isLoyaltyMVP: (state, getters, rootState, rootGetters) => {
    return getters.getValidLogin
  },

  isAuthModalVisible: state => state.authModal.isVisible,

  getAuthModalStep: state => state.authModal.step,

  getAuthModalStepHistory: state => state.authModal.stepHistory,

  getAuthModalFrom: state => state.authModal.from,

  getAuthModalVerifiedEmail: state => state.authModal.verifiedEmail
}
