import Vue from 'vue'

import {
  parse,
  format,
  AsYouType as asYouType,
  isValidNumber,
  getNumberType,
  parsePhoneNumberFromString
} from 'libphonenumber-js'

import onlyNumbers from 'filters/onlyNumbers'

/**
 * @desc format phone
 * @param phoneObj
 * @param country
 * @return {string}
 */
function phoneFormatFilter(phoneObj, country) {
  if (phoneObj && typeof phoneObj !== 'object') {
    phoneObj = parsePhone(phoneObj)
  }
  if (phoneObj && !phoneObj.hasOwnProperty('country')) {
    phoneObj.country = 'BR'
  }

  // eslint-disable-next-line
  return phoneObj ? new asYouType(phoneObj.country).input(phoneObj.phone) : '-'
}

/**
 *
 * @param country
 * @param number
 */
function phoneAsYouTypeFilter(country, number) {
  // eslint-disable-next-line
  return new asYouType(country).input(number)
}

/**
 *
 * @param country
 * @param number
 * @param type
 */
function phoneTypeFormatFilter(country, number, type) {
  if (!type) {
    type = 'National'
  }

  return format(number, country, type)
}

/**
 *
 * @param value
 */
function parsePhoneFilter(value) {
  return parse(value)
}

/**
 *
 * @param value
 * @param country
 */
function parseInternationalPhoneFilter (value, country = null) {
  const phoneNumber = parsePhoneNumberFromString(value, country)
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber
  }
  return null
}

/**
 * @desc format phone
 * @param number
 * @param country
 * @return {string}
 */
function phoneInternationalFormatFilter (number, country) {
  return parseInternationalPhoneFilter(number, country).formatInternational()
}

/**
 *
 * @param value
 * @param country
 * @param type
 * @return {*}
 */
function validatePhoneFilter(value, country) {
  if (country != null) {
    return isValidNumber(value, country)
  } else {
    return isValidNumber(value)
  }
}

/**
 *
 * @param value
 * @param country
 * @return {*}
 */
function phoneNumberTypeFilter(value, country) {
  return getNumberType(value, country)
}

function getTelLinkFilter(value) {
  return 'tel:' + onlyNumbers(value)
}

Vue.filter('phoneFormat', phoneFormatFilter)
Vue.filter('phoneInternationalFormat', phoneInternationalFormatFilter)
Vue.filter('phoneAsYouType', phoneAsYouTypeFilter)
Vue.filter('phoneTypeFormat', phoneTypeFormatFilter)
Vue.filter('parsePhone', parsePhoneFilter)
Vue.filter('parseInternationalPhone', parseInternationalPhoneFilter)
Vue.filter('validatePhone', validatePhoneFilter)
Vue.filter('phoneNumberType', phoneNumberTypeFilter)
Vue.filter('getTelLink', getTelLinkFilter)

export const phoneInternationalFormat = phoneInternationalFormatFilter
export const phoneFormat = phoneFormatFilter
export const phoneAsYouType = phoneAsYouTypeFilter
export const phoneTypeFormat = phoneTypeFormatFilter
export const parsePhone = parsePhoneFilter
export const parseInternationalPhone = parseInternationalPhoneFilter
export const validatePhone = validatePhoneFilter
export const phoneNumberType = phoneNumberTypeFilter
export const getTelLink = getTelLinkFilter
