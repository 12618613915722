export default {
  methods: {
    isHome () {
      return this.$route?.name?.includes('routes.home')
    },

    isPromotionPage () {
      return this.$route?.name?.includes('routes.best-deals')
    },

    isBookingReviewPage() {
      return this.$route?.name?.includes('routes.customer.booking.evaluate')
    },

    isEntryPage () {
      return this.$route?.name?.includes('routes.rentals') ||
        this.$route?.name?.includes('routes.rental') ||
        this.$route?.name?.includes('routes.locations') ||
        this.$route?.name?.includes('routes.airports')
    },

    isRentDay () {
      return this.$route?.name?.includes('routes.lpbuilder')
    },

    isListPage () {
      return this.$route?.name?.includes('routes.search.list')
    },

    isConfigurePage () {
      return this.$route?.name?.includes('routes.booking.configure')
    },

    isConfirmationPage () {
      return this.$route?.name?.includes('routes.booking.confirmation') ||
        this.$route?.name?.includes('routes.booking.confirmation.insurance')
    },

    isOldPaymentPage () {
      return this.$route?.name?.includes('routes.booking.payment') ||
        this.$route?.name?.includes('routes.booking.payment.insurance')
    },

    footerUnvailableRoutes () {
      return this.$route?.name?.includes('routes.search.list') ||
        this.$route?.name?.includes('routes.booking.configure') ||
        this.$route?.name?.includes('routes.customer.login') ||
        this.$route?.name?.includes('routes.booking.payment') ||
        this.$route?.name?.includes('routes.booking.payment.insurance') ||
        this.$route?.name?.includes('routes.booking.confirmation') ||
        this.$route?.name?.includes('routes.booking.confirmation.insurance') ||
        this.$route?.name?.includes('routes.customer.account') ||
        this.$route?.name?.includes('routes.customer.bookings') ||
        this.$route?.name?.includes('routes.chat-status') ||
        this.$route?.name?.includes('routes.customer.booking.evaluate') ||
        this.$route?.name?.includes('routes.booking.map') ||
        this.$route?.name?.includes('routes.customer.signup') ||
        this.$route?.name?.includes('routes.settings')
    }
  }
}
