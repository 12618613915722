import insiderEnum from '../InsiderEnum'

export default function voucherDiscountInf(params) {
  const voucherName = params?.booking?.coupon?.code ?? (params?.loyalty?.amount ? insiderEnum.REWARDS : insiderEnum.THIS_IS_NULL)
  const nodeSelected = (params?.booking?.coupon) ?? (params?.loyalty ?? null)

  if (!nodeSelected) {
    return {}
  }

  return {
    voucher_name: voucherName,
    voucher_discount: nodeSelected.amount ?? nodeSelected.totalAmount.value,
    voucher_discount_ratio: parseFloat(nodeSelected.percentDiscount),
    voucher_discount_formatted: `${nodeSelected.totalAmount.symbol} ${nodeSelected.totalAmount.formatted}`
  }
}
