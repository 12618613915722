import axios from 'axios'

export default (ctx, inject) => {
  const FEATURE_FLAG_API_CLIENT = axios.create({
    baseURL: process.env.FEATURE_FLAG_API,
    timeout: 10000
  })

  inject('featureFlagApi', FEATURE_FLAG_API_CLIENT)
}
