export default class RedirectChangeCurrency {
  constructor (app) {
    this.appCookie = app.$appCookie
    this.store = app.$store
    this.route = app.$route
    this.bugsnag = app.$bugsnag
  }

  redirectOnChangeCurrency (newCurrency) {
    try {
      this.store.dispatch('SET_CURRENCY', newCurrency)
      this.appCookie.set('currency', newCurrency)

      if (this.route.name.includes('routes.booking.configure')) {
        const pathName = window.location.pathname.split('/')
        pathName[6] = newCurrency
        const newUrl = window.location.origin + pathName.join('/')

        const currentParams = new URLSearchParams(window.location.search)

        if (currentParams.size) {
          currentParams.set('step', 3)
          location.href = `${newUrl}?${currentParams.toString().replace(/\?/g, '')}`
          return
        }

        location.href = newUrl
      } else {
        location.reload()
      }
    } catch (e) {
      this.bugsnag.notify(e)
    }
  }
}
