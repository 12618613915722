export const cookieTypes = {
  RESIDENCE_CODE: 'residenceCode'
}

export const state = () => ({
  appUrl: null,
  appDomain: null,
  authToken: null
})

export const mutations = {
  SET_APP_URL (state, domain) {
    state.appUrl = domain
  },

  SET_APP_DOMAIN (state, domain) {
    if (domain === 'localhost') {
      state.appDomain = 'localhost'
    } else {
      state.appDomain = `.${domain.split('.').slice(1).join('.')}`
    }
  },

  SET_AUTH_TOKEN_COOKIE (state, cookie) {
    state.authToken = cookie
  }
}

export const actions = {
  SET_APP_DOMAIN: ({ commit }, host) => {
    commit('SET_APP_URL', host)
    commit('SET_APP_DOMAIN', host)
  },
  SET_AUTH_TOKEN_COOKIE: ({ commit }, cookie) => {
    commit('SET_AUTH_TOKEN_COOKIE', cookie)
  }
}

export const getters = {
  getAppDomain: state => state.appDomain,
  getAppUrl: state => state.appUrl,
  getAuthToken: state => state.authToken
}
