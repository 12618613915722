import InsiderService from '~/services/Insider/InsiderService'
import InsiderUtils from '~/services/Insider/InsiderUtils'

const insiderUtils = InsiderUtils.start()

export default {
  methods: {

    insiderSearchData() {
      if (!this.itinerary) {
        return
      }

      const defaultParams = {
        locale: this.$store.getters.getLocale,
        currency_code: process.browser ? this.$store.getters.getCurrency : null,
        requestor_id: process.browser ? this.$store.getters.getMobicarRequestorID : null
      }
      return Object.assign({}, { itinerary: this.itinerary }, defaultParams)
    },

    getCarDetails(detailsOnConfigure) {
      const { vehicle, itinerary } = detailsOnConfigure
      const vehicleDetails = vehicle?.configurations.map(config => config.description)
      const payment = detailsOnConfigure.payment || detailsOnConfigure.netRateOption || detailsOnConfigure.payOnArrivalOption

      const taxonomyData = [
        vehicle.category.name,
        ...vehicleDetails,
        payment?.tag?.name,
        detailsOnConfigure.evaluation.index.grade,
        detailsOnConfigure.coverage.name ?? '',
        detailsOnConfigure.rentalCover?.code ?? ''
      ].filter(item => (item && item.length))

      return insiderUtils.buildCarDetails(
        vehicle,
        taxonomyData,
        detailsOnConfigure,
        payment,
        itinerary
      )
    },

    getInsiderConfirmationDetails(reservation) {
      const { Group, Itinerary, Coupon } = reservation
      const groupDetails = Group.Configuration.All.map(config => config.Description)
      const payment = reservation.Payment
      const promotions = reservation.Tags
        .filter(tag => tag.Name.length)
        .map(promotion => promotion.Name)

      const taxonomyData = [
        Group.Category.Name,
        ...groupDetails,
        ...promotions,
        reservation.Coverage.Name ?? '',
        reservation.RentalCover?.Included ? 'rental cover' : ''
      ].filter(item => item.length)

      return insiderUtils.buildInsiderConfirmation(
        reservation,
        Group,
        taxonomyData,
        payment,
        Coupon,
        promotions,
        Itinerary
      )
    },

    buildUserData(hasToReturn = false) {
      const userData = this.$store.getters['user/getLoggedUser']

      if (!userData) return

      this.insiderEventName = 'User'

      const buildedUserData = InsiderService.start().handlerUserInfs(
        {
          userData,
          requestorId: insiderUtils.getRequestorId()
        }
      )

      if (!hasToReturn) {
        return buildedUserData
      }

      this._setInsiderEvent(buildedUserData)
    }
  }
}
