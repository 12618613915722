export default {

  data () {
    return {
      online: false,
      offline: false,
      isChunkError: null
    }
  },

  beforeDestroy () {
    try {
      if ('online' in window && 'offline' in window) {
        window.removeEventListener('online')
        window.removeEventListener('offline')
      }
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  methods: {
    /**
         * @desc Check for device connectivity, emits an Materialize.toast event to user visualization.
         */
    addNetworkListener () {
      window.addEventListener('offline', () => {
        try {
          if (!this.offline) {
            this.offline = true
            Materialize.toast(
              `<span class="toast-text toast-offline"> ${this.$t('error.noInternetConnection')} </span>`,
              3000,
              '',
              function completeCallback () {
                const toastElement = $('.toast-offline').first()[0]
                const toastInstance = toastElement.M_Toast
                if (toastInstance) {
                  toastInstance.remove()
                }
              }
            )

            this.online = false
            this.$router.push({ name: this.$routeResolver('routes.offline') })
          }
        } catch (error) {
          this.$bugsnag.notify(error)
        }
      })

      window.addEventListener('online', () => {
        try {
          if (!this.online) {
            this.online = true
            Materialize.toast(
              `<span class="toast-text toast-online"> ${this.$t('error.connectionRestored')} </span>`,
              3000,
              '',
              function completeCallback () {
                const toastElement = $('.toast-online').first()[0]
                const toastInstance = toastElement.M_Toast
                if (toastInstance) {
                  toastInstance.remove()
                }
              }
            )

            const route = this.$store.getters['reloadoffline/getLastRoute']

            if (route) {
              this.offline = false
              this.$router.push({
                name: route.name,
                params: route.params,
                query: route.query
              })
            } else {
              this.offline = false
              this.$router.push({ name: this.$routeResolver('routes.home') })
            }
          }
        } catch (error) {
          this.$bugsnag.notify(error)
        }
      })

      window.addEventListener('unhandledrejection', (event) => {
        try {
          if (this.isChunkError === null) {
            this.isChunkError = true
          }
          const error = event.reason

          if (this.isChunkError && error && error.name === 'ChunkLoadError') {
            window.location.reload()
          }
        } catch (error) {
          this.$bugsnag.notify(error)
        }
      })
    }
  }
}
