import axios from 'axios'

export default ({ store, env, isDev }, inject) => {
  /**
   * @desc Monta os parametros para as requisições get ou post da aplicação, verifica se os valores são válidos para envio.
   *
   * @param params
   * @param upperCase
   * @returns {Object}
   */
  function buildRequestParams(params, upperCase = false, required = [], formData = false) {
    const response = formData && process.browser ? new FormData() : {}
    const data = Object.assign({}, {
      mobicarRequestorID: store.getters.getMobicarRequestorID,
      ip: store.getters.getUserIp,
      dateTime: new Date()
    }, params)

    Object.keys(data).forEach((key) => {
      const item = data[key]

      if (item || required.indexOf(key) >= 0) {
        if (upperCase && key !== 'isCheckoutStep') {
          key = key.substr(0, 1).toUpperCase() + key.substr(1)
        }

        if (formData && process.browser) {
          if (Array.isArray(item)) {
            item.forEach((v) => {
              response.append(key, v)
            })
          } else {
            response.append(key, item)
          }
        } else {
          Object.assign(response, {
            [key]: item
          })
        }
      }
    })

    return response
  }

  const headers = {}

  if (!process.browser) {
    headers.origin = env.SITE_MOBILE_URL
  }

  const client = axios.create({
    baseURL: process.server && !isDev ? env.INTERNAL_ENDPOINT : env.FACADE_ENDPOINT,
    timeout: env.FACADE_TIMEOUT || 10000,
    proxyHeaders: false,
    headers
  })

  client.interceptors.request.use(function (request) {
    request.timestamp = new Date().getTime()

    return request
  }, function (error) {
    return Promise.reject(error)
  })

  client.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    return Promise.reject(error)
  })

  const promotionsApi = {
    client: axios,

    /**
     * @desc this method sends a HTTP Get Request to the server
     *
     * @param {string} uri
     * @param {object} params
     * @param {boolean} upperCase
     * @return {Promise}
     */
    get: (uri, params, upperCase = false) => {
      let cancel = {}

      if (params != null && params.hasOwnProperty('cancelToken')) {
        cancel = { cancelToken: params.cancelToken }
        delete params.cancelToken
      }

      try {
        return client.get(uri, Object.assign({}, cancel, { params: buildRequestParams(params, upperCase) }))
      } catch (e) {
        return null
      }
    }
  }

  inject('promotionsApi', promotionsApi)
}
