import cookie from 'cookie'
import findKey from 'lodash/findKey'
import i18nConfig from 'config/i18n'
import currencies from 'config/currencies'
import LocaleStrategyService from 'services/LocaleStrategyService'
import { ROUTES_CANT_ACCESS_OFFLINE } from 'config/landing-pages'
import { COUPON_BY_PARTNER } from 'config/partners'
import requestIp from 'request-ip'

export const state = () => ({
  debug: false,
  locales: i18nConfig.availableLocales,
  countryResidence: null,
  locale: null,
  loading: false,
  loadPage: false,
  loadSkeleton: false,
  loadPagePaypal: false,
  loadCompleteTravel: false,
  currency: null,
  exchangeRate: {
    from: 'BRL',
    to: 'BRL',
    rate: 1
  },
  ip: null,
  geoip: null,
  phones: null,
  cookies: null,
  gtm: {
    initialized: false
  },
  mobicarRequestorID: process.env.MOBICARREQUESTOR_ID,
  readGdpr: false,
  sessionId: null,
  deviceType: null,
  isClosedPromotionsToolbar: false,
  landingPagesDate: null,
  autoCoupon: null,
  lastAction: null,
  loadChatBot: false,
  customEvent: 'default',
  events: null,
  premmiaEvent: null,
  gclid: undefined,
  excludeBrazilFromSearch: false,
  previousChatRoute: null,
  headerCloudFront: null,
  isModalUpdateCurrencyOpen: false,
  objectUpdateCurrency: null,
  uuid: null,
  googleScriptsLoaded: false
})

export const actions = {
  SET_UPDATE_CURRENCY_MODAL ({ commit }, value) {
    commit('SET_UPDATE_CURRENCY_MODAL', value)
  },
  SET_UPDATE_OBJECT_CURRENCY_MODAL ({ commit }, obj) {
    commit('SET_UPDATE_OBJECT_CURRENCY_MODAL', obj)
  },
  SET_SESSION_ID({ commit }, id) {
    commit('SET_SESSION_ID', id)
  },
  SET_LAST_ACTION({ commit }, id) {
    commit('SET_LAST_ACTION', id)
  },

  SET_MOBICAR_REQUESTOR({ commit }, id) {
    commit('SET_MOBICAR_REQUESTOR', id)
  },

  SET_LOCALE({ commit }, locale) {
    commit('SET_LOCALE', locale)
  },

  SET_AUTO_COUPON({ commit }, coupon) {
    commit('SET_AUTO_COUPON', coupon)
  },

  SET_CURRENCY({ commit }, currency) {
    commit('SET_CURRENCY', currency)
  },

  SET_CURRENCY_EXCHANGE_FROM_BRL({ commit }, exchangeRate) {
    commit('SET_CURRENCY_EXCHANGE_FROM_BRL', exchangeRate)
  },

  SET_DEBUG({ commit }, debug) {
    commit('SET_DEBUG', debug)
  },

  START_LOADING({ commit }) {
    commit('START_LOADING', true)
  },

  STOP_LOADING({ commit }) {
    commit('STOP_LOADING', false)
  },

  START_LOADING_PAGE({ commit }) {
    commit('START_LOADING_PAGE', true)
  },

  STOP_LOADING_PAGE({ commit }) {
    commit('STOP_LOADING_PAGE', false)
  },

  START_LOADING_PAYPAL({ commit }) {
    commit('SET_LOADING_PAYPAL', true)
  },

  STOP_LOADING_PAYPAL({ commit }) {
    commit('SET_LOADING_PAYPAL', false)
  },

  START_LOADING_COMPLETE_TRAVEL({ commit }) {
    commit('SET_LOADING_COMPLETE_TRAVEL', true)
  },

  STOP_LOADING_COMPLETE_TRAVEL({ commit }) {
    commit('SET_LOADING_COMPLETE_TRAVEL', false)
  },

  SET_USER_IP({ commit }, ip) {
    commit('SET_USER_IP', ip)
  },

  SET_HEADER_CLOUD_FRONT({ commit }, value) {
    commit('SET_HEADER_CLOUD_FRONT', value)
  },

  async SET_USER_GEOIP({ commit }, { req }) {
    commit('SET_USER_IP', requestIp.getClientIp(req))

    const COUNTRY = (req.headers['cf-ipcountry'] || req.headers['cloudfront-viewer-country']) ?? null

    if (COUNTRY) {
      commit('SET_USER_GEOIP_USER', COUNTRY)

      try {
        if (COUNTRY in i18nConfig.countriesCurrencies) {
          commit('SET_CURRENCY', i18nConfig.countriesCurrencies[COUNTRY])
        }

        const findLocaleFromCountry = findKey(i18nConfig.countriesLanguage, value => value.some(v => v === COUNTRY))

        if (findLocaleFromCountry && i18nConfig.availableLocales.indexOf(findLocaleFromCountry) >= 0) {
          commit('SET_LOCALE', findLocaleFromCountry)
        }
      } catch (e) {
        commit('SET_USER_GEOIP_USER', false)
      }
    } else if (process.env.NODE_ENV === 'development' || /\.development\./.test(req.headers.host)) {
      commit('SET_USER_GEOIP_USER', 'BR')
    } else {
      await this.$rentcarsApi
        .get('v1/geoip/location', { clientIp: requestIp.getClientIp(req) })
        .then((response) => {
          const currencyCode = response.data?.country?.code
          commit('SET_USER_GEOIP_USER', currencyCode)
          if (currencyCode in i18nConfig.countriesCurrencies) {
            commit('SET_CURRENCY', i18nConfig.countriesCurrencies[currencyCode])
          }
        })
        .catch((e) => {
          commit('SET_USER_GEOIP_USER', false)
        })
    }
  },

  SET_USER_COOKIES({ commit }, cookies) {
    commit('SET_USER_COOKIES', cookies)
  },

  SET_LANDING_PAGES_DEBUG({ commit }, query) {
    commit('SET_LANDING_PAGES_DEBUG', query)
  },

  SET_USER_REFERER({ commit }, referer) {
    commit('SET_USER_REFERER', referer)
  },

  SET_USER_CAMPAIGN({ commit }, query) {
    commit('SET_USER_CAMPAIGN', query)
  },

  async SET_PHONES({ commit }, locale) {
    const urlParams = new URLSearchParams(window.location.search)
    const geoIp = urlParams.get('forceGeoip') ?? this.getters.getUserGeoipCountry

    await this.$rentcarsApi
      .get('v1/phone/phones', { locale, geoIp }, false, 10000)
      .then((response) => {
        if (response.status === 200) commit('SET_PHONES', response.data)
      })
      .catch((e) => {
        commit('SET_PHONES', false)
      })
  },

  SET_GTM_INITIALIZED: ({ commit }) => {
    commit('SET_GTM_INITIALIZED')
  },

  SET_COUNTRY_RESIDENCE_CODE: ({ commit }, country) => {
    commit('SET_COUNTRY_RESIDENCE_CODE', country)
  },

  SET_READ_GDPR: ({ commit }, read) => {
    commit('SET_READ_GDPR', read)
  },

  SET_CAN_LOAD_CHAT_BOT: ({ commit }, data) => {
    commit('SET_CAN_LOAD_CHAT_BOT', data)
  },

  SET_CUSTOM_EVENT: ({ commit }, data) => {
    commit('SET_CUSTOM_EVENT', data)
  },

  SET_EVENTS: ({ commit }, data) => {
    commit('SET_EVENTS', data)
  },

  SET_PREMMIA_EVENT: ({ commit }, data) => {
    commit('PREMMIA_EVENT', data)
  },

  SET_EXCLUDE_BRAZIL_FROM_SEARCH: ({ commit }, data) => {
    commit('SET_EXCLUDE_BRAZIL_FROM_SEARCH', data)
  },

  SET_GOOGLE_SCRIPTS_LOADED: ({ commit }, data) => {
    commit('SET_GOOGLE_SCRIPTS_LOADED', data)
  },

  async nuxtServerInit({ commit, state, dispatch }, { req, app: { $rentcarsApi, $promotionsApi }, redirect, query, route }) {
    if (route.path in ROUTES_CANT_ACCESS_OFFLINE) {
      if (route.path.match(/airports|aeroportos|aeropuertos|maio-amarelo|promocoes\/sao-joao|promocoes\/ferias-de-julho|ferias-de-verao|carnaval|vacaciones-de-verano/)) {
        return redirect(301, ROUTES_CANT_ACCESS_OFFLINE[route.path])
      }

      return redirect(302, ROUTES_CANT_ACCESS_OFFLINE[route.path])
    }

    commit('SET_USER_COOKIES', req.headers.cookie)

    let locale = null

    if (req.originalUrl === '/') {
      const LocaleStrategy = new LocaleStrategyService()

      const browserLanguage = req.headers['accept-language']
      locale = LocaleStrategy.getBrowserLanguage(browserLanguage)
    }

    if (locale == null && state.locale) {
      locale = state.locale
    }

    if (!locale) {
      locale = i18nConfig.defaultLocale
    }

    if (route.path === '/' && locale) {
      redirect(302, `/${locale}${route.fullPath}`)
    }

    commit('SET_USER_CAMPAIGN', query)

    if (req.headers.referer) {
      commit('SET_USER_REFERER', req.headers.referer)
    }
    commit('SET_LANDING_PAGES_DEBUG', query)

    if (query && query.hasOwnProperty('allow-pp')) {
      dispatch('search/SET_ABTEST_PP', query['allow-pp'])
    }

    if (query && query.hasOwnProperty('requestorid')) {
      state.mobicarRequestorID = query.requestorid !== '4730' ? parseInt(query.requestorid) : process.env.MOBICARREQUESTOR_ID
    } else if (query && query.hasOwnProperty('requestorId')) {
      state.mobicarRequestorID = query.requestorId !== '4730' ? parseInt(query.requestorId) : process.env.MOBICARREQUESTOR_ID
    }

    const hasQueryRequestorId = query.hasOwnProperty('requestorid') || query.hasOwnProperty('requestorId')
    if ((!hasQueryRequestorId && req.headers.referer === 'https://www.google.com/' && query.hasOwnProperty('gclid')) ||
      (!hasQueryRequestorId && req.headers.referer && query.hasOwnProperty('utm_campaign'))) {
      state.mobicarRequestorID = '16'
    }

    if (state.mobicarRequestorID in COUPON_BY_PARTNER) {
      commit('SET_AUTO_COUPON', COUPON_BY_PARTNER[state.mobicarRequestorID])
    }

    const headerCloudFront = (req.headers['cf-ipcountry'] || req.headers['cloudfront-viewer-country']) ?? 'No-Cloud-Front'
    dispatch('SET_HEADER_CLOUD_FRONT', headerCloudFront)
    await dispatch('SET_USER_GEOIP', { req, route })

    if (route.name && (route.name.indexOf('latampass') !== -1 || ('utm_source' in query && query.utm_source === 'vivo'))) {
      commit('SET_EXCLUDE_BRAZIL_FROM_SEARCH', true)
    }

    const exchangeFrom = 'BRL'

    if (state.currency) {
      await $rentcarsApi
        .get('v1/currency/exchange', {
          from: exchangeFrom,
          to: state.currency
        })
        .then((response) => {
          if (response) {
            commit('SET_CURRENCY_EXCHANGE_FROM_BRL', {
              from: exchangeFrom,
              to: state.currency,
              rate: response.data.exchange
            })
          }
        })
        .catch((e) => { })
    }

    let paramDateCustomEvent = new Date()
    let userGeoipCountry = state.geoip ? state.geoip : ''

    if (query && query.hasOwnProperty('token')) {
      if (process.env.TOKEN_LP === query.token && query.hasOwnProperty('data')) {
        paramDateCustomEvent = `${query.data}T${query.time ?? '00:00'}`
        userGeoipCountry = query.local ? query.local : userGeoipCountry
      }
    }

    await $promotionsApi.get('v1/customEvents', { ip: state.ip, dateTime: paramDateCustomEvent, locale: userGeoipCountry })
      .then((response) => {
        if (response && response.data.events.length > 0) {
          commit('SET_CUSTOM_EVENT', response.data.events[0].slug)
          commit('SET_EVENTS', response.data.events.map((e) => { return e.slug }))
          commit('SET_PREMMIA_EVENT', response.data.events.find((e) => { return e.slug === 'desc_parc_premmia' }))
        } else {
          commit('SET_PREMMIA_EVENT', undefined)
        }
      })
      .catch((e) => { })
  }
}

export const mutations = {
  SET_UPDATE_CURRENCY_MODAL (state, value) {
    state.isModalUpdateCurrencyOpen = value
  },
  SET_UPDATE_OBJECT_CURRENCY_MODAL (state, value) {
    state.objectUpdateCurrency = value
  },
  SET_SESSION_ID(state, id) {
    state.sessionId = id
  },

  SET_LAST_ACTION(state, timestamp) {
    state.lastAction = timestamp
  },

  SET_MOBICAR_REQUESTOR(state, id) {
    state.mobicarRequestorID = id
  },

  SET_LOCALE(state, locale) {
    if (state.locales.indexOf(locale) >= 0) {
      state.locale = locale
    }
  },

  SET_CURRENCY(state, currency) {
    if (currencies.currencies.hasOwnProperty(currency) >= 0) {
      state.currency = currency
    }
  },

  SET_CURRENCY_EXCHANGE_FROM_BRL(state, exchangeRate) {
    state.exchangeRate = exchangeRate
  },

  SET_DEBUG(state, debug) {
    state.debug = debug
  },

  START_LOADING(state) {
    state.loading = true
  },

  STOP_LOADING(state) {
    state.loading = false
  },

  START_LOADING_PAGE(state) {
    state.loadPage = true
  },

  STOP_LOADING_PAGE(state) {
    state.loadPage = false
  },

  SET_LOADING_PAYPAL(state, value) {
    state.loadPagePaypal = value
  },

  SET_LOADING_COMPLETE_TRAVEL(state, value) {
    state.loadCompleteTravel = value
  },

  SET_USER_IP(state, ip) {
    state.ip = ip
  },

  SET_USER_GEOIP_USER(state, geoip) {
    state.geoip = geoip
  },

  SET_HEADER_CLOUD_FRONT (state, value) {
    state.headerCloudFront = value
  },

  SET_USER_REFERER(state, referer) {
    state.referer = referer
  },

  SET_USER_CAMPAIGN(state, query) {
    if (query.hasOwnProperty('gclid')) {
      state.gclid = query.gclid
    }
    if (query.hasOwnProperty('utm_campaign')) {
      state.utm_campaign = query.utm_campaign
    }
    if (query.hasOwnProperty('utm_source')) {
      state.utm_source = query.utm_source
    }
  },

  SET_USER_COOKIES(state, cookies) {
    if (!cookies) {
      return
    }

    const cookiesObj = cookie.parse(cookies)

    if (cookiesObj.hasOwnProperty('hl') && i18nConfig.availableLocales.indexOf(cookiesObj.hl)) {
      state.locale = cookiesObj.hl
    }

    if (cookiesObj.hasOwnProperty('GA_ID')) {
      state.sessionId = cookiesObj.GA_ID
    }
    if (cookiesObj.hasOwnProperty('lastAction')) {
      state.lastAction = parseInt(cookiesObj.lastAction)
    }

    if (cookiesObj.hasOwnProperty('requestorID') && parseInt(cookiesObj.requestorID, 10) > 0) {
      state.mobicarRequestorID = cookiesObj.requestorID
    }

    if (cookiesObj.hasOwnProperty('requestorIDReferrer')) {
      state.mobicarRequestorIDReferrer = cookiesObj.requestorIDReferrer
    }

    if (cookiesObj.hasOwnProperty('closeFloaterApp')) {
      state.closeFloaterApp = parseInt(cookiesObj.closeFloaterApp, 10) === 1
    }

    if (cookiesObj.hasOwnProperty('closePromotionsToolbar')) {
      state.isClosedPromotionsToolbar = parseInt(cookiesObj.closePromotionsToolbar, 10) === 1
    }

    if (cookiesObj.hasOwnProperty('userUuid')) {
      state.uuid = cookiesObj.userUuid
    }

    state.cookies = cookiesObj
  },

  SET_USER_REQUESTOR_ID_REFERRER(state, id) {
    state.mobicarRequestorIDReferrer = id
  },

  SET_LANDING_PAGES_DEBUG(state, query) {
    if (process.env.TOKEN_LP === query.token && query.hasOwnProperty('data')) {
      state.landingPagesDate = query.data
    }
  },

  SET_PHONES(state, phones) {
    state.phones = phones
  },

  SET_GTM_INITIALIZED: (state) => {
    state.gtm.initialized = true
  },

  SET_COUNTRY_RESIDENCE_CODE: (state, country) => {
    state.countryResidence = country
  },

  SET_READ_GDPR: (state, read) => {
    state.readGdpr = read
  },

  SET_DEVICE_TYPE: (state, type) => {
    state.deviceType = type
  },

  SET_AUTO_COUPON: (state, coupon) => {
    state.autoCoupon = coupon
  },

  SET_CAN_LOAD_CHAT_BOT: (state, coupon) => {
    state.loadChatBot = coupon
  },

  SET_CUSTOM_EVENT: (state, customEvent) => {
    state.customEvent = customEvent
  },

  SET_EVENTS: (state, events) => {
    state.events = events
  },

  SET_PREMMIA_EVENT: (state, events) => {
    state.premmiaEvent = events
  },

  SET_EXCLUDE_BRAZIL_FROM_SEARCH: (state, data) => {
    state.excludeBrazilFromSearch = data
  },

  SET_PREVIOUS_ROUTE_CHAT_STATUS: (state, routeName) => {
    state.previousChatRoute = routeName
  },

  SET_GOOGLE_SCRIPTS_LOADED: (state, data) => {
    state.googleScriptsLoaded = data
  }
}

export const getters = {
  getObjectUpdateCurrency: state => state.objectUpdateCurrency,

  getIsModalUpdateCurrencyOpen: state => state.isModalUpdateCurrencyOpen,
  /**
   * @desc returns if current locale is pt_BR
   * @param state
   * @returns {Boolean}
   */
  getIsBrazilianLocale: state => state.locale === i18nConfig.brazilianLocale,

  /**
   * @desc returns if current locale is pt_BR
   * @param state
   * @returns {Boolean}
   */
  getIsMexicanLocale: state => state.locale === i18nConfig.mexicanLocale,

  /**
   * @desc returns if current locale is pt_BR
   * @param state
   * @returns {Boolean}
   */
  getIsSpanishLocale: state => state.locale === i18nConfig.spanishLocale,

  /**
   * @desc returns if current country code is BR
   * @param state
   * @returns {Boolean}
   */
  getIsBrazilianCode: async (state, getters) => {
    return i18nConfig.brazilianCountry === await getters.getUserResidenceCountry
  },

  /**
   * @desc returns if current locale is en
   * @param state
   * @returns {Boolean}
   */
  getIsAmericanLocale: state => state.locale === i18nConfig.unitedStatesLocale,

  /**
   * @desc returns if current country code is US
   * @param state
   * @returns {Boolean}
   */
  getIsAmericanCode: async (state, getters) => {
    return i18nConfig.unitedStatesCountry === await getters.getUserResidenceCountry
  },

  /**
   * @desc returns if current currency code is BRL
   * @param state
   * @returns {Boolean}
   */
  getIsBrazilianCurrency: (state, getters) => {
    return i18nConfig.brazilianCurrency === getters.getCurrency
  },

  /**
   * @desc returns correct locale for public pages
   * @param state
   * @returns {String}
   */
  getLocaleForStaticPage: state => state.locale === 'pt-br' ? 'pt-br' : 'en',

  /**
   * @desc returns MobicarRequestorID
   * @returns {Number}
   */
  getMobicarRequestorID: state => parseInt(state.mobicarRequestorID, 10),

  /**
   * @desc returns MobicarRequestorID
   * @returns {Number}
   */
  getMobicarRequestorIDReferrer: state => parseInt(state.mobicarRequestorIDReferrer, 10),

  /**
   * @desc returns Referer
   * @returns {Number}
   */
  getReferer: state => state.referer,

  /**
   * @desc returns utm_campaign
   * @returns {Number}
   */
  getUtmCampaign: state => state.utm_campaign,

  /**
   * @desc returns utm_source
   * @returns {String}
   */
  getUtmSource: state => state.utm_source,

  /**
   * @desc returns gclid
   * @returns {Number}
   */
  getGclid: state => state.gclid,

  /**
   * @desc returns debug status
   * @param state
   * @returns {Boolean}
   */
  getDebug: state => state.debug || process.env.DEBUG,

  /**
   * @desc returns load bar status
   * @param state
   * @returns {Boolean}
   */
  getLoading: state => state.loading,

  /**
   * @desc returns load page status
   * @param state
   * @returns {Boolean}
   */
  getLoadPage: state => state.loadPage,

  /**
   * @desc returns paypal load page status
   * @param state
   * @returns {Boolean}
   */
  getLoadingPaypalState: state => state.loadPagePaypal,

  /**
   * @desc returns complete travel load page status
   * @param state
   * @returns {Boolean}
   */
  getLoadingCompleteTravelState: state => state.loadCompleteTravel,

  /**
   * @desc returns current currency code
   * @returns {String}
   */
  getCurrency: (state, getters) => {
    return state.currency || i18nConfig.defaultCurrency
  },

  /**
   * @desc return current currency symbol
   */
  getCurrencySymbol: (state, getters) => {
    const currency = state.currency || state.exchangeRate.to

    return currency ? currencies.currencies[currency].htmlcode : ''
  },

  getExchangeRateFromBRL: state => state.exchangeRate,

  getExchangeRate: (state, getters) => getters.getExchangeRateFromBRL.rate,

  /**
   * @desc returns user ip
   * @returns {String}
   */
  getUserIp: state => state.ip,

  getUserUuid: state => state.uuid,

  /**
   * @desc returns user geoip
   * @returns {String}
   */
  getUserGeoipCountry: state => state.geoip ? state.geoip : null,

  getheaderCloudFront: state => state.headerCloudFront,

  isBrazilianGeoip: (state, getters) => getters.getUserGeoipCountry === i18nConfig.brazilianCountry,

  isMexicanGeoip: (state, getters) => getters.getUserGeoipCountry === i18nConfig.mexicanCountry,

  getUserResidenceCountry: async (state, getters) => {
    return getters['user/getUserResidenceCountry'] || state.countryResidence || await getters.getAppResidenceCountry || getters.getUserGeoipCountry || i18nConfig.defaultLocales[getters.getLocale].countryCode
  },

  getEmitterId: (state, getters) => {
    return getters['user/getUserId']
  },

  getUserToken: (state, getters) => {
    return getters['user/getUserToken']
  },

  /*
   * @desc não precisa mais do async await, aos poucos vamos migrando para este no getter
   */
  getNewUserResidenceCountry: (state, getters) => {
    return getters['user/getUserResidenceCountry'] || state.countryResidence || getters.getAppResidenceCountry || getters.getUserGeoipCountry || i18nConfig.defaultLocales[getters.getLocale].countryCode
  },

  /**
   * @desc returns app residence country
   * @returns {string}
   */
  getAppResidenceCountry: state => state.countryResidence,

  /**
   * @desc returns current i18n locale
   * @returns {String}
   */
  getLocale: state => state.locale || i18nConfig.defaultLocale,

  /**
   * @desc returns if GTM is initialized
   * @returns {Boolean}
   */
  getGTMInitialized: (state) => { return state.gtm.initialized },

  /**
   * @desc returns current phones
   * @returns {Array}
   */
  getPhones: state => state.phones ? state.phones : false,

  /**
   * @desc checks if is read GDPR
   * @returns {Boolean}
   */
  isReadGdpr: state => state.readGdpr,

  /**
   * @desc checks if is closed banner app
   * @returns {Boolean}
   */
  isClosedFloaterApp: state => state.closeFloaterApp,
  /**
   * @desc returns last action
   * @returns {String}
   */
  getLastAction: state => state.lastAction,
  /**
   * @desc returns session id (uuid)
   * @returns {String}
   */
  getSessionID: (state) => {
    let SESSSION_ID = state.sessionId
    const formatDate = timestamp => `${new Date(timestamp).getDate()}-${new Date(timestamp).getMonth()}-${new Date(timestamp).getFullYear()}`
    const createSid = () => parseInt(new Date().getTime() / 1000) + '.' + Math.random().toString(36).substring(7)
    const getTimeOfLastAction = () => parseInt((new Date().getTime() - state.lastAction) / 1000)
    const isSameDay = () => formatDate(state.lastAction) === formatDate(new Date().getTime())

    if (!state.sessionId || getTimeOfLastAction() > (60 * 30) || !isSameDay()) {
      SESSSION_ID = createSid()
    }

    return SESSSION_ID
  },

  /**
   * @desc check if device is an Apple's device
   * @returns {Boolean}
   */
  isIOS: state => state.deviceType === 'ios',

  /**
   * @desc check if device is an Android device
   * @returns {Boolean}
   */
  isAndroid: state => state.deviceType === 'android',

  /**
   * @desc checks if is toolbar closed
   * @returns {Boolean}
   */
  isClosedPromotionsToolbar: state => state.isClosedPromotionsToolbar === true,

  /**
   * @desc check if is landing pages available debug
   * @returns {Boolean}
   */
  isLandingPageDebugOn: state => state.landingPagesDate !== null,

  /**
   * @desc return landing page debug date
   * @returns {String|null}
   */
  landingPageDebugDate: state => state.landingPagesDate,

  /**
   * @desc return coupon string
   * @returns {String|null}
   */
  getAutoCoupon: state => state.autoCoupon,

  /**
   * @desc check if can load chatbot script
   * @returns {Boolean}
   */
  canLoadChatBot: state => state.loadChatBot,

  /**
   * @desc return current event promotion
   * @returns {String|null}
   */
  getCustomEvent: state => state.customEvent,

  /**
   * @desc return custom events promotion
   * @returns {String|null}
   */
  getEvents: state => state.events,

  getPremmiaEvent: state => state.premmiaEvent,

  getExistePremmiaEvent: state => state.premmiaEvent !== undefined,

  isExcludeBrazilFromSearch: state => state.excludeBrazilFromSearch,

  getPreviousChatRoute: state => state.previousChatRoute,

  getGoogleUserData: (state, getters) => {
    return getters['user/getGoogleUserData']
  },

  getCookies: state => state.cookies,

  isGoogleScriptsLoaded: state => state.googleScriptsLoaded

}
