export default {
  name: 'AppSelect',

  props: {
    name: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {}
  },

  watch: {
    options(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return

      const self = this

      setTimeout(() => {
        $(self.$el).val(self.value)
        $(self.$el).material_select()
      }, 500)
    }
  },

  updated() {
    $(this.$el).val(this.value)
    $(this.$el).material_select()
  },

  mounted() {
    const self = this

    /* global $ */
    $(this.$el).val(this.value)
    $(this.$el).material_select()

    $(this.$el).on('change', function () {
      self.$emit('input', this.value)
    })
  },

  destroyed() {
    if (this.$route.name !== this.$routeResolver('routes.search.list')) {
      $(this.$el).material_select('destroy')
    }
  }
}
