export default {
  data() {
    return {
      languagesToRedirect: ['pt-br', 'pt-pt', 'es', 'es-ar', 'es-cl', 'es-co', 'es-mx', 'en', 'en-ca', 'en-gb', 'fr-fr', 'fr-ca', 'de-de', 'it-it', 'nl-nl'],
      strRouteHome: 'routes.home.'
    }
  },
  methods: {
    verifyLangToRedirect(lang) {
      return this.languagesToRedirect.includes(lang)
    },
    verifyBackRouteHomeRedirect(backRoute) {
      let verified = false
      if (backRoute.includes(this.strRouteHome)) {
        verified = this.languagesToRedirect.includes(backRoute.replace(this.strRouteHome, ''))
      }
      return verified
    },
    getRouteHomeRedirect(route) {
      let routeNewHome = ''
      if (this.languagesToRedirect.includes(route)) {
        routeNewHome = route
      } else if (route.includes(this.strRouteHome)) {
        routeNewHome = this.getRouteHomeRedirect(route.replace(this.strRouteHome, ''))
      }
      return routeNewHome
    }
  }
}
