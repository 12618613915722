import { setTimeout } from 'timers'
import Vue from 'vue'
import { Settings } from 'luxon'
import moment from 'moment-timezone'
import SearchUrlService from 'services/SearchUrlService'
import localeHelper from './helpers/locale'
import GA4DataLayerService from '~/services/GA4DataLayerService'
import { cookieTypes } from '~/store/cookie'
import RedirectToHomeResponsive from '@/mixins/homeResponsive/redirect-to-home-responsive'

Vue.mixin({

  mixins: [
    localeHelper,
    RedirectToHomeResponsive
  ],

  data() {
    return {
      fromView: false,
      firstAccess: false,
      dataLayerService: GA4DataLayerService.getInstance()
    }
  },

  head() {
    if (!this.fromView) return {}

    return {
      htmlAttrs: {
        lang: this.$store.getters.getLocale
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setInitialValue()
      if (vm.$options.name != null && vm.$options.name.toLowerCase().indexOf('view-') >= 0) {
        Settings.defaultLocale = vm.getLuxonLocale()
        vm.firstAccess = from.name == null
        vm.fromView = true
      }
    })
  },

  beforeRouteLeave(to, from, next) {
    if (this.fromView) {
      this.$store.dispatch('footer/SET_CONFIG', {})
      this.$store.dispatch('anchor/SET_CONFIG', {})
      window.google_tag_manager?.[process.env.GTM_ID]?.dataLayer.reset()
    }

    next()
  },

  computed: {
    showWhatsapp() {
      return this.$store.getters['featureFlag/isWhatsappAllowed']
    }
  },

  beforeMount() {
    this.setLinkEventFromTranslation()
    if (typeof this.$route.params.currency !== 'undefined' && this.$route.params.currency !== this.$appCookie.get('currency') && !this.$store.getters['user/getValidLogin']) {
      this.$store.dispatch('SET_CURRENCY', this.$route.params.currency)
      this.$appCookie.set('currency', this.$route.params.currency)
    }
  },

  mounted() {
    this.$appCookie.set('GA_ID', this.$store.getters.getSessionID)
    this.$store.dispatch('SET_LAST_ACTION', new Date().getTime())
    this.$appCookie.set('lastAction', this.$store.getters.getLastAction)

    if (this.$options.name != null && this.$options.name.toLowerCase().indexOf('view-') >= 0) {
      WebFontLoader.load({
        google: {
          families: ['Material Icons']
        },
        fontactive() {
          const elements = document.getElementsByClassName('no-icons')
          if (elements && elements.length > 0) elements[0].classList.remove('no-icons')
        }
      })

      if (this.$store.getters['user/getValidLogin']) {
        this.setInitialValue()
      }

      if (!this.firstAccess) {
        this.removeGoogleLoginPopUp()
        setTimeout(() => {
          if (!this.$route.name?.match(/routes\.booking\.configure/)) {
            this.sendVirtualPageView()
          }
        }, 100)
      } else {
        setTimeout(() => {
          this.gtmDataLayer({
            'event': 'appLoaded'
          })
        }, 100)
      }

      this.$nextTick(() => {
        this.displayWhatsAppCard()
      })
    }

    this.$store.dispatch(
      'bookingConfigure/SET_BOOKING_PARTNER_TAP',
      false
    )

    if (this.checkPartnerIsTravelClub()) {
      this.$store.dispatch(
        'bookingConfigure/SET_BOOKING_PARTNER_TRAVEL_CLUB',
        true
      )
    } else {
      this.$store.dispatch(
        'bookingConfigure/SET_BOOKING_PARTNER_TRAVEL_CLUB',
        false
      )
    }
  },

  async destroyed() {
    if (this.fromView && window.hasOwnProperty('RentcarsDataLayer')) {
      window.RentcarsDataLayer = {
        ResidenceCodeValue: await this.$store.getters.getUserResidenceCountry,
        GeoIpCountryCode: window.RentcarsDataLayer.GeoIpCountryCode,
        UserIP: window.RentcarsDataLayer.UserIP,
        Currency: window.RentcarsDataLayer.Currency,
        Language: window.RentcarsDataLayer.Language,
        RequestorID: window.RentcarsDataLayer.RequestorID,
        UserEmail: window.RentcarsDataLayer.UserEmail,
        UserID: window.RentcarsDataLayer.UserID,
        UserCPF: window.RentcarsDataLayer.UserCPF,
        SessionID: window.RentcarsDataLayer.SessionID
      }
    }
  },

  methods: {
    removeGoogleLoginPopUp() {
      const COOKIE_EXISTS = this.$appCookie.get('g_state')
      if (
        this.$store.getters['gdpr/isPerformanceAllowed'] &&
        window.google?.accounts &&
        !COOKIE_EXISTS &&
        this.$store.getters.isReadGdpr &&
        !this.$store.getters['user/getValidLogin'] &&
        /^routes\.(customer\.login|search\.list|booking\.configure|booking\.payment)/.test(this.$route.name)
      ) {
        window.google.accounts.id.cancel()
      }
    },
    setLinkEventFromTranslation(forceUpdate = false) {
      setTimeout(() => {
        if (document.querySelectorAll('.routeLink').length > 0) {
          if (this.$options.name != null && (this.$options.name.toLowerCase().indexOf('view-') >= 0 || forceUpdate === true)) {
            document.querySelectorAll('.routeLink').forEach((element) => {
              element.addEventListener('click', (e) => {
                e.preventDefault()
                if (element.dataset.routeName) {
                  this.$router.push({ name: this.$routeResolver(element.dataset.routeName) })
                } else if (element.dataset.routePath) {
                  this.$router.push({ path: element.dataset.routePath })
                }
              }, false)
            })
          }
        }
      }, 200)
    },

    getLuxonLocale() {
      if (this.$i18n.locale === 'pt-pt') {
        return 'pt'
      }
      return this.$i18n.locale
    },
    /**
     * @desc sets RentcarsDataLayer in window object with initials values
     * @type {Object}
     */
    setInitialValue() {
      let userCPF = null
      const aux = window.RentcarsDataLayer.UserID
      const documentType = this.$store.getters['user/getUserDocumentType']?.toUpperCase()
      const getDocumentNumber = this.$store.getters['user/getUserDocumentNumber']

      if (documentType === 'CPF' && getDocumentNumber) {
        userCPF = getDocumentNumber.replace(/[^0-9]/g, '')
      }

      Object.assign(window.RentcarsDataLayer, {
        UserEmail: this.$store.getters['user/getUserEmail'],
        UserID: this.$store.getters['user/getUserId'],
        UserCPF: userCPF
      })

      if (aux !== this.$store.getters['user/getUserId']) {
        this.gtmDataLayer({ 'event': 'userLogin' })
      }
    },

    /**
     * @desc this method adds a property in RentcarsDataLayer window object
     * @param {String} key
     * @param {*} value
     */
    addRentcarsDataLayerProperty(key, value) {
      if (!window.RentcarsDataLayer) {
        this.setInitialValue()
      }

      window.RentcarsDataLayer[key] = value
      this.updateDataLayer()
    },

    updateDataLayer () {
      const tagDataLayer = document.querySelector('#tagsRentcarsDataLayer')

      if (tagDataLayer && window.RentcarsDataLayer) {
        tagDataLayer.textContent = `var RentcarsDataLayer = ${JSON.stringify(window.RentcarsDataLayer)};`
      }
    },

    /**
     * @desc this method removes a property from RentcarsDataLayer window object
     * @param {String} key
     */
    removeRentcarsDataLayerProperty(key) {
      delete window.RentcarsDataLayer[key]
    },

    /**
     * @desc send data to Google Tag Manager data layer
     * @param {*} data
     */
    gtmDataLayer(data) {
      this.dataLayerService.add(data)
    },

    /**
    * @desc send data to Google Tag Manager data layer about Virtual Page View
    */
    sendVirtualPageView() {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'pageview',
        'virtualUrl': window.location.pathname
      })
    },

    /**
     * @desc send data to Google Tag Manager data layer about Custom Virtual Page View
     * @param {*} virtualPagePath
     */
    sendCustomVirtualPageView(virtualPagePath) {
      if (!virtualPagePath) return
      const virtualUrl = window.location.pathname.split('/')
      if (virtualUrl) {
        virtualUrl.splice(4, 0, virtualPagePath)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'pageview',
          'virtualUrl': virtualUrl.join('/')
        })
      }
    },

    setCookieOnStore(cookieName, storePath) {
      const cookie = this.$appCookie.get(cookieName)

      if (cookie) {
        this.$store.dispatch(storePath, cookie)
      }
    },

    isRequestorIdPWA() {
      return this.$store.getters.getMobicarRequestorID !== parseInt(process.env.MOBICARREQUESTOR_ID)
    },

    setPhones() {
      this.$store.dispatch('SET_PHONES', this.$store.getters.getLocale)
    },

    globalDateFormat(moment = true) {
      if (moment) {
        return this.$t('birthday_format').replace('MM', 'MMM')
      }

      return this.$t('birthday_format').toLowerCase().replace('mm', 'LLL')
    },

    async displayWhatsAppCard() {
      const localeSplit = this.getFirstPartOfLocale()
      const featureFlag = (localeSplit === 'es') ? 'whatsapp_cta_mx' : 'whatsapp_cta'

      try {
        let feature = false
        const allowedLanguages = ['pt', 'es']

        if (!allowedLanguages.includes(localeSplit)) {
          this.$store.dispatch('featureFlag/UPDATE_FEATURE_FLAG', { name: featureFlag, value: false })
          return false
        }

        await this.$featureFlagApi
          .get(`get/${featureFlag}`)
          .then((response) => {
            feature = true
          })
          .catch((e) => {
            this.$bugsnag.notify(e)
          })

        if (feature) {
          const times = {
            format: 'YYYY-MM-DD HH:mm a',
            tz: 'America/Sao_Paulo',
            weekend: {
              startTime: '10:00a',
              endTime: '7:00p'
            },
            weekDay: {
              startTime: '8:00a',
              endTime: '9:00p'
            }
          }

          const whatsAppTime = {
            startTime: '',
            endTime: ''
          }

          const currentTime = this.$route.query.whatsDatetime &&
            process.env.NODE_ENV !== 'production'
            ? moment(this.$route.query.whatsDatetime).tz(times.tz)
            : moment().tz(times.tz)

          if (currentTime.isoWeekday() > 0 && currentTime.isoWeekday() < 6) {
            whatsAppTime.startTime = moment(
              `${currentTime.format('YYYY-MM-DD')} ${times.weekDay.startTime}`,
              times.format
            )
              .tz(times.tz)

            whatsAppTime.endTime = moment(
              `${currentTime.format('YYYY-MM-DD')} ${times.weekDay.endTime}`,
              times.format
            )
              .tz(times.tz)
          } else if ([6, 7].includes(currentTime.isoWeekday())) {
            whatsAppTime.startTime = moment(
              `${currentTime.format('YYYY-MM-DD')} ${times.weekend.startTime}`,
              times.format
            )
              .tz(times.tz)

            whatsAppTime.endTime = moment(
              `${currentTime.format('YYYY-MM-DD')} ${times.weekend.endTime}`,
              times.format
            )
              .tz(times.tz)
          }

          const BLACK_FRIDAY_DAYS = [
            {
              BLACK_FRIDAY_START: moment(
                '2022/11/24 9:00a',
                'YYYY-MM-DD HH:mm a'
              ).tz('America/Sao_Paulo'),
              BLACK_FRIDAY_END: moment(
                '2022/11/25 1:00a',
                'YYYY-MM-DD HH:mm a'
              ).tz('America/Sao_Paulo')
            },
            {
              BLACK_FRIDAY_START: moment(
                '2022/11/25 9:00a',
                'YYYY-MM-DD HH:mm a'
              ).tz('America/Sao_Paulo'),
              BLACK_FRIDAY_END: moment(
                '2022/11/26 1:00a',
                'YYYY-MM-DD HH:mm a'
              ).tz('America/Sao_Paulo')
            }
          ]

          const IS_BLACK_FRIDAY_CHAT_ON = BLACK_FRIDAY_DAYS.find((index) => {
            return !!currentTime.isBetween(index.BLACK_FRIDAY_START, index.BLACK_FRIDAY_END)
          })

          if (IS_BLACK_FRIDAY_CHAT_ON || currentTime.isBetween(whatsAppTime.startTime, whatsAppTime.endTime)) {
            this.$store.dispatch('featureFlag/UPDATE_FEATURE_FLAG', { name: featureFlag, value: true })
            return true
          }
        }

        this.$store.dispatch('featureFlag/UPDATE_FEATURE_FLAG', { name: featureFlag, value: false })
        return false
      } catch (error) {
        this.$bugsnag.notify(error)
        this.$store.dispatch('featureFlag/UPDATE_FEATURE_FLAG', { name: featureFlag, value: false })
        return false
      }
    },

    hasDiscountAmount(option) {
      return option.installments &&
      option.installments.length > 0 &&
      option.installments[0].discountAmount &&
      option.installments[0].discountAmount.rateFormatted
    },

    findDiscountInformation(inputDiscInf) {
      let message = ''

      const payment = inputDiscInf.payment
      const type = inputDiscInf.type
      const coupon = inputDiscInf.coupon
      const booking = inputDiscInf.booking
      const currentli = inputDiscInf.currentli
      const name = inputDiscInf.name ? inputDiscInf.name : undefined
      const hasRetention = !!inputDiscInf.payment.retention
      const hasFixedCouponOrLoyalty = (coupon && !coupon.isPercent) || booking.loyalty

      const option = payment.prepaid.options.find((v) => {
        return v.type.toLowerCase() === type && (name ? v.name.toLowerCase() === name : true)
      })

      if (option) {
        const discountCondition = this.hasDiscountAmount(option) &&
          (
            (coupon == null && booking.loyalty == null) ||
            (hasRetention && hasFixedCouponOrLoyalty)
          )

        if (discountCondition) {
          message += '<span class=\'price-off\'>' +
            `<strike>
                ${currentli(payment.prepaid.amountToPay.currency, payment.prepaid.amountToPay.value)}
              </strike>
            </span>`
        }

        message += currentli(option.installments[0].totalAmount.currency, option.installments[0].totalAmount.value)
      } else {
        message = this.currencyIntl(payment.prepaid?.amountToPay?.currency, payment.prepaid?.amountToPay?.value)
      }

      return message
    },

    conditionToDiscountExist(element) {
      return element && element.installments[0].discountAmount && element.installments[0].discountAmount.rate
    },

    buildDiscountAmountObject(element) {
      return {
        formatted: element.installments[0].discountAmount.rateFormatted,
        rate: element.installments[0].discountAmount.rate
      }
    },

    findDiscountPixBoleto(dataSource, type = null) {
      let pix
      let shopfacil
      let pixDiscountRate = {}
      let boletoDiscountRate = {}
      let discountResponse
      let nameOfKeyToReturn

      const acceptableReference = ['shopfacil', 'primeiro_pay', 'dlocal']

      if (type && type === 'options') {
        pix = dataSource.find(x => x.reference === 'pix')
        shopfacil = dataSource.find(x => acceptableReference.indexOf(x.reference) !== -1)
      } else {
        pix = dataSource.options.find(x => x.reference === 'pix')
        shopfacil = dataSource.options.find(x => acceptableReference.indexOf(x.reference) !== -1)
      }

      if (!pix && !shopfacil) {
        return ''
      }

      if (this.conditionToDiscountExist(pix)) {
        pixDiscountRate = this.buildDiscountAmountObject(pix)
      }

      if (this.conditionToDiscountExist(shopfacil)) {
        boletoDiscountRate = this.buildDiscountAmountObject(shopfacil)
      }

      if (!pixDiscountRate.rate && !boletoDiscountRate.rate) {
        return ''
      }

      const onlyPixDiscExBolNotExit = pixDiscountRate.rate && !shopfacil
      const onlyBolDiscExPixNotExit = boletoDiscountRate.rate && !pix

      if (onlyPixDiscExBolNotExit || onlyBolDiscExPixNotExit) {
        nameOfKeyToReturn = 'common.Discount.variable'
        discountResponse = onlyPixDiscExBolNotExit ? pixDiscountRate.formatted : boletoDiscountRate.formatted
      } else {
        nameOfKeyToReturn = 'common.5Discount'

        const onlyPixDiscountExit = pixDiscountRate.rate && !boletoDiscountRate.rate
        const onlyBoletoDiscountExist = !pixDiscountRate.rate && boletoDiscountRate.rate

        if (onlyPixDiscountExit || onlyBoletoDiscountExist) {
          discountResponse = this.returnDiscountFormatted(
            pixDiscountRate.rate,
            pixDiscountRate,
            boletoDiscountRate
          )
        } else {
          discountResponse = this.returnDiscountFormatted(
            pixDiscountRate.rate >= boletoDiscountRate.rate,
            pixDiscountRate,
            boletoDiscountRate
          )
        }
      }

      return this.$t(nameOfKeyToReturn).replace('{{DISCOUNT_VALUE}}', discountResponse)
    },

    returnDiscountFormatted(testCondition, pixDiscountRate, boletoDiscountRate) {
      return testCondition ? pixDiscountRate.formatted : boletoDiscountRate.formatted
    },

    async compareUrlCountryWithInputCountry(inputCountry, redirectToListPage = false) {
      const appCountry = await this.$store.getters.getAppResidenceCountry
      const countryIsDifferent = inputCountry !== appCountry

      this.$store.dispatch(
        'SET_COUNTRY_RESIDENCE_CODE',
        inputCountry
      )

      this.$appCookie.set(
        cookieTypes.RESIDENCE_CODE,
        inputCountry
      )

      if (countryIsDifferent && redirectToListPage) {
        const SearchUrlClient = new SearchUrlService(this.$root, this.$route.params.params, this.$route.params.plan)
        SearchUrlClient.makeSearchListRoute()
        const query = (this.$store.getters.getMobicarRequestorID !== parseInt(process.env.MOBICARREQUESTOR_ID)) ? { requestorid: this.$store.getters.getMobicarRequestorID } : {}
        const backRoute = this.$routeResolver('routes.search.list', this.$store.getters.getLocale, true, { params: SearchUrlClient.toString() }, query)

        await this.$router.push({ path: backRoute })
      } else if (countryIsDifferent) {
        if (this.verifyLangToRedirect(this.$i18n.locale)) {
          window.location.href = `/${this.$i18n.locale}/`
        } else {
          this.$router.push({ name: this.$routeResolver('routes.home') })
        }
      }

      return countryIsDifferent
    },

    checkPartnerIsTravelClub () {
      return this.$store.getters.getMobicarRequestorID === 8597
    },

    /**
     * @deprecated
     */
    checkPartnerIsTAP () {
      return this.$store.getters.getMobicarRequestorID === 5256
    }
  }
})
