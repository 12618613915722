import googleRecaptcha from '~/mixins/google-recaptcha.js'

const DEFAULT_FEATURE_FLAG_RETURN = false
const DEFAULT_START_FEATURE_FLAG_RETURN = false

export default async function getRecaptchaByFeatureFlag (
  bodyRequest,
  featureFlag
) {
  try {
    if (!await _conditionsToStartRecaptchaProcess(featureFlag)) {
      return
    }

    const recaptchaToken = await googleRecaptcha.methods.getGoogleRecaptchaToken('paymentpage')

    if (recaptchaToken) {
      bodyRequest.recaptchaToken = recaptchaToken
    }
  } catch (error) {
    window.console.error(error)
  }
}

async function _conditionsToStartRecaptchaProcess (featureFlag) {
  let response = DEFAULT_START_FEATURE_FLAG_RETURN

  try {
    if (!await _conditionOfFeatureFlag(featureFlag)) {
      return response
    }

    await googleRecaptcha.methods.initGoogleRecaptchaScript()

    if (!_conditionToExecuteThisProcess()) {
      return response
    }

    response = true
  } catch (error) {
    response = DEFAULT_START_FEATURE_FLAG_RETURN
    window.console.error(error)
  }

  return response
}

async function _conditionOfFeatureFlag (featureFlag) {
  let response = DEFAULT_FEATURE_FLAG_RETURN

  try {
    if ((!featureFlag)) {
      return response
    }

    const pwaFeatureFlagContext = window.$nuxt.$cookie.get('pwaRecaptcha')

    response = pwaFeatureFlagContext || response

    if (pwaFeatureFlagContext === null) {
      response = await featureFlag.get('/get/pwa-recaptcha')

      response = response &&
                  response.data &&
                  response.data.ativo

      window.$nuxt.$cookie.set('pwaRecaptcha', response, { expires: '5m' })
    }

    if (typeof response === 'string') {
      response = response === 'true'
    }
  } catch (error) {
    response = DEFAULT_FEATURE_FLAG_RETURN
    window.console.error(error)
  }

  return response
}

function _conditionToExecuteThisProcess () {
  return window &&
          window.grecaptcha &&
          window.grecaptcha.enterprise
}
