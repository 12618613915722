const localforage = require('localforage')
require('localforage-startswith')

localforage.config({
  driver: [
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    localforage.LOCALSTORAGE
  ],
  name: 'rentcarsmobile'
})

export default async ({ store }) => {
  if (process.client && await store.getters.getAppResidenceCountry == null) {
    store.dispatch('SET_COUNTRY_RESIDENCE_CODE', await store.getters.getUserResidenceCountry)
  }
}
