export default ({ store, query, route, app: { $root, $rentcarsApi, $eventBus, $appCookie } }, inject) => {
  store.dispatch('gdpr/INITIALIZE')
    .then(() => {
      if (query) {
        const origem = query.source || query.origem || query.utm_source
        const campanha = query.campaign || query.campanha || query.utm_campaign
        const conteudo = query.content || query.conteudo || query.utm_content

        if (origem || campanha || conteudo) {
          $appCookie.set('Campanha', JSON.stringify({ origem, campanha, conteudo }))
        }
      }
    })
    .catch((e) => { })
}
