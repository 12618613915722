export const state = () => ({
  objectWithRandomicList: {}
})

export const actions = {
  SET_RANDOMIC_ID_LIST({ commit }, objectWithRandomicList) {
    commit('SET_RANDOMIC_ID_LIST', objectWithRandomicList)
  }
}

export const mutations = {
  SET_RANDOMIC_ID_LIST(state, objectWithRandomicList) {
    state.objectWithRandomicList = objectWithRandomicList
  }
}

export const getters = {
  GET_RANDOMIC_ID_LIST: state => state.objectWithRandomicList
}
