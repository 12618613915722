import SearchConfig from 'config/search'
import SearchRepository from 'repositories/SearchRepository'

export const state = () => ({
  pickupPlaceData: null,
  returnPlaceData: null,
  searCodeError: null,
  optionsFromRest: [],
  treatedOptions: [],
  rentalResponseCounter: 0,
  currentOrder: SearchConfig.orderType.recommended,
  responseErrors: [],
  itinerary: null,
  filters: null,
  requirements: null,
  restError: false,
  requestsSent: false,
  ecProduct: null,
  availableRentals: [],
  machineLearning: 0,
  configureURL: null,
  abTestPP: 0,
  scrolled: null,
  showFilter: false,
  shareCard: null,
  isPdPaymentTest: null,
  paymentMethodsOffline: false,
  rentalCompaniesMap: []
})

export const actions = {
  async SET_PICKUP_PLACE ({ commit, getters }, value) {
    const searchData = await getters.getSearchData()
    if (searchData) {
      searchData.PICKUP_PLACE = value
      commit('SET_FORM_DATA', { ...searchData, LOCALE: this.app.i18n.locale })
    } else {
      commit('SET_FORM_DATA', { PICKUP_PLACE: value, LOCALE: this.app.i18n.locale })
    }
  },
  async SET_RETURN_PLACE ({ commit, getters }, value) {
    const searchData = await getters.getSearchData()
    if (searchData) {
      searchData.RETURN_PLACE = value
      commit('SET_FORM_DATA', { ...searchData, LOCALE: this.app.i18n.locale })
    } else {
      commit('SET_FORM_DATA', { RETURN_PLACE: value, LOCALE: this.app.i18n.locale })
    }
  },
  SET_CODE_ERROR ({ commit }, code) {
    commit('SET_CODE_ERROR', code)
  },
  SET_OPTIONS_FROM_REST: ({ commit }, data) => {
    commit('SET_OPTIONS_FROM_REST', data)
  },
  SET_TREATED_OPTIONS: ({ commit }, data) => {
    commit('SET_TREATED_OPTIONS', data)
  },
  INCREMENT_REQUEST_RESPONSE: ({ commit }) => {
    commit('INCREMENT_REQUEST_RESPONSE')
  },
  SET_CURRENT_ORDER: ({ commit }, value) => {
    commit('SET_CURRENT_ORDER', value)
  },
  CLEAR_APPLIED_FILTERS: ({ commit }) => {
    commit('CLEAR_APPLIED_FILTERS')
  },
  SET_ITINERARY: ({ commit }, itinerary) => {
    commit('SET_ITINERARY', itinerary)
  },
  SET_FILTERS: ({ commit }, filters) => {
    commit('SET_FILTERS', filters)
  },
  SET_REQUIREMENTS: ({ commit }, value) => {
    commit('SET_REQUIREMENTS', value)
  },
  SET_PAYMENT_METHODS_OFFLINE: ({ commit }, value) => {
    commit('SET_PAYMENT_METHODS_OFFLINE', value)
  },
  CLEAR_SEARCH_RESULTS: ({ commit }) => {
    commit('CLEAR_APPLIED_FILTERS')
    commit('CLEAR_SEARCH_RESULTS')
  },
  SET_FORM_DATA ({ commit }, { PICKUP_PLACE, PICKUP_DATE, PICKUP_TIME, RETURN_PLACE, RETURN_DATE, RETURN_TIME, SAME_LOCATION }) {
    commit('SET_FORM_DATA', { PICKUP_PLACE, PICKUP_DATE, PICKUP_TIME, RETURN_PLACE, RETURN_DATE, RETURN_TIME, SAME_LOCATION, LOCALE: this.app.i18n.locale })
  },
  GET_SEARCH_DATA () {
    try {
      return SearchRepository.GetSearchData(this.app.i18n.locale)
    } catch (e) {
      return false
    }
  },
  SET_REST_ERROR: ({ commit }, data) => {
    commit('SET_REST_ERROR', data)
  },
  SET_RESPONSE_ERRORS: ({ commit }, data) => {
    commit('SET_RESPONSE_ERRORS', data)
  },
  SET_ALL_REQUEST_SENT: ({ commit }) => {
    commit('SET_ALL_REQUEST_SENT', true)
  },
  ADD_EC_PRODUCT: ({ commit }, data) => {
    if (data != null) commit('ADD_EC_PRODUCT', data)
  },
  SET_RENTAL_AVAILABLE: ({ commit }, code) => {
    commit('SET_RENTAL_AVAILABLE', code)
  },
  SET_MACHINE_LEARNING: ({ commit }, filter) => {
    commit('SET_MACHINE_LEARNING', filter)
  },
  SET_ABTEST_PP: ({ commit }, filter) => {
    commit('SET_ABTEST_PP', filter)
  },
  SET_SCROLLED: ({ commit }, filter) => {
    commit('SET_SCROLLED', filter)
  },
  SET_SHOWFILTER: ({ commit }, filter) => {
    commit('SET_SHOWFILTER', filter)
  },
  SET_CONFIGURE_URL: ({ commit }, data) => {
    commit('SET_CONFIGURE_URL', data)
  },
  SET_SHARE_CARD: ({ commit }, data) => {
    commit('SET_SHARE_CARD', data)
  },
  SET_PD_PAYMENT_TEST: ({ commit }, value) => {
    commit('SET_PD_PAYMENT_TEST', value)
  },
  SET_RENTAL_COMPANY_MAP: ({ commit }, data) => {
    commit('SET_RENTAL_COMPANY_MAP', data)
  }
}

export const mutations = {
  SET_PICKUP_PLACE (state, value) {
    state.pickupPlaceData = value
  },
  SET_RETURN_PLACE (state, value) {
    state.returnPlaceData = value
  },
  SET_CODE_ERROR (state, code) {
    state.searCodeError = code
  },
  SET_OPTIONS_FROM_REST: (state, data) => {
    state.optionsFromRest.push(data)
    state.treatedOptions.push(data)
  },
  SET_TREATED_OPTIONS: (state, data) => {
    state.treatedOptions = data
  },
  INCREMENT_REQUEST_RESPONSE: (state) => {
    state.rentalResponseCounter += 1
  },
  SET_CURRENT_ORDER: (state, value) => {
    state.currentOrder = value
  },
  SET_ITINERARY: (state, itinerary) => {
    state.itinerary = itinerary
  },
  SET_FILTERS: (state, filters) => {
    state.filters = filters
  },
  SET_REQUIREMENTS: (state, value) => {
    state.requirements = value
  },
  CLEAR_SEARCH_RESULTS: (state) => {
    state.filters = null
    state.currentOrder = SearchConfig.orderType.recommended
    state.optionsFromRest = []
    state.treatedOptions = []
    state.originalRestOptions = []
    state.requestsSent = false
    state.rentalResponseCounter = 0
    state.responseErrors = []
    state.filterPromotions = false
    state.requestsSent = false
    state.availableRentals = []
    state.showFilter = false
    state.itinerary = null
  },
  CLEAR_APPLIED_FILTERS: (state) => {
    state.treatedOptions = state.optionsFromRest
    state.responseErrors = []
  },
  SET_FORM_DATA: (state, data) => {
    SearchRepository.SetSearchData(data)
  },
  SET_REST_ERROR: (state, status) => {
    state.restError = status
  },
  SET_RESPONSE_ERRORS: (state, data) => {
    state.responseErrors = state.responseErrors.concat(data)
  },
  SET_ALL_REQUEST_SENT: (state, status) => {
    state.requestsSent = status
  },
  ADD_EC_PRODUCT: (state, data) => {
    state.ecProduct = data
  },
  SET_RENTAL_AVAILABLE: (state, code) => {
    state.availableRentals.push(code)
  },
  SET_MACHINE_LEARNING: (state, code) => {
    state.machineLearning = parseInt(code, 10)
  },
  SET_ABTEST_PP: (state, code) => {
    state.abTestPP = parseInt(code, 10)
  },
  SET_SCROLLED: (state, status) => {
    state.scrolled = status
  },
  SET_SHOWFILTER: (state, status) => {
    state.showFilter = status
  },
  SET_CONFIGURE_URL (state, data) {
    state.configureURL = data
  },
  SET_SHARE_CARD (state, data) {
    state.shareCard = data
  },
  SET_PD_PAYMENT_TEST (state, value) {
    state.isPdPaymentTest = value
  },
  SET_PAYMENT_METHODS_OFFLINE (state, value) {
    state.paymentMethodsOffline = value
  },
  SET_RENTAL_COMPANY_MAP (state, value) {
    state.rentalCompaniesMap = value
  }
}

export const getters = {
  /**
   * @desc check if exists cache and returns the same location data
   * @return {Boolean}
   */
  async getSameLocation (state, getters, rootState, rootGetters) {
    try {
      const { SAME_LOCATION } = await SearchRepository.GetSearchData(rootGetters.getLocale)
      return SAME_LOCATION
    } catch (e) {
      return false
    }
  },

  /**
   * @desc returns search data
   * @return {Object}
   */
  getSearchData: (state, getters, rootState, rootGetters) => async () => {
    const data = await SearchRepository.GetSearchData(rootGetters.getLocale)
    return data
  },

  /**
   * @desc returns the search error code
   * @returns {Number|null}
   */
  getCodeError: state => state.searCodeError,

  /**
   * @desc returns response count
   * @returns {Number}
   */
  getRentalResponseCounter: state => state.rentalResponseCounter,

  /**
   * @desc returns all treated options
   * @returns {Array}
   */
  getTreatedOptions: state => state.treatedOptions,

  /**
   * @desc returns all options from rest
   * @returns {Array}
   */
  getOptionsFromRest: state => state.optionsFromRest,

  /**
   * @desc returns Current Order
   * @return {Number}
   */
  getCurrentOrder: state => state.currentOrder,

  /**
   * @desc checks if has applied filter
   * @return {Boolean}
   */
  hasFilterApplied: (state, getters) => getters.getOptionsFromRest.length !== getters.getTreatedOptions.length,

  /**
   * @desc return itinerary data for search
   * @return {Object}
   */
  getItinerary: state => state.itinerary,

  /**
   * @desc returns filters data for search
   * @return {Object}
   */
  getFilters: state => state.filters,

  /**
   * @desc returns requirements data for search
   * @return {Array}
   */
  getRequirements: state => state.requirements,

  /**
   * @desc return if rest has error
   */
  getRestWithError: state => state.restError,

  /**
   * @desc returns a list of response errors
   */
  getResponseErrors: state => state.responseErrors,

  /**
   * @desc returns if all requests are send
   * @return {Boolean}
   */
  isAllRequestsSent: state => state.requestsSent,

  /**
   * @desc return product from ec
   * @returns {boolean|null}
   */
  getEcProduct: state => state.ecProduct,

  /**
   * @desc returns rentals filters data for search
   * @return {Object}
   */
  getRentalFilters: state => state.filters !== null ? state.filters.Locadora : [],

  /**
   * @desc returns all available rentals with options
   * @returns {Array}
   */
  getAvailableRentals: state => state.availableRentals,

  /**
   * @desc returns Machine Learning Filter
   * @returns {Number}
   */
  getMachineLearning: state => parseInt(state.machineLearning, 10),

  isDomesticItinerary: (state, getters) => {
    if (getters.getItinerary) {
      return getters.getItinerary.Pickup.Country.Code === 1
    }

    return false
  },

  /**
   * @desc returns ABtest Pre Payment
   * @returns {Number}
   */
  getABTestPP: state => parseInt(state.abTestPP, 10),

  /**
   * @desc returns scrolle event happens in Listar
   * @returns {Boolean}
   */
  getScrolled: state => state.scrolled,

  /**
 * @desc returns showFilter to show filter in Search Page
 * @returns {Boolean}
 */
  getShowFilter: state => state.showFilter,

  /**
   * @desc returns getConfigureURL
   * @returns {Object}
   */
  getConfigureURL: state => state.configureURL,

  /**
   * @desc return card option to share
   * @returns {null|Object}
   */
  getShareCard: state => state.shareCard,

  /**
   * @desc return if is PD payment optimize test
   * @returns {Boolean}
   */
  isPdPaymentTest: state => state.isPdPaymentTest !== null ? parseInt(state.isPdPaymentTest, 10) : null,

  /**
   * @desc return payment methods offline decision
   * @returns {Boolean}
   */
  getPaymentMethodsOffline: state => state.paymentMethodsOffline,

  /**
   * @desc return rental companies filtered on map
   * @returns {Array}
   */
  getRentalCompaniesMap: state => state.rentalCompaniesMap

}
