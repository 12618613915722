import vwoSDK from 'vwo-node-sdk'

export default class AbstractVwoAbTest {
  /**
   * @returns {Promise<object>}
   */
  async settingsVwo () {
    return await vwoSDK.getSettingsFile(process.env.VWO_ACCOUNT_ID, process.env.VWO_SDK_KEY)
  }

  /**
   * @param settingsFile
   * @returns {vwoInstance}
   */
  vwoClient (settingsFile) {
    return vwoSDK.launch({
      settingsFile
    })
  }
}
