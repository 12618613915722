export default class RecentSearchService {
  constructor() {
    this.newOption = {}
    this.storeOptions = []

    Object.seal(this)
  }

  setNewOption(option) {
    this.newOption = option
  }

  setStoreOptions(options) {
    this.storeOptions = options
  }

  needCleanStorage() {
    const hasOptions = this.storeOptions != null && this.storeOptions.length > 0
    const hasOldPatternInOptions = this.storeOptions.some(option => !('payOnArrivalOption' in option))

    if (hasOptions) {
      if ((this.newOption.itinerary.pickup.place.id !== this.storeOptions[0].itinerary.pickup.place.id) || hasOldPatternInOptions) {
        return true
      }
    }

    return false
  }

  canUpdateLastOne() {
    if (this.storeOptions != null && this.storeOptions.length > 0 && !this.newOption.hasOwnProperty('Error')) {
      if (
        this.storeOptions[0].vehicle.id === this.newOption.vehicle.id &&
        this.storeOptions[0].rentalCompany.pickup.id === this.newOption.rentalCompany.pickup.id &&
        this.storeOptions[0].itinerary.pickup.ts === this.newOption.itinerary.pickup.ts &&
        this.storeOptions[0].itinerary.dropoff.ts === this.newOption.itinerary.dropoff.ts
      ) {
        return true
      }
    }

    return false
  }
}
