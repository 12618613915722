import AppLoading from 'components/Loads/LoadingBar/LoadingBar.vue'
import ABTests from 'mixins/ab-tests-mixin'
import { LOCATION_TYPE } from 'config/location-types'
import { COUNTRIES_GUID } from 'config/countries-guid'
import { PARTNERS } from 'config/partners'
import ga4 from 'mixins/google/ga4'
let timeout = null

export default {
  name: 'SearchLocations',

  mixins: [ABTests, ga4],

  components: {
    AppLoading
  },

  data () {
    return {
      type: '',
      termSearch: '',
      places: [],
      recentOptions: [],
      navigatorHasGeoLocation: false,
      hideAirports: false,
      hideRecentLocations: false,
      showForm: false,
      showSearchResults: true
    }
  },

  watch: {
    showForm: (value) => {
      try {
        const header = document.getElementById('header-default')

        if (value) {
          if (header) {
            header.style.display = 'none'
            return
          }
        }
        header.style.display = 'block'
      } catch (error) {
        console.log(error)
      }
    }
  },

  mounted () {
    this.setNavigatorGeoLocation()
    this.$eventBus.$on('openSearchBox', this.openSearchBox)

    this.$store.getters['locations/getRecentLocations']
      .then((response) => {
        this.recentOptions = response
      })
  },

  computed: {
    canShowNearBy () {
      if (this.$store.getters.isBrazilianGeoip && this.requestorIdIsKmDeVantagens) {
        return false
      }

      return this.navigatorHasGeoLocation
    },

    /**
     * @desc checks if is kmdevantagens partner
     * @returns {boolean}
     */
    routeIsKmDeVantagens () {
      return this.$route.name === this.$routeResolver('routes.kmdevantagens', this.$i18n.locale, false)
    },

    /**
     * @desc checks if is kmdevantagens partner
     * @returns {boolean}
     */
    requestorIdIsKmDeVantagens () {
      return this.$store.getters.getMobicarRequestorID === PARTNERS.IPIRANGA
    },

    /**
     * @desc checks if is kmdevantagens partner
     * @returns {boolean}
     */
    routeIsLatamPass () {
      return this.$route.name === this.$routeResolver('routes.latampass', this.$i18n.locale, false)
    },

    /**
     * @desc checks if is latampass partner
     * @returns {boolean}
     */
    requestorIdIsLatamPass () {
      return this.$store.getters.getMobicarRequestorID === PARTNERS.LATAMPASS
    },

    /**
     * @desc checks if is vivo partner
     * @returns {boolean}
     */
    requestorIdIsVivo () {
      return this.$store.getters.getMobicarRequestorID === PARTNERS.VIVO
    },

    canShowClearButton() {
      return this.termSearch.length !== 0
    },

    inputFontSize () {
      return { fontSize: '18px' }
    }
  },

  methods: {
    getExcludedGuid() {
      if (
        this.routeIsKmDeVantagens ||
        this.requestorIdIsKmDeVantagens
      ) {
        return COUNTRIES_GUID.BRAZIL
      } else if (
        this.$store.getters.isExcludeBrazilFromSearch &&
        (
          this.routeIsLatamPass ||
          this.requestorIdIsLatamPass ||
          this.requestorIdIsVivo
        )
      ) {
        return COUNTRIES_GUID.BRAZIL
      }

      return null
    },
    sendSearch() {
      clearTimeout(timeout)

      timeout = setTimeout(async () => {
        await this.searchLocations(this.$refs.searchInput.value)
      }, 800)
    },
    /**
     * @desc this methods checks if browser supports GeoLocation
     */
    setNavigatorGeoLocation() {
      this.navigatorHasGeoLocation = !!navigator.geolocation
    },
    createEventGa4 (term) {
      try {
        this.eventSearch({ term: term })
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },
    /**
     * @desc returns an list of places by term
     * @param {String} argument
     */
    async searchLocations(argument) {
      if (argument.length < 3) {
        this.places = []
      } else {
        try {
          this.$store.dispatch('START_LOADING')
          const excludeGuidAndChildren = this.getExcludedGuid()
          const { data } = await this.$rentcarsApi.get(
            'v3/location',
            {
              term: argument,
              max: 7,
              lat: null,
              long: null,
              excludeGuidAndChildren,
              applicationClientId: this.$store.getters.getMobicarRequestorID
            }
          )
          if (data != null && data.length > 0) {
            this.createEventGa4(argument)
            this.places = data
            this.$store.dispatch('STOP_LOADING')
            this.showSearchResults = true
          } else {
            this.places = []
            this.showSearchResults = false
            throw new Error('Localidade não encontrada')
          }
        } catch (error) {
          this.$store.dispatch('STOP_LOADING')

          /* global Materialize */
          Materialize.toast(
            `<span class="toast-text">${this.$t('search.feedbacknoresults')} </span>`, 4000
          )
        }
      }
    },

    /**
     * @desc send log of the search when user click on the results
     */
    searchLog(option) {
      if (this.termSearch.length !== 0) {
        this.$rentcarsApi.post('v2/location/log', {
          sessionId: this.$store.getters.getSessionID,
          searchedTerm: this.termSearch,
          selectedOptionGuid: option.Guid,
          allOptionsGuid: this.places.map(v => v.Guid)
        })
          .catch(() => { })
      }
    },

    /**
     * @desc this method calls the rest by places next to user
     */
    getOptionNextToMe() {
      if (this.navigatorHasGeoLocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.$store.dispatch('START_LOADING')

            this.$rentcarsApi.get('v2/location', { lat: position.coords.latitude, long: position.coords.longitude, term: null, mas: null })
              .then(response => response.data)
              .then((data) => {
                this.$store.dispatch('STOP_LOADING')

                if (data.length > 0) {
                  this.places = data
                } else {
                  throw new Error('Localidade não encontrada')
                }
              })
              .catch((e) => {
                this.$store.dispatch('STOP_LOADING')

                Materialize.toast(
                  '<span class="toast-text">' + this.$t('toast.locationSearchError') + ' <i class="material-icons icon-toast">error_outline</i></span>', 4000
                )
              })
          },
          () => {
            Materialize.toast('<span class="toast-text">' + '<i class="material-icons icon-toast">error_outline</i></span> ' + this.$t('toast.noGeolocation'), 2000)
          }
        )
      }
    },

    /**
     * @desc returns an icon string for place
     * @param {String} type
     */
    getPlaceTypeIcon(type) {
      const prefix = 'icon-rc-'
      switch (type) {
        case LOCATION_TYPE.CITY:
          return `${prefix}city`
        case LOCATION_TYPE.AIRPORT:
          return `${prefix}airport`
        case LOCATION_TYPE.TRAIN:
        case LOCATION_TYPE.SUBWAY:
          return `${prefix}train-station`
        case LOCATION_TYPE.BUS_STATION:
          return `${prefix}bus-station`
        case LOCATION_TYPE.DISTRICT:
        case LOCATION_TYPE.REGION:
          return `${prefix}neighborhood`
        case LOCATION_TYPE.LOCATION:
          return `${prefix}location`
        case LOCATION_TYPE.HARBOR:
          return `${prefix}harbor`
        default:
          return `${prefix}location`
      }
    },

    /**
     * @desc this method executes when user click on a option from search list
     * @param option
     */
    async selectOption(option) {
      const sameLocation = this.$store.getters['search/getSameLocation']

      if (this.type === 'suggestion') {
        this.$store.dispatch('user/APPEND_CUSTOMER_INTEREST_CITY', option.Name)
        this.$store.dispatch('user/SET_INTERESTED_CITIES_UPDATED', true)
      }
      if (this.type === 'pickup') {
        if (option.canonicalName) {
          option.Name = option.canonicalName
        }
        if (!option.Code) {
          option.Code = option.idLegadoLocalidade
        }
        await this.$store.dispatch('search/SET_PICKUP_PLACE', option)
      }

      if (this.type === 'return' || sameLocation) {
        if (option.canonicalName) {
          option.Name = option.canonicalName
        }
        if (!option.Code) {
          option.Code = option.idLegadoLocalidade
        }
        await this.$store.dispatch('search/SET_RETURN_PLACE', option)
      }

      if (this.type !== 'suggestion') {
        this.$store.dispatch('locations/APPEND_RECENT_LOCATION', option)
      }

      const data = await this.$store.dispatch('search/GET_SEARCH_DATA')
      this.$eventBus.$emit('selectedOption', data)

      this.searchLog(option)

      this.backRoute()
    },

    inputFocus () {
      if (this.showForm) {
        setTimeout(() => {
          this.$el.querySelector('#text-search').focus()
        }, 100)
      }
    },

    /**
     * @desc this method clears the term
     */
    cleanSearchData () {
      this.termSearch = ''
      if (this.showForm) {
        this.$el.querySelector('#text-search').focus()
      }
      this.places = []
    },

    openSearchBox(argument) {
      this.type = argument.type

      if (argument.hasOwnProperty('hideAirports')) {
        this.hideAirports = argument.hideAirports
      }

      if (argument.hasOwnProperty('hideRecentLocations')) {
        this.hideRecentLocations = argument.hideRecentLocations
      }

      this.showForm = true
      this.inputFocus()

      $('body').addClass('block-content').attr('touch-action', 'none')
    },

    backRoute() {
      this.showForm = false
      this.cleanSearchData()
      $('body').removeClass('block-content').removeAttr('touch-action')
    },

    numberStores(numberStores) {
      return this.$t('taxonomy.rcbylocation', {
        'stores': numberStores
      })
    },

    getLocationName (location) {
      return location.canonicalName || location.Name
    }
  },

  destroyed() {
    this.showForm = false
    this.cleanSearchData()
    this.$eventBus.$off('openSearchBox')
  }
}
