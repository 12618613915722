import insiderEnum from '../InsiderEnum.js'

export default {
  objects: [
    insiderEnum.USER,
    insiderEnum.SEARCH,
    insiderEnum.CONFIRMATION,
    insiderEnum.RENTAL_COMPANY,
    insiderEnum.LOCATIONS,
    insiderEnum.CAMPAIGN_LP,
    insiderEnum.HOME,
    insiderEnum.PROMOTTIONS,
    insiderEnum.UPDATE_USER_INFORMATION,
    insiderEnum.PRODUCT,
    insiderEnum.LISTING_PAGE_VIEW,
    insiderEnum.BASKET,
    insiderEnum.TRANSACTION_OBJECT
  ],

  events: [
    insiderEnum.VIEWED_NO_RESULTS,
    insiderEnum.PRODUCT_DETAIL_PAGE_VIEW,
    insiderEnum.VIEWED_PAYMENT_STEP,
    insiderEnum.VIEWED_LOGIN_STEP,
    insiderEnum.SIGN_UP_CONFIRMATION,
    insiderEnum.LOGGED_IN,
    insiderEnum.LOGGED_OUT,
    insiderEnum.SESSION_START,
    insiderEnum.PURCHASE
  ],

  setOfMainAttributes: [
    'name',
    'taxonomy',
    'currency',
    'unit_price',
    'unit_sale_price',
    'url',
    'stock',
    'color',
    'size',
    'product_image_url'
  ],

  sendObjectToDispatch: [
    insiderEnum.PRODUCT,
    insiderEnum.BASKET,
    insiderEnum.TRANSACTION_OBJECT,
    insiderEnum.UPDATE_USER_INFORMATION,
    insiderEnum.SEARCH
  ]
}
