import Vue from 'vue'
import VueCookie from 'vue-cookie'
import extend from 'lodash/extend'
import { PERFORMANCE, PARTNERS, MANDATORY } from 'config/gdpr-cookies'
import EXTERNAL_PARTNERS_ID from '../core/domain/enums/shared/PartnerIdEnum'

Vue.use(VueCookie)

export default ({ store, isHMR, query }, inject) => {
  if (isHMR) return

  store.dispatch('cookie/SET_APP_DOMAIN', document.location.hostname)

  const appCookieConfig = {
    domain: store.getters['cookie/getAppUrl'],
    expires: EXTERNAL_PARTNERS_ID.includes(query.requestorid) ? '30m' : '30D'
  }

  inject('appCookie', {
    set: (key, value, config = {}) => {
      if (
        MANDATORY.cookies.includes(key) ||
        (
          store.getters['gdpr/isPerformanceAllowed'] &&
          PERFORMANCE.cookies.includes(key)
        ) ||
        (
          store.getters['gdpr/arePartnersAllowed'] &&
          PARTNERS.cookies.includes(key)
        )
      ) {
        return VueCookie.set(key, value, extend(appCookieConfig, config))
      }

      return false
    },
    get: key => VueCookie.get(key),
    delete: (key, domain = null) => {
      if (domain !== null) {
        return VueCookie.delete(key, { domain })
      }

      return VueCookie.delete(key, { domain: appCookieConfig.domain })
    }
  })
}
