import { mapGetters } from 'vuex'

import BottomSheet from '../BottomSheet/BottomSheet.vue'
import Currencies from 'config/currencies'
import filter from 'lodash/filter'
import RedirectChangeCurrency from '../../../services/Authentication/RedirectChangeCurrency'

export default {
  name: 'app-modal-change-currency',

  components: {
    BottomSheet
  },

  data() {
    return {
      modalIsOpen: false
    }
  },

  computed: {
    ...mapGetters({
      isModalUpdateCurrencyOpen: 'getIsModalUpdateCurrencyOpen',
      objectCurrencyModal: 'getObjectUpdateCurrency',
      userLogged: 'user/getLoggedUser',
      currencySelected: 'getCurrency'
    })
  },

  mounted() {
    setTimeout(() => {
      this.modalIsOpen = this.isModalUpdateCurrencyOpen
    }, 3000)
  },

  watch: {
    'isModalUpdateCurrencyOpen' (newValue) {
      this.modalIsOpen = newValue
    }
  },

  methods: {
    currenciesOptions() {
      return filter(Currencies.currencies).map(currency => ({
        value: currency.code,
        label: `${this.$t('currencies.' + currency.code)}`,
        htmlcode: currency.htmlcode
      }))
    },

    getCurrency(isUserCurrency) {
      try {
        const { currentCurrency, newCurrency } = this.objectCurrencyModal
        const findCurrency = isUserCurrency ? newCurrency : currentCurrency
        const currency = this.currenciesOptions().find(currency => currency.value === findCurrency)

        if (currency) {
          return currency
        }
      } catch (e) {
        return {
          label: '',
          htmlcode: '',
          value: ''
        }
      }
    },

    continueCurrentCurrency(updateCurrency = true) {
      if (updateCurrency) {
        const body = {
          currency: this.$store.getters.getCurrency,
          email: this.$store.getters['user/getUserEmail']
        }

        try {
          this.$rentcarsApi.put('v1/user/change-currency', body)
        } catch (error) {
          this.$bugsnag.notify(error)
        }
      }

      this.$store.dispatch('SET_UPDATE_CURRENCY_MODAL', false)
      this.$store.dispatch('SET_UPDATE_OBJECT_CURRENCY_MODAL', {})
      this.modalIsOpen = false
    },

    updateCurrency() {
      new RedirectChangeCurrency(this).redirectOnChangeCurrency(this.objectCurrencyModal.newCurrency)
      this.continueCurrentCurrency(false)
    }
  }
}
