export default {
  NEW_PAYMENT_V2: 'PAYMENT_V2',
  NEW_LIST_CONFIGURE_FLOW: 'New List and Configure Page Flow',
  ASYNC_CONFIRMATION: 'PWA Async Confirmation Foco',
  HIDE_PAY_METHODS_MODAL: 'HIDE_PAY_METHODS_MODAL',
  HIDE_PAYMENT_METHODS_MODAL_VIEW: 'HIDE_PAYMENT_METHODS_MODAL_VIEW',
  BOOKING_TIMER: 'paymentBookingTimerPWA',
  BOOKING_TIMER_SHORTAGE: 'paymentTimerAndShortagePWA',
  MACHINE_LEARNING: 'machineLearningPWA',
  INTERACTION_WITH_RENTAL_COVER: '[XP][PWA]InteractionWithRentalCover',
  REMOVE_PD: 'removePD',
  REMOVE_PD_UNIDAS: 'removePDUnidas',
  INTERACTION_WITH_RENTAL_COVER_V2: '[XP][PWA]InteractionWithRentalCoverV2',
  INTERACTION_WITH_RENTAL_COVER_V3: '[XP][PWA]InteractionWithRentalCoverV3',
  RENTPROTECTION_PWA: 'rentProtectionPwa',
  RENTPROTECTION_LOCAL: 'rentProtectionLocal'
}
