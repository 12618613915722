export const state = () => ({
  abTest: {
    name: null,
    version: null
  }
})

export const mutations = {
  SET_AB_TEST (state, name) {
    state.abTest.name = name
  },

  SET_AB_VERSION (state, variant) {
    state.abTest.version = variant
  }
}

export const actions = {
  SET_AB_TEST: ({ commit }, name) => {
    commit('SET_AB_TEST', name)
  },

  SET_AB_VERSION: ({ commit }, variant) => {
    commit('SET_AB_VERSION', variant)
  }
}

export const getters = {
  getABTest: state => state.abTest.name,
  getABVersion: state => state.abTest.version
}
