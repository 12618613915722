export default {
  defaultPickupTime: '10:00',
  defaultReturnTime: '10:00',
  orderType: {
    recommended: 'recommended',
    lowestPrice: 'price-asc',
    highestPrice: 'price-desc',
    promotion: 'promotions',
    evaluation: 'evaluation'
  }
}
