import i18n from 'config/i18n'
import SupplierFactory from 'factories/configure/SupplierFactory'
import PlansFactory from 'factories/configure/PlansFactory'
import OptionalsFactory from 'factories/booking/OptionalsFactory'
import CoverageFactory from 'factories/booking/CoverageFactory'
import ItineraryFactory from 'factories/booking/ItineraryFactory'

export default class BookingConfigurationFactory {
  /**
   * @desc Represents a BookingConfigurationFactory
   * @constructor
   */
  constructor(configuration) {
    if (configuration == null) {
      return {}
    }
    return this.legacyConfigurationNormalization(configuration)
  }

  /**
   * @desc treats configuration from legacy
   * @param {object} configuration
   * @return {object}
   */
  legacyConfigurationNormalization(configuration) {
    return {
      IsNational: configuration.Itinerario.Retirada.Pais.Codigo === i18n.brazilianID,
      Itinerary: new ItineraryFactory(configuration.Itinerario),
      Suppliers: {
        Pickup: new SupplierFactory(configuration.Locadora.Retirada),
        Dropoff: new SupplierFactory(configuration.Locadora.Devolucao)
      },
      Plans: new PlansFactory(configuration.Plano),
      Optionals: new OptionalsFactory(configuration.Opcional.Opcao),
      Coverages: new CoverageFactory(configuration.Protecao.Opcao)
    }
  }
}
