export default {
  async getUser (param, app, userJWT = null) {
    let endpoint
    switch (typeof param) {
      case 'string':
        endpoint = `/v1/user?email=${param}`
        break
      case 'number':
        endpoint = `/v1/user/${param}`
        break
    }

    let data = null
    let status = null

    await app.$rentcarsApi
      .get(endpoint,
        null,
        false,
        null,
        userJWT
      )
      .then((response) => {
        data = response.data
        status = response.status
      })
      .catch(() => { })

    return status === 200 ? data : null
  },

  async changeCurrencyUser (body, app) {
    try {
      await app.$rentcarsApi.put('v1/user/change-currency', body)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  }
}
