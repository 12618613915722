import { mapGetters } from 'vuex'
import Moment from 'moment-timezone'

export default {
  name: 'itinerary-header',

  computed: {
    ...mapGetters({
      itinerary: 'search/getItinerary',
      itineraryNew: 'searchNew/getItinerary'
    }),

    pickupDate() {
      const value = this.itinerary ? this.itinerary.Pickup.Date : this.itineraryNew.pickup.ts
      return Moment(value).format(this.globalDateFormat())
    },

    returnDate() {
      const value = this.itinerary ? this.itinerary.Return.Date : this.itineraryNew.dropoff.ts
      return Moment(value).format(this.globalDateFormat())
    },

    itineraryName() {
      return this.itinerary ? this.itinerary.Pickup.Place.Name : this.itineraryNew.pickup.place.name
    }
  },

  methods: {
    openEditSearch() {
      this.$store.dispatch('search/SET_SCROLLED', false)
      return this.$router.push({ name: this.$routeResolver('routes.search.change-search') })
    }
  }
}
