import insiderEnum from 'services/Insider/InsiderEnum.js'
import Moment from 'moment-timezone'

export default function buildUserObject (inputUserObject) {
  const { loggedUser, insiderObject } = inputUserObject
  const pageInsert = _getPageInsert(inputUserObject)

  if (pageInsert && !insiderObject.page) {
    insiderObject.page = {
      type: null
    }

    insiderObject.page.type = inputUserObject.typeOfObject.charAt(0).toUpperCase() + inputUserObject.typeOfObject.slice(1)
  }

  if (_isHomeObject(insiderObject.page.type)) {
    if (insiderObject.custom) {
      insiderObject.user = {
        custom: insiderObject.custom
      }
    }

    insiderObject.page.type = inputUserObject.typeOfObject.charAt(0).toUpperCase() + inputUserObject.typeOfObject.slice(1)
  }

  delete insiderObject.custom

  if (!insiderObject.user) {
    insiderObject.user = {}
  }

  insiderObject.user.custom = _buildCustomObject(loggedUser)

  if (!loggedUser?.authentication) {
    return insiderObject
  }
  insiderObject.user = {
    uuid: loggedUser?.id?.toString(),
    gender: loggedUser?.gender === 1 ? insiderEnum.MALE : insiderEnum.FEMALE,
    birthday: loggedUser?.birthdate,
    gdpr_optin: _avaliableGdprOption(loggedUser),
    sms_optin: _avaliableOption(loggedUser),
    whatsapp_optin: _avaliableOption(loggedUser),
    email_optin: _avaliableOption(loggedUser),
    name: loggedUser?.name,
    surname: loggedUser?.surname,
    email: loggedUser?.email,
    phone_number: loggedUser?.phone?.replaceAll(' ', '').replace('-', ''),
    language: loggedUser?.language ? _adapteLanguage(loggedUser?.language.toLowerCase()) : null,
    authentication: loggedUser?.authentication,
    ...insiderObject.user
  }
  insiderObject.user.custom.residence_continent_id = loggedUser?.continent?.code
  return insiderObject
}

function _buildCustomObject (loggedUser) {
  const custom = {}
  custom.device_type = insiderEnum.MOBILE
  custom.country = loggedUser?.country?.alpha2
  custom.city = loggedUser?.city?.name
  custom.state = loggedUser?.state?.name
  custom.timezone = Moment.tz.guess()

  return custom
}

function _getPageInsert (inputUserObject) {
  return inputUserObject.pageInsert === null || inputUserObject.pageInsert === undefined ? false : inputUserObject.pageInsert
}

function _adapteLanguage (language) {
  return !language ? null : language.split('_').map((e, k) => { return k === 1 ? e.toLowerCase() : e }).join('-')
}

function _avaliableOption (loggedUser) {
  if (loggedUser.newsletter === undefined || loggedUser.newsletter === null) {
    return loggedUser.offersUpdate ?? false
  }

  return loggedUser.newsletter
}

function _avaliableGdprOption (loggedUser) {
  if (!(loggedUser?.acceptanceTerm)) {
    return false
  }

  if (typeof loggedUser.acceptanceTerm === 'boolean') {
    return loggedUser.acceptanceTerm
  }

  return loggedUser.acceptanceTerm === 1 || loggedUser.acceptanceTerm === '1'
}

function _isHomeObject (typeOfObject) {
  return [
    insiderEnum.HOME
  ].includes(typeOfObject.toLowerCase())
}
