import RentcarsLegacyLoginService from '~/services/Authentication/RentcarsLegacyLoginService'

export default {
  methods: {
    initGsiScript (root) {
      try {
        if ('head' in document && document.head.nodeType === 1) {
          const GOOGLE_SIGN_IN_SCRIPT = document.createElement('script')
          GOOGLE_SIGN_IN_SCRIPT.src = 'https://accounts.google.com/gsi/client'
          GOOGLE_SIGN_IN_SCRIPT.defer = true
          GOOGLE_SIGN_IN_SCRIPT.onload = function () {
            root.$store.dispatch('SET_GOOGLE_SCRIPTS_LOADED', true)
            window.google.accounts.id.initialize({
              client_id: process.env.GOOGLE_SIGN_IN_CLIENT_ID,
              cancel_on_tap_outside: false,
              callback: (response) => {
                root.$rentcarsApi.post('/v1/user/easy-login', {
                  credential: response.credential
                })
                  .then(async (response) => {
                    let USER = await RentcarsLegacyLoginService.updateUserCountryAndReturnData(
                      {
                        response,
                        store: root.$store,
                        rentcarsApi: root.$rentcarsApi,
                        tokenJWT: root.$store.getters['user/getUserToken'],
                        userID: root.$store.getters['user/getUserId']
                      }
                    )

                    if (RentcarsLegacyLoginService.shouldUpdateUserAcceptedTerms(USER)) {
                      const responseUpdateTerms = await RentcarsLegacyLoginService.updateUserAcceptedTerms({
                        response,
                        rentcarsApi: root.$rentcarsApi,
                        tokenJWT: root.$store.getters['user/getUserToken']
                      })

                      if (responseUpdateTerms?.token) {
                        USER = {
                          ...USER,
                          acceptanceTerm: true
                        }
                      }
                    }

                    const appCurrency = root.$appCookie.get('currency')
                    if (response?.data?.currency && response.data.currency !== appCurrency) {
                      const openModalData = {
                        currentCurrency: appCurrency,
                        newCurrency: response.data.currency
                      }

                      setTimeout(() => {
                        const includedRoutes = [
                          `routes.booking.configure.${root.$store.getters.getLocale}`,
                          `routes.home.${root.$store.getters.getLocale}`
                        ]

                        if (includedRoutes.includes(root.$route.name)) {
                          root.$store.dispatch('SET_UPDATE_CURRENCY_MODAL', true)
                        }
                      }, 300)

                      root.$store.dispatch('SET_UPDATE_OBJECT_CURRENCY_MODAL', openModalData)
                    }

                    if (USER) {
                      root.$store.dispatch('user/SET_USER_LOGIN', USER)
                      root.$eventBus.$emit('GOOGLE_USER_AUTH', USER)
                    }
                  })
                  .catch((err) => {
                    root.$eventBus.$emit('RENTCARS_LEGACY_AUTH_ERROR', err)
                  })
              }
            })
            if (
              !document.cookie.split(';').some(item => item.trim().startsWith('g_state=')) &&
              root.$store.getters.isReadGdpr &&
              !root.$store.getters['user/getValidLogin'] &&
              !/^routes\.(customer\.login|search\.list|booking\.configure|booking\.payment)/.test(root.$route.name)
            ) {
              window.google.accounts.id.prompt()
            }
          }
          document.head.appendChild(GOOGLE_SIGN_IN_SCRIPT)
        }
      } catch (error) {

      }
    }
  }
}
