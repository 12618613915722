import Moment from 'moment-timezone'
import formatDateToInsider from '~/services/Insider/utils/formatDateToInsider'
import insiderEnum from '~/services/Insider/InsiderEnum'

export default class InsiderUtils {
  static instance

  constructor (config = null) {
    if ((!config) || (config !== 'start') || InsiderUtils.instance) {
      throw new Error(insiderEnum.ERROR_CONFIG_MSG)
    }
  }

  static start () {
    if (!InsiderUtils.instance) {
      InsiderUtils.instance = new InsiderUtils('start')
    }

    return InsiderUtils.instance
  }

  formatTimeStamp (ts, format = null) {
    const moment = new Moment(ts).tz(Moment.tz.guess())
    return (format) ? moment.format(format) : moment
  }

  getCountry () {
    return window.$nuxt.$store.getters.getAppResidenceCountry
  }

  getAdditionalValues (extra, typeOfItem = insiderEnum.INCLUDED) {
    if (!extra) return null

    let response = extra.items.filter(item => item[typeOfItem])

    if (typeOfItem === insiderEnum.INCLUDED) {
      response = response.filter(item => !item[insiderEnum.INCLUDED_PLAN])
    }

    return response.map(item => item.name)
  }

  buildSearchObject (params) {
    const response = {
      currency: params?.currency_code,
      rental_length: params?.itinerary?.daily,
      request_device: insiderEnum.MOBILE,
      requestor_id: params?.requestor_id,
      days_in_advance: params?.itinerary?.daysadvance,
      pickup_city_id: params?.itinerary?.pickup?.city?.id,
      pickup_location_id: params?.itinerary?.pickup?.city?.id,
      ...this.mainItineraryInformation(params?.itinerary, params?.user),
      dropoff_city_id: params?.itinerary?.dropoff?.city?.id,
      dropoff_location_id: params?.itinerary?.dropoff?.place?.id,
      country: this.getCountry(),
      url: this.getUrl()
    }

    return response
  }

  mainItineraryInformation (itinerary, user = null) {
    if (!itinerary) {
      return {}
    }

    return {
      pickup_country: itinerary?.pickup?.country?.iso,
      pickup_city: itinerary?.pickup?.city?.name,
      pickup_type: itinerary?.pickup?.place?.type,
      pickup_date: itinerary?.pickup?.ts,
      pickup_time: itinerary?.pickup?.ts ? this.formatTimeStamp(itinerary.pickup.ts, 'HH:mm:ss') : null,
      dropoff_continent_id: itinerary?.dropoff?.continent?.iso,
      dropoff_continent: itinerary?.dropoff?.continent?.name,
      dropoff_country: itinerary?.dropoff?.country?.iso,
      dropoff_city: itinerary?.dropoff?.city?.name,
      dropoff_type: itinerary?.dropoff?.place?.type,
      dropoff_date: itinerary?.dropoff?.ts,
      dropoff_time: itinerary?.dropoff?.ts ? this.formatTimeStamp(itinerary.dropoff.ts, 'HH:mm:ss') : null,
      dropoff_formatted_date: itinerary?.dropoff?.formattedDate,
      pickup_formatted_date: itinerary?.pickup?.formattedDate
    }
  }

  buildViewdBaseObject (input) {
    const { params, vehicle, itinerary, utm, taxonomyData, locale } = input

    const user = input.user ?? (params.user ?? null)

    return {
      product_image_url: vehicle.image,
      ...this.mainItineraryInformation(itinerary, user),
      rental_length: itinerary.daily,
      days_in_advance: itinerary.daysadvance,
      language: locale.toLowerCase(),
      country: params.bookingInfo?.countryResidence.ISO,
      supplier_name: params.rentalCompany.brand,
      requestor_id: params.requestor_id ?? this.getRequestorId(),
      request_device: insiderEnum.MOBILE,
      ...utm,
      product: vehicle.similar,
      product_id: vehicle.id,
      name: vehicle.name,
      taxonomy: taxonomyData,
      category: vehicle.category.name,
      url: params.url ?? this.getUrl(),
      additional: this.getAdditionalValues(params.extra, insiderEnum.INCLUDED),
      additional_included: this.getAdditionalValues(params.extra, insiderEnum.INCLUDED_PLAN)
    }
  }

  getUrl () {
    return `${process.env.BASE_URL}${window.$nuxt.$route.fullPath}`
  }

  getRequestorId () {
    return window.$nuxt.$store.getters.getMobicarRequestorID
  }

  getLocale () {
    return window.$nuxt.$store.getters.getLocale
  }

  getCookie (key) {
    return window.$nuxt.$cookie.get(key)
  }

  processLanguage (language) {
    if (!language) {
      return null
    }

    if (language === 'en') {
      return 'en_US'
    }

    if (language === 'es') {
      return 'es_ES'
    }

    const languageArray = language.split('-')

    return [
      languageArray[0].toLowerCase(),
      languageArray[1].toUpperCase()
    ].join('_')
  }

  userInfs (userData, utmObject, requestorId, clienteReturning) {
    return {
      uuid: userData?.id.toString() ?? null,
      name: userData?.name ?? null,
      surname: userData?.surname ?? null,
      email: userData?.email ?? null,
      gender: userData?.gender ?? null,
      phone_number: userData?.phone ?? null,
      authentication: userData?.authentication ?? null,
      language: this.processLanguage(window.navigator?.language ?? null),
      country: userData?.country?.name ?? null,
      birthday: userData?.birthdate ?? null,
      city: userData?.city?.name ?? null,
      city_id: userData?.city?.id ?? null,
      timezone: Moment.tz.guess(),
      email_optin: userData?.newsletter ?? null,
      gdpr_optin: true,
      sms_optin: userData?.newsletter ?? null,
      whatsapp_optin: userData?.newsletter ?? null,
      requestor_id: requestorId,
      custom: {
        acceptance_term: userData?.acceptanceTerm ?? null,
        zip_code: userData?.zipCode ?? null,
        neighborhood: userData?.neighborhood ?? null,
        address: userData?.address ?? null,
        returning: clienteReturning,
        ...utmObject
      }
    }
  }

  buildCarDetails (vehicle, taxonomyData, detailsOnConfigure, payment, itinerary) {
    return {
      product: vehicle,
      id: vehicle?.id,
      name: vehicle?.name,
      taxonomy: taxonomyData,
      category: vehicle?.category,
      rental_company: detailsOnConfigure.rentalCompany?.reference,
      caracteristics: vehicle.configurations,
      currency: payment.totalAmount?.currency,
      unit_price: payment.totalAmount?.value,
      unit_sale_price: payment.totalAmountDiscount?.value,
      url: this.getUrl(),
      product_image_url: vehicle.image,
      subtotal: payment.totalAmountDiscount?.value,
      promotions: payment.tag?.name,
      custom: {
        plan: detailsOnConfigure.plan.name,
        payment: payment.type,
        charged_ttv: payment.totalAmount.value,
        charged_commissionable_ttv: detailsOnConfigure.commission.commissioned.value,
        charged_commission: detailsOnConfigure.commission.mobicar.value,
        exchange_rate: detailsOnConfigure.exchange.value,
        original_currency: detailsOnConfigure.totalAmount.currency,
        suplier_name: detailsOnConfigure.rentalCompany.reference,
        pickup_continent: itinerary.pickup.continent.name,
        pickup_country: itinerary.pickup.country.acronym,
        pickup_city: itinerary.pickup.city.name,
        pickup_type: itinerary.pickup.place.type.toLowerCase() === 'airport' ? 'Airport' : 'City',
        pickup_date: formatDateToInsider(itinerary.pickup.ts),
        pickup_time: this.formatTimeStamp(itinerary.pickup.ts, 'HH:mm:ss'),
        dropoff_continent: itinerary.dropoff.continent.name,
        dropoff_country: itinerary.dropoff.country.acronym,
        dropoff_city: itinerary.dropoff.city.name,
        dropoff_type: itinerary.dropoff.place?.type.toLowerCase(),
        dropoff_date: formatDateToInsider(itinerary.dropoff.ts),
        dropoff_time: this.formatTimeStamp(itinerary.dropoff.ts, 'hh:mm:ss'),
        rental_length: itinerary.daily,
        days_in_advance: itinerary.daysadvance
      }
    }
  }

  buildInsiderConfirmation (reservation, Group, taxonomyData, payment, Coupon, promotions, Itinerary) {
    return {
      basket: {
        order_id: reservation.Booking.ID,
        product: Group,
        id: Group.Code,
        name: Group.Name,
        taxonomy: taxonomyData,
        category: [Group.Category],
        rental_company: reservation.Supplier.Reference,
        caracteristics: Group.Configuration.Featured,
        currency: payment.Currency,
        unit_price: payment.Details.Totals.TotalAmount,
        unit_sale_price: payment.Details.Totals.TotalAmount,
        url: this.getUrl(),
        product_image_url: Group.Photo,
        subtotal: payment.SubTotal,
        vouchers: Coupon?.Code,
        voucher_discount: Coupon?.Discount.Amount,
        voucher_discount_ratio: Coupon ? parseFloat(Coupon.Discount.Amount) / payment.Details.PlanAmount.TotalAmount : null,
        promotions,
        custom: {
          plan: payment.Details.PlanAmount.Name,
          payment: payment.PaymentType,
          charged_ttv: `${payment.Symbol} ${payment.FormattedSubTotal}`,
          charged_commissionable_ttv: `${payment.Symbol} ${reservation.Commission.Commissioned.Amount}`,
          charged_commission: `${payment.Symbol} ${reservation.Commission.Mobicar.Amount}`,
          exchange_rate: reservation.Exchange.Value,
          original_currency: payment.Currency,
          suplier_name: reservation.Supplier.Name,
          pickup_continent: Itinerary.Pickup.Continent.Name,
          pickup_country: Itinerary.Pickup.Country.initials,
          pickup_city: Itinerary.Pickup.City.Name,
          pickup_type: Itinerary.Pickup.Place.Type.toLowerCase() === 'airport' ? 'Airport' : 'City',
          pickup_date: Itinerary.Pickup.Date,
          pickup_time: Itinerary.Pickup.Time,
          dropoff_continent: Itinerary.Return.Continent.Name,
          dropoff_country: Itinerary.Return.Country.initials,
          dropoff_city: Itinerary.Return.City.Name,
          dropoff_type: Itinerary.Return.Place.Type.toLowerCase(),
          dropoff_date: Itinerary.Return.Date,
          dropoff_time: Itinerary.Return.Time,
          rental_length: Itinerary.Daily,
          days_in_advance: Itinerary.DaysAdvance
        }
      }
    }
  }

  conditionToSpecialCase (insiderName) {
    return [insiderEnum.SESSION_START].indexOf(insiderName) !== -1
  }

  builderListingInputeData (itinerary) {
    let response = false

    try {
      const pickup = itinerary.pickup
      const pickupDateTime = new Moment(pickup.ts)

      const dropoff = itinerary.dropoff
      const dropoffDateTime = new Moment(dropoff.ts)

      response = {
        pickup_continent: pickup.continent?.name,
        pickup_country: pickup.country?.iso,
        pickup_city: pickup.city?.name,
        pickup_type: pickup.place?.type.toLowerCase(),
        pickup_date: formatDateToInsider(pickupDateTime),
        pickup_time: pickupDateTime.format('hh:mm'),
        dropoff_continent: dropoff.continent?.name,
        dropoff_country: dropoff.country?.iso,
        dropoff_city: dropoff.city.name,
        dropoff_type: dropoff.place?.type.toLowerCase(),
        dropoff_date: formatDateToInsider(dropoffDateTime),
        dropoff_time: dropoffDateTime.format('hh:mm'),
        rental_length: itinerary.daily,
        days_in_advance: itinerary.daysadvance
      }
    } catch (error) {
      response = false
    }

    return response
  }

  builderSearchEventData (itinerary, store) {
    if (!itinerary) {
      return
    }

    const defaultParams = {
      locale: store.getters.getLocale,
      currency_code: process.browser ? store.getters.getCurrency : null,
      requestor_id: process.browser ? store.getters.getMobicarRequestorID : null
    }

    return Object.assign({}, { itinerary }, defaultParams)
  }

  insiderFormatLanguage (locale) {
    if (!locale) {
      return
    }

    const localeArray = locale.split('-')

    if (localeArray.length === 0) {
      return
    }

    if (localeArray[1]) {
      localeArray[1] = localeArray[1].toUpperCase()
    } else {
      localeArray[1] = localeArray[0].toUpperCase()
    }

    return localeArray.join('_')
  }

  conditionToGoOutOfProductDetailView (data, query) {
    return (!data) || (query?.step && (query.step === '2' || query.step === '3'))
  }

  ifRentalCoverIsIncluded (params) {
    return params?.rentalCover?.included
  }

  rentalcoverProcess (params, insiderEvent) {
    insiderEvent.rentalcover = this.ifRentalCoverIsIncluded(params)
  }
}
