export default {
  name: 'Gdpr',

  data() {
    return {
      activeGdpr: false
    }
  },

  mounted() {
    setTimeout(() => {
      this.activeGdpr = !this.$store.getters.isReadGdpr
    }, 500)
  },

  watch: {
    '$store.getters.isReadGdpr'(newValue, oldValue) {
      if (newValue !== oldValue) this.activeGdpr = !newValue
    }
  },

  methods: {
    closeGdpr() {
      this.$appCookie.set('readGdpr', 1)
      this.$store.dispatch('SET_READ_GDPR', true)
      this.activeGdpr = false
      if (
        'google' in window &&
        this.$store.getters['gdpr/isPerformaceAllowed'] &&
        !/^routes\.(customer\.login|search\.list|booking\.configure|booking\.payment)/.test(this.$route.name)
      ) {
        window.google.accounts.id.prompt()
      }
    }
  }
}
