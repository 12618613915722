import localforage from 'localforage'

const NAMESPACE = 'UserRepository-'
const UserDataKey = 'UserData'

export const GetUserData = () => localforage.getItem(NAMESPACE + UserDataKey)

export const SetUserData = async (data) => {
  await localforage.setItem(NAMESPACE + UserDataKey, data).then((value) => {
    return value
  }).catch(() => {})
}

export const DeleteUserData = () => localforage.removeItem(NAMESPACE + UserDataKey)

export default { GetUserData, SetUserData, DeleteUserData }
