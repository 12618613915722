export const state = () => ({
  data: null
})

export const actions = {
  SET_DATA: ({ commit }, data) => {
    commit('SET_DATA', data)
  }
}

export const mutations = {
  SET_DATA: (state, data) => {
    state.data = data
  }
}

export const getters = {
  GET_DATA: state => state.data
}
