import insiderEnum from '../../services/Insider/InsiderEnum'
import StoreDataInteractions from '../../services/Insider/StoreDataInteractions'

export default {
  methods: {
    storeProcessExecution(params) {
      const storeDataInteraction = StoreDataInteractions.start()
      const storeGetInsider = this.$store.getters[insiderEnum.INSIDER_GET_DATA]

      if (storeDataInteraction.verifyIfHasToSet(params)) {
        this.$store.dispatch(
          insiderEnum.INSIDER_SET_DATA,
          storeDataInteraction.inputDataToSet(params)
        )
      }

      if (storeDataInteraction.verifyIfHasToGetInput(params)) {
        storeDataInteraction.insertDataToParams(storeGetInsider, params)
      }
    }
  }
}
