import VwoABTest from 'mixins/dynamicTestPlataform/vwoABTest'
import vwoVariantEnum from 'mixins/dynamicTestPlataform/vwoVariantEnum'
import vwoTrackKeyEnum from 'mixins/dynamicTestPlataform/vwoTrackKeyEnum'

export default {

  methods: {
    defineConfigForce () {
      return this.$route.query.forceV2
    },

    async getVariationName (campaignKey) {
      try {
        const userId = this.getUserId()
        const vwoAbTest = new VwoABTest()

        await vwoAbTest.getVwoABTest(userId, campaignKey)
        return await vwoAbTest.getVwoVariationName(userId, campaignKey)
      } catch (e) {
        this.$bugsnag.notify(e)
        return vwoVariantEnum.DEFAULT
      }
    },

    async isVariationAbTest (campaignKey, variant) {
      try {
        const variantName = await this.getVariationName(campaignKey)
        return (variantName === variant) || this.defineConfigForce()
      } catch (e) {
        this.$bugsnag.notify(e)
        return this.defineConfigForce()
      }
    },

    async setMetricInTheFlow (campaignKey, metricKey, options = {}) {
      try {
        const userId = this.getUserId()
        const vwoAbTest = new VwoABTest()
        await vwoAbTest.setVwoTrackABTest(userId, campaignKey, metricKey, options)
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async setBookingConversionFullMetrics (campaignKey, booking, commission, bookingCode) {
      try {
        const { RentalCover, Optionals } = booking
        await this.setBookingConversionMetrics(campaignKey, commission, bookingCode)
        await this.setRentalcoverConversionMetrics(campaignKey, RentalCover, commission, bookingCode)
        await this.setOptionalsMetrics(campaignKey, Optionals)
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async setBookingConversionMetrics (campaignKey, commission, bookingCode) {
      try {
        const options = {
          eventProperties: {
            booking_id: bookingCode,
            revenue: commission?.GA4?.value ?? 0
          }
        }
        await this.setMetricInTheFlow(campaignKey, vwoTrackKeyEnum.METRIC_KEY_BOOKING_CONVERSION, options)
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async setRentalcoverConversionMetrics (campaignKey, rentalCover, commission, bookingCode) {
      try {
        if (rentalCover?.Included) {
          const options = {
            eventProperties: {
              booking_id: bookingCode,
              revenue: commission?.GA4?.value ?? 0
            }
          }
          await this.setMetricInTheFlow(campaignKey, vwoTrackKeyEnum.METRIC_KEY_RENTAL_COVER_CONVERSION, options)
        }
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async setOptionalsMetrics (campaignKey, optionals) {
      const optionalsIncluded = optionals.filter(optional => optional.Included && !optional.IncludedPlan)

      if (optionalsIncluded?.length > 0) {
        await this.setMetricInTheFlow(campaignKey, vwoTrackKeyEnum.METRIC_KEY_OPTIONALS_CONVERSION)
      }
    },

    async setAcessPaymentPage (campaignKey, options = {}) {
      try {
        await this.setMetricInTheFlow(campaignKey, vwoTrackKeyEnum.METRIC_KEY_VIEW_PAYMENT_PAGE, options)
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async setAcessLoginPage (campaignKey, options = {}) {
      try {
        await this.setMetricInTheFlow(campaignKey, vwoTrackKeyEnum.METRIC_KEY_VIEW_LOGIN_PAGE, options)
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async setLoginToPayment (campaignKey, options = {}) {
      try {
        await this.setMetricInTheFlow(campaignKey, vwoTrackKeyEnum.METRIC_KEY_LOGIN_TO_PAYMENT, options)
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    getUserId () {
      return this.$appCookie.get('userUuid')
    }
  }
}
