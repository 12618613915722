import axios from 'axios'

export default ({ env }, inject) => {
  inject('rentcarsCdn', (uri) => {
    return setPath(uri, env.RENTCARS_CDN)
  })

  inject('mobileCdn', (uri) => {
    if (env.NODE_ENV !== 'development') {
      return setPath(uri, env.MOBILE_CDN)
    } else {
      return setPath(uri, env.BASE_URL.replace(/http:\/\//i, 'http://static.'))
    }
  })

  function setPath(uri, target) {
    if (uri?.substring(0, 1) === '/') {
      return `${target}${uri}`
    }
    return `${target}/${uri}`
  }

  const client = axios.create({
    baseURL: env.CDN_PROMOTION,
    timeout: 10000,
    proxyHeaders: false
  })

  const promotionsCdn = {
    client: axios,

    get: (uri) => {
      return client.get(uri)
    }
  }

  inject('promotionsCdn', promotionsCdn)
}
