import { mapState, mapGetters } from 'vuex'
import AppModal from 'components/Modal/Default/Default.vue'
import Multiselect from 'vue-multiselect'
import UserControlService from '../../../services/Authentication/UserControlService'

export default {
  name: 'AppModalChangeCountry',

  extends: AppModal,

  props: {
    id: {
      required: false,
      type: String
    },
    title: {
      required: false,
      type: String
    }
  },

  components: {
    Multiselect
  },

  data() {
    return {
      modalResidenceCountryCode: '',
      currentCountry: '',
      residenceCountry: this.$store.getters['user/getUserResidenceCountry']
    }
  },

  async mounted () {
    this.modalResidenceCountryCode = await this.$store.getters.getAppResidenceCountry
    this.currentCountry = this.countries.filter(country => country.iso === this.modalResidenceCountryCode)

    if ('residenceCode' in this.$route.params) {
      this.modalResidenceCountryCode = this.$route.params.residenceCode
    }

    if (this.isValidLogin) {
      await this.openModal()
    }
  },

  watch: {
    async route() {
      this.modalResidenceCountryCode = await this.$store.getters.getAppResidenceCountry
      const isSameResidence = this.userLogged?.country?.alpha2 === this.modalResidenceCountryCode
      if (!isSameResidence && this.isValidLogin) {
        await this.openModal()
      }
    }
  },

  computed: {
    ...mapState({
      route: 'route'
    }),
    ...mapGetters({
      isValidLogin: 'user/getValidLogin',
      userLogged: 'user/getLoggedUser'
    }),

    countries() {
      let countries = this.$store.getters['common/getCountries'](this.$helpers.localeForTranslation())
      countries = countries.map((country) => {
        country.currentNameLanguage = country.name[this.$i18n.locale]
        return country
      })
      return countries
    }
  },

  methods: {
    openModal() {
      if (this.canOpenModalByRoute() && this.modalResidenceCountryCode !== this.residenceCountry) {
        this.emitGTM('open-modal')
        setTimeout(() => {
          $(this.$el).modal({
            startingTop: '50%',
            endingTop: '50%',
            dismissible: false
          }).modal('open')
        }, 2000)
      }
    },

    changeCountry () {
      const appResidence = this.$store.getters.getAppResidenceCountry
      const countryInformed = this.currentCountry?.iso ?? this.currentCountry[0].iso
      const redirectToListPage = countryInformed !== appResidence

      this.$validator.validateAll()
        .then((value) => {
          if (value === false) {
            return
          }
          const email = this.$store.getters['user/getUserEmail']

          const body = {
            country: countryInformed,
            email,
            token: this.$store.getters['user/getUserToken'],
            id: this.$store.getters['user/getUserId']
          }

          this.$rentcarsApi
            .put('/v1/user', body, false, [], false)
            .then(async (response) => {
              if (response.status === 200) {
                $(this.$el).modal('close')

                const data = await UserControlService.getUser(email, this.$root, this.$store.getters['user/getUserToken'])

                this.$store.dispatch('user/UPDATE_USER_DATA', data)
                Materialize.toast('<span class="toast-text">' + this.$t('toast.updateProfileSuccess') + ' <i class="material-icons icon-toast">error_outline</i></span>', 4000)

                if (redirectToListPage) {
                  await this.compareUrlCountryWithInputCountry(countryInformed, redirectToListPage)
                }
              } else {
                Materialize.toast('<span class="toast-text">' + this.$t('toast.updateProfileError') + ' <i class="material-icons icon-toast">error_outline</i></span>', 4000)
              }
            })
            .catch((e) => {
              Materialize.toast('<span class="toast-text">' + this.$t('toast.updateProfileError') + ' <i class="material-icons icon-toast">error_outline</i></span>', 4000)
            })
        })
        .catch(() => {
        })
    },

    canOpenModalByRoute() {
      return this.isRouteWithRedirect() || this.isRouteWithoutRedirect()
    },

    isRouteWithRedirect() {
      const routes = [
        'routes.search.list',
        'routes.booking.detail',
        'routes.booking.configure',
        'routes.booking.identification'
      ]

      return this.route.name != null && routes.some(v => this.route.name.indexOf(v) >= 0)
    },

    isRouteWithoutRedirect() {
      const routes = [
        'routes.booking.confirmation',
        'routes.customer.account',
        'routes.customer.bookings',
        'routes.customer.bookings.old'
      ]

      return this.route.name != null && routes.some(v => this.route.name.indexOf(v) >= 0)
    },

    getFlagIcon(value) {
      const iso = value?.toLowerCase()

      return `${this.$mobileCdn(`images/flags/4x3/${iso}.svg`)}`
    },

    textStrategy(country) {
      try {
        const name = country.name[this.$i18n.locale]

        return name
      } catch (e) {
        return null
      }
    },

    emitGTM (eventName) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: eventName,
        category: 'configurar',
        action: 'country-confirmation',
        label: eventName
      })
    },

    changeCurrentCountry () {
      this.emitGTM('select-country')
    }
  }
}
