import insiderEnum from './InsiderEnum'

export default class ConditionToStart {
  static verificationHasCondToStop(params = null, insiderEventName = null) {
    if (ConditionToStart.argsIsNull(params, insiderEventName)) {
      return true
    }
    const condInsiderName = ConditionToStart.insiderName(params, insiderEventName)
    let condToStartSessionPageVire = false

    const nameOfEvent = params.insiderEventName ?? insiderEventName

    if (ConditionToStart.setOfEventsWithCookies().indexOf(nameOfEvent) !== -1) {
      condToStartSessionPageVire = ConditionToStart.accesPagesViewThatHasCookie(params, insiderEnum.SESSION_PAGE_VIEW)
    }

    return condInsiderName || condToStartSessionPageVire
  }

  static argsIsNull(params, insiderEventName) {
    return params === null && insiderEventName === null
  }

  static insiderName(params, insiderEventName) {
    const conditionOfInsiderName = params && params.insiderEventName ? params.insiderEventName : (insiderEventName ? insiderEventName.toLowerCase() : null)

    if (insiderEventName && (!params.insiderEventName)) {
      params.insiderEventName = insiderEventName
    }

    return !conditionOfInsiderName
  }

  static accesPagesViewThatHasCookie(params, typeOfCondition) {
    let condition = false
    let generalCondition

    if (typeOfCondition === insiderEnum.SESSION_PAGE_VIEW) {
      generalCondition = ConditionToStart.pgViewDataObject(insiderEnum.SESSION_PAGE_VIEW, insiderEnum.SESSION_PAGE_VIEW_COOKIG)
    } else {
      return condition
    }

    if (params.insiderEventName === generalCondition.sessionName) {
      condition = window.$nuxt.$cookie.get(generalCondition.eventName)

      if (condition === 'false' || !condition) {
        window.$nuxt.$cookie.set(generalCondition.eventName, true)
      }
    }
    return condition === 'true'
  }

  static pgViewDataObject(sessionName = false, eventName = false) {
    return {
      sessionName,
      eventName
    }
  }

  static setOfEventsWithCookies() {
    return [insiderEnum.SESSION_START, insiderEnum.HOME_PAGE_VIEW]
  }
}
