import { v1 as uuidv1 } from 'uuid'

export default ({ app: { $appCookie } }) => {
  if ($appCookie.get('userUuid') === null) {
    $appCookie.set('userUuid', uuidv1())
  }
}

export const getUserIdWhenNull = () => {
  const app = window?.$nuxt
  try {
    const userUuid = uuidv1()
    app?.$appCookie.set('userUuid', userUuid)
    return userUuid
  } catch (error) {
    app?.$bugsnag.notify(error)
    return null
  }
}
