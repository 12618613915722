export default {
  'defaultCurrency': 'USD',
  'fallback': 'USD',
  'brazilianCurrency': 'BRL',
  'currencies': {
    'USD': {
      'htmlcode': 'US$',
      'code': 'USD',
      'top': true,
      'locale': 'en_US'
    },
    'ARS': {
      'htmlcode': 'ARS',
      'code': 'ARS',
      'top': true,
      'locale': 'es_ES'
    },
    'AUD': {
      'htmlcode': 'AUD',
      'code': 'AUD',
      'top': false,
      'locale': 'en_AU'
    },
    'BRL': {
      'htmlcode': 'R$',
      'code': 'BRL',
      'top': true,
      'locale': 'pt_BR'
    },
    'BOB': {
      'htmlcode': 'BOB',
      'code': 'BOB',
      'top': false,
      'locale': 'es_BO'
    },
    'CAD': {
      'htmlcode': 'CAD',
      'code': 'CAD',
      'top': true,
      'locale': 'en_CA'
    },
    'CLP': {
      'htmlcode': 'CLP',
      'code': 'CLP',
      'top': true,
      'locale': 'es_CL'
    },
    'CNY': {
      'htmlcode': 'CNY',
      'code': 'CNY',
      'top': false,
      'locale': 'zh_CN'
    },
    'COP': {
      'htmlcode': 'COP',
      'code': 'COP',
      'top': false,
      'locale': 'es_CO'
    },
    'EUR': {
      'htmlcode': '&euro;',
      'code': 'EUR',
      'top': true,
      'locale': 'de'
    },
    'JPY': {
      'htmlcode': 'JPY',
      'code': 'JPY',
      'top': false,
      'locale': 'ja'
    },
    'MXN': {
      'htmlcode': 'MXN',
      'code': 'MXN',
      'top': true,
      'locale': 'es_MX'
    },
    'NZD': {
      'htmlcode': 'NZD',
      'code': 'NZD',
      'top': false,
      'locale': 'en_NZ'
    },
    'PEN': {
      'htmlcode': 'PEN',
      'code': 'PEN',
      'top': false,
      'locale': 'es_PE'
    },
    'GBP': {
      'htmlcode': '&pound;',
      'code': 'GBP',
      'top': true,
      'locale': 'en_GB'
    },
    'CHF': {
      'htmlcode': 'CHF',
      'code': 'CHF',
      'top': false,
      'locale': 'fr_CH'
    },
    'UYU': {
      'htmlcode': 'UYU',
      'code': 'UYU',
      'top': false,
      'locale': 'es_UY'
    },
    // 'VEF': {
    //   'htmlcode': 'VEF',
    //   'code': 'VEF',
    //   'top': false,
    //   'locale': 'es_VE'
    // },
    'ZAR': {
      'htmlcode': 'ZAR',
      'code': 'ZAR',
      'top': false,
      'locale': 'en_ZA'
    },
    // 'PYG': {
    //   'htmlcode': 'PYG',
    //   'code': 'PYG',
    //   'top': false,
    //   'locale': 'es_PY'
    // },
    'DOP': {
      'htmlcode': 'DOP',
      'code': 'DOP',
      'top': false,
      'locale': 'es_DO'
    },

    'SGD': {
      'htmlcode': 'SGD',
      'code': 'SGD',
      'top': false,
      'locale': 'en'
    },

    'DKK': {
      'htmlcode': 'DKK',
      'code': 'DKK',
      'top': false,
      'locale': 'en'
    },

    'RUB': {
      'htmlcode': 'RUB',
      'code': 'RUB',
      'top': false,
      'locale': 'en'
    },

    'SEK': {
      'htmlcode': 'SEK',
      'code': 'SEK',
      'top': false,
      'locale': 'en'
    },

    'PLN': {
      'htmlcode': 'PLN',
      'code': 'PLN',
      'top': false,
      'locale': 'en'
    },

    'TWD': {
      'htmlcode': 'TWD',
      'code': 'TWD',
      'top': false,
      'locale': 'en'
    },

    'CZK': {
      'htmlcode': 'CZK',
      'code': 'CZK',
      'top': false,
      'locale': 'en'
    },

    'ILS': {
      'htmlcode': 'ILS',
      'code': 'ILS',
      'top': false,
      'locale': 'en'
    },

    'OMR': {
      'htmlcode': 'OMR',
      'code': 'OMR',
      'top': false,
      'locale': 'en'
    },

    'JOD': {
      'htmlcode': 'JOD',
      'code': 'JOD',
      'top': false,
      'locale': 'en'
    },

    'AZN': {
      'htmlcode': 'AZN',
      'code': 'AZN',
      'top': false,
      'locale': 'en'
    },

    'BGN': {
      'htmlcode': 'BGN',
      'code': 'BGN',
      'top': false,
      'locale': 'en'
    },

    'HKD': {
      'htmlcode': 'HKD',
      'code': 'HKD',
      'top': false,
      'locale': 'en'
    },

    'KWD': {
      'htmlcode': 'KWD',
      'code': 'KWD',
      'top': false,
      'locale': 'en'
    },

    'BHD': {
      'htmlcode': 'BHD',
      'code': 'BHD',
      'top': false,
      'locale': 'en'
    },

    'FJD': {
      'htmlcode': 'FJD',
      'code': 'FJD',
      'top': false,
      'locale': 'en'
    },

    'GEL': {
      'htmlcode': 'GEL',
      'code': 'GEL',
      'top': false,
      'locale': 'en'
    },

    'QAR': {
      'htmlcode': 'QAR',
      'code': 'QAR',
      'top': false,
      'locale': 'en'
    },

    'AED': {
      'htmlcode': 'AED',
      'code': 'AED',
      'top': false,
      'locale': 'en'
    },

    'SAR': {
      'htmlcode': 'SAR',
      'code': 'SAR',
      'top': false,
      'locale': 'en'
    },

    'MYR': {
      'htmlcode': 'MYR',
      'code': 'MYR',
      'top': false,
      'locale': 'en'
    },

    'RON': {
      'htmlcode': 'RON',
      'code': 'RON',
      'top': false,
      'locale': 'en'
    },

    'TRY': {
      'htmlcode': 'TRY',
      'code': 'TRY',
      'top': false,
      'locale': 'en'
    },

    'NOK': {
      'htmlcode': 'NOK',
      'code': 'NOK',
      'top': false,
      'locale': 'en'
    },

    'NAD': {
      'htmlcode': 'NAD',
      'code': 'NAD',
      'top': false,
      'locale': 'en'
    },

    'EGP': {
      'htmlcode': 'EGP',
      'code': 'EGP',
      'top': false,
      'locale': 'en'
    },

    'MDL': {
      'htmlcode': 'MDL',
      'code': 'MDL',
      'top': false,
      'locale': 'en'
    },

    'UAH': {
      'htmlcode': 'UAH',
      'code': 'UAH',
      'top': false,
      'locale': 'en'
    },

    'THB': {
      'htmlcode': 'THB',
      'code': 'THB',
      'top': false,
      'locale': 'en'
    },

    'INR': {
      'htmlcode': 'INR',
      'code': 'INR',
      'top': false,
      'locale': 'en'
    },

    'HUF': {
      'htmlcode': 'HUF',
      'code': 'HUF',
      'top': false,
      'locale': 'en'
    },

    'KZT': {
      'htmlcode': 'KZT',
      'code': 'KZT',
      'top': false,
      'locale': 'en'
    },

    'XOF': {
      'htmlcode': 'XOF',
      'code': 'XOF',
      'top': false,
      'locale': 'en'
    },

    'KRW': {
      'htmlcode': 'KRW',
      'code': 'KRW',
      'top': false,
      'locale': 'en'
    }
  }
}
