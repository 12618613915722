export const AUTH_MODAL_STEPS = {
  GREETINGS: 'greetings',
  EMAIL_CHECKER: 'email-checker',
  LOGIN: 'login',
  REGISTER: 'register',
  RECOVER_PASSWORD: 'recover-password'
}

export const AUTH_MODAL_FROM = {
  DEFAULT: 'default',
  CONFIGURE: 'configure',
  IDENTIFICATION: 'identification',
  ACCOUNT: 'account',
  PROMOTIONS: 'promotions'
}
