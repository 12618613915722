// LIST OF ROUTES WITHOUT BANNER ON FIRST ACCESS
export const ROUTES_WITHOUT_BANNER = [
  'routes.booking.detail',
  'routes.booking.configure',
  'routes.booking.payment',
  'routes.booking.confirmation',
  'routes.booking.identification',
  'routes.customer.signup',
  'routes.customer.login',
  'routes.customer.password-recover',
  'routes.customer.booking.change',
  'routes.customer.booking.cancel',
  'routes.customer.booking.voucher'
]
