export default class HashIdGenerate {
  static instance

  constructor () {
    this._hashId
  }

  static getInstance () {
    if (!HashIdGenerate.instance) {
      HashIdGenerate.instance = new HashIdGenerate()
    }

    return HashIdGenerate.instance
  }

  hashGenerate () {
    if (!this._hashId) {
      this._hashId = Math.random().toString(36).slice(2)
    }
  }

  cleanHashGenerate () {
    this._hashId = null
  }

  getHashGenerate () {
    return this._hashId
  }
}
