import InsiderEnum from './InsiderEnum'

export default class EventsAccepted {
  static setVarInRelationEvents(params, utmProperties, url, requestorId, userLogged) {
    if (EventsAccepted.eventsThatAcceptUrlAttrParams(params)) {
      params.utm = utmProperties
      params.url = url
    }

    if (EventsAccepted.eventsThatAcceptRequestorId(params)) {
      params.requestorId = requestorId
    }

    if (EventsAccepted.eventsThatAcceptUserInformation(params)) {
      params.userData = userLogged
    }
  }

  static eventsThatAcceptUrlAttrParams(params) {
    return [
      InsiderEnum.PRODUCT_DETAIL_PAGE_VIEW,
      InsiderEnum.VIEWED_PAYMENT_STEP,
      InsiderEnum.VIEWED_LOGIN_STEP,
      InsiderEnum.LISTING_PAGE_VIEW,
      InsiderEnum.VIEWED_NO_RESULTS,
      InsiderEnum.HOME_PAGE_VIEW,
      InsiderEnum.SESSION_START
    ].includes(params.insiderEventName)
  }

  static eventsThatAcceptRequestorId(params) {
    return [
      'session_start',
      'home_page_view',
      'sign_up_confirmation'
    ].includes(params.insiderEventName)
  }

  static eventsThatAcceptUserInformation(params) {
    return ['sign_up_confirmation'].includes(params.insiderEventName)
  }
}
