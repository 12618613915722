import LazyHydrate from 'vue-lazy-hydration'
import DefaultLayout from 'layouts/default.vue'

export default {
  name: 'DefaultLayout',

  extends: DefaultLayout,

  components: { LazyHydrate },

  methods: {
    /**
     * Return class component
     * @returns {{[p: string]: boolean, "reset-this": boolean}}
     */
    getLayoutClass() {
      return {
        'blackfriday-2019': this.$store.getters.getCustomEvent === 'black_friday_evento',
        'blackfriday-2019-esquenta': this.$store.getters.getCustomEvent === 'black_friday_esquenta',
        'blackfriday-2019-europa': this.$store.getters.getCustomEvent === 'bf_europa_evento',
        'blackfriday-2019-europa-esquenta': this.$store.getters.getCustomEvent === 'bf_europa_esquenta',
        'blackfriday-2019-latam': this.$store.getters.getCustomEvent === 'bf_latam_evento',
        'cyberweek-2019': this.$store.getters.getCustomEvent === 'cyber_week_evento',
        'cybermonday-2019': this.$store.getters.getCustomEvent === 'cyber_monday_evento',
        'traveltuesday-2019': this.$store.getters.getCustomEvent === 'travel_tuesday_evento',
        'wrapper-all no-icons': true
      }
    }
  }
}
