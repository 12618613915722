export const ROUTES_CANT_ACCESS_OFFLINE = [
  'routes.info.contact',
  'routes.customer.login',
  'routes.rental',
  'routes.rental.airport',
  'routes.rental.country',
  'routes.rental.location',
  'routes.rentals',
  'routes.rentals.country',
  'routes.locations',
  'routes.faq',
  'routes.customer.password-recover',
  'routes.booking.detail',
  'routes.customer.booking.cancel',
  'routes.customer.booking.change',
  'routes.search.unavailable-vehicle',
  'routes.customer.signup',
  'routes.airports',
  'routes.airports.country',
  'routes.airports.location',
  'routes.locations',
  'routes.locations.country',
  'routes.locations.location',
  'routes.info.privacy',
  'routes.info.requirements'
]
