export default {
  listOfCustom: [
    'requestor_id',
    'utm',
    'days_in_advance',
    'dropoff_date',
    'dropoff_city',
    'dropoff_city_id',
    'dropoff_continent',
    'dropoff_country',
    'dropoff_location_id',
    'dropoff_time',
    'dropoff_type',
    'dropoff_location',
    'pickup_date',
    'pickup_city',
    'pickup_city_id',
    'pickup_continent',
    'pickup_country',
    'pickup_location_id',
    'pickup_location',
    'pickup_time',
    'pickup_type',
    'rental_length',
    'authentication',
    'commission',
    'product',
    'payment',
    'plan',
    'supplier_name',
    'rentalcover',
    'payment_currency',
    'payment_value',
    'promotions',
    'coverage',
    'additional',
    'additional_included',
    'payment_type',
    'payment_currency_symbol',
    'payment_formatted_amount',
    'advantages',
    'amount',
    'formatted_amount',
    'order_code',
    'type',
    'category',
    'voucher_discount_formatted',
    'pickup_formatted_date',
    'dropoff_formatted_date',
    'rental_company_evaluation',
    'currency_after_amount'
  ]
}
