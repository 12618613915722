export const state = () => ({
  previousRouteStore: null
})

export const actions = {
  SET_PREVIOUS_ROUTE_STORE: ({ commit }, data) => {
    commit('SET_PREVIOUS_ROUTE_STORE', data)
  }
}
export const mutations = {
  SET_PREVIOUS_ROUTE_STORE: (state, stateSelected) => {
    state.previousRouteStore = stateSelected
  }
}

export const getters = {
  getPreviousRouteStore: state => state.previousRouteStore
}
