export default {
  async doRentcarsLegacyLogin(email, credential, app, tokenJWT = null, userID = null) {
    if (!email || !credential || !app) {
      return
    }

    const endpoint = '/v1/user/login'
    const params = {
      email,
      password: credential.password,
      facebook: credential.facebookId,
      google: credential.googleId,
      apple: credential.appleAuthId
    }

    const { data, status } = await app.$rentcarsApi
      .post(endpoint, params, false, true, tokenJWT)
      .catch((err) => {
        app.$eventBus.$emit('RENTCARS_LEGACY_AUTH_ERROR', err)
        return null
      })

    const appCurrency = app.$appCookie.get('currency')
    if (data?.currency && data.currency !== appCurrency) {
      const openModalData = {
        currentCurrency: appCurrency,
        newCurrency: data.currency
      }
      const includedRoutes = [
        `routes.booking.configure.${app.$store.getters.getLocale}`,
        `routes.home.${app.$store.getters.getLocale}`
      ]

      setTimeout(() => {
        if (includedRoutes.includes(app.$route.name)) {
          app.$store.dispatch('SET_UPDATE_CURRENCY_MODAL', true)
        }
      }, 300)

      app.$store.dispatch('SET_UPDATE_OBJECT_CURRENCY_MODAL', openModalData)
    }

    if (data?.country?.alpha2 === undefined) {
      await app.$rentcarsApi.put(
        '/v1/user',
        {
          email: data.email,
          country: await app.$store.getters.getUserResidenceCountry,
          token: tokenJWT,
          id: data.id
        },
        false,
        [],
        false
      )
        .catch(() => { })

      const response = await app.$rentcarsApi.get('/v1/user/me', null, false, 10000, data.token)

      if (response.status === 200 && response.data) {
        return response.data
      }
    } else if (status === 200 && data) {
      return data
    }

    return null
  },

  async doRentcarsSocialLogin(credential, app, tokenJWT = null, userID = null) {
    if (!credential || !app) {
      return
    }

    const endpoint = '/v1/user/social-login'
    const params = {
      email: credential.email,
      name: credential.givenName,
      surname: credential.familyName,
      facebook: credential.facebookId,
      google: credential.googleId,
      apple: credential.appleAuthId
    }

    app.$store.dispatch('user/SET_FACEBOOK_ERROR_35', { ERROR: false })

    const response = await app.$rentcarsApi
      .post(endpoint, params, false, true, tokenJWT)
      .catch((err) => {
        app.$eventBus.$emit('RENTCARS_LEGACY_AUTH_ERROR', err)
      })
    let USER = await this.updateUserCountryAndReturnData(
      {
        response,
        store: app.$store,
        rentcarsApi: app.$rentcarsApi,
        tokenJWT: response.data.token,
        userID: response.data.id
      }
    )

    if (this.shouldUpdateUserAcceptedTerms(USER)) {
      const responseUpdateTerms = await this.updateUserAcceptedTerms({
        response,
        rentcarsApi: app.$rentcarsApi,
        tokenJWT: response.data.token
      })

      if (responseUpdateTerms?.token) {
        USER = {
          ...USER,
          acceptanceTerm: true
        }
      }
    }

    app.$store.dispatch('SET_CURRENCY', response.data.currency)
    const appCurrency = app.$appCookie.get('currency')
    if (response.data?.currency && response.data.currency !== appCurrency) {
      const openModalData = {
        currentCurrency: appCurrency,
        newCurrency: response.data.currency
      }

      setTimeout(() => {
        const includedRoutes = [
          `routes.booking.configure.${app.$store.getters.getLocale}`,
          `routes.home.${app.$store.getters.getLocale}`
        ]

        if (includedRoutes.includes(app.$route.name)) {
          app.$store.dispatch('SET_UPDATE_CURRENCY_MODAL', true)
        }
      }, 300)

      app.$store.dispatch('SET_UPDATE_OBJECT_CURRENCY_MODAL', openModalData)
    }

    return USER
  },

  async updateUserCountryAndReturnData({ response, store, rentcarsApi, tokenJWT, userID }) {
    if (
      response && response.hasOwnProperty('status') &&
      response.status === 200 &&
      response.data?.country?.alpha2 === undefined) {
      await rentcarsApi.put(
        '/v1/user',
        {
          email: response.data.email,
          country: await store.getters.getUserResidenceCountry,
          token: tokenJWT,
          id: response.data.id
        },
        false,
        [],
        false
      )
        .catch((e) => {

        })

      const userResponse = await rentcarsApi.get('/v1/user/me', null, false, 10000, response.data?.token)
        .catch(() => {

        })
      if (userResponse.status === 200 && userResponse.data) {
        return userResponse.data
      }
      return response.data
    }
    return response.data
  },

  async updateUserAcceptedTerms ({ response, rentcarsApi, tokenJWT }) {
    const hasConditionToAcceptTerms = response?.status === 200 && response?.data?.acceptanceTerm === undefined

    if (hasConditionToAcceptTerms) {
      const body = {
        email: response.data.email,
        acceptanceTerm: '1'
      }

      const responseUpdateTerms = await rentcarsApi.put(
        '/v1/user/acceptance-term',
        body,
        false,
        [],
        false,
        tokenJWT
      )

      if (responseUpdateTerms.status === 200 && responseUpdateTerms.data) {
        return responseUpdateTerms.data
      }
      return response.data
    }
  },

  shouldUpdateUserAcceptedTerms (user) {
    return user && user.acceptanceTerm === undefined
  },

  registrationParams(data, app) {
    if (!data) return

    const params = {
      first_name: data.givenName,
      last_name: data.familyName,
      title: data.hasOwnProperty('gender') && data.gender === 'female' ? '2' : '1',
      email: data.email,
      MobicarRequestorID: app.$store.getters.getMobicarRequestorID,
      Locale: app.$helpers.localeForRequest()
    }

    if (data.hasOwnProperty('googleId')) {
      params.googleId = data.googleId
    }

    if (data.hasOwnProperty('facebookId')) {
      params.facebookToken = data.facebookId
    }

    if (data.hasOwnProperty('appleAuthId')) {
      params.appleId = data.appleAuthId
    }

    return params
  }
}
