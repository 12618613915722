export const state = () => ({
  config: {}
})

export const actions = {
  SET_CONFIG({ commit }, value) {
    commit('SET_CONFIG', value)
  },
  APPEND_CONFIG({ commit }, value) {
    commit('APPEND_CONFIG', value)
  }
}

export const mutations = {
  SET_CONFIG(state, value) {
    state.config = value
  },
  APPEND_CONFIG(state, value) {
    state.config = Object.assign({}, state.config, value)
  }
}

export const getters = {
  /**
   * @desc return an object with header config
   * @returns {Object}
   */
  getConfig: state => state.config
}
