import { cookieTypes } from '~/store/cookie'

export default async ({ store, app: { $appCookie, $rentcarsApi, $loadLanguageAsync, i18n }, query }) => {
  store.dispatch('SET_READ_GDPR', parseInt($appCookie.get('readGdpr', 1), 10) === 1)
  store.dispatch('SET_COUNTRY_RESIDENCE_CODE', $appCookie.get(cookieTypes.RESIDENCE_CODE))

  $appCookie.set('hl', store.getters.getLocale)

  store.dispatch('cookie/SET_AUTH_TOKEN_COOKIE', $appCookie.get('auth_token'))
  $appCookie.delete('auth_token')

  await $loadLanguageAsync(store.getters.getLocale)

  const lastId = $appCookie.get('requestorID')
  if ($appCookie.get('requestorID') !== (store.getters.getMobicarRequestorID).toString()) {
    $appCookie.delete('requestorIDReferrer')
    $appCookie.set('requestorID', store.getters.getMobicarRequestorID)
    $appCookie.delete('Campanha', window.location.hostname)
  }
  if ((process.browser && 'gclid' in query && document.referrer === 'https://www.google.com/') || 'utm_campaign' in query) {
    $appCookie.delete('Campanha', window.location.hostname)
  }
  if (lastId !== '16' && store.getters.getMobicarRequestorID === 16) {
    $appCookie.set('requestorIDReferrer', lastId)
    store.commit('SET_USER_REQUESTOR_ID_REFERRER', lastId)
  }

  if ($appCookie.get('pdPaymentTest') != null) {
    store.dispatch('search/SET_PD_PAYMENT_TEST', $appCookie.get('pdPaymentTest'))
  }

  if (!$appCookie.get('currency')) {
    $appCookie.set('currency', store.getters.getCurrency)
  } else {
    store.dispatch('SET_CURRENCY', $appCookie.get('currency'))
  }

  let countries = null

  await $rentcarsApi.get('v1/customer/nationality')
    .then((response) => {
      if (response.data != null && response.data.length > 0) {
        countries = response.data
      }
    })
    .catch(() => { })

  store.dispatch('common/SET_COUNTRIES', countries)

  await store.dispatch('user/INIT_MODULE')

  store.dispatch('SET_USER_CAMPAIGN', query)
}
