export const state = () => ({
  optionals: null,
  protections: null
})

export const actions = {
  SET_OPTIONALS: ({ commit }, data) => {
    commit('SET_OPTIONALS', data)
  },
  SET_PROTECTIONS: ({ commit }, data) => {
    commit('SET_PROTECTIONS', data)
  }
}

export const mutations = {
  SET_OPTIONALS: (state, data) => {
    state.optionals = data
  },
  SET_PROTECTIONS: (state, data) => {
    state.protections = data
  }
}

export const getters = {
  GET_OPTIONALS: state => state.optionals,
  GET_PROTECTIONS: state => state.protections
}
