export const state = () => ({
  performance: false,
  partners: false
})

export const actions = {
  INITIALIZE({ commit }, value) {
    commit('INITIALIZE', value)
  },
  UPDATE({ commit }, { performance, partners }) {
    commit('UPDATE', { performance, partners })
  }
}

export const mutations = {
  INITIALIZE(state) {
    if (this.$appCookie.get('preferences') === null) {
      this.$appCookie.set(
        'preferences',
        JSON.stringify({
          performance: true,
          partners: true
        })
      )
    }

    const PREFERENCES = JSON.parse(this.$appCookie.get('preferences'))

    if ('performance' in PREFERENCES) {
      state.performance = PREFERENCES.performance
    }

    if ('partners' in PREFERENCES) {
      state.partners = PREFERENCES.partners
    }
  },
  UPDATE(state, data) {
    state.performance = data.performance
    state.partners = data.partners
  }
}

export const getters = {
  isPerformanceAllowed: state => state.performance,
  arePartnersAllowed: state => state.partners
}
