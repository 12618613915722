import Moment from 'moment-timezone'
import InsiderEnum from './InsiderEnum'
import InsiderUtils from './InsiderUtils'
import SessionEventState from './SessionEventState'
import switchDefaultCustom from './utils/switchDefaultCustom'
import taxonomyData from './utils/taxonomyData'
import cashbackToBePay from './utils/cashbackToBePay'
import HashIdGenerate from '~/services/HashIdGenerate.js'
import financialInformation from './utils/financialInformation'
import insiderSet from '~/services/Insider/sets/insiderSet.js'
import voucherDiscountInf from './utils/voucherDiscountInf.js'

export default class InsiderGlobal {
  utils = InsiderUtils.start()

  load (pageType = null) {
    this.pageType = {
      page: {
        type: pageType
      }
    }
    this.insiderEventName = pageType
    this.insiderObject = {}
    this.insiderEvent = {}
    this.currentTimezone = Moment.tz.guess()
    this.hashIdGenerate = HashIdGenerate.getInstance()
    this.insiderEnum = InsiderEnum
    this.insiderSet = insiderSet
  }

  setBasketObject (data) {
    this.insiderObject = {
      ...this.pageType,
      basket: data.basket
    }
  }

  setDefaultObject (data) {
    this.insiderObject = {
      ...data,
      ...this.pageType
    }
  }

  setListingPageObject (data) {
    this.insiderObject = {
      ...data,
      ...this.pageType
    }
    this.resetUserIO(data?.user)
  }

  sendEvent (timestamp = 'now') {
    if (window.Insider) {
      window.Insider.track('events', this.getFormattedInsiderEvent(timestamp))
    }
  }

  setHomeObject (custom) {
    window.insider_object = {
      page: {
        type: 'Home'
      },
      custom
    }
  }

  setCookie (key, value) {
    window.$nuxt.$cookie.set(key, value)
  }

  baseSearchObject (params) {
    return this.utils.buildSearchObject(params)
  }

  clientHasReturning () {
    const lastAccessDate = this.utils.getCookie('last_access_date')
    const amountOfDays = this.getAmountOfDays(lastAccessDate)

    this.setCookie(
      'last_access_date',
      new Moment().tz(this.currentTimezone).format('YYYY-MM-DD HH:mm:ss')
    )

    return amountOfDays >= 30
  }

  getAmountOfDays (timestamp = null) {
    const currentDateTime = this.utils.formatTimeStamp()
    const lastAccessDateTime = timestamp ? this.utils.formatTimeStamp(timestamp) : this.utils.formatTimeStamp()

    return currentDateTime.diff(lastAccessDateTime, 'days')
  }

  getInsiderObject () {
    return Object.assign({}, this.pageType, this.insiderObject)
  }

  getFormattedInsiderEvent (timestamp = 'now') {
    let currentTimestamp = new Moment()

    if (timestamp && timestamp !== 'now') {
      currentTimestamp = new Moment(timestamp)
    }

    const response = [
      {
        event_name: this.pageType.page.type,
        timestamp: currentTimestamp.tz(this.currentTimezone).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
        event_params: switchDefaultCustom(this.insiderEvent)
      }
    ]

    if (SessionEventState.start().get()) {
      response.push(
        {
          event_name: this.insiderEnum.SESSION_START,
          timestamp: currentTimestamp.tz(this.currentTimezone).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
          event_params: SessionEventState.start().get()
        }
      )
      SessionEventState.start().set(false)
    }

    return response
  }

  getViewdBaseObject (params) {
    const {
      itinerary,
      vehicle,
      evaluation,
      cashback
    } = params

    const payment = params.payment || params.netRateOption || params.payOnArrivalOption
    const vehicleDetails = vehicle?.configurations.map(config => config.description)
    const locale = params.locale || params.bookingInfo.locale || this.utils.getLocale()

    const response = {
      product_image_url: `https:${process.env.RENTCARS_CDN.replace('https:', '')}${vehicle.image}`,
      ...this.utils.mainItineraryInformation(itinerary, params.user),
      rental_length: itinerary.daily,
      days_in_advance: itinerary.daysadvance,
      language: locale.toLowerCase(),
      supplier_name: params.rentalCompany.brand.replace(' Rent a Car', ''),
      product: vehicle.code,
      product_id: vehicle.id,
      name: vehicle.name,
      taxonomy: taxonomyData(vehicle, vehicleDetails, payment, evaluation, params),
      rental_company_evaluation: evaluation?.index?.value,
      quantity: this.insiderEnum.QUANTITY_BOOKING,
      additional: this.utils.getAdditionalValues(params.extra, this.insiderEnum.INCLUDED),
      url: this.utils.getUrl()
    }

    const cashbackType = cashbackToBePay(cashback, this.insiderEnum.CASHBACK_TYPE_VERIFY)

    if (cashbackType) {
      response.type = cashbackType
    }

    return response
  }

  handlerUserInfs (params) {
    const userData = params.userData
    const utmObject = params.utmObject ?? (params.utm ?? {})

    if (!userData) {
      return {}
    }

    return this.utils.userInfs(userData, utmObject, params.requestorId, this.clientHasReturning())
  }

  toSessionsEvent (params) {
    const paramsToSend = {}
    let numberOfAttr = 0
    let numberOfAttrIsZero = 0
    let utms
    let propertyUtm

    if (paramsToSend.utmObject) {
      utms = paramsToSend.utmObject
      propertyUtm = 'utmObject'
    } else if (paramsToSend.utm) {
      utms = paramsToSend.utm
      propertyUtm = 'utm'
    } else {
      utms = {}
      propertyUtm = ''
    }

    const ruleUtm = ['utm', 'utmObject'].includes(propertyUtm)

    if (ruleUtm) {
      for (const property in utms) {
        numberOfAttr += 1
        if (!utms[property]) {
          paramsToSend[property] = params[property]
          numberOfAttrIsZero += 1
        }
      }
    }

    this.insiderEvent = {
      requestor_id: params.requestorId
    }

    if (ruleUtm && (numberOfAttr !== numberOfAttrIsZero)) {
      this.insiderEvent[propertyUtm] = utms
    }
  }

  builderObjectOfFinancialInformation (params) {
    return {
      currency: financialInformation(params, this.insiderEnum.GET_BR_CURRENCY),
      subtotal: financialInformation(params, this.insiderEnum.TOTAL_VLW_WITHOUT_RENTALCOVER),
      payment_currency: financialInformation(params, this.insiderEnum.GET_CURRENCY),
      payment_value: financialInformation(params, this.insiderEnum.GET_TOTAL_VALUE),
      payment_formatted_amount: financialInformation(params, this.insiderEnum.GET_TOTAL_FORMATTED_VALUE)
    }
  }

  getInsiderEvent () {
    return this.insiderEvent
  }

  setViewedSteps (params) {
    const payment = params.payment || params.netRateOption || params.payOnArrivalOption
    this.insiderEvent = {
      promotion_name: payment?.tag?.name ? [payment?.tag?.name] : null,
      plan: params.plan.name,
      payment: payment.type,
      language: this.utils.insiderFormatLanguage(params?.locale),
      user: params.user,
      ...this.builderObjectOfFinancialInformation(params),
      ...this.getViewdBaseObject(params)
    }
  }

  _adapterInsiderElement (params, functionName) {
    this[functionName](params)

    return this.insiderEvent
  }

  setPurchase (params) {
    params = Object.assign(params, {})
    const { booking, paymentType } = params

    const argViewedStep = {
      ...booking,
      user: params.user
    }

    this.insiderEvent = {
      ...this.insiderEvent,
      payment_type: paymentType,
      order_id: booking?.bookingInfo?.id,
      order_code: booking?.bookingInfo?.code,
      event_group_id: booking?.bookingInfo?.id,
      payment: paymentType === this.insiderEnum.PAY_AT_DESTINATION ? this.insiderEnum.PD : booking?.payment?.type,
      language: this.utils.insiderFormatLanguage(params?.locale),
      ...this.setViewedSteps(argViewedStep),
      ...voucherDiscountInf(params),
      ...this.getViewdBaseObject(booking),
      ...this.builderObjectOfFinancialInformation(params)
    }
  }

  resetUserIO (user) {
    if (!user && window.insider_object) {
      delete window.insider_object.user
    }
  }
}
