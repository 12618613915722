export default {
  name: 'AppLoading',

  props: {
    message: {
      type: String,
      default: null
    },
    darkTheme: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    /**
     * @desc return the state from loading
     * @returns {boolean}
     */
    loading: function () {
      return this.$store.getters.getLoading
    }
  }
}
