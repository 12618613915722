export class VwoCampaignClass {
  constructor (campaignKey, variant, uuid) {
    this.campaignKey = campaignKey
    this.variant = variant
    this.uuid = uuid
  }
}

export const state = () => ({
  campaigns: []
})

export const mutations = {
  ADD_CAMPAIGN (state, { getters, campaign }) {
    if (!(campaign instanceof VwoCampaignClass)) {
      this.$bugsnag.notify('Invalid VwoCampaign object', campaign)
      return
    }

    if (getters.getCampaign(campaign.campaignKey, campaign.uuid)) {
      this.$bugsnag.notify('Campaign Already exists', campaign)
      return
    }

    state.campaigns.push(campaign)
  }
}

export const actions = {
  ADD_CAMPAIGN: ({ commit, getters }, campaign) => {
    commit('ADD_CAMPAIGN', { getters, campaign })
  }
}

export const getters = {
  getCampaigns: state => state.campaigns,

  getCampaign: state => (campaignKey, uuid) => {
    return state.campaigns.find(arrayItem =>
      arrayItem?.campaignKey?.toLowerCase() === campaignKey.toLowerCase() && arrayItem.uuid === uuid
    )
  }
}
