module.exports = {
  rules: {
    'winrent': {
      'code': 301,
      'slug': 'leasys'
    },
    'VIPService': {
      'code': 301,
      'slug': 'vipservice'
    },
    'Confort': {
      'code': 301,
      'slug': 'confort'
    },
    'Ecodrive': {
      'code': 301,
      'slug': 'ecodrive'
    },
    'mex-rent-a-car': {
      'code': 301,
      'slug': 'mex'
    }
  }
}
