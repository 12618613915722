export const state = () => ({
  ctaCardList: {
    variant: 0,
    abTestName: null
  }
})

export const mutations = {
  SET_CTA_CARD_LIST_VARIANT(state, variant) {
    state.ctaCardList.variant = variant
  },

  SET_CTA_CARD_LIST_AB_TEST_NAME(state, abTestName) {
    state.ctaCardList.abTestName = abTestName
  }
}

export const actions = {
  SET_CTA_CARD_LIST_VARIANT: ({ commit }, variant) => {
    commit('SET_CTA_CARD_LIST_VARIANT', variant)
  },

  SET_CTA_CARD_LIST_AB_TEST_NAME: ({ commit }, abTestName) => {
    commit('SET_CTA_CARD_LIST_AB_TEST_NAME', abTestName)
  }
}

export const getters = {
  getCtaCardListAbTest: state => state.ctaCardList
}
