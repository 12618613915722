export const PARTNERS = {
  PREMMIA: 649,
  TUDOAZUL: 625,
  VISA: 483,
  ACCOR: 5257,
  SAMSUNG: 1036,
  DOTZ: 421,
  IPIRANGA: 1181,
  ALUGUEL_CARROS: 13,
  LOCACAO_VEICULOS: 15,
  VIAJA_NET: 21,
  GOL_SITE: 123,
  GOL_PHONE: 269,
  PAYPAL: 1090,
  LATAMPASS: 1342,
  BRADESCO_SEGUROS: 1316,
  BRADESCO_EXCLUSIVE: 2043,
  BRADESCO_PRIME: 2480,
  LIVELO: 2160,
  TIMPRAMIM: 2310,
  TIMBLACK: 2372,
  SEGUROSPROMO: 2614,
  PASSAGENSPROMO: 2592,
  SKYMILHAS: 2700,
  OAB: 2899,
  KAYAK: 26,
  IUPP: 4035,
  SICREDI: 3594,
  VIVO: 4309,
  RENTAL_CENTER_FOCO: 4730,
  ITAU_PERSONNALITE: 3616,
  IN_MAIS: 5270,
  ALL_POINTS: 4905,
  TAP_MILES_GO: 5256,
  ESFERA: 8091,
  TRAVELCLUB: 8597,
  QATARAIRWAYS: 8843
}

export const PARTNERS_WITHOUT_EC = [
  PARTNERS.ALUGUEL_CARROS,
  PARTNERS.LOCACAO_VEICULOS,
  PARTNERS.VIAJA_NET,
  PARTNERS.GOL_SITE,
  PARTNERS.GOL_PHONE
]

export const PARTNERS_COOKIE_1D = [
  PARTNERS.DOTZ,
  PARTNERS.IPIRANGA,
  PARTNERS.LIVELO,
  PARTNERS.LATAMPASS,
  PARTNERS.TIMPRAMIM,
  PARTNERS.TIMBLACK,
  PARTNERS.BRADESCO_PRIME,
  PARTNERS.SEGUROSPROMO,
  PARTNERS.PASSAGENSPROMO,
  PARTNERS.SKYMILHAS,
  PARTNERS.OAB,
  PARTNERS.ITAU_PERSONNALITE,
  PARTNERS.IN_MAIS,
  PARTNERS.ALL_POINTS
]

export const COUPON_BY_PARTNER = {
  [PARTNERS.PAYPAL]: 'RCPAYPAL19',
  [PARTNERS.VISA]: 'RCVISA12', // RCVISA cupom fora da promocao
  [PARTNERS.SAMSUNG]: 'RCSMSG10',
  [PARTNERS.BRADESCO_SEGUROS]: 'RCBSEG19',
  [PARTNERS.BRADESCO_EXCLUSIVE]: 'CARSBRA',
  [PARTNERS.BRADESCO_PRIME]: 'PRIMECARS',
  [PARTNERS.TIMPRAMIM]: 'TIMPRAMIM',
  [PARTNERS.TIMBLACK]: 'TIMBLACK',
  [PARTNERS.SEGUROSPROMO]: 'SEGUROSPROMO',
  [PARTNERS.PASSAGENSPROMO]: 'PASSAGENSPROMO',
  [PARTNERS.SKYMILHAS]: 'SKYMILHAS05',
  [PARTNERS.OAB]: 'OAB10OFF',
  [PARTNERS.SICREDI]: 'SICREDIRC10',
  [PARTNERS.ITAU_PERSONNALITE]: 'ITAURC15'
}
