import localforage from 'localforage'

const NAMESPACE = 'RecentSearchesRepository-'
const RecentSearchesKey = 'RecentSearchesKey'

export const GetRecentSearches = async (locale) => {
  const item = await localforage.getItem(`${NAMESPACE}${RecentSearchesKey}-${locale}`)
  if (item != null) {
    return item
  }

  return []
}

export const SetRecentSearches = async (data, locale) => {
  await localforage.setItem(`${NAMESPACE}${RecentSearchesKey}-${locale}`, data).then((value) => {
    return value
  }).catch(() => {})
}

export const DeleteRecentSearches = locale => localforage.removeItem(`${NAMESPACE}${RecentSearchesKey}-${locale}`)

export default { GetRecentSearches, SetRecentSearches, DeleteRecentSearches }
