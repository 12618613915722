import axios from 'axios'

export default ({ app, store, env, route, isDev }, inject) => {
  function treatLocale(locale = '') {
    if (locale === 'pt-br') {
      return 'pt_BR'
    } else if (locale === 'es') {
      return 'es_ES'
    } else if (locale === 'es-mx') {
      return 'es_MX'
    } else if (locale === 'es-cl') {
      return 'es_CL'
    } else if (locale === 'es-ar') {
      return 'es_AR'
    } else if (locale === 'es-co') {
      return 'es_CO'
    } else if (locale === 'en-gb') {
      return 'en_GB'
    } else if (locale === 'nl-nl') {
      return 'nl_NL'
    } else if (locale === 'it-it') {
      return 'it_IT'
    } else if (locale === 'fr-fr') {
      return 'fr_FR'
    } else if (locale === 'de-de') {
      return 'de_DE'
    } else if (locale === 'pt-pt') {
      return 'pt_PT'
    } else if (locale === 'fr-ca') {
      return 'fr_CA'
    }
    return 'en_US'
  }

  /**
   * @desc Monta os parametros para as requisições get ou post da aplicação, verifica se os valores são válidos para envio.
   *
   * @param params
   * @param upperCase
   * @returns {Object}
   */
  function buildRequestParams(params, upperCase = false, required = [], formData = false) {
    const response = formData && process.browser ? new FormData() : {}
    const detectarSistemaOperacional = () => {
      let sistemaOperacional = 'Indeterminado'
      try {
        sistemaOperacional = navigator.platform
      } catch (e) { }
      return { SistemaOperacional: sistemaOperacional }
    }
    const sistemaOperacional = process.browser ? detectarSistemaOperacional() : {}
    const data = Object.assign({}, {
      mobicarRequestorID: store.getters.getMobicarRequestorID,
      locale: treatLocale(store.getters.getLocale),
      environment: 'mobile',
      ClienteIP: store.getters.getUserIp
    }, params, sistemaOperacional)

    const noUpperCaseList = ['isCheckoutStep', 'encryptedCardNumber', 'encryptedExpiryMonth', 'encryptedExpiryYear', 'encryptedSecurityCode']

    Object.keys(data).forEach((key) => {
      const item = data[key]

      if (item || required.indexOf(key) >= 0) {
        if (upperCase && (noUpperCaseList.indexOf(key) === -1)) {
          key = key.substr(0, 1).toUpperCase() + key.substr(1)
        }

        if (formData && process.browser) {
          if (Array.isArray(item)) {
            if (key === 'allOptionsGuid') {
              item.forEach((v) => {
                response.append(`${key}[]`, v)
              })
            } else {
              item.forEach((v) => {
                response.append(key, v)
              })
            }
          } else {
            response.append(key, item)
          }
        } else {
          Object.assign(response, {
            [key]: item
          })
        }
      }
    })

    return response
  }

  const headers = {}

  if (!process.browser) {
    headers.origin = env.SITE_MOBILE_URL
  }

  const client = axios.create({
    baseURL: process.server && !isDev ? env.INTERNAL_ENDPOINT : env.FACADE_ENDPOINT,
    timeout: env.FACADE_TIMEOUT || 10000,
    proxyHeaders: false,
    headers
  })

  client.interceptors.request.use(function (request) {
    request.timestamp = new Date().getTime()

    return request
  }, function (error) {
    return Promise.reject(error)
  })

  client.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    return Promise.reject(error)
  })

  const rentcarsApi = {
    client: axios,

    /**
     * @desc this method sends a HTTP Get Request to the server
     *
     * @param {string} uri
     * @param {object} params
     * @param {boolean} upperCase
     * @return {Promise}
     */
    get: (uri, params, upperCase = false, timeout = null, userJWT = null) => {
      let cancel = {}

      if (params != null && params.hasOwnProperty('cancelToken')) {
        cancel = { cancelToken: params.cancelToken }
        delete params.cancelToken
      }

      if (userJWT) {
        return client.get(
          uri,
          {
            headers: { 'Authorization': `Bearer ${userJWT}` },
            params: buildRequestParams(params, upperCase),
            timeout: timeout || process.env.FACADE_TIMEOUT
          }
        )
      }

      return client.get(uri, Object.assign({}, cancel, (timeout ? { timeout } : {}), { params: buildRequestParams(params, upperCase) }))
    },

    /**
     * @desc this method sends a HTTP Post Request to the server
     *
     * @param {string} uri
     * @param {object} params
     * @param {boolean} upperCase
     * @param {boolean} formData
     * @return {Promise}
     */
    post: (uri, params, upperCase = false, formData = true, userJWT = null) => {
      const contentType = formData ? 'multipart/form-data' : 'application/json'

      const requestObject = {
        url: uri,
        method: 'post',
        data: buildRequestParams(params, upperCase, [], formData),
        config: {
          headers: { 'Content-Type': contentType }
        }
      }

      if (userJWT) {
        requestObject.headers = { 'Authorization': `Bearer ${userJWT}` }
      }

      return client(requestObject)
    },

    /**
     * @desc this method sends a HTTP Put Request to the server
     *
     * @param {string} uri
     * @param {object} params
     * @param {boolean} upperCase
     * @return {Promise}
     */
    put: (uri, params, upperCase = false, required = [], formData = true, userJWT = null) => {
      const contentType = formData ? 'multipart/form-data' : 'application/json'

      const requestObject = {
        url: uri,
        method: 'put',
        data: buildRequestParams(params, upperCase, required, formData),
        config: {
          headers: { 'Content-Type': contentType }
        }
      }

      if (userJWT) {
        requestObject.headers = { Authorization: `Bearer ${userJWT}` }
      }

      return client(requestObject)
    },

    /**
     * @desc this method sends a HTTP DELETE Request to the server
     *
     * @param {string} uri
     * @return {Promise}
     */
    delete: (uri) => {
      return client.delete(uri, { headers: { 'mobicar-requestor-id': store.getters.getMobicarRequestorID } })
    }
  }

  inject('rentcarsApi', rentcarsApi)
}
