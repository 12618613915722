export default {
  methods: {
    getGoogleRecaptchaToken (action = 'homepage') {
      return new Promise((resolve, reject) => {
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise.execute(process.env.RECAPTCHAV3_ENTERPRISE_SITE_KEY, { action })
            .then(token => resolve(token))
            .catch(() => {
              this.$bugsnag.notify(new Error('Could not get Google reCAPTCHA Token'))
              reject(new Error('Could not get Google reCAPTCHA Token'))
            })
        })
      })
    },

    initGoogleRecaptchaScript () {
      return new Promise((resolve, reject) => {
        if ('grecaptcha' in window) {
          return resolve(true)
        }

        const script = document.createElement('script')
        script.name = 'google-recaptcha'
        script.onload = () => resolve(true)
        script.onerror = () => {
          this.$bugsnag.notify(new Error('Could not load Google reCAPTCHA'))
          reject(new Error('Could not load Google reCAPTCHA'))
        }
        script.async = true
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.RECAPTCHAV3_ENTERPRISE_SITE_KEY}`
        document.head.appendChild(script)
      })
    }
  }
}
