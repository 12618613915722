import { mapGetters } from 'vuex'
import Header from 'components/Headers/Default/HeaderDefault.vue'
import AppModalChangeCountry from 'components/Modal/ModalChangeCountry/ModalChangeCountry.vue'
import AppModalChangeCurrency from 'components/Modal/ModalChangeCurrency/ModalChangeCurrency.vue'
import BottomSheet from 'components/Modal/BottomSheet/BottomSheet.vue'
import { ROUTES_WITHOUT_BANNER } from 'config/routes-without-first-banner'
import Moment from 'moment-timezone'
import { ROUTES_WITH_LP_TOOLBAR } from 'config/routes-without-lp-toolbar'
import Insider from 'mixins/insider-mixin'
import Cookies from 'mixins/cookie'
import Routes from 'mixins/routes'
import networklistener from 'mixins/networklistener'
import Intercom from 'mixins/intercom'
import { hydrateWhenIdle, hydrateWhenVisible } from 'vue-lazy-hydration'
import Auth from 'components/Customer/Auth/Auth.vue'

import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

const Footer = hydrateWhenVisible(() => import('components/Footer/Footer.vue'))
const SideNav = hydrateWhenIdle(() => import('components/Headers/SideNav/SideNav.vue'))

export default {
  name: 'DefaultLayout',

  mixins: [
    Insider,
    Cookies,
    Routes,
    networklistener,
    Intercom
  ],

  components: {
    'app-header': Header,
    'app-footer': Footer,
    'app-anchor': () => import('components/Anchor/Anchor.vue'),
    'rental-center-header': () => import('components/Headers/rentalCenterHeader/rentalCenterHeader.vue'),
    'rental-center-footer': () => import('components/Footer/rentalCenterFooter/rentalCenterFooter.vue'),
    'side-nav': SideNav,
    AppModalChangeCountry,
    BottomSheet,
    AppModalChangeCurrency,
    'app-auth': Auth
  },

  head () {
    return {
      htmlAttrs: {
        lang: this.$store.getters.getLocale
      }
    }
  },

  data() {
    return {
      canShowBanner: true,
      isBig: this.setIsBig(),
      canShowFooter: false
    }
  },

  created () {
    this.setTestABPrePayment()
    this.checkIfCanShowBanner()

    this._setInsiderEvent({
      insiderEventName: 'session_start'
    })
  },

  mounted () {
    if (this.$root.$route.name !== `routes.offline.${this.$i18n.locale}`) {
      this.$store.dispatch('reloadoffline/SET_LAST_ROUTE', this.$root.$route)
    }
    /**
     * Initiates connectivity listener.
     */
    this.addNetworkListener()
    this.callIntercomFlag()
  },

  watch: {
    '$route' (to, from) {
      const route = this.$store.getters['reloadoffline/getLastRoute']
      if (
        route &&
        route.name !== to.name &&
        to.name !== `routes.offline.${this.$i18n.locale}`
      ) {
        this.$store.dispatch('reloadoffline/SET_LAST_ROUTE', this.$root.$route)
      }
    }
  },

  computed: {
    ...mapGetters({
      isCheckedUrlToken: 'isLandingPageDebugOn',
      isRentalCenter: 'rentalCenter/isRentalCenter'
    }),

    canShowToolbar() {
      const routeNameWithoutLocale = this.$route.name.replace(`.${this.$i18n.locale}`, '')

      return !this.$store.getters.isClosedPromotionsToolbar && (ROUTES_WITH_LP_TOOLBAR.includes(routeNameWithoutLocale) || this.$route.name === 'locale')
    },

    currentDate() {
      return (this.$store.getters.landingPageDebugDate && this.isCheckedUrlToken) ? this.$store.getters.landingPageDebugDate : new Moment().format('YYYY-MM-DD')
    }
  },

  methods: {
    /**
     * @desc Checks for the routes that will not appear the floater banner
     */
    checkIfCanShowBanner() {
      ROUTES_WITHOUT_BANNER.forEach((routeName) => {
        if (this.$route.name === this.$routeResolver(routeName)) this.canShowBanner = false
      })
    },

    /**
     * @desc Changes the floater banner layout only for listing
     * @returns {boolean}
     */
    setIsBig() {
      return this.$route.name !== this.$routeResolver('routes.search.list')
    },

    setTestABPrePayment() {
      if (!process.browser) return

      const ppQuery = this.$store.getters['search/getABTestPP']
      const ppCookie = this.$appCookie.get('allow-pp')

      if (ppQuery >= 1 && ppQuery !== parseInt(ppCookie, 10)) {
        this.$appCookie.set('allow-pp', ppQuery)
      } else if (ppQuery === 0 && ppCookie != null) {
        this.$store.dispatch('search/SET_ABTEST_PP', ppCookie)
      }
    },

    canShowFooterVisibility (canShowFooter) {
      this.canShowFooter = canShowFooter
    },

    async callIntercomFlag () {
      this.initIntercom()
      await this.$featureFlagApi
        .get('get/intercom_chat_pwa')
        .then((response) => {
          if (response.data?.ativo) {
            this.initIntercom()
          }
        })
        .catch((e) => {
          this.$bugsnag.notify(e)
          return false
        })
    }
  },

  beforeDestroy () {
    this.$root.$off('open-modal-update-currency')
  }
}
