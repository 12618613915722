import InsiderService from '../services/Insider/InsiderService'
import ConditionToStart from '../services/Insider/ConditionToStart'
import EventsAccepted from '../services/Insider/EventsAccepted'
import InsiderUtils from '../services/Insider/InsiderUtils'
import ActionOnInsiderObject from '../services/Insider/ActionOnInsiderObject'
import SessionEventState from '../services/Insider/SessionEventState'
import storeMixins from './insiderelements/storeMixins'
import switchCaseEventsMixin from './insiderelements/switchCaseEventsMixin'
import switchCaseObjectsMixin from './insiderelements/switchCaseObjectsMixin'
import secondFunctionsInsiderMixins from './insiderelements/secondFunctionsInsiderMixins'
import insiderSet from '../services/Insider/sets/insiderSet'

const insiderUtils = InsiderUtils.start()

export default {
  data () {
    return {
      insiderPageView: null,
      insiderEventName: null,
      insiderData: null,
      insiderObject: null,
      insiderEvent: null
    }
  },

  mixins: [
    storeMixins,
    switchCaseEventsMixin,
    switchCaseObjectsMixin,
    secondFunctionsInsiderMixins
  ],

  methods: {
    setInsider (input) {
      if (!this._conditionToManteinThisRouting(input)) {
        return false
      }

      input.type.forEach((elementType) => {
        if (insiderSet.objects.includes(elementType)) {
          this._setInsiderObject(
            elementType,
            this._adapterInput(
              input,
              'insiderObjectName',
              elementType
            )
          )
        } else if (insiderSet.events.includes(elementType)) {
          this._setInsiderEvent(
            this._adapterInput(
              input,
              'insiderEventName',
              elementType
            )
          )
        }
      })
    },

    _setInsiderObject (typeOfObject = null, insiderObject = null) {
      try {
        if (typeof window !== 'undefined') {
          if (!typeOfObject && this.insiderPageView) {
            typeOfObject = this.insiderPageView
          }

          if (!insiderObject && this.insiderObject) {
            insiderObject = this.insiderObject
          }

          ActionOnInsiderObject.execute(
            insiderObject,
            typeOfObject,
            this.$route
          )

          this.switchCaseObjects(
            insiderObject,
            typeOfObject
          )
          const updatedInsiderObject = this.ifHasToUpdateUserInsiderOption(
            insiderObject,
            typeOfObject
          )

          window.insider_object = { ...window.insider_object, ...updatedInsiderObject }

          if (this._conditionToInvokeDispatchReInitialize(insiderSet, typeOfObject)) {
            window.Insider.eventManager.dispatch('init-manager:re-initialize')
          }
        }
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    _setInsiderEvent (params) {
      try {
        if (typeof window !== 'undefined' && params) {
          if ((!params.insiderEventName) && this.insiderEventName) {
            params.insiderEventName = this.insiderEventName
          }

          const condToStart = ConditionToStart.verificationHasCondToStop(
            params,
            params.insiderEventName
          )

          if (condToStart) {
            return false
          }

          EventsAccepted.setVarInRelationEvents(
            params,
            insiderUtils.getUrl(),
            insiderUtils.getRequestorId(),
            this.$store.getters['user/getLoggedUser']
          )

          this.storeProcessExecution(params)

          const insiderService = InsiderService.start(params.insiderEventName)
          this.switchCaseEvents(params, insiderService)

          if (insiderUtils.conditionToSpecialCase(params.insiderEventName)) {
            SessionEventState.start().set(this.insiderEvent)
          } else {
            insiderService.sendEvent()
          }
        }
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    _conditionToManteinThisRouting (input) {
      try {
        return typeof input === 'object' &&
               Object.keys(input).length > 0 &&
               input.type !== undefined &&
               Array.isArray(input.type) > 0 &&
               typeof window !== 'undefined'
      } catch (error) {
        return false
      }
    },

    _adapterInput (input, attrName, elementType) {
      const inputElement = Object.assign({}, input)
      delete inputElement.type
      inputElement[attrName] = elementType

      return inputElement
    },

    _conditionToInvokeDispatchReInitialize (insiderSet, typeOfObject) {
      try {
        return window.Insider &&
               window.insider_object &&
               typeof window.insider_object === 'object' &&
               Object.keys(window.insider_object).length > 0 &&
               insiderSet.sendObjectToDispatch.includes(typeOfObject)
      } catch (error) {
        return false
      }
    }
  }
}
