export const state = () => ({
  rentalsSent: 0,
  totalRentals: 0,
  resultsFromRest: [],
  ga4: [],
  companies: {},
  filteredResults: [],
  sortOptionSelected: 'recommended',
  hasPromotions: false,
  enabledFilters: [],
  itinerary: null,
  endpointsData: null,
  initialSort: [],
  nearbyPlaces: null,
  urlParam: null,
  rentalCompanies: []
})

export const actions = {
  ENDPOINTS_DATA: ({ commit }, data) => {
    commit('ENDPOINTS_DATA', data)
    commit('TOTAL_OF_RENTALS', data.stores.length > 0 ? data.stores.length : data.rentalCompanies.length)
    commit('SET_ITINERARY', data.itinerary)
    commit('SET_NEARBY', data.nearbyPlaces)
  },
  INCREMENT_REQUEST_RESPONSE: ({ commit }) => {
    commit('INCREMENT_REQUEST_RESPONSE')
  },
  INCREMENT_RESULTS: ({ commit }, data) => {
    commit('INCREMENT_RESULTS', data)
  },
  INCREMENT_COMPANIES: ({ commit }, data) => {
    commit('INCREMENT_COMPANIES', data)
  },
  SET_ALL_RESULTS: ({ commit }, resultsFromRest) => {
    commit('SET_ALL_RESULTS', resultsFromRest)
  },
  SET_FILTERED_RESULTS: ({ commit }, filteredResults) => {
    commit('SET_FILTERED_RESULTS', filteredResults)
  },
  SET_SORT_OPTION_SELECTED: ({ commit }, sortOptionSelected) => {
    commit('SET_SORT_OPTION_SELECTED', sortOptionSelected)
  },
  SET_HAS_PROMOTION: ({ commit }, hasPromotions) => {
    commit('SET_HAS_PROMOTION', hasPromotions)
  },
  SET_ENABLED_FILTERS: ({ commit }, enabledFilters) => {
    commit('SET_ENABLED_FILTERS', enabledFilters)
  },
  CLEAR: ({ commit }) => {
    commit('CLEAR')
  },
  SET_INITIAL_SORT: ({ commit }, data) => {
    commit('SET_INITIAL_SORT', data)
  },
  SET_URL_PARAM: ({ commit }, data) => {
    commit('SET_URLPARAM', data)
  },
  TOTAL_OF_RENTALS: ({ commit }, total) => {
    commit('TOTAL_OF_RENTALS', total)
  },
  SET_RENTAL_SENT: ({ commit }, total) => {
    commit('SET_RENTAL_SENT', total)
  },
  SET_RENTAL_COMPANIES_MAP: ({ commit }, data) => {
    commit('SET_RENTAL_COMPANIES_MAP', data)
  }
}

export const mutations = {
  ENDPOINTS_DATA: (state, data) => {
    state.endpointsData = data
  },
  TOTAL_OF_RENTALS: (state, quantity) => {
    state.totalRentals = parseInt(quantity, 10)
  },
  SET_RENTAL_SENT: (state, quantity) => {
    state.rentalsSent = parseInt(quantity, 10)
  },
  INCREMENT_REQUEST_RESPONSE: (state) => {
    state.rentalsSent += 1
  },
  INCREMENT_RESULTS: (state, data) => {
    state.resultsFromRest = state.resultsFromRest.concat(data)
    state.ga4 = state.ga4.concat(data.map((r) => { return r.GA4.items }))
  },
  INCREMENT_COMPANIES: (state, data) => {
    state.companies[data.id] = data
  },
  SET_ALL_RESULTS: (state, resultsFromRest) => {
    state.resultsFromRest = resultsFromRest
    state.ga4 = resultsFromRest.map((r) => { return r.GA4.items })
  },
  SET_FILTERED_RESULTS: (state, filteredResults) => {
    state.filteredResults = filteredResults
  },
  SET_SORT_OPTION_SELECTED: (state, sortOptionSelected) => {
    state.sortOptionSelected = sortOptionSelected
  },
  SET_HAS_PROMOTION: (state, hasPromotions) => {
    state.hasPromotions = hasPromotions
  },
  SET_ENABLED_FILTERS: (state, enabledFilters) => {
    state.enabledFilters = enabledFilters
  },
  SET_ITINERARY: (state, itinerary) => {
    state.itinerary = itinerary
  },
  SET_NEARBY: (state, nearbyPlaces) => {
    state.nearbyPlaces = nearbyPlaces
  },
  SET_URLPARAM: (state, urlParam) => {
    state.urlParam = urlParam
  },
  CLEAR: (state) => {
    state.rentalsSent = 0
    state.totalRentals = 0
    state.resultsFromRest = []
    state.ga4 = []
    state.companies = {}
    state.filteredResults = []
    state.sortOptionSelected = 'recommended'
    state.hasPromotions = false
    state.enabledFilters = []
    state.itinerary = null
    state.endpointsData = null
    state.initialSort = []
    state.nearbyPlaces = null
    state.urlParam = null
    state.rentalCompanies = []
  },
  SET_INITIAL_SORT: (state, data) => {
    state.initialSort = data
  },
  SET_RENTAL_COMPANIES_MAP: (state, data) => {
    state.rentalCompanies = data
  }
}

export const getters = {
  /**
   * @desc returns if all requests are send
   * @return {Boolean}
   */
  isAllRequestsSent: state => state.rentalsSent === state.totalRentals,

  /**
   * @desc returns length os companies
   * @returns {Number}
   */
  getTotalCompanies: state => state.totalRentals,

  /**
   * @desc returns number of rentals sent
   * @return {Number}
   */
  quantityRentalsSent: state => state.rentalsSent,

  /**
   * @desc all response from rest
   * @returns {Array}
   */
  getResults: state => state.resultsFromRest,

  getGa4: state => state.ga4,

  /**
   * @desc returns all companies
   * @returns {Object}
   */
  getCompanies: state => state.companies,

  /**
   * @desc returns a company
   * @returns {Object}
   */
  getCompany: state => code => state.companies[code] || null,

  /**
  * @desc return filteredResults
  * @returns {String|null}
  */
  getFilteredResults: state => state.filteredResults,

  /**
  * @desc return sort method is active
  * @returns {String|null}
  */
  getSortOptionSelected: state => state.sortOptionSelected,

  /**
  * @desc return if has promotion
  * @returns {String|null}
  */
  getHasPromotion: state => state.hasPromotions,

  /**
  * @desc return if has promotion
  * @returns {String|null}
  */
  getEnabledFilters: state => state.enabledFilters.filter(v => v !== undefined),

  /**
   * @desc returns an itinerary
   * @returns {Object}
   */
  getItinerary: state => state.itinerary,

  /**
   * @desc returns neabyPlaces
   * @returns {Object}
   */
  getNearbyPlaces: state => state.nearbyPlaces,

  /**
   * @desc returns urlParam
   * @returns {String}
   */
  getUrlParam: state => state.urlParam,

  /**
   * @desc returns if is a domestic pickup
   * @returns {Boolean}
   */
  isDomesticItinerary: state => state.itinerary !== null && state.itinerary.pickup.country.id === 1,

  /**
   * @desc returns data from endpoints request
   * @returns {Object}
   */
  getEndpointsData: state => state.endpointsData,

  /**
   * @desc returns initial sort data
   * @returns {Object}
   */
  getInitialSort: state => state.initialSort,

  /**
   * @desc returns rental Companies to Map
   * @returns {Object}
   */
  getRentalCompanies: state => state.rentalCompanies

}
