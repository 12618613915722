export const state = () => ({
  config: {}
})

export const actions = {
  SET_CONFIG({ commit }, value) {
    commit('SET_CONFIG', value)
  }
}

export const mutations = {
  SET_CONFIG(state, value) {
    state.config = value
  }
}

export const getters = {
  /**
   * @desc return an object with anchor config
   * @returns {Object}
   */
  getConfig: state => state.config
}
