export default {
  search: {
    defaultPickupTime: '10:00',
    defaultReturnTime: '10:00'
  },
  companyLabels: {
    companyName: 'Rentcars',
    telephoneNumber: '4003-7367'
  },
  localization: {
    brazilianID: 1,
    brazilianCode: 'BR',
    /**
     * 1 - Brazil
     * 2 - United States
     * 18 - Australia
     * 41 - Canada
     */
    countriesWithState: [1, 2, 18, 41]
  },
  amp_error: 1999
}
