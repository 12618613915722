export const state = () => ({
  ga4EventSelectedItem: false,
  lastConfigureUrl: ''
})

export const actions = {
  SET_GA4_SELECT_ITEM_CONTENT ({ commit }, selectItem) {
    commit('SET_GA4_SELECT_ITEM', selectItem)
  },
  SET_GA4_LAST_URL_CONFIGURE ({ commit }, url) {
    commit('SET_GA4_CONFIGURE_URL', url)
  }
}

export const mutations = {
  SET_GA4_SELECT_ITEM (state, selectItem) {
    state.ga4EventSelectedItem = selectItem
  },
  SET_GA4_CONFIGURE_URL (state, url) {
    state.lastConfigureUrl = url
  }
}

export const getters = {
  getGA4SelectItem: state => state.ga4EventSelectedItem,
  getGa4LastConfigureUrl: state => state.lastConfigureUrl
}
