export default function formatDateToInsider (itineraryDateInput) {
  let response

  if (!itineraryDateInput) {
    return
  }

  if (typeof itineraryDateInput === 'object') {
    response = itineraryDateInput.toISOString()
  } else if (typeof itineraryDateInput === 'string') {
    response = (new Date(itineraryDateInput.split('T')[0])).toISOString()
  }

  return response
}
