module.exports = {
  brazilianID: 1,
  unitedStatesID: 2,
  brazilianCountry: 'BR',
  brazilianLocale: 'pt-br',
  brazilianCurrency: 'BRL',
  brazilianCountryAreaCode: 55,
  unitedStatesLocale: 'en',
  unitedStatesCountry: 'US',
  mexicanCountry: 'MX',
  mexicanLocale: 'es-mx',
  spanishCountry: 'ES',
  spanishLocale: 'es',
  defaultCountry: 'BR',
  defaultLocale: 'en',
  defaultCurrency: 'USD',
  fallbackCurrency: 'USD',
  availableLocales: ['pt-br', 'en', 'es', 'es-ar', 'es-cl', 'es-co', 'es-mx', 'it-it', 'pt-pt', 'en-gb', 'nl-nl', 'de-de', 'fr-fr', 'fr-ca'],
  ampAvailableLocales: ['pt-br'],
  defaultLocales: {
    'de-de': {
      'code': 'de_DE',
      'name': 'Deutsch',
      'countryCode': 'DE',
      'flag': 'de-de.png'
    },
    'en': {
      'code': 'en_US',
      'name': 'English (US)',
      'countryCode': 'US',
      'flag': 'us.png',
      'derivation': [
        {
          'name': 'English (CA)',
          'countryCode': 'CA'
        }
      ]
    },
    'en-gb': {
      'code': 'en_GB',
      'name': 'English (UK)',
      'countryCode': 'GB',
      'flag': 'en-gb.png'
    },
    'nl-nl': {
      'code': 'nl_NL',
      'name': 'Nederlands (NL)',
      'countryCode': 'NL',
      'flag': 'nl-nl.png'
    },
    'es': {
      'code': 'es_ES',
      'name': 'Español',
      'countryCode': 'ES',
      'flag': 'es.png'
    },
    'es-ar': {
      'code': 'es_AR',
      'name': 'Español (AR)',
      'countryCode': 'AR',
      'flag': 'es-ar.png'
    },
    'es-cl': {
      'code': 'es_CL',
      'name': 'Español (CL)',
      'countryCode': 'CL',
      'flag': 'es-cl.png'
    },
    'es-co': {
      'code': 'es_CO',
      'name': 'Español (CO)',
      'countryCode': 'CO',
      'flag': 'es-co.png'
    },
    'es-mx': {
      'code': 'es_MX',
      'name': 'Español (MX)',
      'countryCode': 'MX',
      'flag': 'es-mx.png'
    },
    'fr-fr': {
      'code': 'fr_FR',
      'name': 'Français (FR)',
      'countryCode': 'FR',
      'flag': 'fr-fr.png'
    },
    'it-it': {
      'code': 'it_IT',
      'name': 'Italiano',
      'countryCode': 'IT',
      'flag': 'it-it.png'
    },
    'pt-br': {
      'code': 'pt_BR',
      'name': 'Português (BR)',
      'countryCode': 'BR',
      'flag': 'br.png'
    },
    'pt-pt': {
      'code': 'pt_PT',
      'name': 'Português (PT)',
      'countryCode': 'PT',
      'flag': 'pt-pt.png'
    },
    'fr-ca': {
      'code': 'fr_CA',
      'name': 'Français (CA)',
      'countryCode': 'CA',
      'flag': 'fr-ca.png'
    }
  },
  countriesLanguage: {
    'es': ['AR', 'BO', 'CO', 'CR', 'CU', 'DO', 'EC', 'SV', 'CQ', 'GT', 'HN', 'NI', 'PA', 'PY', 'PE', 'ES', 'UY', 'VE', 'PR'],
    'es-cl': ['CL'],
    'es-ar': ['AR'],
    'es-co': ['CO'],
    'es-mx': ['MX'],
    'pt-br': ['BR'],
    'it-it': ['IT'],
    'fr-fr': ['FR'],
    'pt-pt': ['PT'],
    'de-de': ['DE'],
    'en-GB': ['GB'],
    'nl-nl': ['NL'],
    'fr-ca': ['CA']
  },
  countriesCurrencies: {
    'AR': 'ARS',
    'BO': 'BOB',
    // 'PY': 'PYG',
    'DO': 'DOP',
    'JP': 'JPY',
    // 'VE': 'VEF',
    'CL': 'CLP',
    'CN': 'CNY',
    'PE': 'PEN',
    'CO': 'COP',
    'UY': 'UYU',
    'BR': 'BRL',
    'MX': 'MXN',
    'AU': 'AUD',
    'CX': 'AUD',
    'IC': 'AUD',
    'IH': 'AUD',
    'NF': 'AUD',
    'KI': 'AUD',
    'NR': 'AUD',
    'TV': 'AUD',
    'CA': 'CAD',
    'LI': 'CHF',
    'CH': 'CHF',
    'DE': 'EUR',
    'AD': 'EUR',
    'AT': 'EUR',
    'BE': 'EUR',
    'CY': 'EUR',
    'SK': 'EUR',
    'SI': 'EUR',
    'ES': 'EUR',
    'EE': 'EUR',
    'FI': 'EUR',
    'FR': 'EUR',
    'GR': 'EUR',
    'GP': 'EUR',
    'GF': 'EUR',
    'NL': 'EUR',
    'RE': 'EUR',
    'AX': 'EUR',
    'IE': 'EUR',
    'IT': 'EUR',
    'LV': 'EUR',
    'LT': 'EUR',
    'LU': 'EUR',
    'MT': 'EUR',
    'MQ': 'EUR',
    'YT': 'EUR',
    'MC': 'EUR',
    'ME': 'EUR',
    'PT': 'EUR',
    'SM': 'EUR',
    'BL': 'EUR',
    'SP': 'EUR',
    'TF': 'EUR',
    'VA': 'EUR',
    'XK': 'EUR',
    'GB': 'GBP',
    'GG': 'GBP',
    'IM': 'GBP',
    'JE': 'GBP',
    'PN': 'NZD',
    'CK': 'NZD',
    'TK': 'NZD',
    'NU': 'NZD',
    'NZ': 'NZD'
  },
  languageLocales: {
    'es': ['es'],
    'es-mx': ['es-mx'],
    'es-cl': ['es-cl'],
    'es-ar': ['es-ar'],
    'es-co': ['es-co'],
    'pt-br': ['pt-br'],
    'pt-pt': ['pt-pt'],
    'en-gb': ['en-gb'],
    'nl-nl': ['nl-nl'],
    'de-de': ['de-de'],
    'it-it': ['it-it'],
    'fr-fr': ['fr-fr'],
    'fr-ca': ['fr-ca']
  }
}
