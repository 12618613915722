export const ROUTES_WITH_LP_TOOLBAR = [
  'routes.home',
  'routes.locations',
  'routes.locations.country',
  'routes.locations.location',
  'routes.rentals',
  'routes.rentals.country',
  'routes.rental',
  'routes.rental.country',
  'routes.rental.location',
  'routes.rental.airport',
  'routes.airports',
  'routes.airports.country',
  'routes.airports.location',
  'routes.search.list',
  'routes.booking.detail',
  'routes.booking.configure',
  'routes.booking.payment',
  'routes.booking.confirmation',
  'routes.search.old-book-no-date',
  'routes.search.book-no-date'
]
