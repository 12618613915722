export default class LocationFactory {
  /**
   * @desc Represents a LocationFactory
   * @constructor
   */
  constructor(location) {
    if (location == null) {
      return {}
    }
    return this.legacyLocationNormalization(location)
  }

  /**
   * @desc treats configuration from legacy
   * @param {object} configuration
   * @return {object}
   */
  legacyLocationNormalization(location) {
    if (location.hasOwnProperty('Airport')) {
      return {
        Airport: location.Airport,
        Code: location.Code,
        Names: {
          'en': location.Names.en_us,
          'es': location.Names.es_ar,
          'es-cl': location.Names.es_cl,
          'es-ar': location.Names.es_ar,
          'es-co': location.Names.es_co,
          'es-mx': location.Names.es_mx,
          'pt-br': location.Names.pt_br,
          'en-gb': location.Names.en_gb,
          'nl-nl': location.Names.nl_nl,
          'it-it': location.Names.it_it,
          'fr-fr': location.Names.fr_fr,
          'de-de': location.Names.de_de,
          'pt-pt': location.Names.pt_pt,
          'fr-ca': location.Names.fr_ca
        }
      }
    } else if (location.hasOwnProperty('Aeroporto')) {
      return {
        Airport: location.Aeroporto != null,
        Code: parseInt(location.Codigo, 10),
        Names: location.LocalidadeI18n
      }
    } else {
      return {
        Airport: location.airport,
        Code: location.id,
        Names: {
          'en': location.name.en_us,
          'es': location.name.es_ar,
          'es-cl': location.name.es_cl,
          'es-ar': location.name.es_ar,
          'es-co': location.name.es_co,
          'es-mx': location.name.es_mx,
          'pt-br': location.name.pt_br,
          'en-gb': location.name.en_gb,
          'nl-nl': location.name.nl_nl,
          'it-it': location.name.it_it,
          'fr-fr': location.name.fr_fr,
          'de-de': location.name.de_de,
          'pt-pt': location.name.pt_pt,
          'fr-ca': location.names.fr_ca
        }
      }
    }
  }
}
