export default async ({ app: { store } }) => {
  let userCPF = null

  if (store.getters['user/getUserDocumentType'] === 'CPF') {
    userCPF = store.getters['user/getUserDocumentNumber'].replace(/[^0-9]/g, '')
  }

  window.RentcarsDataLayer = {
    ResidenceCodeValue: await store.getters.getUserResidenceCountry,
    GeoIpCountryCode: store.getters.getUserGeoipCountry,
    UserIP: store.getters.getUserIp,
    Currency: store.getters.getCurrency,
    Language: store.getters.getLocale,
    RequestorID: store.getters.getMobicarRequestorID,
    UserEmail: store.getters['user/getUserEmail'],
    UserID: store.getters['user/getUserId'],
    UserCPF: userCPF,
    SessionID: store.getters.getSessionID
  }
}
