export default ({ app: { store, router } }, inject) => {
  inject('routeResolver', (name, locale = null, returnPath = false, params = {}, query = {}) => {
    if (!locale) {
      locale = store.getters.getLocale
    }

    const routeName = `${name}.${locale}`
    if (returnPath && typeof returnPath === 'boolean') {
      return router.resolve({ name: routeName, params: params, query }).href
    } else if ((returnPath && typeof returnPath === 'string') || returnPath === '') {
      return returnPath + router.resolve({ name: routeName, params: params, query }).href
    }

    return routeName
  })
}
