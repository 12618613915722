import switchDefaultCustom from '~/services/Insider/utils/switchDefaultCustom'
import InsiderGlobal from '~/services/Insider/InsiderGlobal.js'
import buildUserObject from './utils/buildUserObject.js'

export default class InsiderObject extends InsiderGlobal {
  static instance

  static start () {
    if (!InsiderObject.instance) {
      InsiderObject.instance = new InsiderObject()
      InsiderObject.instance.load(null)
    }
    this.winInsiderObject = null
    this.objectName = null
    return InsiderObject.instance
  }

  updateUserInformation (params) {
    if (!window.insider_object) {
      window.insider_object = {}
    }

    buildUserObject(
      {
        loggedUser: params,
        insiderObject: window.insider_object
      }
    )

    for (const property in window.insider_object) {
      const propertiesThatIsPossible = ['user', 'requestor_id', 'request_device']

      if (!propertiesThatIsPossible.includes(property)) {
        delete window.insider_object[property]
      }
    }
  }

  productObject (params) {
    const language = window.location.pathname.split('/')[1]

    this.objectName = 'product'
    this.winInsiderObject = null

    this.winInsiderObject = this._adapterInsiderElement(
      params,
      this.insiderEnum.SET_VIEWD_STEPS
    )

    if (!this._verifyIfExistInsiderObject()) {
      window.insider_object = {}
    }

    if (!this._verifyIfProductExist()) {
      window.insider_object.product = {}
    }

    window.insider_object.product.id = params?.vehicle?.id.toString()

    this.insiderSet.setOfMainAttributes.forEach((attrName) => {
      if (this.winInsiderObject[attrName]) {
        window.insider_object.product[attrName] = this.winInsiderObject[attrName]
      }
    })

    const objectDefautAndCustom = switchDefaultCustom(this.winInsiderObject)

    if (objectDefautAndCustom.custom) {
      window.insider_object.product.custom = objectDefautAndCustom.custom
    }

    const { pickup, dropoff } = params.itinerary

    window.insider_object.page = { type: 'Product' }

    window.insider_object.product.custom.pickup_city_id = pickup.city?.id ? pickup.city.id : null
    window.insider_object.product.custom.dropoff_city_id = dropoff.city?.id ? dropoff.city.id : null

    window.insider_object.product.custom.pickup_formatted_date = pickup.formattedDate ?? null
    window.insider_object.product.custom.dropoff_formatted_date = dropoff.formattedDate ?? null

    window.insider_object.product.custom.pickup_state = pickup.state?.name ? pickup.state.name : null
    window.insider_object.product.custom.dropoff_state = dropoff.state?.name ? dropoff.state.name : null

    window.insider_object.product.custom.pickup_location = pickup.place.name ?? null
    window.insider_object.product.custom.pickup_location_id = pickup.place.id ?? null

    window.insider_object.product.custom.dropoff_location = dropoff.place.name ?? null
    window.insider_object.product.custom.dropoff_location_id = dropoff.place.id ?? null

    if (
      window.insider_object?.product?.custom?.payment_value &&
      window.insider_object?.product?.custom?.payment_currency &&
      language
    ) {
      window.insider_object.product.custom.payment_formatted_amount = window.insider_object.product.custom.payment_value.toLocaleString(
        language,
        {
          style: 'currency',
          currency: window.insider_object.product.custom.payment_currency
        })
    }
  }

  basketObject (params) {
    if (!this._verifyIfProductExist()) {
      this.productObject(params)
    }

    window.insider_object.basket = {}

    window.insider_object.basket.currency = this.getCurrency()
    window.insider_object.basket.total = this.getTotal()

    if (params.loyalty) {
      window.insider_object.product.custom = { credit: params.loyalty, ...window.insider_object.product.custom }
    }

    window.insider_object.basket.line_items = [
      {
        product: Object.assign({}, window.insider_object.product),
        quantity: 1,
        subtotal: this.getSubtotal()
      }
    ]

    window.insider_object.page = { type: 'Basket' }
    window.insider_object.basket.total = params.totalAmount.brlValue
    delete window.insider_object.product
  }

  transactionObject (params) {
    const { booking, paymentType } = params

    const insiderObject = {}

    insiderObject.transaction = {}

    if (window.insider_object?.basket) {
      const totalAmount = params.totalAmount?.brlValue ? params.totalAmount?.brlValue : booking.payment?.totalAmount?.brlValue
      insiderObject.transaction.order_id = booking?.bookingInfo?.code ? booking?.bookingInfo?.code : ''
      insiderObject.transaction.currency = window.insider_object.basket.currency ?? null
      insiderObject.transaction.total = totalAmount ?? null
      insiderObject.transaction.payment_type = paymentType === this.insiderEnum.PAY_AT_DESTINATION ? this.insiderEnum.PD : booking?.payment?.type
      insiderObject.transaction.line_items = window.insider_object.basket.line_items

      insiderObject.user = window.insider_object.user

      insiderObject.page = { type: 'Confirmation' }

      if (window.insider_object.basket.line_items &&
        window.insider_object.basket.line_items.length > 0 &&
        window.insider_object.basket.line_items[0].product.custom &&
        window.insider_object.basket.line_items[0].product.custom.credit) {
        insiderObject.custom = { credit: window.insider_object.basket.line_items[0].product.custom.credit }
      }

      sessionStorage.setItem('insiderTransaction', JSON.stringify(insiderObject))
    }
  }

  getObjectName () {
    return this.objectName
  }

  getWinInsiderObject () {
    return this.winInsiderObject
  }

  getCurrency () {
    const condition = this.winInsiderObject && this.winInsiderObject.currency
    return condition ? this.winInsiderObject.currency : undefined
  }

  getTotal () {
    const condition = this.winInsiderObject && this.winInsiderObject.payment_value

    return condition ? this.winInsiderObject.payment_value : undefined
  }

  getSubtotal () {
    const condition = this.winInsiderObject && this.winInsiderObject.subtotal

    return condition ? this.winInsiderObject.subtotal : undefined
  }

  _verifyIfExistInsiderObject () {
    let response = false

    try {
      response = window.insider_object &&
      typeof window.insider_object === 'object' &&
      Object.keys(window.insider_object) > 0
    } catch (error) {
      response = false
    }

    return response
  }

  _verifyIfProductExist () {
    let response = false

    try {
      response = this._verifyIfExistInsiderObject() &&
                 window.insider_object.product &&
                 typeof window.insider_object.product === 'object' &&
                 Object.keys(window.insider_object.product) > 0
    } catch (error) {
      response = false
    }

    return response
  }

  _verifyIfBasketExist () {
    let response = false

    try {
      response = this._verifyIfExistInsiderObject() &&
                 window.insider_object.basket &&
                 typeof window.insider_object.basket === 'object' &&
                 Object.keys(window.insider_object.basket).length > 0
    } catch (error) {
      response = false
    }

    return response
  }
}
