import { datadogRum } from '@datadog/browser-rum'

export default ({ $config: { ddApplicationId, ddApplicationToken, ddVersion, ddEnv } }) => {
  datadogRum.init({
    applicationId: ddApplicationId,
    clientToken: ddApplicationToken,
    site: 'datadoghq.com',
    service: 'front-mobile',
    env: ddEnv,
    version: ddVersion,
    sessionSampleRate: 1,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}
