import Moment from 'moment-timezone'
import RecentSearchService from 'services/RecentSearchService'
import RecentSearchesRepository from 'repositories//RecentSearchesRepository'
import BookingConfigurationFactory from 'factories/BookingConfigurationFactory'

const MAX_RECENT_SEARCHES_RESULTS = 2
const RecentSearchClient = new RecentSearchService()

export const state = () => ({
  resentSearch: [],
  bookingPageView: null,
  bookingPageConfigureView: null,
  bookingPageConfigurePayOnConfigure: null,
  bankSlipPrimeiroPay: null,
  updateBookingPreloaded: null
})

export const actions = {
  SET_UPDATE_BOOKING_PRELOADED: ({ commit }, data) => {
    commit('SET_UPDATE_BOOKING_PRELOADED', data)
  },

  SET_BOOKING_VIEW_DATA: ({ commit, dispatch }, data) => {
    commit('SET_BOOKING_VIEW_DATA', data)
    dispatch('ADD_RECENT_SEARCH', data)
  },

  SET_BOOKING_CONFIGURE_DATA: ({ commit, dispatch }, { search, configure, locale }) => {
    commit('SET_BOOKING_VIEW_DATA', search)
    commit('SET_BOOKING_CONFIGURE_DATA', configure)
    dispatch('ADD_RECENT_SEARCH', Object.assign({}, search, { locale }))
  },

  SET_BOOKING_CONFIGURE_DATA_PAY_ON_CONFIGURE: ({ commit, dispatch }, { data, locale }) => {
    commit('SET_BOOKING_CONFIGURE_DATA_PAY_ON_CONFIGURE', data)
    dispatch('ADD_RECENT_SEARCH', Object.assign({}, data, { locale }))
  },

  CLEAR_BOOKING_VIEW_DATA_FROM_REST: ({ commit }) => {
    commit('CLEAR_BOOKING_VIEW_DATA_FROM_REST')
  },

  /**
   * @desc action to calls ADD_RECENT_SEARCH
   */
  ADD_RECENT_SEARCH: async ({ commit, getters }, data) => {
    const recentSearch = await RecentSearchesRepository.GetRecentSearches(data.locale)
    if (data != null && data.locale != null) {
      commit('ADD_RECENT_SEARCH', { current: recentSearch, new: data })
    }
  },

  /**
   * @desc action to calls CLEAN_RECENT_SEARCH
   */
  CLEAN_RECENT_SEARCH: ({ commit }, locale) => {
    commit('CLEAN_RECENT_SEARCH', locale)
  },

  SET_BANKSLIP_PRIMEIRO_PAY: ({ commit }, data) => {
    commit('SET_BANKSLIP_PRIMEIRO_PAY', data)
  }
}

export const mutations = {
  SET_UPDATE_BOOKING_PRELOADED: (state, data) => {
    state.updateBookingPreloaded = data
  },

  SET_BOOKING_VIEW_DATA(state, data) {
    state.bookingPageView = data
  },

  SET_BOOKING_CONFIGURE_DATA: (state, data) => {
    state.bookingPageConfigureView = data
  },

  SET_BOOKING_CONFIGURE_DATA_PAY_ON_CONFIGURE: (state, data) => {
    state.bookingPageConfigurePayOnConfigure = data
  },
  CLEAR_BOOKING_VIEW_DATA_FROM_REST: (state) => {
    state.bookingPageView = null
    state.bookingPageConfigureView = null
    state.bankSlipPrimeiroPay = null
  },

  ADD_RECENT_SEARCH: (state, data) => {
    data.new.cacheDate = Moment().format()
    state.resentSearch = data.current

    RecentSearchClient.setNewOption(data.new)
    RecentSearchClient.setStoreOptions(state.resentSearch)

    if (RecentSearchClient != null && RecentSearchClient.needCleanStorage()) {
      state.resentSearch = []
      RecentSearchClient.setStoreOptions(state.resentSearch)
    } else if (RecentSearchClient != null && RecentSearchClient.canUpdateLastOne()) {
      state.resentSearch = state.resentSearch.splice(1)
    }

    state.resentSearch.splice(0, 0, data.new)

    if (state.resentSearch.length >= MAX_RECENT_SEARCHES_RESULTS) {
      state.resentSearch.splice(MAX_RECENT_SEARCHES_RESULTS, (state.resentSearch.length - MAX_RECENT_SEARCHES_RESULTS))
    }

    RecentSearchesRepository.SetRecentSearches(state.resentSearch, data.new.locale)
  },

  /**
   * @desc this method remove a LocalStorage data
   */
  CLEAN_RECENT_SEARCH: (state, locale) => {
    state.resentSearch = []
    RecentSearchesRepository.DeleteRecentSearches(locale)
  },

  SET_BANKSLIP_PRIMEIRO_PAY: (state, data) => {
    state.bankSlipPrimeiroPay = data
  }
}

export const getters = {
  /**
   * @desc returns updateBookingPreloaded
   * @return {null|Object}
   */
  getUpdateBookingPreloaded: state => state.updateBookingPreloaded,

  /**
   * @desc returns bookingPageView
   * @return {null|Object}
   */
  getBookingPageConfigurePayOnConfigure: state => state.bookingPageConfigurePayOnConfigure,
  /**
   * @desc returns bookingPageView
   * @return {null|Object}
   */
  getBookingPageView: state => state.bookingPageView,

  /**
   * @desc returns bookingPageConfigureView
   * @return {null|Object}
   */
  getBookingPageConfigureView: state => new BookingConfigurationFactory(state.bookingPageConfigureView),

  /**
   * @desc returns all recent searches
   * @returns {Array}
   */
  getRecentSearches: state => async (locale) => {
    if (!process.browser) return

    let resentSearch = []

    if (await RecentSearchesRepository.GetRecentSearches(locale)) {
      resentSearch = await RecentSearchesRepository.GetRecentSearches(locale)
    }

    return resentSearch
  },

  getBankslipPrimeiroPay: state => state.bankSlipPrimeiroPay,

  /**
   * @desc returns bookingPricePrediction
   * @return {undefined|Object}
   */
  getBookingPricePrediction: state => state.bookingPageConfigurePayOnConfigure?.pricePrediction
}
