export const state = () => ({
  stateSelected: {}
})

export const actions = {
  SET_STATE_SELECTED: ({ commit }, data) => {
    commit('SET_STATE_SELECTED', data)
  }
}
export const mutations = {
  SET_STATE_SELECTED: (state, stateSelected) => {
    state.stateSelected = stateSelected
  }
}

export const getters = {
  getStateSelected: state => state.stateSelected
}
