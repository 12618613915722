export default {
  name: 'AppModal',

  props: {
    id: {
      required: true,
      type: String
    },
    title: {
      required: false,
      type: String
    },
    titleHtml: {
      required: false,
      type: [Boolean],
      default: false
    },
    titleError: {
      required: false,
      type: Boolean,
      default: false
    },
    modalClose: {
      type: Boolean,
      required: false,
      default: true
    },
    description: {
      required: false,
      type: [String, Boolean]
    },
    descriptionType: {
      required: false,
      type: [String, Boolean]
    },
    showFooter: {
      required: false,
      type: Boolean,
      default: true
    },
    layoutBtn: {
      required: false,
      type: String,
      default: 'rc-btn--cta'
    },
    showSecondaryFooter: {
      required: false,
      type: Boolean,
      default: false
    },
    footerMessage: {
      type: String,
      required: false,
      default: undefined
    },
    fixedFooter: {
      required: false,
      type: Boolean,
      default: false
    },
    autoOpen: {
      required: false,
      type: Boolean,
      default: false
    },
    showFooterYesNo: {
      required: false,
      type: Boolean,
      default: false
    },
    themeLayout: {
      required: false,
      type: String,
      default: ''
    },
    typeButton: {
      required: false,
      type: String,
      default: 'btn-default'
    },
    isCouponLeanMore: {
      required: false,
      type: Boolean,
      default: false
    },
    customFooter: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      themeLayoutClass: 'modal__' + this.themeLayout,
      themeLayoutFixedFooter: 'modal-fixed-footer'
    }
  },

  mounted() {
    setTimeout(() => {
      $(this.$el)
        .modal({
          startingTop: '50%',
          endingTop: '50%',
          dismissible: this.modalClose,
          ready: function () { $('body').attr('style', 'overflow:hidden; display:fixed; height:100vh;') },
          complete: function () { $('body').attr('style', '') }
        })
      $('[data-target="' + this.$el.id + '"]')
        .on('click', () => {
          $(this.$el).modal('open')
        })
      if (this.autoOpen) {
        $(this.$el).modal('open')
      }
    }, 100)
  },

  methods: {
    getClassComponent() {
      if (this.fixedFooter === true) {
        return this.themeLayoutFixedFooter + ' ' + this.themeLayoutClass
      } else {
        return this.themeLayoutClass
      }
    }
  },

  destroyed() {
    /**
     * @desc Remove o overlay quando o usuário volta no browser já com um modal aberto
     */
    $('body').removeAttr('style')
    $('.modal-overlay').remove()
  }
}
