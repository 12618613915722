export default {
  inserted: function (el, binding) {
    $(document).ready(function () {
      let loading = false

      $(document).on('scroll', function () {
        if (loading === false) {
          if (isOnScreen($(el))) {
            loading = true

            el.setAttribute('src', binding.value)
          }
        }
      })

      function isOnScreen(element) {
        const win = $(window)
        const screenTop = win.scrollTop()
        const screenBottom = screenTop + win.height()

        const elementTop = element.offset().top
        const elementBottom = elementTop + element.height()

        return elementBottom > screenTop && elementTop < screenBottom
      }
    })
  }
}
