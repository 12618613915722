const SKYSCANNER = '34'
const KAYAK = '26'
const JETCOST = '149'
const LILIGO = '4426'
const AUTORENTALS = '2892'

const EXTERNAL_PARTNERS_ID = [
  SKYSCANNER,
  KAYAK,
  JETCOST,
  LILIGO,
  AUTORENTALS
]

export default EXTERNAL_PARTNERS_ID
