import buildUserObject from './utils/buildUserObject.js'
import InsiderGlobal from './InsiderGlobal.js'

const MOBILE = 'Mobile'

export default class InsiderService extends InsiderGlobal {
  static instance

  static start (pageType = null) {
    if (!InsiderService.instance) {
      InsiderService.instance = new InsiderService()
    }

    InsiderService.instance.load(pageType)

    return InsiderService.instance
  }

  setSearched (params) {
    this.insiderEvent = {
      language: this.utils.insiderFormatLanguage(params?.locale),
      referer: window.$nuxt.$store.getters.getReferer ?? null,
      ...this.baseSearchObject(params)
    }
  }

  setEmptySearch (params) {
    this.insiderEvent = this.baseSearchObject(params)
  }

  setLoginStep (params) {
    this.insiderEvent = this.getViewdBaseObject(params)
    this.utils.rentalcoverProcess(params, this.insiderEvent)
  }

  setUserData(params) {
    this.insiderEvent.user = params
  }

  setPaymentStep (params) {
    this.setViewedSteps(params)
    this.insiderEvent.coverage = params.coverage?.id
    this.insiderEvent.device_type = MOBILE
  }

  setSignUpConfirmation (params) {
    const userInformation = this.handlerUserInfs(params)

    this.insiderEvent = {
      name: userInformation.name,
      authentication: params?.authentication
    }
  }

  setLoggedIn (params) {
    this.insiderEvent.authentication = params.authentication
  }

  setLoggedOut () {
    this.insiderEvent = {}
  }

  setSessionStart (params) {
    this.toSessionsEvent(params)
  }

  setHomePageStart (params) {
    this.toSessionsEvent(params)
  }

  updateUserInformation (params) {
    if (!window.insider_object) {
      window.insider_object = {}
    }

    buildUserObject(
      {
        loggedUser: params,
        insiderObject: window.insider_object
      }
    )

    for (const property in window.insider_object) {
      const propertiesThatIsPossible = ['user', 'requestor_id', 'request_device']

      if (!propertiesThatIsPossible.includes(property)) {
        delete window.insider_object[property]
      }
    }

    if (window.Insider) {
      window.Insider.eventManager.dispatch('init-manager:re-initialize')
    }
  }
}
