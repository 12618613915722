export const state = () => ({
  faq: null
})

export const actions = {
  SET_FAQ_CONTENT({ commit }, faq) {
    commit('SET_FAQ', faq)
  }
}

export const mutations = {
  SET_FAQ(state, faq) {
    state.faq = faq
  }
}

export const getters = {
  getFaq: state => state.faq
}
