export default class IntercomAuthService {
  constructor(context) {
    this.rentcarsApi = context.$rentcarsApi || context.app.$rentcarsApi
  }

  async getHashUser(jwt) {
    const response = await this.rentcarsApi.get(
      '/v1/user/hash-user',
      null,
      false,
      null,
      jwt
    )
    if (response.data.hash) {
      return response.data.hash
    }
  }
}
