import { cookieTypes } from "../pwa/store/cookie"

export const MANDATORY = {
  'cookies': [
    'requestorID',
    'requestorIDReferrer',
    'countryResidence', // deprecated
    cookieTypes.RESIDENCE_CODE,
    'currency',
    'hl',
    'preferences',
    'readGdpr',
    'userUuid',
    'rewardsShowValue',
    'MGM',
    'auth.token',
    'auth_token'
  ],
  'indexeddb': []
}

export const PERFORMANCE = {
  'cookies': [
    'flag',
    'GA_ID',
    'lastAction',
    'alt_online',
    'signupDiscountRegistered',
    'signupDiscountClosed',
    'flag',
    'allow-pp',
    'wooTracker',
    'RentcarsWoopraTracker',
    '_gaexp',
    'gclid',
    'g_state',
    'auth_token'
  ],
  'indexeddb': [
    'Search-LastLocations'
  ]
}

export const PARTNERS = {
  'cookies': [
    'RKT',
    'ranMID',
    'ranEAID',
    'ranSiteID',
    'ranDate',
    'utm_campaign_rakuten',
    '_hjid',
    'advcake_query',
    'advcake_trackid',
    'advcake_url',
    'advcake_utm_campaign',
    'advcake_utm_content',
    'user_unic_ac_id',
    'xcntID',
    '_zid',
    'analytic_id',
    'vcake_trackid',
    'kayakclickid',
    '_rtbhouse_source_',
    'Campanha'
  ],
  'indexeddb': []
}
