export default ({ app: { $appCookie } }) => {
  function getParam(p) {
    const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
  }

  function getExpiryRecord(value) {
    return {
      value: value,
      expiryDate: '3M'
    }
  }

  const gclidParam = getParam('gclid')
  let gclidRecord = null

  const gclsrcParam = getParam('gclsrc')
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam)
    $appCookie.set('gclid', gclidRecord.value, { expires: gclidRecord.expiryDate })
  }
}
