export default {
  data() {
    return {
      key: null,
      sessionId: null,
      userId: null
    }
  },

  methods: {
    executeSift () {
      if (this.$store.getters['user/getValidLogin']) {
        this.key = process.env.SIFT_SCIENCE_KEY
        this.sessionId = document.cookie.match(/(^| )userUuid=([^;]+)/)[2] || ''
        this.userId = this.$store.getters['user/getUserEmail'] || ''

        const _sift = window._sift = window._sift || []
        _sift.push(['_setAccount', this.key])
        _sift.push(['_setUserId', this.userId])
        _sift.push(['_setSessionId', this.sessionId])
        _sift.push(['_trackPageview'])

        const e = document.createElement('script')
        e.src = 'https://cdn.siftscience.com/s.js'
        document.body.appendChild(e)
      }
    }
  }
}
