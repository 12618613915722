import insiderEnum from '../../services/Insider/InsiderEnum'

export default {
  methods: {
    switchCaseEvents (params, insiderService) {
      switch (params.insiderEventName) {
        case insiderEnum.LISTING_PAGE_VIEW:
          insiderService.setSearched(params)
          break

        case insiderEnum.VIEWED_NO_RESULTS:
          insiderService.setEmptySearch(params)
          break

        case insiderEnum.PRODUCT_DETAIL_PAGE_VIEW:
          insiderService.setViewedSteps(params)
          break

        case insiderEnum.VIEWED_PAYMENT_STEP:
          insiderService.setPaymentStep(params)
          break

        case insiderEnum.VIEWED_LOGIN_STEP:
          insiderService.setLoginStep(params)
          break

        case insiderEnum.SIGN_UP_CONFIRMATION:
          insiderService.setSignUpConfirmation(params)
          break

        case insiderEnum.LOGGED_IN:
          insiderService.setLoggedIn(params)
          break

        case insiderEnum.LOGGED_OUT:
          insiderService.setLoggedOut()
          break

        case insiderEnum.USER:
          insiderService.setUserData(params)
          break

        case insiderEnum.SESSION_START:
          insiderService.setSessionStart(params)
          break

        case insiderEnum.HOME_PAGE_VIEW:
          insiderService.setHomePageStart(params)
          break

        case insiderEnum.PURCHASE:
          insiderService.setPurchase(params)
          break
      }

      this.insiderEvent = insiderService.getInsiderEvent()
    }
  }
}
