<template>
  <div class="error-page">
    <template v-if="error.statusCode === 404">
      <div class="container">
        <img class="error-page--image" :src="$mobileCdn('/images/icons/image-error-page-404.svg')">

        <h1 class="error-page--title" v-html="getTitle()" />

        <p class="error-page--text" v-html="getMessage()" />
      </div>
    </template>

    <app-search-v2 />

    <home-most-popular-destinations v-if="locations" :locations="locations" sugestion-title />
  </div>
</template>

<script>
import AppSearchV2 from 'components/Search/FormV2/index.vue'
import appConfig from 'config'
import { Settings } from 'luxon'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
const HomeMostPopularDestinations = hydrateWhenVisible(() => import('components/MostPopularDestinations/home/MostPopularDestinations.vue'))

export default {
  name: 'view-error',

  fetch({ store }) {
    store.dispatch('header/SET_CONFIG', {
      logo: true,
      menu: true,
      recentSearch: true,
      showFlag: true
    })

    store.dispatch('footer/SET_CONFIG', {
      show: true
    })
  },

  components: {
    AppSearchV2,
    HomeMostPopularDestinations
  },

  props: {
    error: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      sugestionTitle: true,
      locations: null,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        preloadImages: false,
        lazy: false
      },
      config: {
        rules: {
          geoIp: this.$store.getters.getUserGeoipCountry,
          residenceCode: this.$store.getters.getAppResidenceCountry,
          locale: this.$store.getters.getLocale,
          requestorId: this.$store.getters.getMobicarRequestorID,
          currency: this.$store.getters.getCurrency,
          isLogged: this.$store.getters['user/getValidLogin']
        }
      }
    }
  },

  head() {
    return {
      title: `${appConfig.companyLabels.companyName} - 404`
    }
  },

  created() {
    Settings.defaultLocale = this.getLuxonLocale()
  },

  mounted () {
    this.addClickListener()

    this.$store.dispatch('header/APPEND_CONFIG', {
      logo: true,
      menu: true,
      recentSearch: true,
      showFlag: true
    })

    this.setLocation()
  },

  methods: {
    addClickListener() {
      const backHome = document.getElementById('link-back-home')

      if (backHome) {
        backHome.addEventListener('click', () => {
          this.$router.push({ name: this.$routeResolver('routes.home', this.$store.getters.getLocale) })
        })
      }
    },

    getTitle() {
      if (this.title) return this.title

      return `
        ${this.$t('errorpage.title')}
      `
    },

    getMessage() {
      if (this.message) return this.message

      return `<span>${this.$t('errorpage.thepagenotfound', { path: this.$route.path.substring(this.$route.path.lastIndexOf('/')) })}</span>
      <br> <br>
        <span>${this.$t('errorpage.notfoundtext')}</span>
      `
    },

    getLuxonLocale() {
      if (this.$i18n.locale === 'pt-pt') {
        return 'pt'
      }
      return this.$i18n.locale
    },

    setLocation() {
      const paramsLocations = {
        locale: this.$store.getters.getLocale,
        currency: 'BRL'
      }

      this.$rentcarsApi
        .get('v2/location/locationsHome', paramsLocations)
        .then((response) => {
          this.locations = response.data
        })
        .catch((e) => {
        })
    }
  }
}
</script>
<style src="./error.scss" lang="scss"></style>
