export const ROUTES_CANT_ACCESS_OFFLINE = {
  '/en/dotz': '/en/',
  '/es/dotz': '/es/',
  '/es-mx/dotz': '/es-mx/',

  '/de-de/livelo': '/de-de/',
  '/es/livelo': '/es/',
  '/es-mx/livelo': 'es-mx',
  '/en/livelo': '/en/',
  '/en-gb/livelo': '/en-gb/',
  '/nl-nl/livelo': '/nl-nl/',
  '/fr-fr/livelo': '/fr-fr/',
  '/it-it/livelo': '/it-it/',
  '/pt-pt/livelo': '/pt-pt/',

  '/en/premmia': '/en/',
  '/es/premmia': '/es/',
  '/es-mx/premmia': '/es-mx/',

  '/en/samsung': '/en/',
  '/es/samsung': '/es/',
  '/es-mx/samsung': '/es-mx/',

  '/en/tudoazul': '/en/',
  '/es/tudoazul': '/es/',
  '/es-mx/tudoazul': '/es-mx/',

  '/en/visa': '/en/',
  '/es/visa': '/es/',
  '/es-mx/visa': '/es-mx/',

  '/en/sicredi': '/en/',
  '/en-gb/sicredi': '/en-gb/',
  '/nl-nl/sicredi': '/nl-nl/',
  '/es/sicredi': '/es/',
  '/es-cl/sicredi': '/es-cl/',
  '/es-ar/sicredi': '/es-ar/',
  '/es-co/sicredi': '/es-co/',
  '/es-mx/sicredi': '/es-mx/',
  '/de-de/sicredi': '/de-de/',
  '/it-it/sicredi': '/it-it/',
  '/pt-pt/sicredi': '/pt-pt/',
  '/fr-fr/sicredi': '/fr-fr/',
  '/fr-ca/sicredi': '/fr-ca/',

  '/de-de/kmdevantagens': '/de-de/',
  '/es/kmdevantagens': '/es/',
  '/es-mx/kmdevantagens': 'es-mx',
  '/en/kmdevantagens': '/en/',
  '/en-gb/kmdevantagens': '/en-gb/',
  '/nl-nl/kmdevantagens': '/nl-nl/',
  '/fr-fr/kmdevantagens': '/fr-fr/',
  '/it-it/kmdevantagens': '/it-it/',
  '/pt-pt/kmdevantagens': '/pt-pt/',

  '/pt-br/trabalheconosco': 'http://careers.rentcars.com/',

  '/en-gb/buenfin': '/en-gb/',
  '/nl-nl/buenfin': '/nl-nl/',
  '/es/buenfin': '/es/',
  '/en/buenfin': '/en/',
  '/de-de/buenfin': '/de-de/',
  '/it-it/buenfin': '/it-it/',
  '/pt-br/buenfin': '/pt-br/',
  '/pt-pt/buenfin': '/pt-pt/',
  '/fr-fr/buenfin': '/fr-fr/',

  '/en-gb/hotsale': '/en-gb/',
  '/nl-nl/hotsale': '/nl-nl/',
  '/es/hotsale': '/es/',
  '/en/hotsale': '/en/',
  '/de-de/hotsale': '/de-de/',
  '/it-it/hotsale': '/it-it/',
  '/pt-br/hotsale': '/pt-br/',
  '/pt-pt/hotsale': '/pt-pt/',
  '/fr-fr/hotsale': '/fr-fr/',
  '/es-ar/hotsale': '/es-ar/',
  '/es-cl/hotsale': '/es-cl/',

  '/de-de/tv': '/de-de/',
  '/es/tv': '/es/',
  '/es-mx/tv': 'es-mx',
  '/en/tv': '/en/',
  '/en-gb/tv': '/en-gb/',
  '/nl-nl/tv': '/nl-nl/',
  '/fr-fr/tv': '/fr-fr/',
  '/it-it/tv': '/it-it/',
  '/pt-pt/tv': '/pt-pt/',

  '/de-de/ferias-de-verao': '/de-de/',
  '/en/ferias-de-verao': '/en/',
  '/en-gb/ferias-de-verao': '/en-gb/',
  '/nl-nl/ferias-de-verao': '/nl-nl/',
  '/fr-fr/ferias-de-verao': '/fr-fr/',
  '/it-it/ferias-de-verao': '/it-it/',
  '/pt-pt/ferias-de-verao': '/pt-pt/',
  '/pt-br/ferias-de-verao': '/pt-br/lp/ferias-de-verao',

  '/de-de/latampass': '/de-de/',
  '/es/latampass': '/es/',
  '/es-mx/latampass': 'es-mx',
  '/en/latampass': '/en/',
  '/en-gb/latampass': '/en-gb/',
  '/nl-nl/latampass': '/nl-nl/',
  '/fr-fr/latampass': '/fr-fr/',
  '/it-it/latampass': '/it-it/',
  '/pt-pt/latampass': '/pt-pt/',

  '/de-de/oab': '/de-de/',
  '/es/oab': '/es/',
  '/es-mx/oab': 'es-mx',
  '/en/oab': '/en/',
  '/en-gb/oab': '/en-gb/',
  '/nl-nl/oab': '/nl-nl/',
  '/fr-fr/oab': '/fr-fr/',
  '/it-it/oab': '/it-it/',
  '/pt-pt/oab': '/pt-pt/',

  '/de-de/carnaval': '/de-de/',
  '/es/carnaval': '/es/',
  '/es-mx/carnaval': 'es-mx',
  '/en/carnaval': '/en/',
  '/en-gb/carnaval': '/en-gb/',
  '/nl-nl/carnaval': '/nl-nl/',
  '/fr-fr/carnaval': '/fr-fr/',
  '/it-it/carnaval': '/it-it/',
  '/pt-br/carnaval': '/pt-br/lp/carnaval',
  '/pt-pt/carnaval': '/pt-pt/',

  '/de-de/promocoes/sao-joao': '/de-de/',
  '/es/promocoes/sao-joao': '/es/',
  '/es-mx/promocoes/sao-joao': 'es-mx',
  '/en/promocoes/sao-joao': '/en/',
  '/en-gb/promocoes/sao-joao': '/en-gb/',
  '/nl-nl/promocoes/sao-joao': '/nl-nl/',
  '/fr-fr/promocoes/sao-joao': '/fr-fr/',
  '/it-it/promocoes/sao-joao': '/it-it/',
  '/pt-pt/promocoes/sao-joao': '/pt-pt/',
  '/pt-br/promocoes/sao-joao': '/pt-br/lp/sao-joao',

  '/de-de/bradescoseguros': '/de-de/',
  '/es/bradescoseguros': '/es/',
  '/es-mx/bradescoseguros': 'es-mx',
  '/en/bradescoseguros': '/en/',
  '/en-gb/bradescoseguros': '/en-gb/',
  '/nl-nl/bradescoseguros': '/nl-nl/',
  '/fr-fr/bradescoseguros': '/fr-fr/',
  '/it-it/bradescoseguros': '/it-it/',
  '/pt-pt/bradescoseguros': '/pt-pt/',

  '/de-de/bradescoexclusive': '/de-de/',
  '/es/bradescoexclusive': '/es/',
  '/es-mx/bradescoexclusive': 'es-mx',
  '/en/bradescoexclusive': '/en/',
  '/en-gb/bradescoexclusive': '/en-gb/',
  '/nl-nl/bradescoexclusive': '/nl-nl/',
  '/fr-fr/bradescoexclusive': '/fr-fr/',
  '/it-it/bradescoexclusive': '/it-it/',
  '/pt-pt/bradescoexclusive': '/pt-pt/',

  '/de-de/bradescoprime': '/de-de/',
  '/es/bradescoprime': '/es/',
  '/es-mx/bradescoprime': 'es-mx',
  '/en/bradescoprime': '/en/',
  '/en-gb/bradescoprime': '/en-gb/',
  '/nl-nl/bradescoprime': '/nl-nl/',
  '/fr-fr/bradescoprime': '/fr-fr/',
  '/it-it/bradescoprime': '/it-it/',
  '/pt-pt/bradescoprime': '/pt-pt/',

  '/de-de/maio-amarelo': '/de-de/',
  '/es/maio-amarelo': '/es/',
  '/es-mx/maio-amarelo': 'es-mx',
  '/en/maio-amarelo': '/en/',
  '/en-gb/maio-amarelo': '/en-gb/',
  '/nl-nl/maio-amarelo': '/nl-nl/',
  '/fr-fr/maio-amarelo': '/fr-fr/',
  '/it-it/maio-amarelo': '/it-it/',
  '/pt-pt/maio-amarelo': '/pt-pt/',
  '/pt-br/maio-amarelo': '/pt-br/lp/maio-amarelo',

  '/de-de/promocoes/ferias-de-julho': '/de-de/',
  '/es/promociones/ferias-de-julho': '/es/',
  '/es-mx/promociones/ferias-de-julho': 'es-mx',
  '/en/promociones/ferias-de-julho': '/en/',
  '/en-gb/promocoes/ferias-de-julho': '/en-gb/',
  '/nl-nl/promocoes/ferias-de-julho': '/nl-nl/',
  '/fr-fr/promocoes/ferias-de-julho': '/fr-fr/',
  '/it-it/promocoes/ferias-de-julho': '/it-it/',
  '/pt-pt/promocoes/ferias-de-julho': '/pt-pt/',
  '/pt-br/promocoes/ferias-de-julho': '/pt-br/lp/ferias-de-julho',

  '/de-de/timpramim': '/de-de/',
  '/es/timpramim': '/es/',
  '/es-mx/timpramim': 'es-mx',
  '/en/timpramim': '/en/',
  '/en-gb/timpramim': '/en-gb/',
  '/nl-nl/timpramim': '/nl-nl/',
  '/fr-fr/timpramim': '/fr-fr/',
  '/it-it/timpramim': '/it-it/',
  '/pt-pt/timpramim': '/pt-pt/',

  '/de-de/timblack': '/de-de/',
  '/es/timblack': '/es/',
  '/es-mx/timblack': 'es-mx',
  '/en/timblack': '/en/',
  '/en-gb/timblack': '/en-gb/',
  '/nl-nl/timblack': '/nl-nl/',
  '/fr-fr/timblack': '/fr-fr/',
  '/it-it/timblack': '/it-it/',
  '/pt-pt/timblack': '/pt-pt/',

  '/en/itau-personnalite': '/en/',
  '/en-gb/itau-personnalite': '/en-gb/',
  '/nl-nl/itau-personnalite': '/nl-nl/',
  '/es/itau-personnalite': '/es/',
  '/es-cl/itau-personnalite': '/es-cl/',
  '/es-ar/itau-personnalite': '/es-ar/',
  '/es-co/itau-personnalite': '/es-co/',
  '/es-mx/itau-personnalite': '/es-mx/',
  '/de-de/itau-personnalite': '/de-de/',
  '/it-it/itau-personnalite': '/it-it/',
  '/pt-pt/itau-personnalite': '/pt-pt/',
  '/fr-fr/itau-personnalite': '/fr-fr/',
  '/fr-ca/itau-personnalite': '/fr-ca/',

  '/en/allaccor': '/en/',
  '/en-gb/allaccor': '/en-gb/',
  '/nl-nl/allaccor': '/nl-nl/',
  '/es-cl/allaccor': '/es-cl/',
  '/es-ar/allaccor': '/es-ar/',
  '/es-co/allaccor': '/es-co/',
  '/es-mx/allaccor': '/es-mx/',
  '/de-de/allaccor': '/de-de/',
  '/it-it/allaccor': '/it-it/',
  '/pt-pt/allaccor': '/pt-pt/',
  '/fr-fr/allaccor': '/fr-fr/',
  '/fr-ca/allaccor': '/fr-ca/',

  '/de-de/road-trip-america-latina': '/de-de/',
  '/en/road-trip-america-latina': '/en/',
  '/en-gb/road-trip-america-latina': '/en-gb/',
  '/nl-nl/road-trip-america-latina': '/nl-nl/',
  '/fr-fr/road-trip-america-latina': '/fr-fr/',
  '/it-it/road-trip-america-latina': '/it-it/',
  '/pt-pt/road-trip-america-latina': '/pt-pt/',

  '/de-de/segurospromo': '/de-de/',
  '/es/segurospromo': '/es/',
  '/es-mx/segurospromo': 'es-mx',
  '/en/segurospromo': '/en/',
  '/en-gb/segurospromo': '/en-gb/',
  '/nl-nl/segurospromo': '/nl-nl',
  '/fr-fr/segurospromo': '/fr-fr/',
  '/it-it/segurospromo': '/it-it/',
  '/pt-pt/segurospromo': '/pt-pt/',

  '/de-de/passagenspromo': '/de-de/',
  '/es/passagenspromo': '/es/',
  '/es-mx/passagenspromo': 'es-mx',
  '/en/passagenspromo': '/en/',
  '/en-gb/passagenspromo': '/en-gb/',
  '/nl-nl/passagenspromo': '/nl-nl/',
  '/fr-fr/passagenspromo': '/fr-fr/',
  '/it-it/passagenspromo': '/it-it/',
  '/pt-pt/passagenspromo': '/pt-pt/',

  '/de-de/skymilhas': '/de-de/',
  '/es/skymilhas': '/es/',
  '/es-mx/skymilhas': 'es-mx',
  '/en/skymilhas': '/en/',
  '/en-gb/skymilhas': '/en-gb/',
  '/nl-nl/skymilhas': '/nl-nl/',
  '/fr-fr/skymilhas': '/fr-fr/',
  '/it-it/skymilhas': '/it-it/',
  '/pt-pt/skymilhas': '/pt-pt/',

  '/es-mx/vacaciones-de-verano': '/es-mx/lp/vacaciones-de-verano',
  '/es/vacaciones-de-verano': '/es/lp/vacaciones-de-verano',

  '/pt-br/aeroportos/brasil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/pt-br/aeroportos/brasil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/es/aeropuertos/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/es/aeropuertos/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/es-mx/aeropuertos/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/es-mx/aeropuertos/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/es-cl/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/es-cl/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/es-ar/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/es-ar/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/es-co/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/es-co/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/en/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/en/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/en-gb/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/en-gb/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/nl-nl/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/nl-nl/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/fr-fr/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/fr-fr/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/it-it/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/it-it/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/de-de/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/de-de/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',
  '/pt-pt/airports/brazil/vdc-pedro-otacilio-figueiredo-vitoria-da-conquista-bahia': '/pt-pt/airports/brazil/vdc-glauber-rocha-vitoria-da-conquista-bahia',

  '/blog': 'https://blog.rentcars.com/',
  '/pt-br/blog': 'https://blog.rentcars.com/',
  '/pt-pt/blog': 'https://blog.rentcars.com/',
  '/es/blog': 'https://blog.rentcars.com/es',
  '/es-ar/blog': 'https://blog.rentcars.com/es',
  '/es-co/blog': 'https://blog.rentcars.com/es',
  '/es-cl/blog': 'https://blog.rentcars.com/es',
  '/es-mx/blog': 'https://blog.rentcars.com/es',
  '/en/blog': 'https://blog.rentcars.com/en',
  '/en-gb/blog': 'https://blog.rentcars.com/en',
  '/de-de/blog': 'https://blog.rentcars.com/en',
  '/nl-nl/blog': 'https://blog.rentcars.com/en',
  '/fr-ca/blog': 'https://blog.rentcars.com/en',
  '/fr-fr/blog': 'https://blog.rentcars.com/en',
  '/it-it/blog': 'https://blog.rentcars.com/en'

}

export const PROMOTIONS_DATES = {
  blackfriday: {
    'all': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2024-10-31 15:00',
      pre_ends: '2024-11-24 14:59',
      esquenta_starts: '2024-10-25 09:00',
      esquenta_ends: '2024-10-31 14:59',
      promo_starts: '2024-11-24 15:00',
      promo_ends: '2024-12-02 07:59',
      extended_starts: '2024-12-02 08:00',
      extended_ends: '2024-12-09 07:59',
      finish_starts: '2024-12-09 08:00',
      finish_ends: '2025-10-31 23:59'
    },
    'pt_br': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2024-10-31 15:00',
      pre_ends: '2024-11-24 14:59',
      esquenta_starts: '2024-10-25 09:00',
      esquenta_ends: '2024-10-31 14:59',
      promo_starts: '2024-11-24 15:00',
      promo_ends: '2024-12-02 07:59',
      extended_starts: '2024-12-02 08:00',
      extended_ends: '2024-12-09 07:59',
      finish_starts: '2024-12-09 08:00',
      finish_ends: '2025-10-31 23:59'
    },
    en: {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-11-10 23:59',
      esquenta_starts: '2019-11-11 00:00',
      esquenta_ends: '2019-11-28 16:59',
      promo_starts: '2019-11-28 17:00',
      promo_ends: '2019-12-01 23:59',
      finish_starts: '2019-12-02 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    es: {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-11-10 23:59',
      esquenta_starts: '2019-11-11 00:00',
      esquenta_ends: '2019-11-28 16:59',
      promo_starts: '2019-11-28 17:00',
      promo_ends: '2019-12-01 23:59',
      finish_starts: '2019-12-02 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-cl': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-11-10 23:59',
      esquenta_starts: '2019-11-11 00:00',
      esquenta_ends: '2019-11-28 16:59',
      promo_starts: '2019-11-28 17:00',
      promo_ends: '2019-12-01 23:59',
      finish_starts: '2019-12-02 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-ar': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-11-10 23:59',
      esquenta_starts: '2019-11-11 00:00',
      esquenta_ends: '2019-11-28 16:59',
      promo_starts: '2019-11-28 17:00',
      promo_ends: '2019-12-01 23:59',
      finish_starts: '2019-12-02 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-co': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-11-10 23:59',
      esquenta_starts: '2019-11-11 00:00',
      esquenta_ends: '2019-11-28 16:59',
      promo_starts: '2019-11-28 17:00',
      promo_ends: '2019-12-01 23:59',
      finish_starts: '2019-12-02 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-mx': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-11-10 23:59',
      esquenta_starts: '2019-11-11 00:00',
      esquenta_ends: '2019-11-28 16:59',
      promo_starts: '2019-11-28 17:00',
      promo_ends: '2019-12-01 23:59',
      finish_starts: '2019-12-02 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es_mx': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2022-11-14 00:00',
      pre_ends: '2022-11-20 23:59',
      esquenta_starts: '2022-11-21 00:00',
      esquenta_ends: '2022-11-24 23:59',
      promo_starts: '2022-11-25 00:00',
      promo_ends: '2022-11-27 23:59',
      finish_starts: '2022-11-28 00:00',
      finish_ends: '2023-10-30 23:59'
    }
  },
  buenfin: {
    'all': {
      toolbar_starts: '2018-11-12 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2024-10-31 15:00',
      pre_ends: '2024-11-14 14:59',
      esquenta_starts: '2022-11-01 00:00',
      esquenta_ends: '2022-11-17 23:59',
      promo_starts: '2024-11-14 15:00',
      promo_ends: '2024-11-19 07:59',
      finish_starts: '2024-11-19 08:00',
      finish_ends: '2025-10-31 23:59'
    },
    'es-mx': {
      toolbar_starts: '2018-11-12 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2024-10-31 15:00',
      pre_ends: '2024-11-14 14:59',
      esquenta_starts: '2020-10-26 00:00',
      esquenta_ends: '2020-11-08 23:59',
      promo_starts: '2024-11-14 15:00',
      promo_ends: '2024-11-19 07:59',
      finish_starts: '2024-11-19 08:00',
      finish_ends: '2025-10-31 23:59'
    }
  },
  cybermonday: {
    'all': {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2021-09-01 00:00',
      pre_ends: '2021-11-28 23:59',
      promo_starts: '2023-11-27 00:00',
      promo_ends: '2023-11-27 23:59',
      finish_starts: '2023-11-28 00:00',
      finish_ends: '2024-11-24 23:59'
    },
    'pt-br': {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-12-01 23:59',
      promo_starts: '2019-12-02 00:00',
      promo_ends: '2019-12-08 23:59',
      finish_starts: '2019-12-09 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    en: {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-12-01 23:59',
      promo_starts: '2019-12-02 00:00',
      promo_ends: '2019-12-08 23:59',
      finish_starts: '2019-12-09 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-mx': {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-12-01 23:59',
      promo_starts: '2019-12-02 00:00',
      promo_ends: '2019-12-08 23:59',
      finish_starts: '2019-12-09 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    es: {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-12-01 23:59',
      promo_starts: '2019-12-02 00:00',
      promo_ends: '2019-12-08 23:59',
      finish_starts: '2019-12-09 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-cl': {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-12-01 23:59',
      promo_starts: '2019-12-02 00:00',
      promo_ends: '2019-12-08 23:59',
      finish_starts: '2019-12-09 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-ar': {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-12-01 23:59',
      promo_starts: '2019-12-02 00:00',
      promo_ends: '2019-12-08 23:59',
      finish_starts: '2019-12-09 00:00',
      finish_ends: '2020-08-31 23:59'
    },
    'es-co': {
      toolbar_starts: '2018-11-08 00:00',
      toolbar_ends: '2018-11-18 23:59',
      pre_starts: '2019-09-01 00:00',
      pre_ends: '2019-12-01 23:59',
      promo_starts: '2019-12-02 00:00',
      promo_ends: '2019-12-08 23:59',
      finish_starts: '2019-12-09 00:00',
      finish_ends: '2020-08-31 23:59'
    }
  },
  traveltuesday: {
    'all': {
      toolbar_starts: '2018-11-19 00:00',
      toolbar_ends: '2018-11-25 23:59',
      pre_starts: '2021-09-01 00:00',
      pre_ends: '2021-11-29 23:59',
      promo_starts: '2023-11-28 00:00',
      promo_ends: '2023-11-28 23:59',
      finish_starts: '2023-11-29 00:00',
      finish_ends: '2024-12-02 23:59'
    }
  },
  hotsale: {
    'es-mx': {
      pre_starts: '2023-05-22 00:00',
      pre_ends: '2023-05-24 23:59',
      esquenta_starts: '2022-04-25 00:00',
      esquenta_ends: '2022-05-22 23:59',
      promo_starts: '2024-04-03 00:00',
      promo_ends: '2024-05-23 23:59',
      finish_starts: '2024-05-24 00:00',
      finish_ends: '2025-04-01 23:59'
    },
    'es-ar': {
      pre_starts: '2023-05-01 00:00',
      pre_ends: '2023-05-07 23:59',
      esquenta_starts: '2022-04-25 00:00',
      esquenta_ends: '2022-05-28 23:59',
      promo_starts: '2023-05-08 00:00',
      promo_ends: '2023-06-11 23:59',
      finish_starts: '2023-06-12 00:00',
      finish_ends: '2024-05-12 23:59'
    },
    'es-cl': {
      pre_starts: '2023-05-22 00:00',
      pre_ends: '2023-05-28 23:59',
      esquenta_starts: '2022-04-25 00:00',
      esquenta_ends: '2022-05-22 23:59',
      promo_starts: '2023-05-29 00:00',
      promo_ends: '2023-06-11 23:59',
      finish_starts: '2023-06-12 00:00',
      finish_ends: '2024-05-12 23:59'
    },
    'es-co': {
      pre_starts: '2024-10-09 00:00',
      pre_ends: '2024-10-16 23:59',
      esquenta_starts: '2022-04-25 00:00',
      esquenta_ends: '2022-05-22 23:59',
      promo_starts: '2024-10-17 00:00',
      promo_ends: '2024-10-21 23:59',
      finish_starts: '2024-10-22 00:00',
      finish_ends: '2024-10-27 23:59'
    }
  },
  hottravel: {
    'es-mx': {
      esquenta_starts: '2024-09-25 00:00',
      esquenta_ends: '2024-10-02 23:59',
      promo_starts: '2024-10-03 00:00',
      promo_ends: '2024-10-10 23:59',
      finish_starts: '2024-10-11 00:00',
      finish_ends: '2024-10-18 23:59'
    }
  },
  carnaval: {
    starts: '2019-02-04 00:00',
    ends: '2019-03-05 23:59:59'
  },
  consumerBanner: {
    starts: '2019-03-22 00:00',
    ends: '2019-03-31 23:59:59'
  },
  ptPt15offpromo: {
    starts: '2019-10-02 20:00',
    ends: '2019-10-13 19:59:59'
  },
  bradescopromo: {
    starts: '2019-09-06 00:00',
    ends: '2019-09-15 23:59:59'
  },
  portugal15off: {
    starts: '2019-10-02 20:00',
    ends: '2019-10-13 19:59'
  },
  couponEsCl: {
    starts: '2019-10-16 00:00',
    ends: '2019-10-31 23:59'
  },
  couponEsMx: {
    starts: '2019-10-15 00:00',
    ends: '2019-10-31 23:59'
  },
  cashbackBanner: {
    starts: '2020-11-01 00:00',
    ends: '2020-12-04 23:59'
  }
}

export const PROMOTIONS_PARTNERS_DATES = {
  latampass: {
    promo: {
      starts: '2020-02-15 00:00',
      ends: '2020-02-21 23:59:59',
      points: 4
    }
  },
  visa: {
    promo: {
      starts: '2020-02-03 00:00',
      ends: '2020-02-14 23:59:59'
    }
  },
  sicredi: {
    promo: {
      starts: '2020-02-03 00:00',
      ends: '2020-02-14 23:59:59'
    }
  },
  premmia: {
    blackfriday: {
      starts: '2018-11-19 00:00',
      ends: '2018-11-26 23:59:59'
    }
  },
  dotz: {
    blackfriday: {
      starts: '2018-11-19 00:00',
      ends: '2018-11-26 23:59:59'
    },
    points5x1: {
      starts: '2020-02-03 00:00',
      ends: '2020-02-09 23:59',
      dotz: 5
    }
  },
  bradescoseguros: {
    promo: {
      starts: '2019-10-15 00:00',
      ends: '2019-10-21 23:59:59'
    }
  },
  tudoazul: {
    promo: {
      starts: '2020-01-16 00:00',
      ends: '2020-01-20 23:59',
      points: 5
    },
    promo10x1: {
      starts: '2020-02-12 00:00',
      ends: '2020-02-14 23:59',
      points: 10
    }
  },
  livelo: {
    points3x1: {
      starts: '2020-02-10 00:00',
      ends: '2020-02-14 23:59',
      points: 3
    }
  },
  timblack: {
    euapromo: {
      starts: '2019-08-15 00:00',
      ends: '2019-08-31 23:59'
    }
  }
}

export const BEST_CARS = {
  'en': [
    {
      'bg-image': 'imagens/modules/promo/bg-orlando.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'car-image': 'imagens/modules/promo/car-quest.png',
      'location': 'location.usa',
      'title': 'lp.summer.family',
      'url': 'locations/united-states/orlando-florida',
      'minValue': '120'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-cancun.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'car-image': 'imagens/modules/promo/car-trax.png',
      'location': 'location.mexico',
      'title': 'lp.summer.friends',
      'url': 'locations/mexico/cancun-qr-quintana-roo',
      'minValue': '62'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-panama.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-pa.png',
      'car-image': 'imagens/modules/promo/car-optima.png',
      'location': 'location.panama',
      'title': 'lp.summer.business',
      'url': 'locations/panama/panama-pp-provincia-de-panama',
      'minValue': '104'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-buenos-aires.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-ar.png',
      'car-image': 'imagens/modules/promo/car-clio.png',
      'location': 'location.argentina',
      'title': 'lp.summer.weekend',
      'url': 'locations/argentina/buenos-aires-buenos-aires',
      'minValue': '147'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-rio-de-janeiro.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'car-image': 'imagens/modules/promo/car-kwid.png',
      'location': 'location.brazil',
      'title': 'lp.summer.economy',
      'url': 'locations/brazil/rio-de-janeiro-rio-de-janeiro',
      'minValue': '50'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-locarno.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-ch.png',
      'car-image': 'imagens/modules/promo/car-xc60.png',
      'location': 'location.switzerland',
      'title': 'lp.summer.nature',
      'url': 'locations/switzerland/locarno-district-ti-ticino',
      'minValue': '271'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-sorrento.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'car-image': 'imagens/modules/promo/car-panda.png',
      'location': 'location.italy',
      'title': 'lp.summer.italy',
      'url': 'locations/italy/sorrento-ca-campania',
      'minValue': '205'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-mallorca.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'car-image': 'imagens/modules/promo/car-mii.png',
      'location': 'location.spain',
      'title': 'lp.summer.spain',
      'url': 'locations/spain/palma-de-mallorca-ib-comunitat-autonoma-de-les-illes-balears',
      'minValue': '81'
    }
  ],
  'pt-br': [
    {
      'bg-image': 'imagens/modules/promo/bg-city.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'car-image': 'imagens/modules/promo/car-mobi.png',
      'location': 'location.brazil',
      'title': 'lp.summer.weekend',
      'url': 'localidades/brasil/sao-paulo-sao-paulo',
      'minValue': '62'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-friends.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'car-image': 'imagens/modules/promo/car-hb20.png',
      'location': 'location.brazil',
      'title': 'lp.summer.beach',
      'url': 'localidades/brasil/recife-pernambuco',
      'minValue': '73'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-family.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'car-image': 'imagens/modules/promo/car-prisma.png',
      'location': 'location.brazil',
      'title': 'lp.summer.family',
      'url': 'localidades/brasil/florianopolis-santa-catarina',
      'minValue': '85'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-business.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'car-image': 'imagens/modules/promo/car-kwid.png',
      'location': 'location.brazil',
      'title': 'lp.summer.business',
      'url': 'localidades/brasil/porto-alegre-rio-grande-do-sul',
      'minValue': '62'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-family2.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'car-image': 'imagens/modules/promo/car-rav4.png',
      'location': 'location.usa',
      'title': 'lp.summer.family',
      'url': 'localidades/estados-unidos/orlando-florida',
      'minValue': '135'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-usa.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-uy.png',
      'car-image': 'imagens/modules/promo/car-yaris.png',
      'location': 'location.usa',
      'title': 'lp.summer.economy',
      'url': 'localidades/uruguai/montevideu-mo-departamento-de-montevideo',
      'minValue': '93'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-ny.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'car-image': 'imagens/modules/promo/car-quest.png',
      'location': 'location.usa',
      'title': 'lp.summer.shoppingeua',
      'url': 'localidades/estados-unidos/miami-florida',
      'minValue': '154'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-route.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'car-image': 'imagens/modules/promo/car-santafe.png',
      'location': 'location.usa',
      'title': 'lp.summer.roadtrip',
      'url': 'localidades/estados-unidos/san-francisco-ca-california',
      'minValue': '124'
    }
  ],
  'es': [
    {
      'bg-image': 'imagens/modules/promo/bg-orlando.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'car-image': 'imagens/modules/promo/car-quest.png',
      'location': 'location.usa',
      'title': 'lp.summer.orlando',
      'url': 'localidades/united-states/orlando-florida',
      'minValue': '31'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-cancun.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'car-image': 'imagens/modules/promo/car-trax.png',
      'location': 'location.mexico',
      'title': 'lp.summer.beach',
      'url': 'localidades/mexico/cancun-qr-quintana-roo',
      'minValue': '16'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-panama.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-pa.png',
      'car-image': 'imagens/modules/promo/car-optima.png',
      'location': 'location.panama',
      'title': 'lp.summer.business',
      'url': 'localidades/panama/panama-pp-provincia-de-panama',
      'minValue': '27'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-buenos-aires.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-ar.png',
      'car-image': 'imagens/modules/promo/car-clio.png',
      'location': 'location.argentina',
      'title': 'lp.summer.weekend',
      'url': 'localidades/argentina/buenos-aires-buenos-aires',
      'minValue': '38'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-rio-de-janeiro.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'car-image': 'imagens/modules/promo/car-kwid.png',
      'location': 'location.brazil',
      'title': 'lp.summer.economy',
      'url': 'localidades/brazil/rio-de-janeiro-rio-de-janeiro',
      'minValue': '13'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-locarno.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-ch.png',
      'car-image': 'imagens/modules/promo/car-xc60.png',
      'location': 'location.switzerland',
      'title': 'lp.summer.vacation',
      'url': 'localidades/switzerland/locarno-district-ti-ticino',
      'minValue': '70'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-sorrento.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'car-image': 'imagens/modules/promo/car-panda.png',
      'location': 'location.italy',
      'title': 'lp.summer.roadtrip',
      'url': 'localidades/italy/sorrento-ca-campania',
      'minValue': '53'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-mallorca.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'car-image': 'imagens/modules/promo/car-mii.png',
      'location': 'location.spain',
      'title': 'lp.summer.family',
      'url': 'localidades/spain/palma-de-mallorca-ib-comunitat-autonoma-de-les-illes-balears',
      'minValue': '21'
    }
  ],
  'es-mx': [
    {
      'bg-image': 'imagens/modules/promo/bg-carmen.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'car-image': 'imagens/modules/promo/car-spark.png',
      'location': 'location.mexico',
      'title': 'lp.summer.beach',
      'url': 'localidades/mexico/playa-del-carmen-qr-quintana-roo',
      'minValue': '50'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-monterrey.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'car-image': 'imagens/modules/promo/car-sienna.png',
      'location': 'location.mexico',
      'title': 'lp.summer.family',
      'url': 'localidades/mexico/monterrey-nl-nuevo-leon',
      'minValue': '182'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-mexicocity.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'car-image': 'imagens/modules/promo/car-accent.png',
      'location': 'location.mexico',
      'title': 'lp.summer.business',
      'url': 'localidades/mexico/mexico-city-di-distrito-federal',
      'minValue': '62'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-loscabos.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'car-image': 'imagens/modules/promo/car-matiz.png',
      'location': 'location.mexico',
      'title': 'lp.summer.weekend',
      'url': 'localidades/mexico/los-cabos-bc-baja-california-sur',
      'minValue': '116'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-barcelona.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'car-image': 'imagens/modules/promo/car-panda.png',
      'location': 'location.spain',
      'title': 'lp.summer.vacation',
      'url': 'localidades/spain/barcelona-ct-cataluna',
      'minValue': '85'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-buenos-aires.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-ar.png',
      'car-image': 'imagens/modules/promo/car-up.png',
      'location': 'location.argentina',
      'title': 'lp.summer.economy',
      'url': 'localidades/argentina/buenos-aires-buenos-aires',
      'minValue': '182'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-rio-de-janeiro.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'car-image': 'imagens/modules/promo/car-kwid.png',
      'location': 'location.brazil',
      'title': 'lp.summer.roadtrip',
      'url': 'localidades/brazil/rio-de-janeiro-rio-de-janeiro',
      'minValue': '70'
    },
    {
      'bg-image': 'imagens/modules/promo/bg-orlando.jpg',
      'flag-image': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'car-image': 'imagens/modules/promo/car-quest.png',
      'location': 'location.usa',
      'title': 'lp.summer.orlando',
      'url': 'localidades/united-states/orlando-florida',
      'minValue': '166'
    }
  ]
}

export const TOP_DESTINATIONS = {
  'en': [
    {
      'url': 'locations/united-states/orlando-florida',
      'locale': 'New York',
      'img-city': 'imagens/modules/tarifas/rentcars-new-york.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-new-york-2.png',
      'img-city-alt': 'location.city.newyork',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '112'
    },
    {
      'url': 'locations/united-states/los-angeles-county-ca-california',
      'locale': 'Los Angeles',
      'img-city': 'imagens/modules/tarifas/rentcars-los-angeles.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-los-angeles-2.png',
      'img-city-alt': 'location.city.losangeles',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '88'
    },
    {
      'url': 'locations/united-states/orlando-florida',
      'locale': 'Orlando',
      'img-city': 'imagens/modules/tarifas/rentcars-orlando.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-orlando-2.png',
      'img-city-alt': 'location.city.orlando',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '68'
    },
    {
      'url': 'locations/united-states/las-vegas-nv-nevada',
      'locale': 'Las Vegas',
      'img-city': 'imagens/modules/tarifas/rentcars-las-vegas.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-las-vegas-2.png',
      'img-city-alt': 'location.city.lasvegas',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '92'
    },
    {
      'url': 'locations/united-states/chicago-il-illinois',
      'locale': 'Chicago',
      'img-city': 'imagens/modules/tarifas/rentcars-chicago.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-chicago-2.png',
      'img-city-alt': 'location.city.chicago',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '116'
    },
    {
      'url': 'locations/united-states/washington-dc-district-of-columbia',
      'locale': 'Washington',
      'img-city': 'imagens/modules/tarifas/rentcars-washington.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-washington-2.png',
      'img-city-alt': 'location.city.washington',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '136'
    },
    {
      'url': 'locations/united-states/san-francisco-ca-california',
      'locale': 'San Francisco',
      'img-city': 'imagens/modules/tarifas/rentcars-san-francisco.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-san-francisco-2.png',
      'img-city-alt': 'location.city.sanfrancisco',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '84'
    },
    {
      'url': 'locations/united-states/miami-florida',
      'locale': 'Miami',
      'img-city': 'imagens/modules/tarifas/rentcars-miami.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-miami-2.png',
      'img-city-alt': 'location.city.miami',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '80'
    },
    {
      'url': 'locations/united-states/atlanta-ga-georgia',
      'locale': 'Atlanta',
      'img-city': 'imagens/modules/tarifas/rentcars-atlanta.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-atlanta-2.png',
      'img-city-alt': 'location.city.atlanta',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '108'
    },
    {
      'url': 'locations/united-states/denver-co-colorado',
      'locale': 'Denver',
      'img-city': 'imagens/modules/tarifas/rentcars-denver.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-denver-2.png',
      'img-city-alt': 'location.city.denver',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '140'
    }
  ],
  'pt-br': [
    {
      'url': 'localidades/brasil/sao-paulo-sao-paulo',
      'locale': 'São Paulo',
      'img-city': 'imagens/modules/tarifas/rentcars-sao-paulo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-sao-paulo-2.png',
      'img-city-alt': 'location.city.saopaulo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '49'
    },
    {
      'url': 'localidades/brasil/porto-alegre-rio-grande-do-sul',
      'locale': 'Porto Alegre',
      'img-city': 'imagens/modules/tarifas/rentcars-porto-alegre.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-porto-alegre-2.png',
      'img-city-alt': 'location.city.portoalegre',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '57'
    },
    {
      'url': 'localidades/brasil/rio-de-janeiro-rio-de-janeiro',
      'locale': 'Rio de Janeiro',
      'img-city': 'imagens/modules/tarifas/rentcars-rio-de-janeiro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rio-de-janeiro-2.png',
      'img-city-alt': 'location.city.riodejaneiro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '57'
    },
    {
      'url': 'localidades/brasil/florianopolis-santa-catarina',
      'locale': 'Florianópolis',
      'img-city': 'imagens/modules/tarifas/rentcars-florianopolis.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-florianopolis-2.png',
      'img-city-alt': 'location.city.florianopolis',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '57'
    },
    {
      'url': 'localidades/brasil/recife-pernambuco',
      'locale': 'Recife',
      'img-city': 'imagens/modules/tarifas/rentcars-recife.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-recife-2.png',
      'img-city-alt': 'location.city.recife',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '57'
    },
    {
      'url': 'localidades/brasil/salvador-bahia',
      'locale': 'Salvador',
      'img-city': 'imagens/modules/tarifas/rentcars-salvador.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-salvador-2.png',
      'img-city-alt': 'location.city.salvador',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '57'
    },
    {
      'url': 'localidades/estados-unidos/orlando-florida',
      'locale': 'Orlando',
      'img-city': 'imagens/modules/tarifas/rentcars-orlando.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-orlando-2.png',
      'img-city-alt': 'location.city.orlando',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '112'
    },
    {
      'url': 'localidades/estados-unidos/miami-florida',
      'locale': 'Miami',
      'img-city': 'imagens/modules/tarifas/rentcars-miami.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-miami-2.png',
      'img-city-alt': 'location.city.miami',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '112'
    },
    {
      'url': 'localidades/portugal/lisboa-distrito-de-lisboa',
      'locale': 'Lisboa',
      'img-city': 'imagens/modules/tarifas/rentcars-lisboa.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-lisboa-2.png',
      'img-city-alt': 'location.city.lisboa',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '44'
    },
    {
      'url': 'localidades/estados-unidos/los-angeles-ca-california',
      'locale': 'Los Angeles',
      'img-city': 'imagens/modules/tarifas/rentcars-los-angeles.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-los-angeles-2.png',
      'img-city-alt': 'location.city.losangeles',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '88'
    }
  ],
  'pt-pt': [
    {
      'url': 'locations/portugal/lisbon-distrito-de-lisboa',
      'locale': 'Lisboa',
      'img-city': 'imagens/modules/tarifas/rentcars-lisboa.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-lisboa-2.png',
      'img-city-alt': 'location.city.lisboa',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '44'
    },
    {
      'url': 'locations/portugal/porto',
      'locale': 'Porto',
      'img-city': 'imagens/modules/tarifas/rentcars-porto.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-porto-2.png',
      'img-city-alt': 'location.city.porto',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '44'
    },
    {
      'url': 'locations/portugal/funchal-madeira',
      'locale': 'Funchal',
      'img-city': 'imagens/modules/tarifas/rentcars-funchal-madeira.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-funchal-madeira-2.png',
      'img-city-alt': 'location.city.funchal',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '68'
    },
    {
      'url': 'locations/portugal/braga',
      'locale': 'Braga',
      'img-city': 'imagens/modules/tarifas/rentcars-braga.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-braga-2.png',
      'img-city-alt': 'location.city.braga',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '68'
    },
    {
      'url': 'locations/portugal/faro',
      'locale': 'Faro',
      'img-city': 'imagens/modules/tarifas/rentcars-faro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-faro-2.png',
      'img-city-alt': 'location.city.faro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '44'
    },
    {
      'url': 'locations/portugal/ponta-delgada-azores',
      'locale': 'Ponta Delgada',
      'img-city': 'imagens/modules/tarifas/rentcars-ponta-delgada.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-ponta-delgada-2.png',
      'img-city-alt': 'location.city.ponta-delgada',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '64'
    },
    {
      'url': 'locations/france/paris-ile-de-france',
      'locale': 'Paris',
      'img-city': 'imagens/modules/tarifas/rentcars-paris.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-paris-2.png',
      'img-city-alt': 'location.city.paris',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-fr.png',
      'img-flag-alt': 'location.france',
      'baseValue': '64'
    },
    {
      'url': 'locations/italy/rome-la-lazio',
      'locale': 'Roma',
      'img-city': 'imagens/modules/tarifas/rentcars-rome.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rome-2.png',
      'img-city-alt': 'location.city.rome',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '48'
    },
    {
      'url': 'locations/spain/barcelona-ct-cataluna',
      'locale': 'Barcelona',
      'img-city': 'imagens/modules/tarifas/rentcars-barcelona.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-barcelona-2.png',
      'img-city-alt': 'location.city.barcelona',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/spain/palma-de-mallorca-ib-comunitat-autonoma-de-les-illes-balears',
      'locale': 'Palma',
      'img-city': 'imagens/modules/tarifas/rentcars-palma-de-mallorca.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-palma-de-mallorca-2.png',
      'img-city-alt': 'location.city.palma-de-mallorca',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    }
  ],
  'es-mx': [
    {
      'url': 'localidades/mexico/cancun-qr-quintana-roo',
      'locale': 'Cancún',
      'img-city': 'imagens/modules/tarifas/rentcars-cancun.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-cancun-2.png',
      'img-city-alt': 'location.city.cancun',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '12'
    },
    {
      'url': 'localidades/mexico/guadalajara-ja-jalisco',
      'locale': 'Guadalajara',
      'img-city': 'imagens/modules/tarifas/rentcars-guadalajara.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-guadalajara-2.png',
      'img-city-alt': 'location.city.guadalajara',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '32'
    },
    {
      'url': 'localidades/mexico/mexico-city-di-distrito-federal',
      'locale': 'Ciudad de México',
      'img-city': 'imagens/modules/tarifas/rentcars-ciudad-de-mexico.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-ciudad-de-mexico-2.png',
      'img-city-alt': 'location.city.ciudaddemexico',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '12'
    },
    {
      'url': 'localidades/mexico/merida-yu-yucatan',
      'locale': 'Mérida',
      'img-city': 'imagens/modules/tarifas/rentcars-merida.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-merida-2.png',
      'img-city-alt': 'location.city.merida',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '12'
    },
    {
      'url': 'localidades/mexico/san-jose-del-cabo-bc-baja-california-sur',
      'locale': 'San José del Cabo',
      'img-city': 'imagens/modules/tarifas/rentcars-san-jose-del-cabo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-san-jose-del-cabo-2.png',
      'img-city-alt': 'location.city.sanjosedelcabo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '12'
    },
    {
      'url': 'localidades/mexico/monterrey-nl-nuevo-leon',
      'locale': 'Monterrey',
      'img-city': 'imagens/modules/tarifas/rentcars-monterrey.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-monterrey-2.png',
      'img-city-alt': 'location.city.monterrey',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '20'
    },
    {
      'url': 'localidades/mexico/tijuana-bc-baja-california',
      'locale': 'Tijuana',
      'img-city': 'imagens/modules/tarifas/rentcars-tijuana.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-tijuana-2.png',
      'img-city-alt': 'location.city.tijuana',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '40'
    },
    {
      'url': 'localidades/mexico/hermosillo-so-sonora',
      'locale': 'Hermosillo',
      'img-city': 'imagens/modules/tarifas/rentcars-hermosillo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-hermosillo-2.png',
      'img-city-alt': 'location.city.hermosillo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '40'
    },
    {
      'url': 'localidades/mexico/la-paz-bc-baja-california-sur',
      'locale': 'La Paz',
      'img-city': 'imagens/modules/tarifas/rentcars-la-paz.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-la-paz-2.png',
      'img-city-alt': 'location.city.la-paz',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '40'
    },
    {
      'url': 'localidades/mexico/puerto-vallarta-ja-jalisco',
      'locale': 'Puerto Vallarta',
      'img-city': 'imagens/modules/tarifas/rentcars-puerto-vallarta.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-puerto-vallarta-2.png',
      'img-city-alt': 'location.city.puerto-vallarta',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '40'
    }
  ],
  'es': [
    {
      'url': 'localidades/united-states/miami-florida',
      'locale': 'Miami',
      'img-city': 'imagens/modules/tarifas/rentcars-miami.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-miami-2.png',
      'img-city-alt': 'location.city.miami',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '136'
    },
    {
      'url': 'localidades/mexico/cancun-qr-quintana-roo',
      'locale': 'Cancun',
      'img-city': 'imagens/modules/tarifas/rentcars-cancun.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-cancun-2.png',
      'img-city-alt': 'location.city.cancun',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '41'
    },
    {
      'url': 'localidades/united-states/orlando-florida',
      'locale': 'Orlando',
      'img-city': 'imagens/modules/tarifas/rentcars-orlando.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-orlando-2.png',
      'img-city-alt': 'location.city.orlando',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '136'
    },
    {
      'url': 'localidades/brazil/rio-de-janeiro-rio-de-janeiro',
      'locale': 'Rio de Janeiro',
      'img-city': 'imagens/modules/tarifas/rentcars-rio-de-janeiro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rio-de-janeiro-2.png',
      'img-city-alt': 'location.city.riodejaneiro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '62'
    },
    {
      'url': 'localidades/united-states/fort-lauderdale-fl-florida',
      'locale': 'Fort Lauderdale',
      'img-city': 'imagens/modules/tarifas/rentcars-fortlauderdale.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-fortlauderdale-2.png',
      'img-city-alt': 'location.city.fortlauderdale',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '94'
    },
    {
      'url': 'localidades/mexico/mexico-city-di-distrito-federal',
      'locale': 'Ciudad de Mexico',
      'img-city': 'imagens/modules/tarifas/rentcars-ciudad-de-mexico.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-ciudad-de-mexico-2.png',
      'img-city-alt': 'location.city.ciudaddemexico',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '45'
    },
    {
      'url': 'localidades/united-states/los-angeles-county-ca-california',
      'locale': 'Los Angeles',
      'img-city': 'imagens/modules/tarifas/rentcars-los-angeles.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-los-angeles-2.png',
      'img-city-alt': 'location.city.losangeles',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '115'
    },
    {
      'url': 'localidades/brazil/sao-paulo-sao-paulo',
      'locale': 'São Paulo',
      'img-city': 'imagens/modules/tarifas/rentcars-sao-paulo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-sao-paulo-2.png',
      'img-city-alt': 'location.city.saopaulo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '56'
    },
    {
      'url': 'localidades/chile/puerto-montt-ll-los-lagos',
      'locale': 'Puerto Montt',
      'img-city': 'imagens/modules/tarifas/rentcars-puertomontt.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-puertomontt-2.png',
      'img-city-alt': 'location.city.puertomontt',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '107'
    },
    {
      'url': 'localidades/chile/santiago',
      'locale': 'Santiago',
      'img-city': 'imagens/modules/tarifas/rentcars-santiago.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-santiago-2.png',
      'img-city-alt': 'location.city.santiago',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '70'
    }
  ],
  'es-cl': [
    {
      'url': 'locations/chile/puerto-montt-ll-los-lagos',
      'locale': 'Puerto Montt',
      'img-city': 'imagens/modules/tarifas/rentcars-puertomontt.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-puertomontt-2.png',
      'img-city-alt': 'location.city.puertomontt',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '88'
    },
    {
      'url': 'locations/chile/punta-arenas-ma-region-de-magallanes-y-de-la-antartica-chilena',
      'locale': 'Punta Arenas',
      'img-city': 'imagens/modules/tarifas/rentcars-punta-arenas.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-punta-arenas-2.png',
      'img-city-alt': 'location.city.punta-arenas',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '108'
    },
    {
      'url': 'locations/chile/santiago',
      'locale': 'Santiago',
      'img-city': 'imagens/modules/tarifas/rentcars-santiago.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-santiago-2.png',
      'img-city-alt': 'location.city.santiago',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '52'
    },
    {
      'url': 'locations/chile/la-serena-co-coquimbo',
      'locale': 'La Serena',
      'img-city': 'imagens/modules/tarifas/rentcars-la-serena.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-la-serena-2.png',
      'img-city-alt': 'location.city.la-serena',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '52'
    },
    {
      'url': 'locations/chile/antofagasta-an-antofagasta',
      'locale': 'Antofagasta',
      'img-city': 'imagens/modules/tarifas/rentcars-antofagasta.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-antofagasta-2.png',
      'img-city-alt': 'location.city.antofagasta',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/provincia-de-copiapo-at-atacama',
      'locale': 'Copiapó',
      'img-city': 'imagens/modules/tarifas/rentcars-copiapo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-copiapo-2.png',
      'img-city-alt': 'location.city.copiapo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/concepcion-bi-region-del-biobio',
      'locale': 'Concepción',
      'img-city': 'imagens/modules/tarifas/rentcars-concepcion.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-concepcion-2.png',
      'img-city-alt': 'location.city.concepcion',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/valdivia-lr-region-de-los-rios',
      'locale': 'Valdivia',
      'img-city': 'imagens/modules/tarifas/rentcars-valdivia.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-valdivia-2.png',
      'img-city-alt': 'location.city.valdivia',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/argentina/buenos-aires-buenos-aires',
      'locale': 'Buenos Aires',
      'img-city': 'imagens/modules/tarifas/rentcars-buenos-aires.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-buenos-aires-2.png',
      'img-city-alt': 'location.buenos-aires',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-ar.png',
      'img-flag-alt': 'location.argentina',
      'baseValue': '58'
    },
    {
      'url': 'locations/brazil/rio-de-janeiro-rio-de-janeiro',
      'locale': 'Rio de Janeiro',
      'img-city': 'imagens/modules/tarifas/rentcars-rio-de-janeiro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rio-de-janeiro-2.png',
      'img-city-alt': 'location.city.riodejaneiro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '40'
    }
  ],
  'es-ar': [
    {
      'url': 'locations/chile/puerto-montt-ll-los-lagos',
      'locale': 'Puerto Montt',
      'img-city': 'imagens/modules/tarifas/rentcars-puertomontt.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-puertomontt-2.png',
      'img-city-alt': 'location.city.puertomontt',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '88'
    },
    {
      'url': 'locations/chile/punta-arenas-ma-region-de-magallanes-y-de-la-antartica-chilena',
      'locale': 'Punta Arenas',
      'img-city': 'imagens/modules/tarifas/rentcars-punta-arenas.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-punta-arenas-2.png',
      'img-city-alt': 'location.city.punta-arenas',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '108'
    },
    {
      'url': 'locations/chile/santiago',
      'locale': 'Santiago',
      'img-city': 'imagens/modules/tarifas/rentcars-santiago.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-santiago-2.png',
      'img-city-alt': 'location.city.santiago',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '52'
    },
    {
      'url': 'locations/chile/la-serena-co-coquimbo',
      'locale': 'La Serena',
      'img-city': 'imagens/modules/tarifas/rentcars-la-serena.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-la-serena-2.png',
      'img-city-alt': 'location.city.la-serena',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '52'
    },
    {
      'url': 'locations/chile/antofagasta-an-antofagasta',
      'locale': 'Antofagasta',
      'img-city': 'imagens/modules/tarifas/rentcars-antofagasta.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-antofagasta-2.png',
      'img-city-alt': 'location.city.antofagasta',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/provincia-de-copiapo-at-atacama',
      'locale': 'Copiapó',
      'img-city': 'imagens/modules/tarifas/rentcars-copiapo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-copiapo-2.png',
      'img-city-alt': 'location.city.copiapo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/concepcion-bi-region-del-biobio',
      'locale': 'Concepción',
      'img-city': 'imagens/modules/tarifas/rentcars-concepcion.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-concepcion-2.png',
      'img-city-alt': 'location.city.concepcion',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/valdivia-lr-region-de-los-rios',
      'locale': 'Valdivia',
      'img-city': 'imagens/modules/tarifas/rentcars-valdivia.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-valdivia-2.png',
      'img-city-alt': 'location.city.valdivia',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/argentina/buenos-aires-buenos-aires',
      'locale': 'Buenos Aires',
      'img-city': 'imagens/modules/tarifas/rentcars-buenos-aires.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-buenos-aires-2.png',
      'img-city-alt': 'location.buenos-aires',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-ar.png',
      'img-flag-alt': 'location.argentina',
      'baseValue': '58'
    },
    {
      'url': 'locations/brazil/rio-de-janeiro-rio-de-janeiro',
      'locale': 'Rio de Janeiro',
      'img-city': 'imagens/modules/tarifas/rentcars-rio-de-janeiro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rio-de-janeiro-2.png',
      'img-city-alt': 'location.city.riodejaneiro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '40'
    }
  ],
  'es-co': [
    {
      'url': 'locations/chile/puerto-montt-ll-los-lagos',
      'locale': 'Puerto Montt',
      'img-city': 'imagens/modules/tarifas/rentcars-puertomontt.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-puertomontt-2.png',
      'img-city-alt': 'location.city.puertomontt',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '88'
    },
    {
      'url': 'locations/chile/punta-arenas-ma-region-de-magallanes-y-de-la-antartica-chilena',
      'locale': 'Punta Arenas',
      'img-city': 'imagens/modules/tarifas/rentcars-punta-arenas.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-punta-arenas-2.png',
      'img-city-alt': 'location.city.punta-arenas',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '108'
    },
    {
      'url': 'locations/chile/santiago',
      'locale': 'Santiago',
      'img-city': 'imagens/modules/tarifas/rentcars-santiago.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-santiago-2.png',
      'img-city-alt': 'location.city.santiago',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '52'
    },
    {
      'url': 'locations/chile/la-serena-co-coquimbo',
      'locale': 'La Serena',
      'img-city': 'imagens/modules/tarifas/rentcars-la-serena.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-la-serena-2.png',
      'img-city-alt': 'location.city.la-serena',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '52'
    },
    {
      'url': 'locations/chile/antofagasta-an-antofagasta',
      'locale': 'Antofagasta',
      'img-city': 'imagens/modules/tarifas/rentcars-antofagasta.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-antofagasta-2.png',
      'img-city-alt': 'location.city.antofagasta',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/provincia-de-copiapo-at-atacama',
      'locale': 'Copiapó',
      'img-city': 'imagens/modules/tarifas/rentcars-copiapo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-copiapo-2.png',
      'img-city-alt': 'location.city.copiapo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/concepcion-bi-region-del-biobio',
      'locale': 'Concepción',
      'img-city': 'imagens/modules/tarifas/rentcars-concepcion.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-concepcion-2.png',
      'img-city-alt': 'location.city.concepcion',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/chile/valdivia-lr-region-de-los-rios',
      'locale': 'Valdivia',
      'img-city': 'imagens/modules/tarifas/rentcars-valdivia.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-valdivia-2.png',
      'img-city-alt': 'location.city.valdivia',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-cl.png',
      'img-flag-alt': 'location.chile',
      'baseValue': '104'
    },
    {
      'url': 'locations/argentina/buenos-aires-buenos-aires',
      'locale': 'Buenos Aires',
      'img-city': 'imagens/modules/tarifas/rentcars-buenos-aires.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-buenos-aires-2.png',
      'img-city-alt': 'location.buenos-aires',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-ar.png',
      'img-flag-alt': 'location.argentina',
      'baseValue': '58'
    },
    {
      'url': 'locations/brazil/rio-de-janeiro-rio-de-janeiro',
      'locale': 'Rio de Janeiro',
      'img-city': 'imagens/modules/tarifas/rentcars-rio-de-janeiro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rio-de-janeiro-2.png',
      'img-city-alt': 'location.city.riodejaneiro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '40'
    }
  ],
  'de-de': [
    {
      'url': 'locations/germany/berlin-be-land-berlin',
      'locale': 'Berlin',
      'img-city': 'imagens/modules/tarifas/rentcars-berlin.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-berlin-2.png',
      'img-city-alt': 'location.city.berlin',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-de.png',
      'img-flag-alt': 'location.germany',
      'baseValue': '84'
    },
    {
      'url': 'locations/germany/munich-by-bavaria',
      'locale': 'Munich',
      'img-city': 'imagens/modules/tarifas/rentcars-munich.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-munich-2.png',
      'img-city-alt': 'location.city.munich',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-de.png',
      'img-flag-alt': 'location.germany',
      'baseValue': '84'
    },
    {
      'url': 'locations/germany/frankfurt-oder-bb-brandenburg',
      'locale': 'Frankfurt',
      'img-city': 'imagens/modules/tarifas/rentcars-frankfurt.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-frankfurt-2.png',
      'img-city-alt': 'location.city.frankfurt',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-de.png',
      'img-flag-alt': 'location.germany',
      'baseValue': '68'
    },
    {
      'url': 'locations/germany/hamburg-hh-hamburg',
      'locale': 'Hamburg',
      'img-city': 'imagens/modules/tarifas/rentcars-hamburg.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-hamburg-2.png',
      'img-city-alt': 'location.city.hamburg',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-de.png',
      'img-flag-alt': 'location.germany',
      'baseValue': '76'
    },
    {
      'url': 'locations/portugal/lisbon-distrito-de-lisboa',
      'locale': 'Lisbon',
      'img-city': 'imagens/modules/tarifas/rentcars-lisboa.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-lisboa-2.png',
      'img-city-alt': 'location.city.lisboa',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '48'
    },
    {
      'url': 'locations/spain/barcelona-ct-cataluna',
      'locale': 'Barcelona',
      'img-city': 'imagens/modules/tarifas/rentcars-barcelona.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-barcelona-2.png',
      'img-city-alt': 'location.city.barcelona',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/spain/palma-de-mallorca-ib-comunitat-autonoma-de-les-illes-balears',
      'locale': 'Palma',
      'img-city': 'imagens/modules/tarifas/rentcars-palma-de-mallorca.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-palma-de-mallorca-2.png',
      'img-city-alt': 'location.city.palma-de-mallorca',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/italy/rome-la-lazio',
      'locale': 'Rom',
      'img-city': 'imagens/modules/tarifas/rentcars-rome.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rome-2.png',
      'img-city-alt': 'location.city.rome',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '32'
    },
    {
      'url': 'locations/france/paris-ile-de-france',
      'locale': 'Paris',
      'img-city': 'imagens/modules/tarifas/rentcars-paris.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-paris-2.png',
      'img-city-alt': 'location.city.paris',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-fr.png',
      'img-flag-alt': 'location.france',
      'baseValue': '64'
    },
    {
      'url': 'locations/united-kingdom/london-en-england',
      'locale': 'London',
      'img-city': 'imagens/modules/tarifas/rentcars-london.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-london-2.png',
      'img-city-alt': 'location.city.london',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-gb.png',
      'img-flag-alt': 'location.united-kingdom',
      'baseValue': '80'
    }
  ],
  'en-gb': [
    {
      'url': 'locations/united-kingdom/london-en-england',
      'locale': 'London',
      'img-city': 'imagens/modules/tarifas/rentcars-london.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-london-2.png',
      'img-city-alt': 'location.city.london',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-gb.png',
      'img-flag-alt': 'location.united-kingdom',
      'baseValue': '80'
    },
    {
      'url': 'locations/united-kingdom/bristol-en-england',
      'locale': 'Bristol',
      'img-city': 'imagens/modules/tarifas/rentcars-bristol.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-bristol-2.png',
      'img-city-alt': 'location.city.bristol',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-gb.png',
      'img-flag-alt': 'location.united-kingdom',
      'baseValue': '80'
    },
    {
      'url': 'locations/portugal/lisbon-distrito-de-lisboa',
      'locale': 'Lisbon',
      'img-city': 'imagens/modules/tarifas/rentcars-lisboa.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-lisboa-2.png',
      'img-city-alt': 'location.city.lisboa',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '40'
    },
    {
      'url': 'locations/france/paris-ile-de-france',
      'locale': 'Paris',
      'img-city': 'imagens/modules/tarifas/rentcars-paris.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-paris-2.png',
      'img-city-alt': 'location.city.paris',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-fr.png',
      'img-flag-alt': 'location.france',
      'baseValue': '64'
    },
    {
      'url': 'locations/italy/rome-la-lazio',
      'locale': 'Rome',
      'img-city': 'imagens/modules/tarifas/rentcars-rome.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rome-2.png',
      'img-city-alt': 'location.city.rome',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '32'
    },
    {
      'url': 'locations/spain/barcelona-ct-cataluna',
      'locale': 'Barcelona',
      'img-city': 'imagens/modules/tarifas/rentcars-barcelona.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-barcelona-2.png',
      'img-city-alt': 'location.city.barcelona',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/mexico/cancun-qr-quintana-roo',
      'locale': 'Cancún',
      'img-city': 'imagens/modules/tarifas/rentcars-cancun.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-cancun-2.png',
      'img-city-alt': 'location.city.cancun',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '40'
    },
    {
      'url': 'locations/brazil/sao-paulo-sao-paulo',
      'locale': 'São Paulo',
      'img-city': 'imagens/modules/tarifas/rentcars-sao-paulo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-sao-paulo-2.png',
      'img-city-alt': 'location.city.saopaulo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '56'
    },
    {
      'url': 'locations/brazil/rio-de-janeiro-rio-de-janeiro',
      'locale': 'Rio de Janeiro',
      'img-city': 'imagens/modules/tarifas/rentcars-rio-de-janeiro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rio-de-janeiro-2.png',
      'img-city-alt': 'location.city.riodejaneiro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '52'
    },
    {
      'url': 'locations/united-states/miami-florida',
      'locale': 'Miami',
      'img-city': 'imagens/modules/tarifas/rentcars-miami.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-miami-2.png',
      'img-city-alt': 'location.city.miami',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '92'
    }
  ],
  'nl-nl': [
    {
      'url': 'locations/united-kingdom/london-en-england',
      'locale': 'London',
      'img-city': 'imagens/modules/tarifas/rentcars-london.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-london-2.png',
      'img-city-alt': 'location.city.london',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-gb.png',
      'img-flag-alt': 'location.united-kingdom',
      'baseValue': '80'
    },
    {
      'url': 'locations/united-kingdom/bristol-en-england',
      'locale': 'Bristol',
      'img-city': 'imagens/modules/tarifas/rentcars-bristol.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-bristol-2.png',
      'img-city-alt': 'location.city.bristol',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-gb.png',
      'img-flag-alt': 'location.united-kingdom',
      'baseValue': '80'
    },
    {
      'url': 'locations/portugal/lisbon-distrito-de-lisboa',
      'locale': 'Lisbon',
      'img-city': 'imagens/modules/tarifas/rentcars-lisboa.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-lisboa-2.png',
      'img-city-alt': 'location.city.lisboa',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '40'
    },
    {
      'url': 'locations/france/paris-ile-de-france',
      'locale': 'Paris',
      'img-city': 'imagens/modules/tarifas/rentcars-paris.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-paris-2.png',
      'img-city-alt': 'location.city.paris',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-fr.png',
      'img-flag-alt': 'location.france',
      'baseValue': '64'
    },
    {
      'url': 'locations/italy/rome-la-lazio',
      'locale': 'Rome',
      'img-city': 'imagens/modules/tarifas/rentcars-rome.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rome-2.png',
      'img-city-alt': 'location.city.rome',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '32'
    },
    {
      'url': 'locations/spain/barcelona-ct-cataluna',
      'locale': 'Barcelona',
      'img-city': 'imagens/modules/tarifas/rentcars-barcelona.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-barcelona-2.png',
      'img-city-alt': 'location.city.barcelona',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/mexico/cancun-qr-quintana-roo',
      'locale': 'Cancún',
      'img-city': 'imagens/modules/tarifas/rentcars-cancun.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-cancun-2.png',
      'img-city-alt': 'location.city.cancun',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '40'
    },
    {
      'url': 'locations/brazil/sao-paulo-sao-paulo',
      'locale': 'São Paulo',
      'img-city': 'imagens/modules/tarifas/rentcars-sao-paulo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-sao-paulo-2.png',
      'img-city-alt': 'location.city.saopaulo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '56'
    },
    {
      'url': 'locations/brazil/rio-de-janeiro-rio-de-janeiro',
      'locale': 'Rio de Janeiro',
      'img-city': 'imagens/modules/tarifas/rentcars-rio-de-janeiro.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rio-de-janeiro-2.png',
      'img-city-alt': 'location.city.riodejaneiro',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-br.png',
      'img-flag-alt': 'location.brazil',
      'baseValue': '52'
    },
    {
      'url': 'locations/united-states/miami-florida',
      'locale': 'Miami',
      'img-city': 'imagens/modules/tarifas/rentcars-miami.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-miami-2.png',
      'img-city-alt': 'location.city.miami',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '92'
    }
  ],
  'fr-fr': [
    {
      'url': 'locations/france/paris-ile-de-france',
      'locale': 'Paris',
      'img-city': 'imagens/modules/tarifas/rentcars-paris.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-paris-2.png',
      'img-city-alt': 'location.city.paris',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-fr.png',
      'img-flag-alt': 'location.france',
      'baseValue': '64'
    },
    {
      'url': 'locations/portugal/lisbon-distrito-de-lisboa',
      'locale': 'Lisbonne',
      'img-city': 'imagens/modules/tarifas/rentcars-lisboa.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-lisboa-2.png',
      'img-city-alt': 'location.city.lisbon',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '64'
    },
    {
      'url': 'locations/portugal/porto',
      'locale': 'Porto',
      'img-city': 'imagens/modules/tarifas/rentcars-porto.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-porto-2.png',
      'img-city-alt': 'location.city.porto',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '48'
    },
    {
      'url': 'locations/italy/milao-lombardia',
      'locale': 'Milan',
      'img-city': 'imagens/modules/tarifas/rentcars-milao.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-milao-2.png',
      'img-city-alt': 'location.city.milan',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '48'
    },
    {
      'url': 'locations/italy/rome-la-lazio',
      'locale': 'Rome',
      'img-city': 'imagens/modules/tarifas/rentcars-rome.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rome-2.png',
      'img-city-alt': 'location.city.rome',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '48'
    },
    {
      'url': 'locations/spain/barcelona-ct-cataluna',
      'locale': 'Barcelona',
      'img-city': 'imagens/modules/tarifas/rentcars-barcelona.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-barcelona-2.png',
      'img-city-alt': 'location.city.barcelona',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/spain/palma-de-mallorca-ib-comunitat-autonoma-de-les-illes-balears',
      'locale': 'Palma',
      'img-city': 'imagens/modules/tarifas/rentcars-palma-de-mallorca.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-palma-de-mallorca-2.png',
      'img-city-alt': 'location.city.palma-de-mallorca',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/united-kingdom/london-en-england',
      'locale': 'Londres',
      'img-city': 'imagens/modules/tarifas/rentcars-london.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-london-2.png',
      'img-city-alt': 'location.city.london',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-gb.png',
      'img-flag-alt': 'location.united-kingdom',
      'baseValue': '80'
    },
    {
      'url': 'locations/united-states/los-angeles-county-ca-california',
      'locale': 'Los Angeles',
      'img-city': 'imagens/modules/tarifas/rentcars-los-angeles.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-los-angeles-2.png',
      'img-city-alt': 'location.city.losangeles',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '112'
    },
    {
      'url': 'locations/united-states/miami-florida',
      'locale': 'Miami',
      'img-city': 'imagens/modules/tarifas/rentcars-miami.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-miami-2.png',
      'img-city-alt': 'location.city.miami',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '120'
    }
  ],
  'fr-ca': [
    {
      'url': 'locations/france/paris-ile-de-france',
      'locale': 'Paris',
      'img-city': 'imagens/modules/tarifas/rentcars-paris.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-paris-2.png',
      'img-city-alt': 'location.city.paris',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-fr.png',
      'img-flag-alt': 'location.france',
      'baseValue': '64'
    },
    {
      'url': 'locations/united-states/miami-florida',
      'locale': 'Miami',
      'img-city': 'imagens/modules/tarifas/rentcars-miami.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-miami-2.png',
      'img-city-alt': 'location.city.miami',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '120'
    },
    {
      'url': 'locations/united-states/fort-lauderdale-fl-florida',
      'locale': 'Fort Lauderdale',
      'img-city': 'imagens/modules/tarifas/rentcars-fortlauderdale.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-fortlauderdale-2.png',
      'img-city-alt': 'location.city.fortlauderdale',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '94'
    },
    {
      'url': 'locations/jamaica/kingston',
      'locale': 'Kingston',
      'img-city': 'imagens/modules/tarifas/rentcars-jamaica.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-jamaica-2.png',
      'img-city-alt': 'location.city.kingston',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-jm.png',
      'img-flag-alt': 'location.jamaica',
      'baseValue': '48'
    },
    {
      'url': 'locations/united-states/new-york-city-ny-new-york',
      'locale': 'New York',
      'img-city': 'imagens/modules/tarifas/rentcars-new-york.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-new-york-2.png',
      'img-city-alt': 'location.city.newyork',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '112'
    },
    {
      'url': 'locations/canada/vancouver-british-columbia-canada',
      'locale': 'Vancouver',
      'img-city': 'imagens/modules/tarifas/rentcars-vancouver.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-vancouver-2.png',
      'img-city-alt': 'location.city.vancouver',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-ca.png',
      'img-flag-alt': 'location.canada',
      'baseValue': '48'
    },
    {
      'url': 'locations/united-states/honolulu-hi-hawaii',
      'locale': 'Honolulu',
      'img-city': 'imagens/modules/tarifas/rentcars-los-angeles.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-los-angeles-2.png',
      'img-city-alt': 'location.city.honolulu',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '112'
    },
    {
      'url': 'locations/mexico/cancun-qr-quintana-roo',
      'locale': 'Cancún',
      'img-city': 'imagens/modules/tarifas/rentcars-cancun.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-cancun-2.png',
      'img-city-alt': 'location.city.cancun',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-mx.png',
      'img-flag-alt': 'location.mexico',
      'baseValue': '12'
    },
    {
      'url': 'locations/united-states/los-angeles-county-ca-california',
      'locale': 'Los Angeles',
      'img-city': 'imagens/modules/tarifas/rentcars-los-angeles.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-los-angeles-2.png',
      'img-city-alt': 'location.city.losangeles',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '88'
    },
    {
      'url': 'locations/united-states/orlando-florida',
      'locale': 'New York',
      'img-city': 'imagens/modules/tarifas/rentcars-new-york.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-new-york-2.png',
      'img-city-alt': 'location.city.newyork',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-us.png',
      'img-flag-alt': 'location.usa',
      'baseValue': '112'
    }
  ],
  'it-it': [
    {
      'url': 'locations/italy/rome-la-lazio',
      'locale': 'Roma',
      'img-city': 'imagens/modules/tarifas/rentcars-rome.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-rome-2.png',
      'img-city-alt': 'location.city.rome',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '48'
    },
    {
      'url': 'locations/italy/milao-lombardia',
      'locale': 'Milano',
      'img-city': 'imagens/modules/tarifas/rentcars-milao.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-milao-2.png',
      'img-city-alt': 'location.city.milan',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '44'
    },
    {
      'url': 'locations/italy/naples-ca-campania',
      'locale': 'Napoli',
      'img-city': 'imagens/modules/tarifas/rentcars-napoli.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-napoli-2.png',
      'img-city-alt': 'location.city.napoli',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '44'
    },
    {
      'url': 'locations/italy/bari-ap-puglia',
      'locale': 'Bari',
      'img-city': 'imagens/modules/tarifas/rentcars-bari.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-bari-2.png',
      'img-city-alt': 'location.city.bari',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '44'
    },
    {
      'url': 'locations/italy/palermo-si-sicilia',
      'locale': 'Palermo',
      'img-city': 'imagens/modules/tarifas/rentcars-palermo.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-palermo-2.png',
      'img-city-alt': 'location.city.palermo',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '44'
    },
    {
      'url': 'locations/italy/cagliari-sa-sardegna',
      'locale': 'Cagliari',
      'img-city': 'imagens/modules/tarifas/rentcars-cagliari.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-cagliari-2.png',
      'img-city-alt': 'location.city.cagliari',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-it.png',
      'img-flag-alt': 'location.italy',
      'baseValue': '44'
    },
    {
      'url': 'locations/portugal/lisbon-distrito-de-lisboa',
      'locale': 'Lisbona',
      'img-city': 'imagens/modules/tarifas/rentcars-lisboa.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-lisboa-2.png',
      'img-city-alt': 'location.city.lisboa',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-pt.png',
      'img-flag-alt': 'location.portugal',
      'baseValue': '48'
    },
    {
      'url': 'locations/france/paris-ile-de-france',
      'locale': 'Parigi',
      'img-city': 'imagens/modules/tarifas/rentcars-paris.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-paris-2.png',
      'img-city-alt': 'location.city.paris',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-fr.png',
      'img-flag-alt': 'location.france',
      'baseValue': '64'
    },
    {
      'url': 'locations/spain/palma-de-mallorca-ib-comunitat-autonoma-de-les-illes-balears',
      'locale': 'Palma',
      'img-city': 'imagens/modules/tarifas/rentcars-palma-de-mallorca.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-palma-de-mallorca-2.png',
      'img-city-alt': 'location.city.palma-de-mallorca',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    },
    {
      'url': 'locations/spain/valencia-vc-valencia',
      'locale': 'Valencia',
      'img-city': 'imagens/modules/tarifas/rentcars-valencia.png',
      'img-city-2': 'imagens/modules/tarifas/rentcars-valencia-2.png',
      'img-city-alt': 'location.city.valencia',
      'img-flag': 'imagens/modules/tarifas/flags/rentcars-es.png',
      'img-flag-alt': 'location.spain',
      'baseValue': '32'
    }
  ]
}
