export default {

  data() {
    return {
      completeTripActive: false
    }
  },

  async mounted() {
    const featureFlag = 'complete_trip_pwa'
    await this.$featureFlagApi
      .get(`get/${featureFlag}`)
      .then((response) => {
        this.completeTripActive = response.data.ativo
      })
      .catch(() => { })
  }
}
