export default class GA4DataLayerService {
  static getInstance () {
    if (!GA4DataLayerService.instance) {
      GA4DataLayerService.instance = new GA4DataLayerService()
    }

    return GA4DataLayerService.instance
  }

  add (data) {
    const dataLayer = window.dataLayer = window.dataLayer || []

    if (data) {
      dataLayer.push(data)
    }
  }
}
