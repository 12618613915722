export const PROMOTION_DATES = {
  freeCancellation: {
    starts: '2019-07-17 20:00',
    ends: '2019-08-01 19:59'
  },
  feriasPortugalAgosto: {
    starts: '2019-08-08 20:00',
    ends: '2019-08-25 19:59'
  },
  outboundCampaign: {
    starts: '2019-10-13 19:00',
    ends: '2019-10-20 18:59'
  },
  couponSlider: {
    starts: '2019-10-17 00:00',
    ends: '2019-10-27 23:59'
  },
  couponSliderApp: {
    starts: '2019-10-28 00:00',
    ends: '2019-10-31 23:59'
  },
  couponAppFrIt: {
    starts: '2019-11-06 20:00',
    ends: '2019-11-14 20:59'
  },
  couponAppPt: {
    starts: '2019-11-06 21:00',
    ends: '2019-11-14 21:59'
  },
  titleCyberMonday: {
    starts: '2019-12-02 00:00',
    ends: '2019-12-08 23:59'
  },
  titleBlackFriday: {
    starts: '2019-11-11 00:00',
    ends: '2019-12-01 23:59'
  },
  floaterAppBanner: {
    starts: '2020-02-06 00:00',
    ends: '2020-02-29 23:59'
  },
  birthdayBanner: {
    starts: '2020-09-20 00:00',
    ends: '2020-09-30 23:59'
  },
  rentdayCampaign: {
    starts: '2023-08-01 00:00',
    ends: '2023-08-20 23:59'
  },
  rentdayCampaignToNamAndEmea: {
    starts: '2022-09-01 00:00',
    ends: '2022-09-19 23:59'
  },
  blackFridayCampaign: {
    starts_pre: '2024-10-31 15:00',
    finish_pre: '2024-11-24 14:59',
    starts_week: '2024-11-24 15:00',
    finish_week: '2024-12-02 07:59',
    starts_extended: '2024-12-02 08:00',
    finish_extended: '2024-12-09 07:59',
    starts_ends: '2024-12-09 08:00',
    finish_ends: '2025-10-31 23:59'
  }
}
