import InsiderEnum from '~/services/Insider/InsiderEnum.js'

function totalAmountValue(params) {
  return (params?.booking?.totalAmount?.value) ??
          (params?.totalAmount?.value) ??
          (params?.plan?.totalAmount?.value) ??
          (params?.payment?.prepaid?.amountToPay?.value) ??
          (params?.payment?.totalAmount?.value)
}

function totalBrAmountValue(params) {
  return (params?.booking?.totalAmount?.brlValue) ??
          (params?.totalAmount?.brlValue) ??
          (params?.plan?.totalAmount?.brlValue) ??
          (params?.payment?.prepaid?.amountToPay?.brlValue) ??
          (params?.payment?.totalAmount?.brlValue)
}

function currencyValue(params) {
  return (params?.booking?.totalAmount?.currency) ??
         (params?.totalAmount?.currency) ??
         (params?.plan?.totalAmount?.currency) ??
         (params?.payment?.prepaid?.amountToPay?.currency) ??
         (params?.payment?.totalAmount?.currency)
}

function brCurrencyValue(params) {
  return (params?.booking?.totalAmount?.brlCurrency) ??
         (params?.totalAmount?.brlCurrency) ??
         (params?.plan?.totalAmount?.brlCurrency) ??
         (params?.payment?.prepaid?.amountToPay?.brlCurrency) ??
         (params?.payment?.totalAmount?.brlCurrency)
}

export function subTotalInds(params) {
  return (params?.storeData?.totalAmountWithoutAddServices?.value) ??
          (params?.totalAmountWithoutAdditionalServices?.value)
}

export function setOfValues() {
  return [InsiderEnum.GET_TOTAL_VALUE, InsiderEnum.GET_BR_TOTAL_VALUE]
}

export function setOfCurrencies() {
  return [InsiderEnum.GET_CURRENCY, InsiderEnum.GET_BR_CURRENCY]
}

export function setOfCurrenciesSymbol() {
  return [InsiderEnum.GET_SYMBOL_CURRENCY]
}

export function setOfFormattedValue() {
  return [InsiderEnum.GET_TOTAL_FORMATTED_VALUE]
}

export function amountValueOptions(params, type) {
  if (type === InsiderEnum.GET_TOTAL_VALUE) {
    return totalAmountValue(params)
  }

  if (type === InsiderEnum.GET_BR_TOTAL_VALUE) {
    return totalBrAmountValue(params)
  }
}

export function currencyOptions(params, type) {
  if (type === InsiderEnum.GET_CURRENCY) {
    return currencyValue(params)
  }

  if (type === InsiderEnum.GET_BR_CURRENCY) {
    return brCurrencyValue(params)
  }
}

export function currencySymbol(params, type) {
  if (type === InsiderEnum.GET_SYMBOL_CURRENCY) {
    return (params?.booking?.totalAmount?.symbol) ??
            (params?.totalAmount?.symbol) ??
            (params?.plan?.totalAmount?.symbol) ??
            (params?.payment?.prepaid?.amountToPay?.symbol) ??
            (params?.payment?.totalAmount?.symbol)
  }
}

export function totalFormattedAmountValue(params) {
  return (params?.booking?.totalAmount?.formatted) ??
          (params?.totalAmount?.formatted) ??
          (params?.plan?.totalAmount?.formatted) ??
          (params?.payment?.prepaid?.amountToPay?.formatted) ??
          (params?.payment?.totalAmount?.formatted)
}

export function previewCurrencyValue(params) {
  return !(params?.payment?.previewCurrency)
}
