if (process.browser) {
  require('materialize-css/js/jquery.easing.1.4')
  require('materialize-css/js/velocity.min')
  require('materialize-css/js/global')
  require('materialize-css/js/carousel')
  require('materialize-css/js/sideNav')
  require('materialize-css/js/modal')
  require('materialize-css/js/dropdown')
  require('materialize-css/js/forms')
  require('materialize-css/js/tabs')
  require('materialize-css/js/toasts')
  require('materialize-css/js/tooltip')
  require('materialize-css/js/waves')
  require('materialize-css/js/collapsible')
  require('materialize-css/js/cards')
  require('materialize-css/js/buttons')
  require('materialize-css/js/jquery.hammer')
}
