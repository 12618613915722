import { mapGetters } from 'vuex'
import Currencies from 'config/currencies'
import Configi18n from 'config/i18n'
import Gdpr from 'components/Gdpr/gdpr.vue'
import ItineraryHeader from 'components/Headers/itinerary-header/itinerary-header.vue'
import ChatStatus from 'components/ChatStatus/ChatStatus.vue'
import Moment from 'moment-timezone'
import { PROMOTION_DATES } from 'config/promotion-dates'
import CompleteTrip from 'mixins/complete-trip-mixin'
import googleLoginGsi from '../../../mixins/google-login-gsi'
import Routes from 'mixins/routes'
import SiftScience from '~/mixins/sift-science'
import BlackFridayMixin from '@/mixins/black-friday-mixin'
import RedirectToHomeResponsive from '@/mixins/homeResponsive/redirect-to-home-responsive'

export default {
  name: 'headerDefault',

  mixins: [
    CompleteTrip,
    googleLoginGsi,
    Routes,
    SiftScience,
    BlackFridayMixin,
    RedirectToHomeResponsive
  ],

  components: {
    Gdpr,
    ItineraryHeader,
    ChatStatus
  },

  data() {
    return {
      recentSearchQuantity: 0,
      showImgRentDay: false,
      urlBefore: '',
      textGTM: '',
      isRegisterConfigure: false,
      userResidenceCountry: null
    }
  },

  async created() {
    this.recentSearchQuantity = await this.getRecentSearchQuantity()
  },

  watch: {
    '$route'(to, from) {
      this.urlBefore = from.name
    }
  },

  beforeMount () {
    this.initGsiScript(this.$root)
  },

  async mounted() {
    this.executeSift()
    this.setShowImgRentDay()

    this.$root.$on('switchLoginAndRegister', (isRegister) => {
      this.isRegisterConfigure = isRegister
    })
    this.userResidenceCountry = await this.$store.getters.getUserResidenceCountry
  },

  computed: {
    ...mapGetters({
      currency: 'getCurrency',
      config: 'header/getConfig',
      urlFromConfigure: 'bookingConfigure/getConfigureUrlChangeStore'
    }),

    labelCurrencyLocale() {
      try {
        return `${this.$i18n.locale} | ${Currencies.currencies[this.currency].htmlcode}`
      } catch (e) {
        return `- | ${this.$i18n.locale}`
      }
    },

    /**
     * @desc returns object classes
     * @returns {object}
     */
    navClass () {
      return {
        'navbar-color': this.config.orangeColor,
        'my-account-bar': this.config.customerBookingTabs === true || this.config.requirementsTabs === true || this.config.paymentTabs === true,
        'settings-bar': this.config.settingsTabs === true,
        'small-logo': this.recentSearchQuantity > 0 && this.config.recentSearch,
        'navbar-black': this.config.blackColor || this.isPromotionalEventOnPages(),
        'navbar-red': this.config.redColor,
        'navbar-blue': this.config.blueColor,
        'navbar-green': this.config.greenColor
      }
    },

    contactRoute () {
      return this.$route.name === this.$routeResolver('routes.chat-status')
    },

    /**
     * @desc returns state from load page
     * @returns {boolean}
     */
    isDisabled: function () {
      return this.$store.getters.getLoadingPageState
    },

    /**
     * @desc returns if scroll has happens and if the route is /list
     * @returns {boolean}
     */
    isScrolled() {
      return this.$store.getters['search/getScrolled'] && this.$routeResolver('routes.search.list', this.$i18n.locale) === this.$route.name
    },

    canShowWppHome() {
      return this.config.menu && this.showWhatsapp && this.$route.name !== this.$routeResolver('routes.home')
    },

    isCheckedUrlToken() {
      return (process.env.TOKEN_LP === this.$route.query.token)
    },

    currentDate() {
      return (this.$route.query.data && this.isCheckedUrlToken) ? this.$route.query.data : new Moment().format('YYYY-MM-DD')
    },

    canShowChatStatus() {
      const availableRoutes = {
        'routes.search.list': true,
        'routes.booking.configure': true,
        'routes.booking.payment': true,
        'routes.booking.payment.insurance': true,
        'routes.booking.confirmation': true,
        'routes.booking.confirmation.insurance': true,
        'routes.customer.bookings': true,
        'routes.chat-status': true,
        'routes.home': true
      }
      const updatedRoutes = Object.keys(availableRoutes).reduce((acc, key) => {
        const newKey = this.$routeResolver(key)
        acc[newKey] = availableRoutes[key]
        return acc
      }, {})

      return this.config.menu && updatedRoutes[this.$route.name]
    },

    showChatStatusWhite() {
      return this.config.blackColor || this.config.redColor || this.config.blueColor || this.config.greenColor
    },

    showCompleteTrip() {
      return (this.completeTripActive && this.$store.getters.getLocale === 'pt-br' && this.userResidenceCountry === 'BR')
    }
  },

  methods: {
    setShowImgRentDay() {
      if (Moment(this.currentDate)
        .isBetween(PROMOTION_DATES.rentdayCampaign.starts, PROMOTION_DATES.rentdayCampaign.ends, null, '[]') &&
        (this.$store.getters.getLocale === 'es-co' || this.$store.getters.getLocale === 'es-cl' || this.$store.getters.getLocale === 'es-ar')) {
        this.recentSearchQuantity = 0

        this.showImgRentDisHomeay = false
      }
    },
    /**
     * @desc this method returns to back route
     */
    backRoute() {
      this.$store.dispatch('search/SET_SCROLLED', false)

      if (this.isDisabled) {
        return false
      } else if (this.isRegisterConfigure) {
        return this.$root.$emit('show_login_component', true)
      } else if (this.config.registerBackStep) {
        return this.$root.$emit('back_to_step_login', true)
      } else if (
        this.config.nameRoute === 'routes.booking.configure' ||
        (Boolean(this.urlFromConfigure) && this.stepIsDiferentFrom('1'))) {
        return this.$root.$emit('back_to_step')
      }

      if (this.config.back.path && this.config.back.path.includes('chat-online')) {
        this.$router.push(this.$store.getters.getPreviousChatRoute)
      } else if (this.config.back.name && this.verifyBackRouteHomeRedirect(this.config.back.name)) {
        window.location.href = `/${this.getRouteHomeRedirect(this.config.back.name)}/`
      } else if (!this.config.back.name && this.config.back.path === '/') {
        window.location.href = `/${this.getRouteHomeRedirect(this.$i18n.locale)}/`
      } else {
        this.$router.push(this.config.back)
      }
    },

    /**
     * @desc this method returns to back route
     */
    closeRoute() {
      if (this.isDisabled) {
        return false
      }
      this.$router.go(-1)
    },

    stepIsDiferentFrom(currentStep) {
      const step = this.$route?.query?.step
      return step && parseInt(step) !== parseInt(currentStep)
    },

    /**
     * @desc this method emits an save event
     */
    save() {
      this.$eventBus.$emit('HeaderSave', true)
    },

    /**
     * @desc this method emits an apply event
     */
    apply() {
      this.$eventBus.$emit('HeaderApply', true)
    },

    /**
     * @desc returns the quantity from resent searches
     * @returns {number}
     */
    async getRecentSearchQuantity() {
      const recentSearches = await this.$store.getters['booking/getRecentSearches'](this.$i18n.locale)

      if (recentSearches != null) {
        return recentSearches.length
      }

      return 0
    },

    flagIcon() {
      try {
        if (process.client) {
          let COUNTRY = Configi18n.defaultLocales[this.$i18n.locale].countryCode.toLowerCase()
          if (
            this.$appCookie.get('flag') &&
            this.$appCookie.get('flag') !== Configi18n.defaultLocales[this.$i18n.locale].countryCode.toLowerCase() &&
            'derivation' in Configi18n.defaultLocales[this.$i18n.locale] &&
            Configi18n.defaultLocales[this.$i18n.locale].derivation.some(v => v.countryCode.toLowerCase() === this.$appCookie.get('flag'))
          ) {
            COUNTRY = this.$appCookie.get('flag')
          }
          return `flag-icon flag-icon-${COUNTRY}`
        }
        return 'flag-icon'
      } catch (e) {
        return 'flag-icon'
      }
    },

    setPhones() {
      this.$store.dispatch('SET_PHONES', this.$store.getters.getLocale)
    },

    setClassBoxHeader() {
      const currency = Currencies.currencies[this.currency].htmlcode

      if (currency === 'MXN') {
        const country = Configi18n.defaultLocales[this.$i18n.locale].countryCode.toLowerCase()

        return `navbar-bt-icon flag-box-header icon-${country}`
      }

      return 'navbar-bt-icon flag-box-header'
    },

    getSealPromotion() {
      if (this.$route.name != null) {
        return this.$route.name.includes('routes.home') || this.$route.name.includes('routes.best-deals') || this.$route.name.includes('routes.blackfriday')
      }

      return false
    },

    getRouteGtmAuxiliaryRecipes() {
      if (this.urlBefore.includes('routes.customer.bookings')) {
        this.textGTM = 'my-booking-complete-travel'
      }

      if (this.urlBefore.includes('routes.customer.account')) {
        this.textGTM = 'my-account-complete-travel'
      }

      if (this.urlBefore.includes('routes.customer.wallet')) {
        this.textGTM = 'my-wallet-complete-travel'
      }

      this.gtmDataLayer({
        event: 'event',
        category: 'new-income',
        action: 'complete-travel',
        label: this.textGTM
      })

      return this.textGTM
    },

    routesChanShow () {
      return this.isHome() ||
        this.isPromotionPage() ||
        this.isEntryPage() ||
        this.isRentDay() ||
        this.isBookingReviewPage()
    },

    redirectToHome() {
      if (this.verifyLangToRedirect(this.$i18n.locale)) {
        window.location.href = `/${this.$i18n.locale}/`
      } else {
        this.$router.push(this.$routeResolver('routes.home', this.$i18n.locale, true))
      }
    }
  },

  destroyed() {
    this.$root.$off('switchLoginAndRegister')
  }
}
