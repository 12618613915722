import axios from 'axios'
import { additionalServicesEnum } from '../core/domain/enums/shared/AdditionalServicesEnum'
import { PAYMENT_TYPES } from '../../config/payment-types'
import VwoABTest from 'mixins/dynamicTestPlataform/vwoABTest'
import vwoVariantEnum from '../mixins/dynamicTestPlataform/vwoVariantEnum'
import vwoCampaignKeyEnum from '../mixins/dynamicTestPlataform/vwoCampaignKeyEnum'

export default ({ app: { context }, store: { getters } }, inject) => {
  const REST_CLIENT = axios.create({
    baseURL: process.env.REST_HOST,
    timeout: 60000,
    headers: {
      'mobicar-requestor-id': getters.getMobicarRequestorID
    }
  })

  REST_CLIENT.interceptors.request.use((config) => {
    config.headers['mobicar-requestor-id'] = getters.getMobicarRequestorID
    config.headers.Authorization = 'Bearer ' + getters.getUserToken

    if (config.method.toLowerCase() === 'get') {
      config.params.MobicarRequestorID = getters.getMobicarRequestorID
      config.params.Locale = treatLocale(getters.getLocale)
      config.params.RequestorDevice = 'Mobile'
      config.params.ClienteIP = getters.getUserIp
    } else if (config.method.toLowerCase() === 'post') {
      config.data.MobicarRequestorID = getters.getMobicarRequestorID
      config.data.Locale = treatLocale(getters.getLocale)
      config.data.RequestorDevice = 'Mobile'
      config.data.UsuarioEmissor = getters.getEmitterId
      config.data.ClienteIP = getters.getUserIp
    }

    return config
  })

  REST_CLIENT.interceptors.response.use(async (response) => {
    if (response?.data?.additionalServices && Array.isArray(response.data.additionalServices)) {
      response.data = await additionalServiceFilterInterceptor(response.data)
    }
    return response
  })

  async function getRentProtectionVwoCampaign () {
    const vwoAbTest = new VwoABTest(context)
    if (process.env.NODE_ENV === 'production') {
      return await vwoAbTest.getCampaignVariation(vwoCampaignKeyEnum.RENTPROTECTION_PWA)
    }
    return await vwoAbTest.getCampaignVariation(vwoCampaignKeyEnum.RENTPROTECTION_LOCAL)
  }

  async function additionalServiceFilterInterceptor (responseData) {
    try {
      const newResponseData = responseData
      const hasProtection = responseData?.additionalServices
        ?.filter(item => item.name === additionalServicesEnum.RENT_PROTECTION ||
            item.name === additionalServicesEnum.RENTAL_COVER)

      if (hasProtection.length < 2) {
        return responseData
      }

      const paymentValid = paymentIsValidForRp(responseData?.plan?.paymentType)
      if (!paymentValid) {
        newResponseData.additionalServices = filteredService(
          responseData.additionalServices, additionalServicesEnum.RENT_PROTECTION
        )
        return newResponseData
      }
      const rentProtectionVwoCampaign = await getRentProtectionVwoCampaign()
      if (
        rentProtectionVwoCampaign === vwoVariantEnum.RENTPROTECTION_VARIANT ||
        rentProtectionVwoCampaign === vwoVariantEnum.RENTPROTECTION_VARIANT_LOCAL
      ) {
        newResponseData.rentalCover = null
        newResponseData.additionalServices = filteredService(
          responseData.additionalServices, additionalServicesEnum.RENTAL_COVER
        )
      } else {
        newResponseData.additionalServices = filteredService(
          responseData.additionalServices, additionalServicesEnum.RENT_PROTECTION
        )
      }

      return newResponseData
    } catch (error) {
      return responseData
    }
  }

  function treatLocale(locale = '') {
    if (locale === 'pt-br') {
      return 'pt_BR'
    } else if (locale === 'es') {
      return 'es_ES'
    } else if (locale === 'es-mx') {
      return 'es_MX'
    } else if (locale === 'es-cl') {
      return 'es_CL'
    } else if (locale === 'es-ar') {
      return 'es_AR'
    } else if (locale === 'es-co') {
      return 'es_CO'
    } else if (locale === 'en-gb') {
      return 'en_GB'
    } else if (locale === 'nl-nl') {
      return 'nl_NL'
    } else if (locale === 'it-it') {
      return 'it_IT'
    } else if (locale === 'fr-fr') {
      return 'fr_FR'
    } else if (locale === 'de-de') {
      return 'de_DE'
    } else if (locale === 'pt-pt') {
      return 'pt_PT'
    } else if (locale === 'fr-ca') {
      return 'fr_CA'
    }
    return 'en_US'
  }

  function filteredService (additionalServices, service) {
    return additionalServices.filter(item => item.name !== service)
  }

  function paymentIsValidForRp (paymentType) {
    return [
      PAYMENT_TYPES.PP,
      PAYMENT_TYPES.PPP,
      PAYMENT_TYPES.PPD,
      PAYMENT_TYPES.PC
    ].includes(paymentType)
  }

  inject('rest', REST_CLIENT)
}
