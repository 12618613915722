import map from 'lodash/map'
import { format } from 'currency-formatter'

export default class PlansFactory {
  /**
   * @desc Represents a PlansFactory
   * @constructor
   */
  constructor(plans) {
    const newPlans = plans.map((value) => {
      return this.legacyPlanNormalization(value)
    })
    return newPlans
  }

  /**
   * @desc normalize plan
   * @param {object} plan
   * @return {object}
   */
  legacyPlanNormalization(plan) {
    const newPlan = {
      Code: parseInt(plan.Codigo, 10),
      Initials: plan.Sigla,
      Name: plan.Nome,
      Reference: plan.Referencia,
      Currency: plan.Moeda,
      CurrencyCode: plan.SimboloMoeda,
      Included: plan.Incluso.toLowerCase() === 'true',
      Required: plan.Obrigatorio.toLowerCase() === 'true',
      Item: this.treatItem(plan.Item),
      DailyAmount: parseFloat(plan.ValorDia),
      FormattedDailyAmount: format(parseFloat(plan.ValorDia), {
        code: plan.Moeda,
        format: '%v'
      }),
      TotalAmount: parseFloat(plan.ValorTotal),
      FormattedTotalAmount: format(parseFloat(plan.ValorTotal), {
        code: plan.Moeda,
        format: '%v'
      }),
      Destination: {
        Currency: plan.PagamentoDestino.Moeda,
        CurrencyCode: plan.PagamentoDestino.SimboloMoeda,
        Amount: parseFloat(plan.PagamentoDestino.Valor),
        FormattedAmount: format(parseFloat(plan.PagamentoDestino.Valor), {
          code: plan.Moeda,
          format: '%v'
        })
      },
      Online: {
        Currency: plan.PagamentoOnline.Moeda,
        CurrencyCode: plan.PagamentoOnline.SimboloMoeda,
        Amount: parseFloat(plan.PagamentoOnline.Valor),
        FormattedAmount: format(parseFloat(plan.PagamentoOnline.Valor), {
          code: plan.Moeda,
          format: '%v'
        })
      }
    }

    newPlan.Description = this.treatDescription(newPlan)

    return newPlan
  }

  /**
   * @desc mount description
   * @param {object} plan
   * @return {string}
   */
  treatDescription(plan) {
    const description = []

    description.push(plan.Item.Coverages.Name)

    plan.Item.Optionals.forEach((value) => {
      description.push(value.Name)
    })

    if (plan.Item.Fees.Included) {
      description.push(plan.Item.Fees.Name)
    }

    return description.join(', ')
  }

  /**
   * @desc treats item
   * @param {object} item
   * @return {object}
   */
  treatItem(item) {
    const newItem = {
      IsFreeKm: item.KmLivre.toLowerCase() === 'true',
      Coverages: this.treatCoverage(item.Protecao),
      Fees: this.treatFees(item.Taxas),
      Optionals: this.treatOptionals(item.Opcional)
    }

    return newItem
  }

  /**
   * @desc treats optionals
   * @param {object} optionals
   * @return {object}
   */
  treatOptionals(optionals) {
    return map(optionals, (value) => {
      return {
        Code: value.Codigo,
        Name: value.Nome,
        Description: value.Descricao,
        Included: value.Incluso.toLowerCase() === 'true',
        Quantity: parseInt(value.Quantidade, 10)
      }
    })
  }

  /**
   * @desc treats fees
   * @param {object} fees
   * @return {object}
   */
  treatFees(fees) {
    return {
      Code: fees.Codigo,
      Name: fees.Nome,
      Description: fees.Descricao,
      Currency: fees.Moeda,
      CurrencyCode: fees.SimboloMoeda,
      Amount: fees.Valor,
      Included: fees.Incluso.toLowerCase() === 'true'
    }
  }

  /**
   * @desc treats coverages
   * @param {object} coverage
   * @return {object}
   */
  treatCoverage(coverage) {
    const newCoverage = {
      Code: coverage.Codigo,
      Name: coverage.Nome,
      Items: map(coverage.Item, (value) => {
        return {
          Name: value.Nome,
          Initials: value.Sigla,
          Description: value.Descricao,
          IsFreeInsurance: value.IsentoFranquia.toLowerCase() === 'true',
          Type: value.Tipo ? {
            Name: value.Tipo.Nome,
            Initials: value.Tipo.Sigla,
            Description: value.Tipo.Descricao,
            Type: value.Tipo.Tipo,
            Insurance: value.Tipo.Franquia.toLowerCase() === 'true'
          } : {}
        }
      })
    }

    return newCoverage
  }
}
