import Moment from 'moment-timezone'
import config from 'config'
import AppSelect from 'components/Materialize/Select/Default/Default.vue'
import AppSearchLocations from 'components/Search/Locations/Locations.vue'
import AppModal from 'components/Modal/Default/Default.vue'
import LocationFactory from 'factories/LocationFactory'
import { hydrateOnInteraction } from 'vue-lazy-hydration'
import InsiderMixin from '@/mixins/insider-mixin'
import UserControlService from '../../../services/Authentication/UserControlService'
import { cookieTypes } from '~/store/cookie'

export default {
  name: 'SearchForm',

  components: {
    AppSelect,
    AppCountries: hydrateOnInteraction(
      () => import('components/Materialize/Select/Countries/Countries.vue'),
      { event: 'focus' }
    ),
    AppModal,
    AppSearchLocations
  },

  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showSubTitle: {
      type: Boolean,
      default: true
    },
    errorWarning: {
      type: String,
      default: null
    },
    fullHeight: {
      type: Boolean,
      default: null
    },
    disableCountry: {
      type: Boolean,
      default: false
    },
    headerTitle: {
      type: String,
      default: null
    },
    headerSubTitle: {
      type: String,
      default: null
    },
    currentPlace: {
      type: Object,
      default: null
    },
    clearData: {
      type: Boolean,
      default: false
    },
    recommended: {
      type: Number,
      default: null
    },
    ignoreCurrentPlace: {
      type: Boolean,
      default: false
    },
    fromBookPage: {
      type: Boolean,
      default: false
    },
    h2Title: {
      type: Boolean,
      default: false
    },
    h1Title: {
      type: Boolean,
      default: false
    },
    btnLarge: {
      type: Boolean,
      default: true
    },
    btnColor: {
      type: String,
      default: 'rc-btn--cta'
    },
    rentalTheme: {
      type: String,
      default: null
    },
    rentalCustom: {
      type: Object,
      default: undefined
    },
    showRentalBanner: {
      type: Boolean,
      default: true,
      required: false
    },
    isMonthly: {
      type: Boolean,
      required: false,
      default: false
    },
    fromChangeSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mixins: [InsiderMixin],

  data() {
    return {
      pickupPlaceData: null,
      pickupDate: null,
      pickupTime: config.search.defaultPickupTime,
      returnPlaceData: null,
      returnDate: null,
      returnTime: config.search.defaultReturnTime,
      sameLocation: true,
      navigatorHasGeoLocation: false,
      showSearchBox: false,
      type: null,
      countryCode: null,
      calendarTimezone: 'GMT',
      rentalCustomForm: undefined
    }
  },

  mounted() {
    if (this.rentalCustom !== undefined) {
      this.rentalCustomForm = this.rentalCustom
    }
    const self = this
    this.$store.dispatch('search/SET_SHOWFILTER', true)

    this.$store.dispatch('search/GET_SEARCH_DATA')
      .then((data) => {
        if (data == null) {
          data = {
            SAME_LOCATION: true
          }
        }

        if (self.currentPlace) {
          const location = new LocationFactory(self.currentPlace)

          data.PICKUP_PLACE = location
          data.RETURN_PLACE = location
        }

        self.setInitialValues(data, false)
      })
    this.$eventBus.$on('selectedOption', this.setInitialValues)
    this.$eventBus.$on('selectedOption', this.scrollTopPage)
  },

  watch: {
    getPickupPlaceName(value) {
      if (value != null && !this.fromBookPage) {
        setTimeout(() => {
          this.$validator.validate('pickupPlaceData')
        }, 300)
      }
    },

    getReturnPlaceName(value) {
      if (value != null && !this.fromBookPage) {
        setTimeout(() => {
          this.$validator.validate('termReturn')
        }, 300)
      }
    },

    /**
     * @desc sends GA event if user change pickup date
     * @param {String} newValue
     * @param {String} oldValue
     */
    pickupDate(newValue, oldValue) {
      this.validateDatetime()

      if (newValue != null && newValue.length > 0) {
        if (this.isMonthly && (oldValue == null || Moment.tz(this.returnDate, this.calendarTimezone).diff(newValue, 'days') < 30)) {
          this.returnDate = Moment.tz(this.pickupDate, this.calendarTimezone).add(30, 'days').format()
        } else if (!this.isMonthly && (oldValue == null || Moment.tz(newValue, this.calendarTimezone).isAfter(this.returnDate))) {
          this.returnDate = Moment.tz(newValue, this.calendarTimezone).add(1, 'days').format()
        }
      }

      const disabledTime = this.isTimeDisabled(this.pickupDate, this.pickupTime, this.pickupPlaceData)
      if (disabledTime) {
        const [, availableTime] = this.createTimeList(
          this.pickupDate,
          this.pickupTime,
          this.pickupPlaceData
        )

        this.pickupTime = availableTime
      }
    },

    pickupTime() {
      this.validateDatetime()
    },

    /**
     * @desc sends GA event if user change return date
     * @param {String} newValue
     * @param {String} oldValue
     */
    returnDate(newValue, oldValue) {
      this.validateDatetime()
      if (oldValue == null) {
        if (this.isMonthly && Moment.tz(newValue, this.calendarTimezone).diff(this.pickupDate, 'days') < 30) {
          this.returnDate = Moment.tz(this.pickupDate, this.calendarTimezone).add(30, 'days').format()
        } else if (!this.isMonthly && Moment.tz(newValue, this.calendarTimezone).isSameOrBefore(this.pickupDate)) {
          this.returnDate = Moment.tz(this.pickupDate, this.calendarTimezone).add(1, 'days').format()
        }
      }

      const disabledTime = this.isTimeDisabled(this.returnData, this.returnTime, this.returnPlaceData)
      if (disabledTime) {
        const [, availableTime] = this.createTimeList(
          this.returnDate,
          this.returnTime,
          this.returnPlaceData
        )

        this.returnTime = availableTime
      }
    },

    returnTime() {
      this.validateDatetime()
    },

    async countryCode(value) {
      if (value == null || value.trim().length === 0) {
        return
      }

      const currentCountry = await this.$store.getters.getUserResidenceCountry

      if (value !== currentCountry) {
        if (this.$store.getters['user/getValidLogin']) {
          try {
            const email = this.$store.getters['user/getUserEmail']
            const params = {
              countryCode: this.countryCode,
              email
            }

            const response = await this.$rentcarsApi.put('/v1/user/residence-country', params, false, [], false)

            if (response) {
              const data = await UserControlService.getUser(email, this.$root, this.$store.getters['user/getUserToken'])

              this.$store.dispatch('user/UPDATE_USER_DATA', data)
            }
          } catch (e) {
          }
        }
      }

      this.$store.dispatch('SET_COUNTRY_RESIDENCE_CODE', value)

      if (!process.server) {
        this.$appCookie.set(cookieTypes.RESIDENCE_CODE, value)
      }
    },

    ignoreCurrentPlace(value) {
      if (value === false) {
        this.$store.dispatch('search/GET_SEARCH_DATA')
          .then((data) => {
            if (data == null) {
              data = {
                SAME_LOCATION: true
              }
            }

            this.pickupPlaceData = data.PICKUP_PLACE
            this.returnPlaceData = data.RETURN_PLACE
          })
      }
    }
  },

  computed: {
    /**
     * @desc returns a form search title
     * @returns {String|TranslateResult}
     */
    headerString() {
      return this.headerTitle !== null ? this.headerTitle : this.$t('common.rentACar')
    },

    headerStringTitle() {
      const rentalName = this.headerTitle !== null ? this.headerTitle : this.$t('searchbox.title')
      if (rentalName === 'Alamo Rent a Car') {
        return 'Alamo Rent A Car'
      } else if (rentalName === 'National Rent a Car') {
        return 'National Car Rental'
      } else if (rentalName === 'Enterprise Rent a Car') {
        return 'Enterprise Rent-A-Car'
      }
      return rentalName
    },

    headerStringSubTitle() {
      return this.headerSubTitle !== null ? this.headerSubTitle : this.$t('searchbox.subtitle')
    },

    /**
     * @desc this method returns the locale by current lang
     * @return {String}
     */
    getPickupPlaceName: function () {
      if ([undefined, null].indexOf(this.pickupPlaceData) === -1 &&
        this.pickupPlaceData.hasOwnProperty('Names') &&
        this.$i18n.locale in this.pickupPlaceData.Names) {
        return this.pickupPlaceData.Names[this.$i18n.locale]
      } else if ([undefined, null].indexOf(this.pickupPlaceData) === -1 &&
        this.pickupPlaceData.hasOwnProperty('Name')) {
        return this.pickupPlaceData.Name
      }
    },

    /**
     * @desc this method returns the locale by current lang
     * @return {String}
     */
    getReturnPlaceName: function () {
      if ([undefined, null].indexOf(this.returnPlaceData) === -1 &&
        this.returnPlaceData.hasOwnProperty('Names') &&
        this.$i18n.locale in this.returnPlaceData.Names) {
        return this.returnPlaceData.Names[this.$i18n.locale]
      } else if ([undefined, null].indexOf(this.returnPlaceData) === -1 &&
        this.returnPlaceData.hasOwnProperty('Name')) {
        return this.returnPlaceData.Name
      }
    },

    minPickupDate() {
      return Moment.tz(this.calendarTimezone).format()
    },

    minReturnDate: function () {
      if (this.isMonthly) {
        return Moment.tz(this.pickupDate, this.calendarTimezone).add(30, 'days').format()
      } else {
        return Moment.tz(this.pickupDate, this.calendarTimezone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).format()
      }
    },

    maxCalendarDate() {
      return Moment.tz(this.calendarTimezone).add(2, 'years').format()
    },

    rentalCompanyId() {
      return this.rentalCustom ? 'rental-' + this.rentalCustom.code : null
    }
  },

  methods: {
    scrollTopPage(value) {
      if (value != null && this.$route.name === this.$routeResolver('routes.member-get-member')) {
        $('html, body').animate({
          scrollTop: $($('.search-group')).offset().top - 60
        }, 0)
      }
    },

    blockScroll() {
      const wrapperEl = document.getElementsByClassName('wrapper-all')
      if (wrapperEl && wrapperEl.length > 0) wrapperEl[0].classList.add('block-content')
    },

    unblockScroll() {
      const wrapperEl = document.getElementsByClassName('block-content')
      if (wrapperEl && wrapperEl.length > 0) wrapperEl[0].classList.remove('block-content')
    },

    async setInitialValues(value, fromSelection = true) {
      this.countryCode = await this.$store.getters.getUserResidenceCountry
      if (value == null) {
        return null
      }

      this.pickupPlaceData = value.PICKUP_PLACE
      this.returnPlaceData = value.RETURN_PLACE

      if (this.ignoreCurrentPlace === true && this.currentPlace == null && fromSelection === false) {
        this.pickupPlaceData = null
        this.returnPlaceData = null
      }

      if (Moment.tz(this.calendarTimezone).isAfter(value.PICKUP_DATE)) {
        value.PICKUP_DATE = null
      }

      this.pickupDate = this.pickupDate || value.PICKUP_DATE || Moment.tz(this.calendarTimezone).add(1, 'days').toISOString()
      this.pickupTime = value.PICKUP_TIME || config.search.defaultPickupTime
      this.returnDate = value.RETURN_DATE
      this.returnTime = value.RETURN_TIME || config.search.defaultReturnTime
      this.sameLocation = value.SAME_LOCATION
    },

    /**
     * @desc this method opens the box for search box
     * @param {String} type
     */
    openSearchBox: function (type) {
      if (
        this.$store.getters['gdpr/isPerformanceAllowed'] &&
        window.google?.accounts
      ) {
        window.google.accounts.id.cancel()
      }

      this.$store.dispatch('search/SET_FORM_DATA', {
        PICKUP_PLACE: this.pickupPlaceData,
        PICKUP_DATE: this.pickupDate,
        PICKUP_TIME: this.pickupTime,
        RETURN_PLACE: this.returnPlaceData,
        RETURN_DATE: this.returnDate,
        RETURN_TIME: this.returnTime,
        SAME_LOCATION: this.sameLocation
      })

      this.$eventBus.$emit('openSearchBox', { type })
    },

    /**
     * @desc event called when same location is changed and show/hide input different location
     */
    onChangeSameLocation: function () {
      if (this.sameLocation) {
        this.returnPlaceData = this.pickupPlaceData
      } else {
        this.returnPlaceData = null
      }
    },

    /**
     * @desc return all times options
     * @param {String} type
     * @returns {Array}
     */
    createTimeList (selectedDate, selectedTime, placeData) {
      const times = []
      let firstAvailableTime = null

      for (let i = 0; i < 24; i++) {
        for (let j = 0; j <= 30; j += 30) {
          const timeStr = `${i}:${j.toString().padStart(2, '0')}`
          const treatedTime = this.$options.filters.treatTime(timeStr)

          const timeDisabled = this.isTimeDisabled(
            selectedDate,
            treatedTime,
            placeData
          )

          const selected = selectedTime === treatedTime

          if (!firstAvailableTime && !timeDisabled) {
            firstAvailableTime = treatedTime
          }

          times.push({
            value: treatedTime,
            label: treatedTime,
            selected,
            disabled: timeDisabled
          })
        }
      }

      return [times, firstAvailableTime]
    },

    getTimeOptions (type) {
      const [timesList] = type === 'pickup'
        ? this.createTimeList(this.pickupDate, this.pickupTime, this.pickupPlaceData)
        : this.createTimeList(this.returnDate, this.returnTime, this.returnPlaceData)

      return timesList
    },

    /**
     * @desc Check if time is avaialable to select
     * @param {string} timestamp full timestamp to check
     * @param {string} time time formatted HH:mm
     * @param {string} type returnTime or pickupTime
     * @returns {bool}
     */
    isTimeDisabled (timestamp, time, placeData) {
      try {
        if (timestamp && placeData) {
          const currentCountryDate = Moment.tz(placeData.timezone.name).add(30, 'minutes')
          const dateToCompare = Moment.tz(`${timestamp.replace(/T.*/, '')} ${time}`, placeData.timezone.name)

          if (dateToCompare.isBefore(currentCountryDate)) {
            return true
          }
        }
      } catch (e) {
        return false
      }

      return false
    },

    /**
     * @desc this methods send the user to search list
     */
    sendToSearch() {
      this.$store.dispatch('search/SET_SHOWFILTER', false)
      this.$validator.validateAll()
        .then((value) => {
          if (value === false) {
            return
          }

          const promises = [
            this.$rentcarsApi.get('v1/search/url', {
              pickupPlaceCode: this.pickupPlaceData.Code,
              returnPlaceCode: this.returnPlaceData.Code,
              pickupDate: Moment.tz(this.pickupDate, this.calendarTimezone).format('YYYY-MM-DD'),
              returnDate: Moment.tz(this.returnDate, this.calendarTimezone).format('YYYY-MM-DD'),
              pickupTime: this.pickupTime,
              returnTime: this.returnTime
            })
          ]

          this.$rentcarsApi.client.all(promises)
            .then(this.$rentcarsApi.client.spread((urlEncode) => {
              this.$store.dispatch('search/SET_FORM_DATA', {
                PICKUP_PLACE: this.pickupPlaceData,
                PICKUP_DATE: this.pickupDate,
                PICKUP_TIME: this.pickupTime,
                RETURN_PLACE: this.returnPlaceData,
                RETURN_DATE: this.returnDate,
                RETURN_TIME: this.returnTime,
                SAME_LOCATION: this.sameLocation
              })

              const urlEncodeData = urlEncode.data

              if (urlEncodeData != null && urlEncodeData.hasOwnProperty('url')) {
                this.$store.dispatch('searchNew/CLEAR')
                this.$store.dispatch('search/CLEAR_SEARCH_RESULTS')

                this.pushToList(urlEncodeData.url)
              } else {
                let codeError = null
                if (urlEncodeData.hasOwnProperty('code')) {
                  codeError = urlEncodeData.code
                }

                this.$store.dispatch('search/SET_CODE_ERROR', codeError)

                throw new Error('Invalid search')
              }
            }))
            .catch((e) => {
              this.$store.dispatch('searchNew/CLEAR')
              this.$store.dispatch('search/CLEAR_SEARCH_RESULTS')
              this.$router.push({ name: this.$routeResolver('routes.search.unavailable-vehicle') })
            })
        })
        .catch(() => { })
    },

    pushToList: function (url) {
      const query = {}

      if (this.recommended != null) {
        query.Recommended = this.recommended
      }

      if (this.isRequestorIdPWA()) {
        query.requestorid = this.$store.getters.getMobicarRequestorID
      }

      this.$router.push(Object.assign({}, {
        name: this.$routeResolver('routes.search.list'),
        params: { params: url }
      }, { query }))
    },

    /**
     * @desc set vee-validate
     */
    validateDatetime() {
      const pickupTimezone = (this.pickupPlaceData != null && this.pickupPlaceData.hasOwnProperty('Timezone') ? this.pickupPlaceData.Timezone : null)
      const returnTimezone = (this.returnPlaceData != null && this.returnPlaceData.hasOwnProperty('Timezone') ? this.returnPlaceData.Timezone : null)

      const pickupTime = Moment(this.pickupTime, 'HH:mm')
      const returnTime = Moment(this.returnTime, 'HH:mm')

      this.$validator.detach('pickupDate')
      this.$validator.attach({
        name: 'pickupDate',
        rules: [
          'required',
          'date_format:YYYY-MM-DD',
          `min_date_hour:${this.pickupTime},${pickupTimezone}`,
          `b_date:${Moment.tz(this.pickupDate, this.calendarTimezone).set({ hour: pickupTime.get('hour'), minute: pickupTime.get('minute') }).format()},${Moment.tz(this.returnDate, this.calendarTimezone).set({ hour: returnTime.get('hour'), minute: returnTime.get('minute') }).format()}`
        ].join('|'),
        getter: () => {
          return this.pickupDate // or whatever the value path is.
        }
      })

      this.$validator.detach('returnDate')
      this.$validator.attach({
        name: 'returnDate',
        rules: [
          'required',
          'date_format:YYYY-MM-DD',
          `min_date_hour:${this.returnTime},${returnTimezone}`,
          `a_date:${Moment.tz(this.returnDate, this.calendarTimezone).set({ hour: returnTime.get('hour'), minute: returnTime.get('minute') }).format()},${Moment.tz(this.pickupDate, this.calendarTimezone).set({ hour: pickupTime.get('hour'), minute: pickupTime.get('minute') }).format()}`
        ].join('|'),
        getter: () => {
          return this.returnDate // or whatever the value path is.
        }
      })
    },

    /**
     * @desc return rental image
     * @param {String} rental
     * @returns mobileCdn URL
     */
    mountImgUrl(rental) {
      if (this.$store.getters.getIsBrazilianLocale) {
        if (rental === 'alamo') {
          return this.$mobileCdn('/images/banners/box-search/' + rental + '-seal.png')
        } else if (rental === 'enterprise') {
          return this.$mobileCdn('/images/banners/box-search/' + rental + '-seal.png')
        } else if (rental === 'national') {
          return this.$mobileCdn('/images/banners/box-search/' + rental + '-seal.png')
        }
        return this.$mobileCdn('/images/banners/box-search/' + rental + '.jpg')
      } else {
        return this.$mobileCdn('/images/banners/box-search/' + rental + '.jpg')
      }
    }
  },

  destroyed() {
    this.$validator.detach('pickupDate')
    this.$validator.detach('returnDate')
    $('body').removeClass('block-content')
    this.$eventBus.$off('selectedOption')
  }
}
