import abstractVwoAbTest from 'mixins/dynamicTestPlataform/abstractVwoAbTest'
import { VwoCampaignClass } from '../../store/vwoStore'
import { getUserIdWhenNull } from '../../plugins/uuid-cookie'

export default class VwoABTest extends abstractVwoAbTest {
  constructor (context = null) {
    super()
    this.context = context
  }

  /**
   * @returns {Promise<vwoInstance>}
   */
  async settings () {
    const settingsVwo = await this.settingsVwo()

    return this.vwoClient(settingsVwo)
  }

  /**
   * @param userId
   * @param campaignKey
   * @param options
   * @returns {Promise<*>}
   */
  async getVwoABTest (userId, campaignKey, options = {}) {
    const settings = await this.settings()
    return settings.activate(campaignKey, userId.toString(), options)
  }

  /**
   * @param userId
   * @param campaignKey
   * @returns {Promise<*>}
   */
  async getVwoVariationName (userId, campaignKey) {
    const settings = await this.settings()
    return settings.getVariationName(campaignKey, userId.toString())
  }

  /**
   * @param userId
   * @param campaignKey
   * @param metricKey
   * @param options
   * @returns {Promise<Record<string, boolean>>}
   */
  async setVwoTrackABTest (userId, campaignKey, metricKey, options = {}) {
    const settings = await this.settings()
    const getUserId = userId?.toString() ?? getUserIdWhenNull()

    return await settings.track(campaignKey, getUserId, metricKey, options)
  }

  /**
   * @throws {Error}
   */
  _checkContext () {
    if (!this.context || !this.context.$appCookie || !this.context.store) {
      throw new Error('Context not provided')
    }
  }

  getUserUuid () {
    this._checkContext()
    return this.context.$appCookie.get('userUuid')
  }

  async getCampaignVariation (campaignKey) {
    const uuid = this.getUserUuid()
    let variation = this.context.store.getters['vwoStore/getCampaign'](campaignKey, uuid)
    if (!variation) {
      variation = await this.getVwoABTest(uuid, campaignKey)
      const vwoCampaign = new VwoCampaignClass(campaignKey, variation, uuid)
      this.context.store.dispatch('vwoStore/ADD_CAMPAIGN', vwoCampaign)

      return variation
    }

    return variation.variant
  }
}
