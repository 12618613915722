import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a5a5110 = () => interopDefault(import('../../pwa/pages/airports/index.vue' /* webpackChunkName: "pages/airports/index" */))
const _cbdbf1fa = () => interopDefault(import('../../pwa/pages/app-page/index.vue' /* webpackChunkName: "pages/app-page/index" */))
const _a8ca2230 = () => interopDefault(import('../../pwa/pages/best-price/index.vue' /* webpackChunkName: "pages/best-price/index" */))
const _59c48e9c = () => interopDefault(import('../../pwa/pages/best-rates/index.vue' /* webpackChunkName: "pages/best-rates/index" */))
const _8678b756 = () => interopDefault(import('../../pwa/pages/chat-status/index.vue' /* webpackChunkName: "pages/chat-status/index" */))
const _6c80014a = () => interopDefault(import('../../pwa/pages/coupons/index.vue' /* webpackChunkName: "pages/coupons/index" */))
const _1bd9d7a4 = () => interopDefault(import('../../pwa/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _11a032b2 = () => interopDefault(import('../../pwa/pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _5c448c98 = () => interopDefault(import('../../pwa/pages/invites/index.vue' /* webpackChunkName: "pages/invites/index" */))
const _a257b0a8 = () => interopDefault(import('../../pwa/pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _01fb9628 = () => interopDefault(import('../../pwa/pages/monthly-rate/index.vue' /* webpackChunkName: "pages/monthly-rate/index" */))
const _1caf4cb1 = () => interopDefault(import('../../pwa/pages/offline/index.vue' /* webpackChunkName: "pages/offline/index" */))
const _06361528 = () => interopDefault(import('../../pwa/pages/rental/index.vue' /* webpackChunkName: "pages/rental/index" */))
const _a82a20c6 = () => interopDefault(import('../../pwa/pages/rentals/index.vue' /* webpackChunkName: "pages/rentals/index" */))
const _f8759c18 = () => interopDefault(import('../../pwa/pages/save-quote/index.vue' /* webpackChunkName: "pages/save-quote/index" */))
const _7b41ea6b = () => interopDefault(import('../../pwa/pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _d82f2758 = () => interopDefault(import('../../pwa/pages/airports/country.vue' /* webpackChunkName: "pages/airports/country" */))
const _e0061132 = () => interopDefault(import('../../pwa/pages/airports/location.vue' /* webpackChunkName: "pages/airports/location" */))
const _4890423a = () => interopDefault(import('../../pwa/pages/best-deals/best-deals.vue' /* webpackChunkName: "pages/best-deals/best-deals" */))
const _7f699af2 = () => interopDefault(import('../../pwa/pages/best-price/signup.vue' /* webpackChunkName: "pages/best-price/signup" */))
const _2ec6ffc5 = () => interopDefault(import('../../pwa/pages/countrys/countryV2.vue' /* webpackChunkName: "pages/countrys/countryV2" */))
const _a38ba57e = () => interopDefault(import('../../pwa/pages/customer/account.vue' /* webpackChunkName: "pages/customer/account" */))
const _7f966720 = () => interopDefault(import('../../pwa/pages/customer/account-access.vue' /* webpackChunkName: "pages/customer/account-access" */))
const _4cc39956 = () => interopDefault(import('../../pwa/pages/customer/bookings.vue' /* webpackChunkName: "pages/customer/bookings" */))
const _77f194c2 = () => interopDefault(import('../../pwa/pages/customer/complete-travel.vue' /* webpackChunkName: "pages/customer/complete-travel" */))
const _4581b906 = () => interopDefault(import('../../pwa/pages/customer/login.vue' /* webpackChunkName: "pages/customer/login" */))
const _9bece4ec = () => interopDefault(import('../../pwa/pages/customer/password-new.vue' /* webpackChunkName: "pages/customer/password-new" */))
const _53fa964e = () => interopDefault(import('../../pwa/pages/customer/password-recover.vue' /* webpackChunkName: "pages/customer/password-recover" */))
const _f59de286 = () => interopDefault(import('../../pwa/pages/customer/profile.vue' /* webpackChunkName: "pages/customer/profile" */))
const _16ff3934 = () => interopDefault(import('../../pwa/pages/customer/signup.vue' /* webpackChunkName: "pages/customer/signup" */))
const _6ef478d6 = () => interopDefault(import('../../pwa/pages/customer/wallet.vue' /* webpackChunkName: "pages/customer/wallet" */))
const _65e644f1 = () => interopDefault(import('../../pwa/pages/info/about.vue' /* webpackChunkName: "pages/info/about" */))
const _0487ec71 = () => interopDefault(import('../../pwa/pages/info/anti-discrimination-policy.vue' /* webpackChunkName: "pages/info/anti-discrimination-policy" */))
const _06c49330 = () => interopDefault(import('../../pwa/pages/info/code-conduct.vue' /* webpackChunkName: "pages/info/code-conduct" */))
const _f034bdf8 = () => interopDefault(import('../../pwa/pages/info/contact.vue' /* webpackChunkName: "pages/info/contact" */))
const _1fcceff3 = () => interopDefault(import('../../pwa/pages/info/cookies.vue' /* webpackChunkName: "pages/info/cookies" */))
const _6b3e397a = () => interopDefault(import('../../pwa/pages/info/info.vue' /* webpackChunkName: "pages/info/info" */))
const _a68c557e = () => interopDefault(import('../../pwa/pages/info/opening-hours/index.vue' /* webpackChunkName: "pages/info/opening-hours/index" */))
const _29a54bea = () => interopDefault(import('../../pwa/pages/info/payment.vue' /* webpackChunkName: "pages/info/payment" */))
const _41dca728 = () => interopDefault(import('../../pwa/pages/info/privacy.vue' /* webpackChunkName: "pages/info/privacy" */))
const _8d4f602e = () => interopDefault(import('../../pwa/pages/info/promotional-terms-conditions.vue' /* webpackChunkName: "pages/info/promotional-terms-conditions" */))
const _2576945e = () => interopDefault(import('../../pwa/pages/info/registercompany.vue' /* webpackChunkName: "pages/info/registercompany" */))
const _4470427f = () => interopDefault(import('../../pwa/pages/info/regulamento-rent-protection.vue' /* webpackChunkName: "pages/info/regulamento-rent-protection" */))
const _d2bb6748 = () => interopDefault(import('../../pwa/pages/info/requirements.vue' /* webpackChunkName: "pages/info/requirements" */))
const _65fd0d8a = () => interopDefault(import('../../pwa/pages/info/terms-conditions.vue' /* webpackChunkName: "pages/info/terms-conditions" */))
const _d570ce96 = () => interopDefault(import('../../pwa/pages/landing-pages/back-to-road/index.vue' /* webpackChunkName: "pages/landing-pages/back-to-road/index" */))
const _7537e672 = () => interopDefault(import('../../pwa/pages/landing-pages/bradesco-exclusive/index.vue' /* webpackChunkName: "pages/landing-pages/bradesco-exclusive/index" */))
const _74545b30 = () => interopDefault(import('../../pwa/pages/landing-pages/bradesco-prime/index.vue' /* webpackChunkName: "pages/landing-pages/bradesco-prime/index" */))
const _047f56bf = () => interopDefault(import('../../pwa/pages/landing-pages/bradesco-seguros/index.vue' /* webpackChunkName: "pages/landing-pages/bradesco-seguros/index" */))
const _4d55fa45 = () => interopDefault(import('../../pwa/pages/landing-pages/car-hire-tips/index.vue' /* webpackChunkName: "pages/landing-pages/car-hire-tips/index" */))
const _a9fdc6fa = () => interopDefault(import('../../pwa/pages/landing-pages/cashback/index.vue' /* webpackChunkName: "pages/landing-pages/cashback/index" */))
const _8e1d74e6 = () => interopDefault(import('../../pwa/pages/landing-pages/electric-car/index.vue' /* webpackChunkName: "pages/landing-pages/electric-car/index" */))
const _05960a0e = () => interopDefault(import('../../pwa/pages/landing-pages/ferias-de-julho/index.vue' /* webpackChunkName: "pages/landing-pages/ferias-de-julho/index" */))
const _6639f86f = () => interopDefault(import('../../pwa/pages/landing-pages/juntoseseguros/index.vue' /* webpackChunkName: "pages/landing-pages/juntoseseguros/index" */))
const _4e5cd698 = () => interopDefault(import('../../pwa/pages/landing-pages/livelo/index.vue' /* webpackChunkName: "pages/landing-pages/livelo/index" */))
const _0b2bae8a = () => interopDefault(import('../../pwa/pages/landing-pages/lp-builder/index.vue' /* webpackChunkName: "pages/landing-pages/lp-builder/index" */))
const _63f209a4 = () => interopDefault(import('../../pwa/pages/landing-pages/lp-builder-v2/index.vue' /* webpackChunkName: "pages/landing-pages/lp-builder-v2/index" */))
const _28b5dafe = () => interopDefault(import('../../pwa/pages/landing-pages/maio-amarelo/index.vue' /* webpackChunkName: "pages/landing-pages/maio-amarelo/index" */))
const _0642c091 = () => interopDefault(import('../../pwa/pages/landing-pages/member-get-member/index.vue' /* webpackChunkName: "pages/landing-pages/member-get-member/index" */))
const _571331d6 = () => interopDefault(import('../../pwa/pages/landing-pages/one-way/index.vue' /* webpackChunkName: "pages/landing-pages/one-way/index" */))
const _42508776 = () => interopDefault(import('../../pwa/pages/landing-pages/passagenspromo/index.vue' /* webpackChunkName: "pages/landing-pages/passagenspromo/index" */))
const _08117f9e = () => interopDefault(import('../../pwa/pages/landing-pages/rewards/index.vue' /* webpackChunkName: "pages/landing-pages/rewards/index" */))
const _6ddb88a0 = () => interopDefault(import('../../pwa/pages/landing-pages/road-trip-america-latina/index.vue' /* webpackChunkName: "pages/landing-pages/road-trip-america-latina/index" */))
const _168e7248 = () => interopDefault(import('../../pwa/pages/landing-pages/sao-joao/index.vue' /* webpackChunkName: "pages/landing-pages/sao-joao/index" */))
const _dacf603c = () => interopDefault(import('../../pwa/pages/landing-pages/segurospromo/index.vue' /* webpackChunkName: "pages/landing-pages/segurospromo/index" */))
const _17428fdf = () => interopDefault(import('../../pwa/pages/landing-pages/sky-milhas/index.vue' /* webpackChunkName: "pages/landing-pages/sky-milhas/index" */))
const _3d71e820 = () => interopDefault(import('../../pwa/pages/locations/country.vue' /* webpackChunkName: "pages/locations/country" */))
const _231b696a = () => interopDefault(import('../../pwa/pages/locations/location.vue' /* webpackChunkName: "pages/locations/location" */))
const _08bf264e = () => interopDefault(import('../../pwa/pages/monthly-rate/benefits/index.vue' /* webpackChunkName: "pages/monthly-rate/benefits/index" */))
const _19458216 = () => interopDefault(import('../../pwa/pages/monthly-rate/newsletter/index.vue' /* webpackChunkName: "pages/monthly-rate/newsletter/index" */))
const _a31cc2b0 = () => interopDefault(import('../../pwa/pages/monthly-rate/promotional-banner/index.vue' /* webpackChunkName: "pages/monthly-rate/promotional-banner/index" */))
const _7ea93a80 = () => interopDefault(import('../../pwa/pages/monthly-rate/steps/index.vue' /* webpackChunkName: "pages/monthly-rate/steps/index" */))
const _513f69cc = () => interopDefault(import('../../pwa/pages/NotFound/NotFound.vue' /* webpackChunkName: "pages/NotFound/NotFound" */))
const _c9fadd82 = () => interopDefault(import('../../pwa/pages/places/locationV2.vue' /* webpackChunkName: "pages/places/locationV2" */))
const _0fa7314d = () => interopDefault(import('../../pwa/pages/rental/airport-location.vue' /* webpackChunkName: "pages/rental/airport-location" */))
const _23492ca0 = () => interopDefault(import('../../pwa/pages/rental/country.vue' /* webpackChunkName: "pages/rental/country" */))
const _058b604c = () => interopDefault(import('../../pwa/pages/rental/countryV2.vue' /* webpackChunkName: "pages/rental/countryV2" */))
const _d03e25f0 = () => interopDefault(import('../../pwa/pages/rental/indexV2.vue' /* webpackChunkName: "pages/rental/indexV2" */))
const _f82cb4ea = () => interopDefault(import('../../pwa/pages/rental/location.vue' /* webpackChunkName: "pages/rental/location" */))
const _cbec5ca4 = () => interopDefault(import('../../pwa/pages/rental/location-v2.vue' /* webpackChunkName: "pages/rental/location-v2" */))
const _707a1167 = () => interopDefault(import('../../pwa/pages/rental/locationV2.vue' /* webpackChunkName: "pages/rental/locationV2" */))
const _2464b5de = () => interopDefault(import('../../pwa/pages/rentals-v2/locationV2.vue' /* webpackChunkName: "pages/rentals-v2/locationV2" */))
const _1868c8be = () => interopDefault(import('../../pwa/pages/rentals/country.vue' /* webpackChunkName: "pages/rentals/country" */))
const _0814765c = () => interopDefault(import('../../pwa/pages/rentals/index-v2.vue' /* webpackChunkName: "pages/rentals/index-v2" */))
const _b3229a1e = () => interopDefault(import('../../pwa/pages/search/booking-amp/index.vue' /* webpackChunkName: "pages/search/booking-amp/index" */))
const _495f21d8 = () => interopDefault(import('../../pwa/pages/search/booking-duplicity/index.vue' /* webpackChunkName: "pages/search/booking-duplicity/index" */))
const _340ebd83 = () => interopDefault(import('../../pwa/pages/search/change-coverages/index.vue' /* webpackChunkName: "pages/search/change-coverages/index" */))
const _e9e965ea = () => interopDefault(import('../../pwa/pages/search/change-optionals/index.vue' /* webpackChunkName: "pages/search/change-optionals/index" */))
const _57646447 = () => interopDefault(import('../../pwa/pages/search/change-plan/index.vue' /* webpackChunkName: "pages/search/change-plan/index" */))
const _c251b174 = () => interopDefault(import('../../pwa/pages/search/change-search/index.vue' /* webpackChunkName: "pages/search/change-search/index" */))
const _007b5987 = () => interopDefault(import('../../pwa/pages/search/configure/index.vue' /* webpackChunkName: "pages/search/configure/index" */))
const _97e44f6c = () => interopDefault(import('../../pwa/pages/search/confirmation/index.vue' /* webpackChunkName: "pages/search/confirmation/index" */))
const _25613634 = () => interopDefault(import('../../pwa/pages/search/detail/index.vue' /* webpackChunkName: "pages/search/detail/index" */))
const _1b41934d = () => interopDefault(import('../../pwa/pages/search/filter/index.vue' /* webpackChunkName: "pages/search/filter/index" */))
const _b11d1376 = () => interopDefault(import('../../pwa/pages/search/flight/index.vue' /* webpackChunkName: "pages/search/flight/index" */))
const _c33e167a = () => interopDefault(import('../../pwa/pages/search/identification/index.vue' /* webpackChunkName: "pages/search/identification/index" */))
const _5b1e2773 = () => interopDefault(import('../../pwa/pages/search/list/index.vue' /* webpackChunkName: "pages/search/list/index" */))
const _531ebcfd = () => interopDefault(import('../../pwa/pages/search/map/index.vue' /* webpackChunkName: "pages/search/map/index" */))
const _4c67ca27 = () => interopDefault(import('../../pwa/pages/search/payment/index.vue' /* webpackChunkName: "pages/search/payment/index" */))
const _2177d9ae = () => interopDefault(import('../../pwa/pages/search/recent-searches/index.vue' /* webpackChunkName: "pages/search/recent-searches/index" */))
const _0555fa5f = () => interopDefault(import('../../pwa/pages/search/rental-location/index.vue' /* webpackChunkName: "pages/search/rental-location/index" */))
const _1959fa90 = () => interopDefault(import('../../pwa/pages/search/unavailable-vehicle/index.vue' /* webpackChunkName: "pages/search/unavailable-vehicle/index" */))
const _4c8fcc93 = () => interopDefault(import('../../pwa/pages/settings/cookies-preferences.vue' /* webpackChunkName: "pages/settings/cookies-preferences" */))
const _1292fe3e = () => interopDefault(import('../../pwa/pages/coupons/components/benefits/index.vue' /* webpackChunkName: "pages/coupons/components/benefits/index" */))
const _15242122 = () => interopDefault(import('../../pwa/pages/coupons/components/blogs/index.vue' /* webpackChunkName: "pages/coupons/components/blogs/index" */))
const _27d1fa67 = () => interopDefault(import('../../pwa/pages/coupons/components/faq/index.vue' /* webpackChunkName: "pages/coupons/components/faq/index" */))
const _57f88531 = () => interopDefault(import('../../pwa/pages/coupons/components/news-letter/index.vue' /* webpackChunkName: "pages/coupons/components/news-letter/index" */))
const _a557e4fa = () => interopDefault(import('../../pwa/pages/customer/booking-review/booking-review.vue' /* webpackChunkName: "pages/customer/booking-review/booking-review" */))
const _031ed46c = () => interopDefault(import('../../pwa/pages/customer/booking-review/review-error.vue' /* webpackChunkName: "pages/customer/booking-review/review-error" */))
const _c2c05a82 = () => interopDefault(import('../../pwa/pages/customer/booking-review/review-vehicle-card.vue' /* webpackChunkName: "pages/customer/booking-review/review-vehicle-card" */))
const _1b89f90c = () => interopDefault(import('../../pwa/pages/customer/booking/cancel.vue' /* webpackChunkName: "pages/customer/booking/cancel" */))
const _2be55342 = () => interopDefault(import('../../pwa/pages/customer/booking/change.vue' /* webpackChunkName: "pages/customer/booking/change" */))
const _c522a17a = () => interopDefault(import('../../pwa/pages/customer/booking/compare.vue' /* webpackChunkName: "pages/customer/booking/compare" */))
const _3cdc6e20 = () => interopDefault(import('../../pwa/pages/customer/booking/details.vue' /* webpackChunkName: "pages/customer/booking/details" */))
const _62eff48c = () => interopDefault(import('../../pwa/pages/customer/booking/voucher.vue' /* webpackChunkName: "pages/customer/booking/voucher" */))
const _2caaa7c4 = () => interopDefault(import('../../pwa/pages/landing-pages/allaccor/accor.vue' /* webpackChunkName: "pages/landing-pages/allaccor/accor" */))
const _479481bb = () => interopDefault(import('../../pwa/pages/landing-pages/back-to-road/data_2020.js' /* webpackChunkName: "pages/landing-pages/back-to-road/data_2020" */))
const _6c447659 = () => interopDefault(import('../../pwa/pages/landing-pages/dotz/dotz.vue' /* webpackChunkName: "pages/landing-pages/dotz/dotz" */))
const _4b5cfb34 = () => interopDefault(import('../../pwa/pages/landing-pages/electric-car/Benefits/index.vue' /* webpackChunkName: "pages/landing-pages/electric-car/Benefits/index" */))
const _36bb0754 = () => interopDefault(import('../../pwa/pages/landing-pages/electric-car/Categories/index.vue' /* webpackChunkName: "pages/landing-pages/electric-car/Categories/index" */))
const _44d48047 = () => interopDefault(import('../../pwa/pages/landing-pages/in-mais/in-mais.vue' /* webpackChunkName: "pages/landing-pages/in-mais/in-mais" */))
const _32396f65 = () => interopDefault(import('../../pwa/pages/landing-pages/in-mais/regulations.js' /* webpackChunkName: "pages/landing-pages/in-mais/regulations" */))
const _722fbc19 = () => interopDefault(import('../../pwa/pages/landing-pages/ipiranga/ipiranga.vue' /* webpackChunkName: "pages/landing-pages/ipiranga/ipiranga" */))
const _0a64d003 = () => interopDefault(import('../../pwa/pages/landing-pages/itau-personnalite/itau-personnalite.vue' /* webpackChunkName: "pages/landing-pages/itau-personnalite/itau-personnalite" */))
const _5c7cfa73 = () => interopDefault(import('../../pwa/pages/landing-pages/latampass/latampass.vue' /* webpackChunkName: "pages/landing-pages/latampass/latampass" */))
const _7e3e8d9b = () => interopDefault(import('../../pwa/pages/landing-pages/oab/oab.vue' /* webpackChunkName: "pages/landing-pages/oab/oab" */))
const _22c720cc = () => interopDefault(import('../../pwa/pages/landing-pages/one-way/Categories/index.vue' /* webpackChunkName: "pages/landing-pages/one-way/Categories/index" */))
const _69d42c8e = () => interopDefault(import('../../pwa/pages/landing-pages/paypal-shell-box/paypal-shell-box.vue' /* webpackChunkName: "pages/landing-pages/paypal-shell-box/paypal-shell-box" */))
const _4da8499e = () => interopDefault(import('../../pwa/pages/landing-pages/premmia/premmia.vue' /* webpackChunkName: "pages/landing-pages/premmia/premmia" */))
const _19be456f = () => interopDefault(import('../../pwa/pages/landing-pages/samsung/samsung.vue' /* webpackChunkName: "pages/landing-pages/samsung/samsung" */))
const _99b73ff6 = () => interopDefault(import('../../pwa/pages/landing-pages/sicredi/sicredi.vue' /* webpackChunkName: "pages/landing-pages/sicredi/sicredi" */))
const _da1263ce = () => interopDefault(import('../../pwa/pages/landing-pages/timblack/timblack.vue' /* webpackChunkName: "pages/landing-pages/timblack/timblack" */))
const _bcb074e2 = () => interopDefault(import('../../pwa/pages/landing-pages/timpramim/timpramim.vue' /* webpackChunkName: "pages/landing-pages/timpramim/timpramim" */))
const _9f32fc8e = () => interopDefault(import('../../pwa/pages/landing-pages/tudoazul/tudoazul.vue' /* webpackChunkName: "pages/landing-pages/tudoazul/tudoazul" */))
const _6f58058e = () => interopDefault(import('../../pwa/pages/landing-pages/tv/tv.vue' /* webpackChunkName: "pages/landing-pages/tv/tv" */))
const _beca274e = () => interopDefault(import('../../pwa/pages/landing-pages/visa/visa.vue' /* webpackChunkName: "pages/landing-pages/visa/visa" */))
const _35b00046 = () => interopDefault(import('../../pwa/pages/monthly-rate/annual-subscription/annual-subscription.vue' /* webpackChunkName: "pages/monthly-rate/annual-subscription/annual-subscription" */))
const _270b81b6 = () => interopDefault(import('../../pwa/pages/monthly-rate/monthly-rate-faq/monthly-rate-faq.vue' /* webpackChunkName: "pages/monthly-rate/monthly-rate-faq/monthly-rate-faq" */))
const _355dd0d3 = () => interopDefault(import('../../pwa/pages/promotions/blackfriday/blackfriday.vue' /* webpackChunkName: "pages/promotions/blackfriday/blackfriday" */))
const _312e5650 = () => interopDefault(import('../../pwa/pages/promotions/blackfriday/blackfriday-2019.vue' /* webpackChunkName: "pages/promotions/blackfriday/blackfriday-2019" */))
const _0e46222a = () => interopDefault(import('../../pwa/pages/promotions/blackfriday/data.js' /* webpackChunkName: "pages/promotions/blackfriday/data" */))
const _1a29b9e9 = () => interopDefault(import('../../pwa/pages/promotions/blackfriday/data_2020.js' /* webpackChunkName: "pages/promotions/blackfriday/data_2020" */))
const _9535a696 = () => interopDefault(import('../../pwa/pages/promotions/buenfin/buenfin.vue' /* webpackChunkName: "pages/promotions/buenfin/buenfin" */))
const _682f17ae = () => interopDefault(import('../../pwa/pages/promotions/buenfin/buenfin-2019.vue' /* webpackChunkName: "pages/promotions/buenfin/buenfin-2019" */))
const _7caa405b = () => interopDefault(import('../../pwa/pages/promotions/buenfin/data.js' /* webpackChunkName: "pages/promotions/buenfin/data" */))
const _e1745b50 = () => interopDefault(import('../../pwa/pages/promotions/buenfin/data_2020.js' /* webpackChunkName: "pages/promotions/buenfin/data_2020" */))
const _19a4b32e = () => interopDefault(import('../../pwa/pages/promotions/cybermonday/cybermonday.vue' /* webpackChunkName: "pages/promotions/cybermonday/cybermonday" */))
const _2fb3a896 = () => interopDefault(import('../../pwa/pages/promotions/cybermonday/data.js' /* webpackChunkName: "pages/promotions/cybermonday/data" */))
const _95ce2184 = () => interopDefault(import('../../pwa/pages/promotions/cybermonday/data_2020.js' /* webpackChunkName: "pages/promotions/cybermonday/data_2020" */))
const _2abe8f20 = () => interopDefault(import('../../pwa/pages/promotions/hotsale/data.js' /* webpackChunkName: "pages/promotions/hotsale/data" */))
const _06a70a3f = () => interopDefault(import('../../pwa/pages/promotions/hotsale/hotsale.vue' /* webpackChunkName: "pages/promotions/hotsale/hotsale" */))
const _518e8033 = () => interopDefault(import('../../pwa/pages/promotions/hottravel/data.js' /* webpackChunkName: "pages/promotions/hottravel/data" */))
const _6a1a8665 = () => interopDefault(import('../../pwa/pages/promotions/hottravel/hottravel.vue' /* webpackChunkName: "pages/promotions/hottravel/hottravel" */))
const _3469f9b0 = () => interopDefault(import('../../pwa/pages/promotions/rentsale/data.js' /* webpackChunkName: "pages/promotions/rentsale/data" */))
const _5c310fd5 = () => interopDefault(import('../../pwa/pages/promotions/rentsale/rentsale.vue' /* webpackChunkName: "pages/promotions/rentsale/rentsale" */))
const _8c469d02 = () => interopDefault(import('../../pwa/pages/promotions/traveltuesday/data.js' /* webpackChunkName: "pages/promotions/traveltuesday/data" */))
const _5b3f1098 = () => interopDefault(import('../../pwa/pages/promotions/traveltuesday/data_2020.js' /* webpackChunkName: "pages/promotions/traveltuesday/data_2020" */))
const _070d1806 = () => interopDefault(import('../../pwa/pages/promotions/traveltuesday/traveltuesday.vue' /* webpackChunkName: "pages/promotions/traveltuesday/traveltuesday" */))
const _4b75a25a = () => interopDefault(import('../../pwa/pages/search/configure/v2/index.vue' /* webpackChunkName: "pages/search/configure/v2/index" */))
const _6e5c5a40 = () => interopDefault(import('../../pwa/pages/search/configure/v8/index.vue' /* webpackChunkName: "pages/search/configure/v8/index" */))
const _1e4a4a2d = () => interopDefault(import('../../pwa/pages/search/list/v1/index.vue' /* webpackChunkName: "pages/search/list/v1/index" */))
const _99a20624 = () => interopDefault(import('../../pwa/pages/search/list/v2/index.vue' /* webpackChunkName: "pages/search/list/v2/index" */))
const _35966276 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/review-form.vue' /* webpackChunkName: "pages/customer/booking-review/form/review-form" */))
const _ebb13a44 = () => interopDefault(import('../../pwa/pages/customer/myBookingsV2/bookings/bookings.vue' /* webpackChunkName: "pages/customer/myBookingsV2/bookings/bookings" */))
const _afc192b8 = () => interopDefault(import('../../pwa/pages/customer/myBookingsV2/details/details.vue' /* webpackChunkName: "pages/customer/myBookingsV2/details/details" */))
const _ceea442c = () => interopDefault(import('../../pwa/pages/landing-pages/one-way/MostPopularDestinations/MostPopularDestinations.vue' /* webpackChunkName: "pages/landing-pages/one-way/MostPopularDestinations/MostPopularDestinations" */))
const _73593590 = () => interopDefault(import('../../pwa/pages/search/configure/v2/category-upgrade/index.vue' /* webpackChunkName: "pages/search/configure/v2/category-upgrade/index" */))
const _77f1ab0e = () => interopDefault(import('../../pwa/pages/search/configure/v2/extra-items/index.vue' /* webpackChunkName: "pages/search/configure/v2/extra-items/index" */))
const _046d184e = () => interopDefault(import('../../pwa/pages/search/configure/v2/flight-card-info/index.vue' /* webpackChunkName: "pages/search/configure/v2/flight-card-info/index" */))
const _01ea725e = () => interopDefault(import('../../pwa/pages/search/configure/v2/flight-number/index.vue' /* webpackChunkName: "pages/search/configure/v2/flight-number/index" */))
const _2776e83a = () => interopDefault(import('../../pwa/pages/search/configure/v2/pickup-dropoff/index.vue' /* webpackChunkName: "pages/search/configure/v2/pickup-dropoff/index" */))
const _02be0822 = () => interopDefault(import('../../pwa/pages/search/configure/v2/plans-protections/index.vue' /* webpackChunkName: "pages/search/configure/v2/plans-protections/index" */))
const _c39a84cc = () => interopDefault(import('../../pwa/pages/search/configure/v2/price-lock/index.vue' /* webpackChunkName: "pages/search/configure/v2/price-lock/index" */))
const _0014e0b8 = () => interopDefault(import('../../pwa/pages/search/configure/v2/rent-protection/index.vue' /* webpackChunkName: "pages/search/configure/v2/rent-protection/index" */))
const _f6fabd20 = () => interopDefault(import('../../pwa/pages/search/configure/v2/rent-protection-variant/index.vue' /* webpackChunkName: "pages/search/configure/v2/rent-protection-variant/index" */))
const _43b8450e = () => interopDefault(import('../../pwa/pages/search/configure/v2/rental-cover/index.vue' /* webpackChunkName: "pages/search/configure/v2/rental-cover/index" */))
const _05263fdd = () => interopDefault(import('../../pwa/pages/search/configure/v2/your-reservation/index.vue' /* webpackChunkName: "pages/search/configure/v2/your-reservation/index" */))
const _0cdc8ee8 = () => interopDefault(import('../../pwa/pages/search/configure/v2/yourcar/index.vue' /* webpackChunkName: "pages/search/configure/v2/yourcar/index" */))
const _53d0c460 = () => interopDefault(import('../../pwa/pages/search/list/v2/list-results-count/index.vue' /* webpackChunkName: "pages/search/list/v2/list-results-count/index" */))
const _272155be = () => interopDefault(import('../../pwa/pages/search/list/v2/loading-bar/index.vue' /* webpackChunkName: "pages/search/list/v2/loading-bar/index" */))
const _0620395c = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/inputs/checkbox-input.vue' /* webpackChunkName: "pages/customer/booking-review/form/inputs/checkbox-input" */))
const _2a7ce8d6 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/inputs/radio-input.vue' /* webpackChunkName: "pages/customer/booking-review/form/inputs/radio-input" */))
const _5ccac0b6 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/inputs/review-button.vue' /* webpackChunkName: "pages/customer/booking-review/form/inputs/review-button" */))
const _07f5faa6 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/inputs/score-input.vue' /* webpackChunkName: "pages/customer/booking-review/form/inputs/score-input" */))
const _31209d3c = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/inputs/text-input.vue' /* webpackChunkName: "pages/customer/booking-review/form/inputs/text-input" */))
const _5fb02954 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-booking-used.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-booking-used" */))
const _779a0ff8 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-finish.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-finish" */))
const _2e8370d2 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-new-booking.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-new-booking" */))
const _0fe31cf4 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-no-show.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-no-show" */))
const _60fe149c = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-nps-questions.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-nps-questions" */))
const _b2078164 = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-rental-score.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-rental-score" */))
const _80ceab8c = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-support.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-support" */))
const _0dfb0d0c = () => interopDefault(import('../../pwa/pages/customer/booking-review/form/steps/step-whats-wrong.vue' /* webpackChunkName: "pages/customer/booking-review/form/steps/step-whats-wrong" */))
const _7d939666 = () => interopDefault(import('../../pwa/pages/search/configure/v2/extra-items/extra-confirmation-modal.vue' /* webpackChunkName: "pages/search/configure/v2/extra-items/extra-confirmation-modal" */))
const _25b4349f = () => interopDefault(import('../../pwa/pages/search/configure/v2/extra-items/extra-info-modal.vue' /* webpackChunkName: "pages/search/configure/v2/extra-items/extra-info-modal" */))
const _530cb06e = () => interopDefault(import('../../pwa/pages/search/configure/v2/extra-items/extra-item-card.vue' /* webpackChunkName: "pages/search/configure/v2/extra-items/extra-item-card" */))
const _14f19d8c = () => interopDefault(import('../../pwa/pages/search/configure/v2/flight-number/flight-card.vue' /* webpackChunkName: "pages/search/configure/v2/flight-number/flight-card" */))
const _1bd21361 = () => interopDefault(import('../../pwa/pages/search/configure/v2/flight-number/flight-modal.vue' /* webpackChunkName: "pages/search/configure/v2/flight-number/flight-modal" */))
const _0e3bfdd6 = () => interopDefault(import('../../pwa/pages/search/configure/v2/plans-protections/no-protection-modal.vue' /* webpackChunkName: "pages/search/configure/v2/plans-protections/no-protection-modal" */))
const _13488ab8 = () => interopDefault(import('../../pwa/pages/search/configure/v8/steps/identification.vue' /* webpackChunkName: "pages/search/configure/v8/steps/identification" */))
const _0277f92c = () => interopDefault(import('../../pwa/pages/search/configure/v8/steps/payment.vue' /* webpackChunkName: "pages/search/configure/v8/steps/payment" */))
const _f67bfe70 = () => interopDefault(import('../../pwa/pages/search/configure/v8/steps/paymentV2.vue' /* webpackChunkName: "pages/search/configure/v8/steps/paymentV2" */))
const _54ba3126 = () => interopDefault(import('../../pwa/pages/search/configure/v8/tabs/rental/index.vue' /* webpackChunkName: "pages/search/configure/v8/tabs/rental/index" */))
const _55ca7f5c = () => interopDefault(import('../../pwa/pages/search/configure/v8/tabs/requirements/index.vue' /* webpackChunkName: "pages/search/configure/v8/tabs/requirements/index" */))
const _104f08d6 = () => interopDefault(import('../../pwa/pages/search/configure/v8/tabs/yourcar/index.vue' /* webpackChunkName: "pages/search/configure/v8/tabs/yourcar/index" */))
const _2966fd72 = () => interopDefault(import('../../pwa/pages/search/configure/v2/pickup-dropoff/modal/modal-change-pickup-dropoff.vue' /* webpackChunkName: "pages/search/configure/v2/pickup-dropoff/modal/modal-change-pickup-dropoff" */))
const _6614d898 = () => interopDefault(import('../../pwa/pages/search/configure/v2/pickup-dropoff/modal/modal-type-of-service.vue' /* webpackChunkName: "pages/search/configure/v2/pickup-dropoff/modal/modal-type-of-service" */))
const _5cedbb4e = () => interopDefault(import('../../pwa/pages/search/configure/v2/price-lock/modal/modal-price-lock.vue' /* webpackChunkName: "pages/search/configure/v2/price-lock/modal/modal-price-lock" */))
const _2f814646 = () => interopDefault(import('../../pwa/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _655f96e0 = () => interopDefault(import('../../pwa/pages/home/index.vue' /* webpackChunkName: "" */))
const _c115b93a = () => interopDefault(import('../../pwa/pages/locations/index.vue' /* webpackChunkName: "" */))
const _6c9354ae = () => interopDefault(import('../../pwa/pages/countrys/countryV2.vue' /* webpackChunkName: "" */))
const _2d97f5e7 = () => interopDefault(import('../../pwa/pages/locations/country.vue' /* webpackChunkName: "" */))
const _31590994 = () => interopDefault(import('../../pwa/pages/places/locationV2.vue' /* webpackChunkName: "" */))
const _3a5f8279 = () => interopDefault(import('../../pwa/pages/airports/index.vue' /* webpackChunkName: "" */))
const _91323106 = () => interopDefault(import('../../pwa/pages/airports/country.vue' /* webpackChunkName: "" */))
const _47643d44 = () => interopDefault(import('../../pwa/pages/airports/location.vue' /* webpackChunkName: "" */))
const _759525d8 = () => interopDefault(import('../../pwa/pages/rentals/index.vue' /* webpackChunkName: "" */))
const _3726d150 = () => interopDefault(import('../../pwa/pages/rentals/country.vue' /* webpackChunkName: "" */))
const _866a0944 = () => interopDefault(import('../../pwa/pages/rental/locationV2.vue' /* webpackChunkName: "" */))
const _76088604 = () => interopDefault(import('../../pwa/pages/rental/airport-location.vue' /* webpackChunkName: "" */))
const _b033c31e = () => interopDefault(import('../../pwa/pages/rental/indexV2.vue' /* webpackChunkName: "" */))
const _67ad25d6 = () => interopDefault(import('../../pwa/pages/rental/index.vue' /* webpackChunkName: "" */))
const _033ec9ce = () => interopDefault(import('../../pwa/pages/rental/country.vue' /* webpackChunkName: "" */))
const _748aa142 = () => interopDefault(import('../../pwa/pages/rental/location.vue' /* webpackChunkName: "" */))
const _2e18aeca = () => interopDefault(import('../../pwa/pages/faq/index.vue' /* webpackChunkName: "" */))
const _12c7984a = () => interopDefault(import('../../pwa/pages/best-rates/index.vue' /* webpackChunkName: "" */))
const _b178a5c6 = () => interopDefault(import('../../pwa/pages/save-quote/index.vue' /* webpackChunkName: "" */))
const _b121dd2c = () => interopDefault(import('../../pwa/pages/search/list/index.vue' /* webpackChunkName: "" */))
const _d20048f8 = () => interopDefault(import('../../pwa/pages/search/filter/index.vue' /* webpackChunkName: "" */))
const _690dad1d = () => interopDefault(import('../../pwa/pages/search/detail/index.vue' /* webpackChunkName: "" */))
const _24d111f0 = () => interopDefault(import('../../pwa/pages/search/configure/index.vue' /* webpackChunkName: "" */))
const _232fbe7c = () => interopDefault(import('../../pwa/pages/search/flight/index.vue' /* webpackChunkName: "" */))
const _02f00008 = () => interopDefault(import('../../pwa/pages/search/rental-location/index.vue' /* webpackChunkName: "" */))
const _85a4b320 = () => interopDefault(import('../../pwa/pages/search/change-plan/index.vue' /* webpackChunkName: "" */))
const _40b1fc82 = () => interopDefault(import('../../pwa/pages/search/change-optionals/index.vue' /* webpackChunkName: "" */))
const _2c95260c = () => interopDefault(import('../../pwa/pages/search/change-coverages/index.vue' /* webpackChunkName: "" */))
const _914ef9a2 = () => interopDefault(import('../../pwa/pages/search/change-search/index.vue' /* webpackChunkName: "" */))
const _4b00a306 = () => interopDefault(import('../../pwa/pages/search/booking-duplicity/index.vue' /* webpackChunkName: "" */))
const _09b703b9 = () => interopDefault(import('../../pwa/pages/search/unavailable-vehicle/index.vue' /* webpackChunkName: "" */))
const _d3e9d40c = () => interopDefault(import('../../pwa/pages/search/identification/index.vue' /* webpackChunkName: "" */))
const _487288d0 = () => interopDefault(import('../../pwa/pages/search/payment/index.vue' /* webpackChunkName: "" */))
const _076cdb41 = () => interopDefault(import('../../pwa/pages/search/confirmation/index.vue' /* webpackChunkName: "" */))
const _2ebdbdf7 = () => interopDefault(import('../../pwa/components/Loads/LoadCompleteTravel/LoadCompleteTravel.vue' /* webpackChunkName: "" */))
const _769d3826 = () => interopDefault(import('../../pwa/pages/search/map/index.vue' /* webpackChunkName: "" */))
const _96345828 = () => interopDefault(import('../../pwa/pages/customer/booking-review/booking-review.vue' /* webpackChunkName: "" */))
const _2643ce5c = () => interopDefault(import('../../pwa/pages/search/recent-searches/index.vue' /* webpackChunkName: "" */))
const _25775634 = () => interopDefault(import('../../pwa/pages/customer/login.vue' /* webpackChunkName: "" */))

const _3756a401 = () => interopDefault(import('../../pwa/pages/customer/password-new.vue' /* webpackChunkName: "" */))
const _5c8eaf2c = () => interopDefault(import('../../pwa/pages/customer/account.vue' /* webpackChunkName: "" */))
const _8db28168 = () => interopDefault(import('../../pwa/pages/customer/wallet.vue' /* webpackChunkName: "" */))
const _448a522a = () => interopDefault(import('../../pwa/pages/customer/complete-travel.vue' /* webpackChunkName: "" */))
const _aea0ec34 = () => interopDefault(import('../../pwa/pages/customer/profile.vue' /* webpackChunkName: "" */))
const _34108852 = () => interopDefault(import('../../pwa/pages/customer/account-access.vue' /* webpackChunkName: "" */))
const _74ce6995 = () => interopDefault(import('../../pwa/pages/customer/myBookingsV2/bookings/bookings.vue' /* webpackChunkName: "" */))
const _0b767f1b = () => interopDefault(import('../../pwa/pages/customer/myBookingsV2/details/details.vue' /* webpackChunkName: "" */))
const _db72b00e = () => interopDefault(import('../../pwa/pages/customer/booking/change.vue' /* webpackChunkName: "" */))
const _fc29647a = () => interopDefault(import('../../pwa/pages/customer/booking/cancel.vue' /* webpackChunkName: "" */))
const _6e8d9296 = () => interopDefault(import('../../pwa/pages/customer/booking/voucher.vue' /* webpackChunkName: "" */))
const _0337f16c = () => interopDefault(import('../../pwa/pages/customer/booking/compare.vue' /* webpackChunkName: "" */))
const _e971c858 = () => interopDefault(import('../../pwa/pages/settings/index.vue' /* webpackChunkName: "" */))
const _38580a71 = () => interopDefault(import('../../pwa/pages/info/info.vue' /* webpackChunkName: "" */))
const _3c0692da = () => interopDefault(import('../../pwa/pages/info/about.vue' /* webpackChunkName: "" */))
const _572a18ad = () => interopDefault(import('../../pwa/pages/info/contact.vue' /* webpackChunkName: "" */))
const _a353b7d6 = () => interopDefault(import('../../pwa/pages/info/privacy.vue' /* webpackChunkName: "" */))
const _21dd30c8 = () => interopDefault(import('../../pwa/pages/info/cookies.vue' /* webpackChunkName: "" */))
const _3a19935a = () => interopDefault(import('../../pwa/pages/info/requirements.vue' /* webpackChunkName: "" */))
const _0e2c78da = () => interopDefault(import('../../pwa/pages/info/payment.vue' /* webpackChunkName: "" */))
const _21815307 = () => interopDefault(import('../../pwa/pages/info/registercompany.vue' /* webpackChunkName: "" */))
const _296bb7fe = () => interopDefault(import('../../pwa/pages/info/terms-conditions.vue' /* webpackChunkName: "" */))
const _34cd4ba8 = () => interopDefault(import('../../pwa/pages/info/regulamento-rent-protection.vue' /* webpackChunkName: "" */))
const _53157d27 = () => interopDefault(import('../../pwa/pages/info/code-conduct.vue' /* webpackChunkName: "" */))
const _297ecbb0 = () => interopDefault(import('../../pwa/pages/info/anti-discrimination-policy.vue' /* webpackChunkName: "" */))
const _abd18f28 = () => interopDefault(import('../../pwa/pages/app-page/index.vue' /* webpackChunkName: "" */))
const _61cd2bde = () => interopDefault(import('../../pwa/pages/best-price/index.vue' /* webpackChunkName: "" */))
const _688af62e = () => interopDefault(import('../../pwa/pages/best-price/signup.vue' /* webpackChunkName: "" */))
const _35f9ca28 = () => interopDefault(import('../../pwa/pages/offline/index.vue' /* webpackChunkName: "" */))
const _64454e9e = () => interopDefault(import('../../pwa/pages/best-deals/best-deals.vue' /* webpackChunkName: "" */))
const _5ab469e0 = () => interopDefault(import('../../pwa/pages/landing-pages/dotz/dotz.vue' /* webpackChunkName: "" */))
const _66de3281 = () => interopDefault(import('../../pwa/pages/landing-pages/livelo/index.vue' /* webpackChunkName: "" */))
const _d1c1a120 = () => interopDefault(import('../../pwa/pages/landing-pages/tudoazul/tudoazul.vue' /* webpackChunkName: "" */))
const _61361634 = () => interopDefault(import('../../pwa/pages/landing-pages/samsung/samsung.vue' /* webpackChunkName: "" */))
const _0b09a084 = () => interopDefault(import('../../pwa/pages/landing-pages/in-mais/in-mais.vue' /* webpackChunkName: "" */))
const _06fc4110 = () => interopDefault(import('../../pwa/pages/landing-pages/visa/visa.vue' /* webpackChunkName: "" */))
const _31769c72 = () => interopDefault(import('../../pwa/pages/landing-pages/allaccor/accor.vue' /* webpackChunkName: "" */))
const _68cb0f7c = () => interopDefault(import('../../pwa/pages/landing-pages/sicredi/sicredi.vue' /* webpackChunkName: "" */))
const _e25aeab0 = () => interopDefault(import('../../pwa/pages/landing-pages/premmia/premmia.vue' /* webpackChunkName: "" */))
const _1671cf48 = () => interopDefault(import('../../pwa/pages/landing-pages/sky-milhas/index.vue' /* webpackChunkName: "" */))
const _937b6d20 = () => interopDefault(import('../../pwa/pages/landing-pages/paypal-shell-box/paypal-shell-box.vue' /* webpackChunkName: "" */))
const _fac59888 = () => interopDefault(import('../../pwa/pages/promotions/blackfriday/blackfriday.vue' /* webpackChunkName: "" */))
const _406d0952 = () => interopDefault(import('../../pwa/pages/promotions/cybermonday/cybermonday.vue' /* webpackChunkName: "" */))
const _040b3a66 = () => interopDefault(import('../../pwa/pages/promotions/traveltuesday/traveltuesday.vue' /* webpackChunkName: "" */))
const _44124570 = () => interopDefault(import('../../pwa/pages/landing-pages/tv/tv.vue' /* webpackChunkName: "" */))
const _0c37f51a = () => interopDefault(import('../../pwa/pages/search/booking-amp/index.vue' /* webpackChunkName: "" */))
const _990d4950 = () => interopDefault(import('../../pwa/pages/landing-pages/juntoseseguros/index.vue' /* webpackChunkName: "" */))
const _77c1166a = () => interopDefault(import('../../pwa/pages/landing-pages/latampass/latampass.vue' /* webpackChunkName: "" */))
const _038ba412 = () => interopDefault(import('../../pwa/pages/landing-pages/oab/oab.vue' /* webpackChunkName: "" */))
const _142877f1 = () => interopDefault(import('../../pwa/pages/landing-pages/sao-joao/index.vue' /* webpackChunkName: "" */))
const _011cb930 = () => interopDefault(import('../../pwa/pages/landing-pages/bradesco-seguros/index.vue' /* webpackChunkName: "" */))
const _661459a0 = () => interopDefault(import('../../pwa/pages/landing-pages/bradesco-exclusive/index.vue' /* webpackChunkName: "" */))
const _13153551 = () => interopDefault(import('../../pwa/pages/landing-pages/bradesco-prime/index.vue' /* webpackChunkName: "" */))
const _689a163c = () => interopDefault(import('../../pwa/pages/landing-pages/car-hire-tips/index.vue' /* webpackChunkName: "" */))
const _47fbc8ac = () => interopDefault(import('../../pwa/pages/landing-pages/maio-amarelo/index.vue' /* webpackChunkName: "" */))
const _60430445 = () => interopDefault(import('../../pwa/pages/landing-pages/ferias-de-julho/index.vue' /* webpackChunkName: "" */))
const _86283cf4 = () => interopDefault(import('../../pwa/pages/landing-pages/timpramim/timpramim.vue' /* webpackChunkName: "" */))
const _79af7bd0 = () => interopDefault(import('../../pwa/pages/landing-pages/timblack/timblack.vue' /* webpackChunkName: "" */))
const _480fc40c = () => interopDefault(import('../../pwa/pages/landing-pages/itau-personnalite/itau-personnalite.vue' /* webpackChunkName: "" */))
const _699a0908 = () => interopDefault(import('../../pwa/pages/landing-pages/member-get-member/index.vue' /* webpackChunkName: "" */))
const _758f0a0f = () => interopDefault(import('../../pwa/pages/invites/index.vue' /* webpackChunkName: "" */))
const _ee21c76e = () => interopDefault(import('../../pwa/pages/landing-pages/road-trip-america-latina/index.vue' /* webpackChunkName: "" */))
const _fa154dea = () => interopDefault(import('../../pwa/pages/landing-pages/segurospromo/index.vue' /* webpackChunkName: "" */))
const _7f36259b = () => interopDefault(import('../../pwa/pages/landing-pages/lp-builder-v2/index.vue' /* webpackChunkName: "" */))
const _2c171f2e = () => interopDefault(import('../../pwa/pages/landing-pages/passagenspromo/index.vue' /* webpackChunkName: "" */))
const _0ccd2fb8 = () => interopDefault(import('../../pwa/pages/landing-pages/lp-builder/index.vue' /* webpackChunkName: "" */))
const _4a29d23c = () => interopDefault(import('../../pwa/pages/settings/cookies-preferences.vue' /* webpackChunkName: "" */))
const _3fc7eb11 = () => interopDefault(import('../../pwa/pages/monthly-rate/index.vue' /* webpackChunkName: "" */))
const _aec9bba8 = () => interopDefault(import('../../pwa/pages/landing-pages/cashback/index.vue' /* webpackChunkName: "" */))
const _18bd3d30 = () => interopDefault(import('../../pwa/pages/landing-pages/rewards/index.vue' /* webpackChunkName: "" */))
const _f4b6bc44 = () => interopDefault(import('../../pwa/pages/landing-pages/back-to-road/index.vue' /* webpackChunkName: "" */))
const _09148e4c = () => interopDefault(import('../../pwa/pages/chat-status/index.vue' /* webpackChunkName: "" */))
const _67beef68 = () => interopDefault(import('../../pwa/pages/landing-pages/one-way/index.vue' /* webpackChunkName: "" */))
const _ad636294 = () => interopDefault(import('../../pwa/pages/landing-pages/electric-car/index.vue' /* webpackChunkName: "" */))
const _39eb065c = () => interopDefault(import('../../pwa/pages/coupons/index.vue' /* webpackChunkName: "" */))
const _07a034eb = () => interopDefault(import('../../pwa/pages/customer/signup.vue' /* webpackChunkName: "" */))
const _1f286628 = () => interopDefault(import('../../pwa/pages/promotions/hotsale/hotsale.vue' /* webpackChunkName: "" */))
const _59cb157e = () => interopDefault(import('../../pwa/pages/promotions/rentsale/rentsale.vue' /* webpackChunkName: "" */))
const _2909db75 = () => interopDefault(import('../../pwa/pages/rental/countryV2.vue' /* webpackChunkName: "" */))
const _56c72840 = () => interopDefault(import('../../pwa/pages/info/promotional-terms-conditions.vue' /* webpackChunkName: "" */))
const _6432eec4 = () => interopDefault(import('../../pwa/pages/promotions/buenfin/buenfin.vue' /* webpackChunkName: "" */))
const _6949c5ce = () => interopDefault(import('../../pwa/pages/promotions/hottravel/hottravel.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/airports",
    component: _2a5a5110,
    name: "airports"
  }, {
    path: "/app-page",
    component: _cbdbf1fa,
    name: "app-page"
  }, {
    path: "/best-price",
    component: _a8ca2230,
    name: "best-price"
  }, {
    path: "/best-rates",
    component: _59c48e9c,
    name: "best-rates"
  }, {
    path: "/chat-status",
    component: _8678b756,
    name: "chat-status"
  }, {
    path: "/coupons",
    component: _6c80014a,
    name: "coupons"
  }, {
    path: "/faq",
    component: _1bd9d7a4,
    name: "faq"
  }, {
    path: "/home",
    component: _11a032b2,
    name: "home"
  }, {
    path: "/invites",
    component: _5c448c98,
    name: "invites"
  }, {
    path: "/locations",
    component: _a257b0a8,
    name: "locations"
  }, {
    path: "/monthly-rate",
    component: _01fb9628,
    name: "monthly-rate"
  }, {
    path: "/offline",
    component: _1caf4cb1,
    name: "offline"
  }, {
    path: "/rental",
    component: _06361528,
    name: "rental"
  }, {
    path: "/rentals",
    component: _a82a20c6,
    name: "rentals"
  }, {
    path: "/save-quote",
    component: _f8759c18,
    name: "save-quote"
  }, {
    path: "/settings",
    component: _7b41ea6b,
    name: "settings"
  }, {
    path: "/airports/country",
    component: _d82f2758,
    name: "airports-country"
  }, {
    path: "/airports/location",
    component: _e0061132,
    name: "airports-location"
  }, {
    path: "/best-deals/best-deals",
    component: _4890423a,
    name: "best-deals-best-deals"
  }, {
    path: "/best-price/signup",
    component: _7f699af2,
    name: "best-price-signup"
  }, {
    path: "/countrys/countryV2",
    component: _2ec6ffc5,
    name: "countrys-countryV2"
  }, {
    path: "/customer/account",
    component: _a38ba57e,
    name: "customer-account"
  }, {
    path: "/customer/account-access",
    component: _7f966720,
    name: "customer-account-access"
  }, {
    path: "/customer/bookings",
    component: _4cc39956,
    name: "customer-bookings"
  }, {
    path: "/customer/complete-travel",
    component: _77f194c2,
    name: "customer-complete-travel"
  }, {
    path: "/customer/login",
    component: _4581b906,
    name: "customer-login"
  }, {
    path: "/customer/password-new",
    component: _9bece4ec,
    name: "customer-password-new"
  }, {
    path: "/customer/password-recover",
    component: _53fa964e,
    name: "customer-password-recover"
  }, {
    path: "/customer/profile",
    component: _f59de286,
    name: "customer-profile"
  }, {
    path: "/customer/signup",
    component: _16ff3934,
    name: "customer-signup"
  }, {
    path: "/customer/wallet",
    component: _6ef478d6,
    name: "customer-wallet"
  }, {
    path: "/info/about",
    component: _65e644f1,
    name: "info-about"
  }, {
    path: "/info/anti-discrimination-policy",
    component: _0487ec71,
    name: "info-anti-discrimination-policy"
  }, {
    path: "/info/code-conduct",
    component: _06c49330,
    name: "info-code-conduct"
  }, {
    path: "/info/contact",
    component: _f034bdf8,
    name: "info-contact"
  }, {
    path: "/info/cookies",
    component: _1fcceff3,
    name: "info-cookies"
  }, {
    path: "/info/info",
    component: _6b3e397a,
    name: "info-info"
  }, {
    path: "/info/opening-hours",
    component: _a68c557e,
    name: "info-opening-hours"
  }, {
    path: "/info/payment",
    component: _29a54bea,
    name: "info-payment"
  }, {
    path: "/info/privacy",
    component: _41dca728,
    name: "info-privacy"
  }, {
    path: "/info/promotional-terms-conditions",
    component: _8d4f602e,
    name: "info-promotional-terms-conditions"
  }, {
    path: "/info/registercompany",
    component: _2576945e,
    name: "info-registercompany"
  }, {
    path: "/info/regulamento-rent-protection",
    component: _4470427f,
    name: "info-regulamento-rent-protection"
  }, {
    path: "/info/requirements",
    component: _d2bb6748,
    name: "info-requirements"
  }, {
    path: "/info/terms-conditions",
    component: _65fd0d8a,
    name: "info-terms-conditions"
  }, {
    path: "/landing-pages/back-to-road",
    component: _d570ce96,
    name: "landing-pages-back-to-road"
  }, {
    path: "/landing-pages/bradesco-exclusive",
    component: _7537e672,
    name: "landing-pages-bradesco-exclusive"
  }, {
    path: "/landing-pages/bradesco-prime",
    component: _74545b30,
    name: "landing-pages-bradesco-prime"
  }, {
    path: "/landing-pages/bradesco-seguros",
    component: _047f56bf,
    name: "landing-pages-bradesco-seguros"
  }, {
    path: "/landing-pages/car-hire-tips",
    component: _4d55fa45,
    name: "landing-pages-car-hire-tips"
  }, {
    path: "/landing-pages/cashback",
    component: _a9fdc6fa,
    name: "landing-pages-cashback"
  }, {
    path: "/landing-pages/electric-car",
    component: _8e1d74e6,
    name: "landing-pages-electric-car"
  }, {
    path: "/landing-pages/ferias-de-julho",
    component: _05960a0e,
    name: "landing-pages-ferias-de-julho"
  }, {
    path: "/landing-pages/juntoseseguros",
    component: _6639f86f,
    name: "landing-pages-juntoseseguros"
  }, {
    path: "/landing-pages/livelo",
    component: _4e5cd698,
    name: "landing-pages-livelo"
  }, {
    path: "/landing-pages/lp-builder",
    component: _0b2bae8a,
    name: "landing-pages-lp-builder"
  }, {
    path: "/landing-pages/lp-builder-v2",
    component: _63f209a4,
    name: "landing-pages-lp-builder-v2"
  }, {
    path: "/landing-pages/maio-amarelo",
    component: _28b5dafe,
    name: "landing-pages-maio-amarelo"
  }, {
    path: "/landing-pages/member-get-member",
    component: _0642c091,
    name: "landing-pages-member-get-member"
  }, {
    path: "/landing-pages/one-way",
    component: _571331d6,
    name: "landing-pages-one-way"
  }, {
    path: "/landing-pages/passagenspromo",
    component: _42508776,
    name: "landing-pages-passagenspromo"
  }, {
    path: "/landing-pages/rewards",
    component: _08117f9e,
    name: "landing-pages-rewards"
  }, {
    path: "/landing-pages/road-trip-america-latina",
    component: _6ddb88a0,
    name: "landing-pages-road-trip-america-latina"
  }, {
    path: "/landing-pages/sao-joao",
    component: _168e7248,
    name: "landing-pages-sao-joao"
  }, {
    path: "/landing-pages/segurospromo",
    component: _dacf603c,
    name: "landing-pages-segurospromo"
  }, {
    path: "/landing-pages/sky-milhas",
    component: _17428fdf,
    name: "landing-pages-sky-milhas"
  }, {
    path: "/locations/country",
    component: _3d71e820,
    name: "locations-country"
  }, {
    path: "/locations/location",
    component: _231b696a,
    name: "locations-location"
  }, {
    path: "/monthly-rate/benefits",
    component: _08bf264e,
    name: "monthly-rate-benefits"
  }, {
    path: "/monthly-rate/newsletter",
    component: _19458216,
    name: "monthly-rate-newsletter"
  }, {
    path: "/monthly-rate/promotional-banner",
    component: _a31cc2b0,
    name: "monthly-rate-promotional-banner"
  }, {
    path: "/monthly-rate/steps",
    component: _7ea93a80,
    name: "monthly-rate-steps"
  }, {
    path: "/NotFound/NotFound",
    component: _513f69cc,
    name: "NotFound-NotFound"
  }, {
    path: "/places/locationV2",
    component: _c9fadd82,
    name: "places-locationV2"
  }, {
    path: "/rental/airport-location",
    component: _0fa7314d,
    name: "rental-airport-location"
  }, {
    path: "/rental/country",
    component: _23492ca0,
    name: "rental-country"
  }, {
    path: "/rental/countryV2",
    component: _058b604c,
    name: "rental-countryV2"
  }, {
    path: "/rental/indexV2",
    component: _d03e25f0,
    name: "rental-indexV2"
  }, {
    path: "/rental/location",
    component: _f82cb4ea,
    name: "rental-location"
  }, {
    path: "/rental/location-v2",
    component: _cbec5ca4,
    name: "rental-location-v2"
  }, {
    path: "/rental/locationV2",
    component: _707a1167,
    name: "rental-locationV2"
  }, {
    path: "/rentals-v2/locationV2",
    component: _2464b5de,
    name: "rentals-v2-locationV2"
  }, {
    path: "/rentals/country",
    component: _1868c8be,
    name: "rentals-country"
  }, {
    path: "/rentals/index-v2",
    component: _0814765c,
    name: "rentals-index-v2"
  }, {
    path: "/search/booking-amp",
    component: _b3229a1e,
    name: "search-booking-amp"
  }, {
    path: "/search/booking-duplicity",
    component: _495f21d8,
    name: "search-booking-duplicity"
  }, {
    path: "/search/change-coverages",
    component: _340ebd83,
    name: "search-change-coverages"
  }, {
    path: "/search/change-optionals",
    component: _e9e965ea,
    name: "search-change-optionals"
  }, {
    path: "/search/change-plan",
    component: _57646447,
    name: "search-change-plan"
  }, {
    path: "/search/change-search",
    component: _c251b174,
    name: "search-change-search"
  }, {
    path: "/search/configure",
    component: _007b5987,
    name: "search-configure"
  }, {
    path: "/search/confirmation",
    component: _97e44f6c,
    name: "search-confirmation"
  }, {
    path: "/search/detail",
    component: _25613634,
    name: "search-detail"
  }, {
    path: "/search/filter",
    component: _1b41934d,
    name: "search-filter"
  }, {
    path: "/search/flight",
    component: _b11d1376,
    name: "search-flight"
  }, {
    path: "/search/identification",
    component: _c33e167a,
    name: "search-identification"
  }, {
    path: "/search/list",
    component: _5b1e2773,
    name: "search-list"
  }, {
    path: "/search/map",
    component: _531ebcfd,
    name: "search-map"
  }, {
    path: "/search/payment",
    component: _4c67ca27,
    name: "search-payment"
  }, {
    path: "/search/recent-searches",
    component: _2177d9ae,
    name: "search-recent-searches"
  }, {
    path: "/search/rental-location",
    component: _0555fa5f,
    name: "search-rental-location"
  }, {
    path: "/search/unavailable-vehicle",
    component: _1959fa90,
    name: "search-unavailable-vehicle"
  }, {
    path: "/settings/cookies-preferences",
    component: _4c8fcc93,
    name: "settings-cookies-preferences"
  }, {
    path: "/coupons/components/benefits",
    component: _1292fe3e,
    name: "coupons-components-benefits"
  }, {
    path: "/coupons/components/blogs",
    component: _15242122,
    name: "coupons-components-blogs"
  }, {
    path: "/coupons/components/faq",
    component: _27d1fa67,
    name: "coupons-components-faq"
  }, {
    path: "/coupons/components/news-letter",
    component: _57f88531,
    name: "coupons-components-news-letter"
  }, {
    path: "/customer/booking-review/booking-review",
    component: _a557e4fa,
    name: "customer-booking-review-booking-review"
  }, {
    path: "/customer/booking-review/review-error",
    component: _031ed46c,
    name: "customer-booking-review-review-error"
  }, {
    path: "/customer/booking-review/review-vehicle-card",
    component: _c2c05a82,
    name: "customer-booking-review-review-vehicle-card"
  }, {
    path: "/customer/booking/cancel",
    component: _1b89f90c,
    name: "customer-booking-cancel"
  }, {
    path: "/customer/booking/change",
    component: _2be55342,
    name: "customer-booking-change"
  }, {
    path: "/customer/booking/compare",
    component: _c522a17a,
    name: "customer-booking-compare"
  }, {
    path: "/customer/booking/details",
    component: _3cdc6e20,
    name: "customer-booking-details"
  }, {
    path: "/customer/booking/voucher",
    component: _62eff48c,
    name: "customer-booking-voucher"
  }, {
    path: "/landing-pages/allaccor/accor",
    component: _2caaa7c4,
    name: "landing-pages-allaccor-accor"
  }, {
    path: "/landing-pages/back-to-road/data_2020",
    component: _479481bb,
    name: "landing-pages-back-to-road-data_2020"
  }, {
    path: "/landing-pages/dotz/dotz",
    component: _6c447659,
    name: "landing-pages-dotz-dotz"
  }, {
    path: "/landing-pages/electric-car/Benefits",
    component: _4b5cfb34,
    name: "landing-pages-electric-car-Benefits"
  }, {
    path: "/landing-pages/electric-car/Categories",
    component: _36bb0754,
    name: "landing-pages-electric-car-Categories"
  }, {
    path: "/landing-pages/in-mais/in-mais",
    component: _44d48047,
    name: "landing-pages-in-mais-in-mais"
  }, {
    path: "/landing-pages/in-mais/regulations",
    component: _32396f65,
    name: "landing-pages-in-mais-regulations"
  }, {
    path: "/landing-pages/ipiranga/ipiranga",
    component: _722fbc19,
    name: "landing-pages-ipiranga-ipiranga"
  }, {
    path: "/landing-pages/itau-personnalite/itau-personnalite",
    component: _0a64d003,
    name: "landing-pages-itau-personnalite-itau-personnalite"
  }, {
    path: "/landing-pages/latampass/latampass",
    component: _5c7cfa73,
    name: "landing-pages-latampass-latampass"
  }, {
    path: "/landing-pages/oab/oab",
    component: _7e3e8d9b,
    name: "landing-pages-oab-oab"
  }, {
    path: "/landing-pages/one-way/Categories",
    component: _22c720cc,
    name: "landing-pages-one-way-Categories"
  }, {
    path: "/landing-pages/paypal-shell-box/paypal-shell-box",
    component: _69d42c8e,
    name: "landing-pages-paypal-shell-box-paypal-shell-box"
  }, {
    path: "/landing-pages/premmia/premmia",
    component: _4da8499e,
    name: "landing-pages-premmia-premmia"
  }, {
    path: "/landing-pages/samsung/samsung",
    component: _19be456f,
    name: "landing-pages-samsung-samsung"
  }, {
    path: "/landing-pages/sicredi/sicredi",
    component: _99b73ff6,
    name: "landing-pages-sicredi-sicredi"
  }, {
    path: "/landing-pages/timblack/timblack",
    component: _da1263ce,
    name: "landing-pages-timblack-timblack"
  }, {
    path: "/landing-pages/timpramim/timpramim",
    component: _bcb074e2,
    name: "landing-pages-timpramim-timpramim"
  }, {
    path: "/landing-pages/tudoazul/tudoazul",
    component: _9f32fc8e,
    name: "landing-pages-tudoazul-tudoazul"
  }, {
    path: "/landing-pages/tv/tv",
    component: _6f58058e,
    name: "landing-pages-tv-tv"
  }, {
    path: "/landing-pages/visa/visa",
    component: _beca274e,
    name: "landing-pages-visa-visa"
  }, {
    path: "/monthly-rate/annual-subscription/annual-subscription",
    component: _35b00046,
    name: "monthly-rate-annual-subscription-annual-subscription"
  }, {
    path: "/monthly-rate/monthly-rate-faq/monthly-rate-faq",
    component: _270b81b6,
    name: "monthly-rate-monthly-rate-faq-monthly-rate-faq"
  }, {
    path: "/promotions/blackfriday/blackfriday",
    component: _355dd0d3,
    name: "promotions-blackfriday-blackfriday"
  }, {
    path: "/promotions/blackfriday/blackfriday-2019",
    component: _312e5650,
    name: "promotions-blackfriday-blackfriday-2019"
  }, {
    path: "/promotions/blackfriday/data",
    component: _0e46222a,
    name: "promotions-blackfriday-data"
  }, {
    path: "/promotions/blackfriday/data_2020",
    component: _1a29b9e9,
    name: "promotions-blackfriday-data_2020"
  }, {
    path: "/promotions/buenfin/buenfin",
    component: _9535a696,
    name: "promotions-buenfin-buenfin"
  }, {
    path: "/promotions/buenfin/buenfin-2019",
    component: _682f17ae,
    name: "promotions-buenfin-buenfin-2019"
  }, {
    path: "/promotions/buenfin/data",
    component: _7caa405b,
    name: "promotions-buenfin-data"
  }, {
    path: "/promotions/buenfin/data_2020",
    component: _e1745b50,
    name: "promotions-buenfin-data_2020"
  }, {
    path: "/promotions/cybermonday/cybermonday",
    component: _19a4b32e,
    name: "promotions-cybermonday-cybermonday"
  }, {
    path: "/promotions/cybermonday/data",
    component: _2fb3a896,
    name: "promotions-cybermonday-data"
  }, {
    path: "/promotions/cybermonday/data_2020",
    component: _95ce2184,
    name: "promotions-cybermonday-data_2020"
  }, {
    path: "/promotions/hotsale/data",
    component: _2abe8f20,
    name: "promotions-hotsale-data"
  }, {
    path: "/promotions/hotsale/hotsale",
    component: _06a70a3f,
    name: "promotions-hotsale-hotsale"
  }, {
    path: "/promotions/hottravel/data",
    component: _518e8033,
    name: "promotions-hottravel-data"
  }, {
    path: "/promotions/hottravel/hottravel",
    component: _6a1a8665,
    name: "promotions-hottravel-hottravel"
  }, {
    path: "/promotions/rentsale/data",
    component: _3469f9b0,
    name: "promotions-rentsale-data"
  }, {
    path: "/promotions/rentsale/rentsale",
    component: _5c310fd5,
    name: "promotions-rentsale-rentsale"
  }, {
    path: "/promotions/traveltuesday/data",
    component: _8c469d02,
    name: "promotions-traveltuesday-data"
  }, {
    path: "/promotions/traveltuesday/data_2020",
    component: _5b3f1098,
    name: "promotions-traveltuesday-data_2020"
  }, {
    path: "/promotions/traveltuesday/traveltuesday",
    component: _070d1806,
    name: "promotions-traveltuesday-traveltuesday"
  }, {
    path: "/search/configure/v2",
    component: _4b75a25a,
    name: "search-configure-v2"
  }, {
    path: "/search/configure/v8",
    component: _6e5c5a40,
    name: "search-configure-v8"
  }, {
    path: "/search/list/v1",
    component: _1e4a4a2d,
    name: "search-list-v1"
  }, {
    path: "/search/list/v2",
    component: _99a20624,
    name: "search-list-v2"
  }, {
    path: "/customer/booking-review/form/review-form",
    component: _35966276,
    name: "customer-booking-review-form-review-form"
  }, {
    path: "/customer/myBookingsV2/bookings/bookings",
    component: _ebb13a44,
    name: "customer-myBookingsV2-bookings-bookings"
  }, {
    path: "/customer/myBookingsV2/details/details",
    component: _afc192b8,
    name: "customer-myBookingsV2-details-details"
  }, {
    path: "/landing-pages/one-way/MostPopularDestinations/MostPopularDestinations",
    component: _ceea442c,
    name: "landing-pages-one-way-MostPopularDestinations-MostPopularDestinations"
  }, {
    path: "/search/configure/v2/category-upgrade",
    component: _73593590,
    name: "search-configure-v2-category-upgrade"
  }, {
    path: "/search/configure/v2/extra-items",
    component: _77f1ab0e,
    name: "search-configure-v2-extra-items"
  }, {
    path: "/search/configure/v2/flight-card-info",
    component: _046d184e,
    name: "search-configure-v2-flight-card-info"
  }, {
    path: "/search/configure/v2/flight-number",
    component: _01ea725e,
    name: "search-configure-v2-flight-number"
  }, {
    path: "/search/configure/v2/pickup-dropoff",
    component: _2776e83a,
    name: "search-configure-v2-pickup-dropoff"
  }, {
    path: "/search/configure/v2/plans-protections",
    component: _02be0822,
    name: "search-configure-v2-plans-protections"
  }, {
    path: "/search/configure/v2/price-lock",
    component: _c39a84cc,
    name: "search-configure-v2-price-lock"
  }, {
    path: "/search/configure/v2/rent-protection",
    component: _0014e0b8,
    name: "search-configure-v2-rent-protection"
  }, {
    path: "/search/configure/v2/rent-protection-variant",
    component: _f6fabd20,
    name: "search-configure-v2-rent-protection-variant"
  }, {
    path: "/search/configure/v2/rental-cover",
    component: _43b8450e,
    name: "search-configure-v2-rental-cover"
  }, {
    path: "/search/configure/v2/your-reservation",
    component: _05263fdd,
    name: "search-configure-v2-your-reservation"
  }, {
    path: "/search/configure/v2/yourcar",
    component: _0cdc8ee8,
    name: "search-configure-v2-yourcar"
  }, {
    path: "/search/list/v2/list-results-count",
    component: _53d0c460,
    name: "search-list-v2-list-results-count"
  }, {
    path: "/search/list/v2/loading-bar",
    component: _272155be,
    name: "search-list-v2-loading-bar"
  }, {
    path: "/customer/booking-review/form/inputs/checkbox-input",
    component: _0620395c,
    name: "customer-booking-review-form-inputs-checkbox-input"
  }, {
    path: "/customer/booking-review/form/inputs/radio-input",
    component: _2a7ce8d6,
    name: "customer-booking-review-form-inputs-radio-input"
  }, {
    path: "/customer/booking-review/form/inputs/review-button",
    component: _5ccac0b6,
    name: "customer-booking-review-form-inputs-review-button"
  }, {
    path: "/customer/booking-review/form/inputs/score-input",
    component: _07f5faa6,
    name: "customer-booking-review-form-inputs-score-input"
  }, {
    path: "/customer/booking-review/form/inputs/text-input",
    component: _31209d3c,
    name: "customer-booking-review-form-inputs-text-input"
  }, {
    path: "/customer/booking-review/form/steps/step-booking-used",
    component: _5fb02954,
    name: "customer-booking-review-form-steps-step-booking-used"
  }, {
    path: "/customer/booking-review/form/steps/step-finish",
    component: _779a0ff8,
    name: "customer-booking-review-form-steps-step-finish"
  }, {
    path: "/customer/booking-review/form/steps/step-new-booking",
    component: _2e8370d2,
    name: "customer-booking-review-form-steps-step-new-booking"
  }, {
    path: "/customer/booking-review/form/steps/step-no-show",
    component: _0fe31cf4,
    name: "customer-booking-review-form-steps-step-no-show"
  }, {
    path: "/customer/booking-review/form/steps/step-nps-questions",
    component: _60fe149c,
    name: "customer-booking-review-form-steps-step-nps-questions"
  }, {
    path: "/customer/booking-review/form/steps/step-rental-score",
    component: _b2078164,
    name: "customer-booking-review-form-steps-step-rental-score"
  }, {
    path: "/customer/booking-review/form/steps/step-support",
    component: _80ceab8c,
    name: "customer-booking-review-form-steps-step-support"
  }, {
    path: "/customer/booking-review/form/steps/step-whats-wrong",
    component: _0dfb0d0c,
    name: "customer-booking-review-form-steps-step-whats-wrong"
  }, {
    path: "/search/configure/v2/extra-items/extra-confirmation-modal",
    component: _7d939666,
    name: "search-configure-v2-extra-items-extra-confirmation-modal"
  }, {
    path: "/search/configure/v2/extra-items/extra-info-modal",
    component: _25b4349f,
    name: "search-configure-v2-extra-items-extra-info-modal"
  }, {
    path: "/search/configure/v2/extra-items/extra-item-card",
    component: _530cb06e,
    name: "search-configure-v2-extra-items-extra-item-card"
  }, {
    path: "/search/configure/v2/flight-number/flight-card",
    component: _14f19d8c,
    name: "search-configure-v2-flight-number-flight-card"
  }, {
    path: "/search/configure/v2/flight-number/flight-modal",
    component: _1bd21361,
    name: "search-configure-v2-flight-number-flight-modal"
  }, {
    path: "/search/configure/v2/plans-protections/no-protection-modal",
    component: _0e3bfdd6,
    name: "search-configure-v2-plans-protections-no-protection-modal"
  }, {
    path: "/search/configure/v8/steps/identification",
    component: _13488ab8,
    name: "search-configure-v8-steps-identification"
  }, {
    path: "/search/configure/v8/steps/payment",
    component: _0277f92c,
    name: "search-configure-v8-steps-payment"
  }, {
    path: "/search/configure/v8/steps/paymentV2",
    component: _f67bfe70,
    name: "search-configure-v8-steps-paymentV2"
  }, {
    path: "/search/configure/v8/tabs/rental",
    component: _54ba3126,
    name: "search-configure-v8-tabs-rental"
  }, {
    path: "/search/configure/v8/tabs/requirements",
    component: _55ca7f5c,
    name: "search-configure-v8-tabs-requirements"
  }, {
    path: "/search/configure/v8/tabs/yourcar",
    component: _104f08d6,
    name: "search-configure-v8-tabs-yourcar"
  }, {
    path: "/search/configure/v2/pickup-dropoff/modal/modal-change-pickup-dropoff",
    component: _2966fd72,
    name: "search-configure-v2-pickup-dropoff-modal-modal-change-pickup-dropoff"
  }, {
    path: "/search/configure/v2/pickup-dropoff/modal/modal-type-of-service",
    component: _6614d898,
    name: "search-configure-v2-pickup-dropoff-modal-modal-type-of-service"
  }, {
    path: "/search/configure/v2/price-lock/modal/modal-price-lock",
    component: _5cedbb4e,
    name: "search-configure-v2-price-lock-modal-modal-price-lock"
  }, {
    path: "/",
    component: _2f814646,
    name: "index"
  }, {
    path: "/pt-br/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.pt-br"
  }, {
    path: "/pt-br/localidades",
    component: _c115b93a,
    name: "routes.locations.pt-br"
  }, {
    path: "/pt-br/localidades/portugal",
    component: _6c9354ae,
    name: "routes.locations.countryV2PT.pt-br"
  }, {
    path: "/pt-br/localidades/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.pt-br"
  }, {
    path: "/pt-br/localidades/brasil/sao-paulo-sao-paulo",
    component: _31590994,
    name: "routes.locations.locationV2SP.pt-br"
  }, {
    path: "/pt-br/localidades/mexico/cancun-qr-quintana-roo",
    component: _31590994,
    name: "routes.locations.locationV2Cancun.pt-br"
  }, {
    path: "/pt-br/localidades/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.pt-br"
  }, {
    path: "/pt-br/aeroportos",
    component: _3a5f8279,
    name: "routes.airports.pt-br"
  }, {
    path: "/pt-br/aeroportos/brasil",
    component: _6c9354ae,
    name: "routes.airports.countryV2BR.pt-br"
  }, {
    path: "/pt-br/aeroportos/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.pt-br"
  }, {
    path: "/pt-br/aeroportos/brasil/rec-guararapes-gilberto-freyre-recife-pernambuco",
    component: _31590994,
    name: "routes.airports.locationV2Guararapes.pt-br"
  }, {
    path: "/pt-br/aeroportos/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.pt-br"
  }, {
    path: "/pt-br/locadoras",
    component: _759525d8,
    name: "routes.rentals.pt-br"
  }, {
    path: "/pt-br/locadoras/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.pt-br"
  }, {
    path: "/pt-br/locadora/movida/brasil/recife-pernambuco",
    component: _866a0944,
    name: "routes.rental.locationV2REC.pt-br"
  }, {
    path: "/pt-br/locadora/dollar/estados-unidos/aeroportos/mco-orlando-florida",
    component: _866a0944,
    name: "routes.rental.locationV2MCO.pt-br"
  }, {
    path: "/pt-br/locadora/:companySlug/:countrySlug/aeroportos/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.pt-br"
  }, {
    path: "/pt-br/locadora/foco",
    component: _b033c31e,
    name: "routes.rental.rentalV2FOCO.pt-br"
  }, {
    path: "/pt-br/locadora/localiza",
    component: _b033c31e,
    name: "routes.rental.rentalV2LOC.pt-br"
  }, {
    path: "/pt-br/locadora/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.pt-br"
  }, {
    path: "/pt-br/locadora/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.pt-br"
  }, {
    path: "/pt-br/locadora/hertz/brasil/sao-paulo-sao-paulo",
    component: _866a0944,
    name: "routes.rental.locationV2.pt-br"
  }, {
    path: "/pt-br/locadora/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.pt-br"
  }, {
    path: "/pt-br/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.pt-br"
  }, {
    path: "/pt-br/tarifas",
    component: _12c7984a,
    name: "routes.best-rates.pt-br"
  }, {
    path: "/pt-br/orcamento/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.pt-br"
  }, {
    path: "/pt-br/reserva/listar/:params",
    component: _b121dd2c,
    name: "routes.search.list.pt-br"
  }, {
    path: "/pt-br/reserva/filtrar",
    component: _d20048f8,
    name: "routes.booking.filters.pt-br"
  }, {
    path: "/pt-br/reserva/visualizar/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.pt-br"
  }, {
    path: "/pt-br/reserva/configurar/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.pt-br"
  }, {
    path: "/pt-br/informacao-de-voo",
    component: _232fbe7c,
    name: "routes.configure.flight.pt-br"
  }, {
    path: "/pt-br/alterar-loja/:type",
    component: _02f00008,
    name: "routes.configure.change-store.pt-br"
  }, {
    path: "/pt-br/alterar-plano",
    component: _85a4b320,
    name: "routes.configure.change-plan.pt-br"
  }, {
    path: "/pt-br/alterar-opcionais",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.pt-br"
  }, {
    path: "/pt-br/alterar-protecoes",
    component: _2c95260c,
    name: "routes.configure.change-coverages.pt-br"
  }, {
    path: "/pt-br/alterar-busca",
    component: _914ef9a2,
    name: "routes.search.change-search.pt-br"
  }, {
    path: "/pt-br/duplicidade-reserva",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.pt-br"
  }, {
    path: "/pt-br/reserva/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.pt-br"
  }, {
    path: "/pt-br/reserva/identificacao",
    component: _d3e9d40c,
    name: "routes.booking.identification.pt-br"
  }, {
    path: "/pt-br/reserva/pagamento/:code",
    component: _487288d0,
    name: "routes.booking.payment.pt-br"
  }, {
    path: "/pt-br/reserva/pagamento/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.pt-br"
  }, {
    path: "/pt-br/reserva/confirmacao/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.pt-br"
  }, {
    path: "/pt-br/reserva/confirmacao/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.pt-br"
  }, {
    path: "/pt-br/cliente/complete-sua-viagem/complete",
    component: _2ebdbdf7,
    name: "routes.booking.confirmation.complete.pt-br"
  }, {
    path: "/pt-br/reserva/mapa/:params",
    component: _769d3826,
    name: "routes.booking.map.pt-br"
  }, {
    path: "/pt-br/cliente/minhas-reservas/avaliacao/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.pt-br"
  }, {
    path: "/pt-br/alugar/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.pt-br"
  }, {
    path: "/pt-br/alugar/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.pt-br"
  }, {
    path: "/pt-br/pesquisas-recentes",
    component: _2643ce5c,
    name: "routes.recentSearches.pt-br"
  }, {
    path: "/pt-br/cliente",
    component: _25775634,
    name: "routes.customer.login.pt-br"
  }, {
    path: "/pt-br/cliente/cadastro",
    redirect: (route) => ({ path: '/pt-br/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.pt-br"
  }, {
    path: "/pt-br/cliente/recuperar-senha",
    redirect: (route) => ({ path: '/pt-br/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.pt-br"
  }, {
    path: "/pt-br/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.pt-br"
  }, {
    path: "/pt-br/cliente/minha-conta",
    component: _5c8eaf2c,
    name: "routes.customer.account.pt-br"
  }, {
    path: "/pt-br/cliente/minha-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.pt-br"
  }, {
    path: "/pt-br/minha-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.pt-br"
  }, {
    path: "/pt-br/cliente/complete-sua-viagem",
    component: _448a522a,
    name: "routes.customer.complete-travel.pt-br"
  }, {
    path: "/pt-br/cliente/minha-conta/perfil",
    component: _aea0ec34,
    name: "routes.customer.profile.pt-br"
  }, {
    path: "/pt-br/cliente/minha-conta/informacoes-de-acesso",
    component: _34108852,
    name: "routes.customer.account-access.pt-br"
  }, {
    path: "/pt-br/cliente/minha-conta/minhas-reservas",
    component: _74ce6995,
    name: "routes.customer.bookings.pt-br"
  }, {
    path: "/pt-br/cliente/minhas-reservas",
    component: _74ce6995,
    name: "routes.customer.bookings.alternative.pt-br"
  }, {
    path: "/pt-br/cliente/minha-conta/minhas-reservas/antigas",
    component: _74ce6995,
    name: "routes.customer.bookings.old.pt-br"
  }, {
    path: "/pt-br/cliente/minhas-reservas/reserva/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.pt-br"
  }, {
    path: "/pt-br/cliente/minhas-reservas/reserva/alterar/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.pt-br"
  }, {
    path: "/pt-br/cliente/minhas-reservas/reserva/cancelar/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.pt-br"
  }, {
    path: "/pt-br/cliente/minhas-reservas/reserva/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.pt-br"
  }, {
    path: "/pt-br/cliente/minhas-reservas/reserva/comparar/:bookingCode",
    component: _0337f16c,
    name: "routes.customer.booking.compare.pt-br"
  }, {
    path: "/pt-br/configuracoes",
    component: _e971c858,
    name: "routes.settings.pt-br"
  }, {
    path: "/pt-br/info",
    component: _38580a71,
    name: "routes.info.pt-br"
  }, {
    path: "/pt-br/info/sobre-nos",
    component: _3c0692da,
    name: "routes.info.about.pt-br"
  }, {
    path: "/pt-br/info/contato",
    component: _572a18ad,
    name: "routes.info.contact.pt-br"
  }, {
    path: "/pt-br/info/privacidade",
    component: _a353b7d6,
    name: "routes.info.privacy.pt-br"
  }, {
    path: "/pt-br/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.pt-br"
  }, {
    path: "/pt-br/info/requisitos-para-alugar-veiculos",
    component: _3a19935a,
    name: "routes.info.requirements.pt-br"
  }, {
    path: "/pt-br/info/instrucoes-pagamento",
    component: _0e2c78da,
    name: "routes.info.payment.pt-br"
  }, {
    path: "/pt-br/info/cadastro-de-locadora",
    component: _21815307,
    name: "routes.info.registercompany.pt-br"
  }, {
    path: "/pt-br/info/termos-e-condicoes",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.pt-br"
  }, {
    path: "/pt-br/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.pt-br"
  }, {
    path: "/pt-br/info/codigo-conduta",
    component: _53157d27,
    name: "routes.info.code-conduct.pt-br"
  }, {
    path: "/pt-br/info/politica-antidiscriminatoria",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.pt-br"
  }, {
    path: "/pt-br/app",
    component: _abd18f28,
    name: "routes.app-page.pt-br"
  }, {
    path: "/pt-br/melhorpreco",
    component: _61cd2bde,
    name: "routes.best-price.pt-br"
  }, {
    path: "/pt-br/melhorpreco/cadastro",
    component: _688af62e,
    name: "routes.best-price.signup.pt-br"
  }, {
    path: "/pt-br/offline",
    component: _35f9ca28,
    name: "routes.offline.pt-br"
  }, {
    path: "/pt-br/promocoes",
    component: _64454e9e,
    name: "routes.best-deals.pt-br"
  }, {
    path: "/pt-br/dotz",
    component: _5ab469e0,
    meta: {"locales":["pt-br"]},
    name: "routes.dotz.pt-br"
  }, {
    path: "/pt-br/livelo",
    component: _66de3281,
    name: "routes.livelo.pt-br"
  }, {
    path: "/pt-br/tudoazul",
    component: _d1c1a120,
    name: "routes.tudoazul.pt-br"
  }, {
    path: "/pt-br/samsung",
    component: _61361634,
    name: "routes.samsung.pt-br"
  }, {
    path: "/pt-br/inmais",
    component: _0b09a084,
    name: "routes.inmais.pt-br"
  }, {
    path: "/pt-br/visa",
    component: _06fc4110,
    name: "routes.visa.pt-br"
  }, {
    path: "/pt-br/allaccor",
    component: _31769c72,
    name: "routes.allaccor.pt-br"
  }, {
    path: "/pt-br/sicredi",
    component: _68cb0f7c,
    name: "routes.sicredi.pt-br"
  }, {
    path: "/pt-br/premmia",
    component: _e25aeab0,
    name: "routes.premmia.pt-br"
  }, {
    path: "/pt-br/skymilhas",
    component: _1671cf48,
    name: "routes.skymilhas.pt-br"
  }, {
    path: "/pt-br/paypal-shell-box",
    component: _937b6d20,
    name: "routes.paypal-shell-box.pt-br"
  }, {
    path: "/pt-br/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.pt-br"
  }, {
    path: "/pt-br/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.pt-br"
  }, {
    path: "/pt-br/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.pt-br"
  }, {
    path: "/pt-br/tv",
    component: _44124570,
    name: "routes.tv.pt-br"
  }, {
    path: "/pt-br/reserva/orcamento/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.pt-br"
  }, {
    path: "/pt-br/busca-amp",
    component: _0c37f51a,
    name: "routes.booking.search-amp.pt-br"
  }, {
    path: "/pt-br/juntoseseguros",
    component: _990d4950,
    name: "routes.juntoseseguros.pt-br"
  }, {
    path: "/pt-br/latampass",
    component: _77c1166a,
    meta: {"locales":["pt-br"]},
    name: "routes.latampass.pt-br"
  }, {
    path: "/pt-br/multiplus",
    redirect: "/pt-br/latampass",
    name: "routes.multiplus.pt-br"
  }, {
    path: "/pt-br/oab",
    component: _038ba412,
    name: "routes.oab.pt-br"
  }, {
    path: "/pt-br/promocoes/sao-joao",
    component: _142877f1,
    name: "routes.sao-joao.pt-br"
  }, {
    path: "/pt-br/bradescoseguros",
    component: _011cb930,
    name: "routes.bradesco-seguros.pt-br"
  }, {
    path: "/pt-br/bradescoexclusive",
    component: _661459a0,
    name: "routes.bradesco-exclusive.pt-br"
  }, {
    path: "/pt-br/bradescoprime",
    component: _13153551,
    name: "routes.bradesco-prime.pt-br"
  }, {
    path: "/pt-br/dicas-para-alugar-carro",
    component: _689a163c,
    name: "routes.car-hire-tips.pt-br"
  }, {
    path: "/pt-br/maio-amarelo",
    component: _47fbc8ac,
    name: "routes.maio-amarelo.pt-br"
  }, {
    path: "/pt-br/promocoes/ferias-de-julho",
    component: _60430445,
    name: "routes.ferias-de-julho.pt-br"
  }, {
    path: "/pt-br/timpramim",
    component: _86283cf4,
    name: "routes.timpramim.pt-br"
  }, {
    path: "/pt-br/timblack",
    component: _79af7bd0,
    name: "routes.timblack.pt-br"
  }, {
    path: "/pt-br/itau-personnalite",
    component: _480fc40c,
    meta: {"locales":["pt-br"]},
    name: "routes.itau-personnalite.pt-br"
  }, {
    path: "/pt-br/indique-amigos",
    component: _699a0908,
    name: "routes.member-get-member.pt-br"
  }, {
    path: "/pt-br/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.pt-br"
  }, {
    path: "/pt-br/road-trip-america-latina",
    component: _ee21c76e,
    name: "routes.roadtripamericalatina.pt-br"
  }, {
    path: "/pt-br/segurospromo",
    component: _fa154dea,
    name: "routes.segurospromo.pt-br"
  }, {
    path: "/pt-br/tap-miles-and-go",
    component: _7f36259b,
    redirect: "/pt-br/",
    name: "routes.tapmilesandgo.pt-br"
  }, {
    path: "/pt-br/passagenspromo",
    component: _2c171f2e,
    name: "routes.passagenspromo.pt-br"
  }, {
    path: "/pt-br/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.pt-br"
  }, {
    path: "/pt-br/preferencias/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.pt-br"
  }, {
    path: "/pt-br/aluguel-mensal",
    component: _3fc7eb11,
    meta: {"locales":["pt-br"]},
    name: "routes.monthly-rate.pt-br"
  }, {
    path: "/pt-br/cashback",
    component: _aec9bba8,
    meta: {"locales":["pt-br","pt-pt","en","es","es-ar","es-co","es-mx","fr-fr","it-it","de-de","en-gb","es-cl","nl-nl"]},
    name: "routes.cashback.pt-br"
  }, {
    path: "/pt-br/rewards",
    component: _18bd3d30,
    meta: {"locales":["pt-br","pt-pt","en","es","es-ar","es-co","es-mx","fr-fr","it-it","de-de","en-gb","es-cl","nl-nl"]},
    name: "routes.rewards.pt-br"
  }, {
    path: "/pt-br/de-volta-para-estrada",
    component: _f4b6bc44,
    meta: {"locales":["pt-br"]},
    name: "routes.back-to-road.pt-br"
  }, {
    path: "/pt-br/chat-status",
    component: _09148e4c,
    meta: {"locales":["pt-br","pt-pt","en","es","es-ar","es-co","es-mx","fr-fr","it-it","de-de","en-gb","es-cl","nl-nl"]},
    name: "routes.chat-status.pt-br"
  }, {
    path: "/pt-br/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.pt-br"
  }, {
    path: "/pt-br/aluguel-de-carro-eletrico",
    component: _ad636294,
    name: "routes.electric-car-rental.pt-br"
  }, {
    path: "/pt-br/esfera",
    component: _7f36259b,
    name: "routes.esfera.pt-br"
  }, {
    path: "/pt-br/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.pt-br"
  }, {
    path: "/pt-br/cupons",
    component: _39eb065c,
    name: "routes.coupons.pt-br"
  }, {
    path: "/pt-br/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/pt-br/', query: {}}),
    name: "routes.newsletter.confirmar.pt-br"
  }, {
    path: "/pt-br/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.pt-br"
  }, {
    path: "/en/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.en"
  }, {
    path: "/en/locations",
    component: _c115b93a,
    name: "routes.locations.en"
  }, {
    path: "/en/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.en"
  }, {
    path: "/en/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.en"
  }, {
    path: "/en/airports",
    component: _3a5f8279,
    name: "routes.airports.en"
  }, {
    path: "/en/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.en"
  }, {
    path: "/en/airports/brazil/cnf-confins-belo-horizonte-minas-gerais",
    component: _31590994,
    name: "routes.airports.locationV2Confins.en"
  }, {
    path: "/en/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.en"
  }, {
    path: "/en/companies",
    component: _759525d8,
    name: "routes.rentals.en"
  }, {
    path: "/en/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.en"
  }, {
    path: "/en/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.en"
  }, {
    path: "/en/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.en"
  }, {
    path: "/en/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.en"
  }, {
    path: "/en/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.en"
  }, {
    path: "/en/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.en"
  }, {
    path: "/en/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.en"
  }, {
    path: "/en/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.en"
  }, {
    path: "/en/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.en"
  }, {
    path: "/en/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.en"
  }, {
    path: "/en/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.en"
  }, {
    path: "/en/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.en"
  }, {
    path: "/en/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.en"
  }, {
    path: "/en/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.en"
  }, {
    path: "/en/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.en"
  }, {
    path: "/en/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.en"
  }, {
    path: "/en/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.en"
  }, {
    path: "/en/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.en"
  }, {
    path: "/en/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.en"
  }, {
    path: "/en/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.en"
  }, {
    path: "/en/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.en"
  }, {
    path: "/en/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.en"
  }, {
    path: "/en/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.en"
  }, {
    path: "/en/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.en"
  }, {
    path: "/en/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.en"
  }, {
    path: "/en/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.en"
  }, {
    path: "/en/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.en"
  }, {
    path: "/en/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.en"
  }, {
    path: "/en/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.en"
  }, {
    path: "/en/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.en"
  }, {
    path: "/en/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.en"
  }, {
    path: "/en/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.en"
  }, {
    path: "/en/customer",
    component: _25775634,
    name: "routes.customer.login.en"
  }, {
    path: "/en/customer/register",
    component: _07a034eb,
    name: "routes.customer.signup.en"
  }, {
    path: "/en/customer/password-recover",
    redirect: (route) => ({ path: '/en/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.en"
  }, {
    path: "/en/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.en"
  }, {
    path: "/en/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.en"
  }, {
    path: "/en/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.en"
  }, {
    path: "/en/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.en"
  }, {
    path: "/en/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.en"
  }, {
    path: "/en/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.en"
  }, {
    path: "/en/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.en"
  }, {
    path: "/en/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.en"
  }, {
    path: "/en/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.en"
  }, {
    path: "/en/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.en"
  }, {
    path: "/en/settings",
    component: _e971c858,
    name: "routes.settings.en"
  }, {
    path: "/en/info",
    component: _38580a71,
    name: "routes.info.en"
  }, {
    path: "/en/info/about",
    component: _3c0692da,
    name: "routes.info.about.en"
  }, {
    path: "/en/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.en"
  }, {
    path: "/en/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.en"
  }, {
    path: "/en/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.en"
  }, {
    path: "/en/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.en"
  }, {
    path: "/en/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.en"
  }, {
    path: "/en/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.en"
  }, {
    path: "/en/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.en"
  }, {
    path: "/en/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.en"
  }, {
    path: "/en/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.en"
  }, {
    path: "/en/app",
    component: _abd18f28,
    name: "routes.app-page.en"
  }, {
    path: "/en/offline",
    component: _35f9ca28,
    name: "routes.offline.en"
  }, {
    path: "/en/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.en"
  }, {
    path: "/en/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.en"
  }, {
    path: "/en/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.en"
  }, {
    path: "/en/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.en"
  }, {
    path: "/en/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.en"
  }, {
    path: "/en/search-amp",
    component: _0c37f51a,
    name: "routes.booking.search-amp.en"
  }, {
    path: "/en/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.en"
  }, {
    path: "/en/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.en"
  }, {
    path: "/en/liverpool",
    redirect: "/es-mx/lp/liverpool",
    name: "routes.liverpool.en"
  }, {
    path: "/en/tap-miles-and-go",
    component: _7f36259b,
    redirect: "/en/",
    name: "routes.tapmilesandgo.en"
  }, {
    path: "/en/together-against-covid",
    component: _990d4950,
    name: "routes.juntoseseguros.en"
  }, {
    path: "/en/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.en"
  }, {
    path: "/en/rewards",
    component: _18bd3d30,
    name: "routes.rewards.en"
  }, {
    path: "/en/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.en"
  }, {
    path: "/en/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.en"
  }, {
    path: "/en/cashback",
    component: _aec9bba8,
    name: "routes.cashback.en"
  }, {
    path: "/en/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.en"
  }, {
    path: "/en/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.en"
  }, {
    path: "/en/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.en"
  }, {
    path: "/en/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.en"
  }, {
    path: "/en/coupons",
    component: _39eb065c,
    name: "routes.coupons.en"
  }, {
    path: "/en/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/en/', query: {}}),
    name: "routes.newsletter.confirmar.en"
  }, {
    path: "/en/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.en"
  }, {
    path: "/es/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.es"
  }, {
    path: "/es/localidades",
    component: _c115b93a,
    name: "routes.locations.es"
  }, {
    path: "/es/localidades/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.es"
  }, {
    path: "/es/localidades/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.es"
  }, {
    path: "/es/aeropuertos",
    component: _3a5f8279,
    name: "routes.airports.es"
  }, {
    path: "/es/aeropuertos/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.es"
  }, {
    path: "/es/aeropuertos/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.es"
  }, {
    path: "/es/rentadoras",
    component: _759525d8,
    name: "routes.rentals.es"
  }, {
    path: "/es/rentadoras/spain",
    component: _6c9354ae,
    name: "routes.rentals.countryV2ES.es"
  }, {
    path: "/es/rentadoras/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.es"
  }, {
    path: "/es/rentadora/avis/honduras/aeropuertos/rtb-roatan-departamento-de-islas-de-la-bahia",
    component: _866a0944,
    name: "routes.rental.locationV2AVIS.es"
  }, {
    path: "/es/rentadora/:companySlug/:countrySlug/aeropuertos/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.es"
  }, {
    path: "/es/rentadora/dollar",
    component: _b033c31e,
    name: "routes.rental.rentalV2DOL.es"
  }, {
    path: "/es/rentadora/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.es"
  }, {
    path: "/es/rentadora/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.es"
  }, {
    path: "/es/rentadora/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.es"
  }, {
    path: "/es/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.es"
  }, {
    path: "/es/mejores-precios",
    component: _12c7984a,
    name: "routes.best-rates.es"
  }, {
    path: "/es/invita-amigos",
    component: _699a0908,
    name: "routes.member-get-member.es"
  }, {
    path: "/es/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.es"
  }, {
    path: "/es/reserva/lista/:params",
    component: _b121dd2c,
    name: "routes.search.list.es"
  }, {
    path: "/es/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.es"
  }, {
    path: "/es/reserva/filtro",
    component: _d20048f8,
    name: "routes.booking.filters.es"
  }, {
    path: "/es/reserva/visualizar/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.es"
  }, {
    path: "/es/reserva/configuracion/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.es"
  }, {
    path: "/es/informacion-de-vuelo",
    component: _232fbe7c,
    name: "routes.configure.flight.es"
  }, {
    path: "/es/alterar-oficina/:type",
    component: _02f00008,
    name: "routes.configure.change-store.es"
  }, {
    path: "/es/alterar-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.es"
  }, {
    path: "/es/alterar-opcionales",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.es"
  }, {
    path: "/es/alterar-cobertura-de-danos",
    component: _2c95260c,
    name: "routes.configure.change-coverages.es"
  }, {
    path: "/es/cambiar-busqueda",
    component: _914ef9a2,
    name: "routes.search.change-search.es"
  }, {
    path: "/es/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.es"
  }, {
    path: "/es/reserva/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.es"
  }, {
    path: "/es/reserva/identificacion",
    component: _d3e9d40c,
    name: "routes.booking.identification.es"
  }, {
    path: "/es/reserva/pago/:code",
    component: _487288d0,
    name: "routes.booking.payment.es"
  }, {
    path: "/es/reserva/pago/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.es"
  }, {
    path: "/es/reserva/confirmacion/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.es"
  }, {
    path: "/es/reserva/confirmacion/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.es"
  }, {
    path: "/es/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.es"
  }, {
    path: "/es/cliente/mis-reservas/valoracion/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.es"
  }, {
    path: "/es/alquiler/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.es"
  }, {
    path: "/es/alquiler/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.es"
  }, {
    path: "/es/investigaciones-recientes",
    component: _2643ce5c,
    name: "routes.recentSearches.es"
  }, {
    path: "/es/cliente",
    component: _25775634,
    name: "routes.customer.login.es"
  }, {
    path: "/es/cliente/registro",
    redirect: (route) => ({ path: '/es/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.es"
  }, {
    path: "/es/cliente/recuperar-contrasena",
    redirect: (route) => ({ path: '/es/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.es"
  }, {
    path: "/es/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.es"
  }, {
    path: "/es/cliente/mi-cuenta",
    component: _5c8eaf2c,
    name: "routes.customer.account.es"
  }, {
    path: "/es/cliente/mi-cuenta/perfil",
    component: _aea0ec34,
    name: "routes.customer.profile.es"
  }, {
    path: "/es/cliente/mi-cuenta/informacion-de-acceso",
    component: _34108852,
    name: "routes.customer.account-access.es"
  }, {
    path: "/es/cliente/mis-reservas",
    component: _74ce6995,
    name: "routes.customer.bookings.es"
  }, {
    path: "/es/cliente/mis-reservas/antiguas",
    component: _74ce6995,
    name: "routes.customer.bookings.old.es"
  }, {
    path: "/es/cliente/mis-reservas/reserva/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.es"
  }, {
    path: "/es/cliente/mis-reservas/reserva/modificar/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.es"
  }, {
    path: "/es/cliente/mis-reservas/reserva/cancelar/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.es"
  }, {
    path: "/es/cliente/mis-reservas/reserva/cupon/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.es"
  }, {
    path: "/es/ajustes",
    component: _e971c858,
    name: "routes.settings.es"
  }, {
    path: "/es/info",
    component: _38580a71,
    name: "routes.info.es"
  }, {
    path: "/es/info/sobre-nosotros",
    component: _3c0692da,
    name: "routes.info.about.es"
  }, {
    path: "/es/info/contacto",
    component: _572a18ad,
    name: "routes.info.contact.es"
  }, {
    path: "/es/info/privacidad",
    component: _a353b7d6,
    name: "routes.info.privacy.es"
  }, {
    path: "/es/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.es"
  }, {
    path: "/es/info/requisitos-para-alquiler-de-coches",
    component: _3a19935a,
    name: "routes.info.requirements.es"
  }, {
    path: "/es/info/pago-instrucciones",
    component: _0e2c78da,
    name: "routes.info.payment.es"
  }, {
    path: "/es/info/terminos-y-condiciones",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.es"
  }, {
    path: "/es/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.es"
  }, {
    path: "/es/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.es"
  }, {
    path: "/es/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.es"
  }, {
    path: "/es/app",
    component: _abd18f28,
    name: "routes.app-page.es"
  }, {
    path: "/es/offline",
    component: _35f9ca28,
    name: "routes.offline.es"
  }, {
    path: "/es/promociones",
    component: _64454e9e,
    name: "routes.best-deals.es"
  }, {
    path: "/es/reserva/presupuesto/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.es"
  }, {
    path: "/es/consejos-para-alquiler-de-coche",
    component: _689a163c,
    name: "routes.car-hire-tips.es"
  }, {
    path: "/es/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.es"
  }, {
    path: "/es/road-trip-america-latina",
    component: _ee21c76e,
    name: "routes.roadtripamericalatina.es"
  }, {
    path: "/es/juntos-contra-covid",
    component: _990d4950,
    name: "routes.juntoseseguros.es"
  }, {
    path: "/es/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.es"
  }, {
    path: "/es/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.es"
  }, {
    path: "/es/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.es"
  }, {
    path: "/es/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.es"
  }, {
    path: "/es/rewards",
    component: _18bd3d30,
    name: "routes.rewards.es"
  }, {
    path: "/es/cliente/mi-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.es"
  }, {
    path: "/es/mi-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.es"
  }, {
    path: "/es/cashback",
    component: _aec9bba8,
    name: "routes.cashback.es"
  }, {
    path: "/es/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.es"
  }, {
    path: "/es/allaccor",
    component: _31769c72,
    name: "routes.allaccor.es"
  }, {
    path: "/es/alquiler-coche-electrico",
    component: _ad636294,
    name: "routes.electric-car-rental.es"
  }, {
    path: "/es/aquiller-coche-one-way",
    component: _67beef68,
    name: "routes.one-way.es"
  }, {
    path: "/es/travelclub",
    component: _7f36259b,
    name: "routes.travelclub.es"
  }, {
    path: "/es/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.es"
  }, {
    path: "/es/coupons",
    component: _39eb065c,
    name: "routes.coupons.es"
  }, {
    path: "/es/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/es/', query: {}}),
    name: "routes.newsletter.confirmar.es"
  }, {
    path: "/es/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.es"
  }, {
    path: "/es-ar/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.es-ar"
  }, {
    path: "/es-ar/locations",
    component: _c115b93a,
    name: "routes.locations.es-ar"
  }, {
    path: "/es-ar/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.es-ar"
  }, {
    path: "/es-ar/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.es-ar"
  }, {
    path: "/es-ar/airports",
    component: _3a5f8279,
    name: "routes.airports.es-ar"
  }, {
    path: "/es-ar/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.es-ar"
  }, {
    path: "/es-ar/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.es-ar"
  }, {
    path: "/es-ar/companies",
    component: _759525d8,
    name: "routes.rentals.es-ar"
  }, {
    path: "/es-ar/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.es-ar"
  }, {
    path: "/es-ar/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.es-ar"
  }, {
    path: "/es-ar/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.es-ar"
  }, {
    path: "/es-ar/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.es-ar"
  }, {
    path: "/es-ar/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.es-ar"
  }, {
    path: "/es-ar/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.es-ar"
  }, {
    path: "/es-ar/best-rates",
    component: _12c7984a,
    alias: "/es-ar/mejores-precios",
    name: "routes.best-rates.es-ar"
  }, {
    path: "/es-ar/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.es-ar"
  }, {
    path: "/es-ar/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.es-ar"
  }, {
    path: "/es-ar/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.es-ar"
  }, {
    path: "/es-ar/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.es-ar"
  }, {
    path: "/es-ar/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.es-ar"
  }, {
    path: "/es-ar/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.es-ar"
  }, {
    path: "/es-ar/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.es-ar"
  }, {
    path: "/es-ar/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.es-ar"
  }, {
    path: "/es-ar/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.es-ar"
  }, {
    path: "/es-ar/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.es-ar"
  }, {
    path: "/es-ar/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.es-ar"
  }, {
    path: "/es-ar/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.es-ar"
  }, {
    path: "/es-ar/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.es-ar"
  }, {
    path: "/es-ar/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.es-ar"
  }, {
    path: "/es-ar/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.es-ar"
  }, {
    path: "/es-ar/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.es-ar"
  }, {
    path: "/es-ar/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.es-ar"
  }, {
    path: "/es-ar/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.es-ar"
  }, {
    path: "/es-ar/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.es-ar"
  }, {
    path: "/es-ar/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.es-ar"
  }, {
    path: "/es-ar/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.es-ar"
  }, {
    path: "/es-ar/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.es-ar"
  }, {
    path: "/es-ar/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.es-ar"
  }, {
    path: "/es-ar/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.es-ar"
  }, {
    path: "/es-ar/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.es-ar"
  }, {
    path: "/es-ar/customer",
    component: _25775634,
    name: "routes.customer.login.es-ar"
  }, {
    path: "/es-ar/customer/register",
    redirect: (route) => ({ path: '/es-ar/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.es-ar"
  }, {
    path: "/es-ar/customer/password-recover",
    redirect: (route) => ({ path: '/es-ar/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.es-ar"
  }, {
    path: "/es-ar/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.es-ar"
  }, {
    path: "/es-ar/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.es-ar"
  }, {
    path: "/es-ar/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.es-ar"
  }, {
    path: "/es-ar/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.es-ar"
  }, {
    path: "/es-ar/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.es-ar"
  }, {
    path: "/es-ar/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.es-ar"
  }, {
    path: "/es-ar/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.es-ar"
  }, {
    path: "/es-ar/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.es-ar"
  }, {
    path: "/es-ar/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.es-ar"
  }, {
    path: "/es-ar/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.es-ar"
  }, {
    path: "/es-ar/settings",
    component: _e971c858,
    name: "routes.settings.es-ar"
  }, {
    path: "/es-ar/info",
    component: _38580a71,
    name: "routes.info.es-ar"
  }, {
    path: "/es-ar/info/about",
    component: _3c0692da,
    name: "routes.info.about.es-ar"
  }, {
    path: "/es-ar/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.es-ar"
  }, {
    path: "/es-ar/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.es-ar"
  }, {
    path: "/es-ar/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.es-ar"
  }, {
    path: "/es-ar/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.es-ar"
  }, {
    path: "/es-ar/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.es-ar"
  }, {
    path: "/es-ar/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.es-ar"
  }, {
    path: "/es-ar/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.es-ar"
  }, {
    path: "/es-ar/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.es-ar"
  }, {
    path: "/es-ar/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.es-ar"
  }, {
    path: "/es-ar/app",
    component: _abd18f28,
    name: "routes.app-page.es-ar"
  }, {
    path: "/es-ar/offline",
    component: _35f9ca28,
    name: "routes.offline.es-ar"
  }, {
    path: "/es-ar/mejorprecio",
    component: _61cd2bde,
    name: "routes.best-price.es-ar"
  }, {
    path: "/es-ar/mejorprecio/register",
    component: _688af62e,
    name: "routes.best-price.signup.es-ar"
  }, {
    path: "/es-ar/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.es-ar"
  }, {
    path: "/es-ar/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.es-ar"
  }, {
    path: "/es-ar/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.es-ar"
  }, {
    path: "/es-ar/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.es-ar"
  }, {
    path: "/es-ar/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.es-ar"
  }, {
    path: "/es-ar/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.es-ar"
  }, {
    path: "/es-ar/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.es-ar"
  }, {
    path: "/es-ar/lp/vacaciones-de-verano",
    component: _0ccd2fb8,
    name: "routes.vacacionesdeverano.es-ar"
  }, {
    path: "/es-ar/hotsale",
    component: _1f286628,
    name: "routes.hotsale.es-ar"
  }, {
    path: "/es-ar/road-trip-america-latina",
    component: _ee21c76e,
    name: "routes.roadtripamericalatina.es-ar"
  }, {
    path: "/es-ar/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.es-ar"
  }, {
    path: "/es-ar/rewards",
    component: _18bd3d30,
    name: "routes.rewards.es-ar"
  }, {
    path: "/es-ar/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.es-ar"
  }, {
    path: "/es-ar/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.es-ar"
  }, {
    path: "/es-ar/cashback",
    component: _aec9bba8,
    name: "routes.cashback.es-ar"
  }, {
    path: "/es-ar/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.es-ar"
  }, {
    path: "/es-ar/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.es-ar"
  }, {
    path: "/es-ar/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.es-ar"
  }, {
    path: "/es-ar/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.es-ar"
  }, {
    path: "/es-ar/coupons",
    component: _39eb065c,
    name: "routes.coupons.es-ar"
  }, {
    path: "/es-ar/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/es-ar/', query: {}}),
    name: "routes.newsletter.confirmar.es-ar"
  }, {
    path: "/es-ar/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.es-ar"
  }, {
    path: "/es-cl/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.es-cl"
  }, {
    path: "/es-cl/locations",
    component: _c115b93a,
    name: "routes.locations.es-cl"
  }, {
    path: "/es-cl/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.es-cl"
  }, {
    path: "/es-cl/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.es-cl"
  }, {
    path: "/es-cl/airports",
    component: _3a5f8279,
    name: "routes.airports.es-cl"
  }, {
    path: "/es-cl/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.es-cl"
  }, {
    path: "/es-cl/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.es-cl"
  }, {
    path: "/es-cl/companies",
    component: _759525d8,
    name: "routes.rentals.es-cl"
  }, {
    path: "/es-cl/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.es-cl"
  }, {
    path: "/es-cl/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.es-cl"
  }, {
    path: "/es-cl/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.es-cl"
  }, {
    path: "/es-cl/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.es-cl"
  }, {
    path: "/es-cl/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.es-cl"
  }, {
    path: "/es-cl/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.es-cl"
  }, {
    path: "/es-cl/mejorprecio",
    component: _61cd2bde,
    name: "routes.best-price.es-cl"
  }, {
    path: "/es-cl/mejorprecio/register",
    component: _688af62e,
    name: "routes.best-price.signup.es-cl"
  }, {
    path: "/es-cl/best-rates",
    component: _12c7984a,
    alias: "/es-cl/mejores-precios",
    name: "routes.best-rates.es-cl"
  }, {
    path: "/es-cl/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.es-cl"
  }, {
    path: "/es-cl/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.es-cl"
  }, {
    path: "/es-cl/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.es-cl"
  }, {
    path: "/es-cl/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.es-cl"
  }, {
    path: "/es-cl/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.es-cl"
  }, {
    path: "/es-cl/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.es-cl"
  }, {
    path: "/es-cl/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.es-cl"
  }, {
    path: "/es-cl/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.es-cl"
  }, {
    path: "/es-cl/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.es-cl"
  }, {
    path: "/es-cl/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.es-cl"
  }, {
    path: "/es-cl/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.es-cl"
  }, {
    path: "/es-cl/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.es-cl"
  }, {
    path: "/es-cl/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.es-cl"
  }, {
    path: "/es-cl/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.es-cl"
  }, {
    path: "/es-cl/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.es-cl"
  }, {
    path: "/es-cl/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.es-cl"
  }, {
    path: "/es-cl/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.es-cl"
  }, {
    path: "/es-cl/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.es-cl"
  }, {
    path: "/es-cl/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.es-cl"
  }, {
    path: "/es-cl/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.es-cl"
  }, {
    path: "/es-cl/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.es-cl"
  }, {
    path: "/es-cl/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.es-cl"
  }, {
    path: "/es-cl/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.es-cl"
  }, {
    path: "/es-cl/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.es-cl"
  }, {
    path: "/es-cl/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.es-cl"
  }, {
    path: "/es-cl/customer",
    component: _25775634,
    name: "routes.customer.login.es-cl"
  }, {
    path: "/es-cl/customer/register",
    redirect: (route) => ({ path: '/es-cl/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.es-cl"
  }, {
    path: "/es-cl/customer/password-recover",
    redirect: (route) => ({ path: '/es-cl/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.es-cl"
  }, {
    path: "/es-cl/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.es-cl"
  }, {
    path: "/es-cl/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.es-cl"
  }, {
    path: "/es-cl/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.es-cl"
  }, {
    path: "/es-cl/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.es-cl"
  }, {
    path: "/es-cl/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.es-cl"
  }, {
    path: "/es-cl/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.es-cl"
  }, {
    path: "/es-cl/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.es-cl"
  }, {
    path: "/es-cl/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.es-cl"
  }, {
    path: "/es-cl/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.es-cl"
  }, {
    path: "/es-cl/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.es-cl"
  }, {
    path: "/es-cl/settings",
    component: _e971c858,
    name: "routes.settings.es-cl"
  }, {
    path: "/es-cl/info",
    component: _38580a71,
    name: "routes.info.es-cl"
  }, {
    path: "/es-cl/info/about",
    component: _3c0692da,
    name: "routes.info.about.es-cl"
  }, {
    path: "/es-cl/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.es-cl"
  }, {
    path: "/es-cl/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.es-cl"
  }, {
    path: "/es-cl/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.es-cl"
  }, {
    path: "/es-cl/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.es-cl"
  }, {
    path: "/es-cl/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.es-cl"
  }, {
    path: "/es-cl/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.es-cl"
  }, {
    path: "/es-cl/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.es-cl"
  }, {
    path: "/es-cl/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.es-cl"
  }, {
    path: "/es-cl/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.es-cl"
  }, {
    path: "/es-cl/app",
    component: _abd18f28,
    name: "routes.app-page.es-cl"
  }, {
    path: "/es-cl/offline",
    component: _35f9ca28,
    name: "routes.offline.es-cl"
  }, {
    path: "/es-cl/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.es-cl"
  }, {
    path: "/es-cl/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.es-cl"
  }, {
    path: "/es-cl/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.es-cl"
  }, {
    path: "/es-cl/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.es-cl"
  }, {
    path: "/es-cl/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.es-cl"
  }, {
    path: "/es-cl/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.es-cl"
  }, {
    path: "/es-cl/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.es-cl"
  }, {
    path: "/es-cl/lp/vacaciones-de-verano",
    component: _0ccd2fb8,
    name: "routes.vacacionesdeverano.es-cl"
  }, {
    path: "/es-cl/rentsale",
    component: _59cb157e,
    name: "routes.rentsale.es-cl"
  }, {
    path: "/es-cl/road-trip-america-latina",
    component: _ee21c76e,
    name: "routes.roadtripamericalatina.es-cl"
  }, {
    path: "/es-cl/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.es-cl"
  }, {
    path: "/es-cl/rewards",
    component: _18bd3d30,
    name: "routes.rewards.es-cl"
  }, {
    path: "/es-cl/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.es-cl"
  }, {
    path: "/es-cl/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.es-cl"
  }, {
    path: "/es-cl/cashback",
    component: _aec9bba8,
    name: "routes.cashback.es-cl"
  }, {
    path: "/es-cl/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.es-cl"
  }, {
    path: "/es-cl/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.es-cl"
  }, {
    path: "/es-cl/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.es-cl"
  }, {
    path: "/es-cl/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.es-cl"
  }, {
    path: "/es-cl/coupons",
    component: _39eb065c,
    name: "routes.coupons.es-cl"
  }, {
    path: "/es-cl/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/es-cl/', query: {}}),
    name: "routes.newsletter.confirmar.es-cl"
  }, {
    path: "/es-cl/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.es-cl"
  }, {
    path: "/es-co/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.es-co"
  }, {
    path: "/es-co/locations",
    component: _c115b93a,
    name: "routes.locations.es-co"
  }, {
    path: "/es-co/locations/mexico",
    component: _6c9354ae,
    name: "routes.locations.countryV2MX.es-co"
  }, {
    path: "/es-co/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.es-co"
  }, {
    path: "/es-co/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.es-co"
  }, {
    path: "/es-co/airports",
    component: _3a5f8279,
    name: "routes.airports.es-co"
  }, {
    path: "/es-co/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.es-co"
  }, {
    path: "/es-co/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.es-co"
  }, {
    path: "/es-co/companies",
    component: _759525d8,
    name: "routes.rentals.es-co"
  }, {
    path: "/es-co/companies/mexico",
    component: _6c9354ae,
    name: "routes.rentals.countryV2.es-co"
  }, {
    path: "/es-co/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.es-co"
  }, {
    path: "/es-co/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.es-co"
  }, {
    path: "/es-co/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.es-co"
  }, {
    path: "/es-co/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.es-co"
  }, {
    path: "/es-co/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.es-co"
  }, {
    path: "/es-co/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.es-co"
  }, {
    path: "/es-co/best-rates",
    component: _12c7984a,
    alias: "/es-co/mejores-precios",
    name: "routes.best-rates.es-co"
  }, {
    path: "/es-co/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.es-co"
  }, {
    path: "/es-co/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.es-co"
  }, {
    path: "/es-co/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.es-co"
  }, {
    path: "/es-co/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.es-co"
  }, {
    path: "/es-co/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.es-co"
  }, {
    path: "/es-co/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.es-co"
  }, {
    path: "/es-co/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.es-co"
  }, {
    path: "/es-co/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.es-co"
  }, {
    path: "/es-co/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.es-co"
  }, {
    path: "/es-co/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.es-co"
  }, {
    path: "/es-co/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.es-co"
  }, {
    path: "/es-co/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.es-co"
  }, {
    path: "/es-co/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.es-co"
  }, {
    path: "/es-co/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.es-co"
  }, {
    path: "/es-co/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.es-co"
  }, {
    path: "/es-co/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.es-co"
  }, {
    path: "/es-co/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.es-co"
  }, {
    path: "/es-co/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.es-co"
  }, {
    path: "/es-co/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.es-co"
  }, {
    path: "/es-co/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.es-co"
  }, {
    path: "/es-co/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.es-co"
  }, {
    path: "/es-co/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.es-co"
  }, {
    path: "/es-co/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.es-co"
  }, {
    path: "/es-co/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.es-co"
  }, {
    path: "/es-co/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.es-co"
  }, {
    path: "/es-co/customer",
    component: _25775634,
    name: "routes.customer.login.es-co"
  }, {
    path: "/es-co/customer/register",
    redirect: (route) => ({ path: '/es-co/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.es-co"
  }, {
    path: "/es-co/customer/password-recover",
    redirect: (route) => ({ path: '/es-co/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.es-co"
  }, {
    path: "/es-co/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.es-co"
  }, {
    path: "/es-co/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.es-co"
  }, {
    path: "/es-co/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.es-co"
  }, {
    path: "/es-co/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.es-co"
  }, {
    path: "/es-co/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.es-co"
  }, {
    path: "/es-co/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.es-co"
  }, {
    path: "/es-co/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.es-co"
  }, {
    path: "/es-co/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.es-co"
  }, {
    path: "/es-co/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.es-co"
  }, {
    path: "/es-co/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.es-co"
  }, {
    path: "/es-co/settings",
    component: _e971c858,
    name: "routes.settings.es-co"
  }, {
    path: "/es-co/info",
    component: _38580a71,
    name: "routes.info.es-co"
  }, {
    path: "/es-co/info/about",
    component: _3c0692da,
    name: "routes.info.about.es-co"
  }, {
    path: "/es-co/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.es-co"
  }, {
    path: "/es-co/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.es-co"
  }, {
    path: "/es-co/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.es-co"
  }, {
    path: "/es-co/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.es-co"
  }, {
    path: "/es-co/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.es-co"
  }, {
    path: "/es-co/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.es-co"
  }, {
    path: "/es-co/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.es-co"
  }, {
    path: "/es-co/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.es-co"
  }, {
    path: "/es-co/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.es-co"
  }, {
    path: "/es-co/app",
    component: _abd18f28,
    name: "routes.app-page.es-co"
  }, {
    path: "/es-co/offline",
    component: _35f9ca28,
    name: "routes.offline.es-co"
  }, {
    path: "/es-co/mejorprecio",
    component: _61cd2bde,
    name: "routes.best-price.es-co"
  }, {
    path: "/es-co/mejorprecio/register",
    component: _688af62e,
    name: "routes.best-price.signup.es-co"
  }, {
    path: "/es-co/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.es-co"
  }, {
    path: "/es-co/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.es-co"
  }, {
    path: "/es-co/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.es-co"
  }, {
    path: "/es-co/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.es-co"
  }, {
    path: "/es-co/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.es-co"
  }, {
    path: "/es-co/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.es-co"
  }, {
    path: "/es-co/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.es-co"
  }, {
    path: "/es-co/lp/vacaciones-de-verano",
    component: _0ccd2fb8,
    name: "routes.vacacionesdeverano.es-co"
  }, {
    path: "/es-co/rentsale",
    component: _59cb157e,
    name: "routes.rentsale.es-co"
  }, {
    path: "/es-co/hotsale",
    component: _1f286628,
    redirect: () => ({ path: '/es-co/' }),
    name: "routes.hotsale.es-co"
  }, {
    path: "/es-co/road-trip-america-latina",
    component: _ee21c76e,
    name: "routes.roadtripamericalatina.es-co"
  }, {
    path: "/es-co/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.es-co"
  }, {
    path: "/es-co/rewards",
    component: _18bd3d30,
    name: "routes.rewards.es-co"
  }, {
    path: "/es-co/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.es-co"
  }, {
    path: "/es-co/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.es-co"
  }, {
    path: "/es-co/cashback",
    component: _aec9bba8,
    name: "routes.cashback.es-co"
  }, {
    path: "/es-co/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.es-co"
  }, {
    path: "/es-co/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.es-co"
  }, {
    path: "/es-co/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.es-co"
  }, {
    path: "/es-co/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.es-co"
  }, {
    path: "/es-co/coupons",
    component: _39eb065c,
    name: "routes.coupons.es-co"
  }, {
    path: "/es-co/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/es-co/', query: {}}),
    name: "routes.newsletter.confirmar.es-co"
  }, {
    path: "/es-co/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.es-co"
  }, {
    path: "/es-mx/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.es-mx"
  }, {
    path: "/es-mx/localidades",
    component: _c115b93a,
    name: "routes.locations.es-mx"
  }, {
    path: "/es-mx/localidades/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.es-mx"
  }, {
    path: "/es-mx/localidades/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.es-mx"
  }, {
    path: "/es-mx/aeropuertos",
    component: _3a5f8279,
    name: "routes.airports.es-mx"
  }, {
    path: "/es-mx/aeropuertos/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.es-mx"
  }, {
    path: "/es-mx/aeropuertos/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.es-mx"
  }, {
    path: "/es-mx/rentadoras",
    component: _759525d8,
    name: "routes.rentals.es-mx"
  }, {
    path: "/es-mx/rentadoras/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.es-mx"
  }, {
    path: "/es-mx/rentadora/:companySlug/:countrySlug/aeropuertos/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.es-mx"
  }, {
    path: "/es-mx/rentadora/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.es-mx"
  }, {
    path: "/es-mx/rentadora/avis/mexico",
    component: _2909db75,
    name: "routes.rental.countryV2MEX.es-mx"
  }, {
    path: "/es-mx/rentadora/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.es-mx"
  }, {
    path: "/es-mx/rentadora/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.es-mx"
  }, {
    path: "/es-mx/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.es-mx"
  }, {
    path: "/es-mx/mejorprecio",
    component: _61cd2bde,
    name: "routes.best-price.es-mx"
  }, {
    path: "/es-mx/mejorprecio/register",
    component: _688af62e,
    name: "routes.best-price.signup.es-mx"
  }, {
    path: "/es-mx/mejores-precios",
    component: _12c7984a,
    name: "routes.best-rates.es-mx"
  }, {
    path: "/es-mx/invita-amigos",
    component: _699a0908,
    name: "routes.member-get-member.es-mx"
  }, {
    path: "/es-mx/reserva/lista/:params",
    component: _b121dd2c,
    name: "routes.search.list.es-mx"
  }, {
    path: "/es-mx/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.es-mx"
  }, {
    path: "/es-mx/reserva/filtro",
    component: _d20048f8,
    name: "routes.booking.filters.es-mx"
  }, {
    path: "/es-mx/reserva/visualizar/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.es-mx"
  }, {
    path: "/es-mx/reserva/configuracion/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.es-mx"
  }, {
    path: "/es-mx/informacion-de-vuelo",
    component: _232fbe7c,
    name: "routes.configure.flight.es-mx"
  }, {
    path: "/es-mx/alterar-oficina/:type",
    component: _02f00008,
    name: "routes.configure.change-store.es-mx"
  }, {
    path: "/es-mx/alterar-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.es-mx"
  }, {
    path: "/es-mx/alterar-opcionales",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.es-mx"
  }, {
    path: "/es-mx/alterar-cobertura-de-danos",
    component: _2c95260c,
    name: "routes.configure.change-coverages.es-mx"
  }, {
    path: "/es-mx/cambiar-busqueda",
    component: _914ef9a2,
    name: "routes.search.change-search.es-mx"
  }, {
    path: "/es-mx/booking-duplicity.",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.es-mx"
  }, {
    path: "/es-mx/reserva/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.es-mx"
  }, {
    path: "/es-mx/reserva/identificacion",
    component: _d3e9d40c,
    name: "routes.booking.identification.es-mx"
  }, {
    path: "/es-mx/reserva/pago/:code",
    component: _487288d0,
    name: "routes.booking.payment.es-mx"
  }, {
    path: "/es-mx/reserva/pago/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.es-mx"
  }, {
    path: "/es-mx/reserva/confirmacion/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.es-mx"
  }, {
    path: "/es-mx/reserva/confirmacion/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.es-mx"
  }, {
    path: "/es-mx/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.es-mx"
  }, {
    path: "/es-mx/cliente/mis-reservas/valoracion/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.es-mx"
  }, {
    path: "/es-mx/rentar/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.es-mx"
  }, {
    path: "/es-mx/rentar/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.es-mx"
  }, {
    path: "/es-mx/investigaciones-recientes",
    component: _2643ce5c,
    name: "routes.recentSearches.es-mx"
  }, {
    path: "/es-mx/cliente",
    component: _25775634,
    name: "routes.customer.login.es-mx"
  }, {
    path: "/es-mx/cliente/registro",
    redirect: (route) => ({ path: '/es-mx/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.es-mx"
  }, {
    path: "/es-mx/cliente/recuperar-contrasena",
    redirect: (route) => ({ path: '/es-mx/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.es-mx"
  }, {
    path: "/es-mx/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.es-mx"
  }, {
    path: "/es-mx/cliente/mi-cuenta",
    component: _5c8eaf2c,
    name: "routes.customer.account.es-mx"
  }, {
    path: "/es-mx/cliente/mi-cuenta/perfil",
    component: _aea0ec34,
    name: "routes.customer.profile.es-mx"
  }, {
    path: "/es-mx/cliente/mi-cuenta/informacion-de-acceso",
    component: _34108852,
    name: "routes.customer.account-access.es-mx"
  }, {
    path: "/es-mx/cliente/mis-reservas",
    component: _74ce6995,
    name: "routes.customer.bookings.es-mx"
  }, {
    path: "/es-mx/cliente/mis-reservas/antiguas",
    component: _74ce6995,
    name: "routes.customer.bookings.old.es-mx"
  }, {
    path: "/es-mx/cliente/mis-reservas/reserva/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.es-mx"
  }, {
    path: "/es-mx/cliente/mis-reservas/reserva/modificar/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.es-mx"
  }, {
    path: "/es-mx/cliente/mis-reservas/reserva/cancelar/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.es-mx"
  }, {
    path: "/es-mx/cliente/mis-reservas/reserva/cupon/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.es-mx"
  }, {
    path: "/es-mx/ajustes",
    component: _e971c858,
    name: "routes.settings.es-mx"
  }, {
    path: "/es-mx/info",
    component: _38580a71,
    name: "routes.info.es-mx"
  }, {
    path: "/es-mx/info/sobre-nosotros",
    component: _3c0692da,
    name: "routes.info.about.es-mx"
  }, {
    path: "/es-mx/info/contacto",
    component: _572a18ad,
    name: "routes.info.contact.es-mx"
  }, {
    path: "/es-mx/info/privacidad",
    component: _a353b7d6,
    name: "routes.info.privacy.es-mx"
  }, {
    path: "/es-mx/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.es-mx"
  }, {
    path: "/es-mx/info/requisitos-para-alquiler-de-coches",
    component: _3a19935a,
    name: "routes.info.requirements.es-mx"
  }, {
    path: "/es-mx/info/pago-instrucciones",
    component: _0e2c78da,
    name: "routes.info.payment.es-mx"
  }, {
    path: "/es-mx/info/terminos-y-condiciones",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.es-mx"
  }, {
    path: "/es-mx/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.es-mx"
  }, {
    path: "/es-mx/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.es-mx"
  }, {
    path: "/es-mx/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.es-mx"
  }, {
    path: "/es-mx/info/terminos-y-condiciones-promocionales",
    component: _56c72840,
    name: "routes.info.promotional-terms-conditions.es-mx"
  }, {
    path: "/es-mx/terminos-y-condiciones-promocional",
    redirect: (route) => ({ path: '/es-mx/info/terminos-y-condiciones-promocionales', query: { ...route.query }}),
    name: "routes.promotional-terms-conditions.es-mx"
  }, {
    path: "/es-mx/app",
    component: _abd18f28,
    name: "routes.app-page.es-mx"
  }, {
    path: "/es-mx/offline",
    component: _35f9ca28,
    name: "routes.offline.es-mx"
  }, {
    path: "/es-mx/promociones",
    component: _64454e9e,
    name: "routes.best-deals.es-mx"
  }, {
    path: "/es-mx/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.es-mx"
  }, {
    path: "/es-mx/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.es-mx"
  }, {
    path: "/es-mx/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.es-mx"
  }, {
    path: "/es-mx/buenfin",
    component: _6432eec4,
    name: "routes.buenfin.es-mx"
  }, {
    path: "/es-mx/hotsale",
    component: _1f286628,
    name: "routes.hotsale.es-mx"
  }, {
    path: "/es-mx/hottravel",
    component: _6949c5ce,
    name: "routes.hottravel.es-mx"
  }, {
    path: "/es-mx/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.es-mx"
  }, {
    path: "/es-mx/reserva/presupuesto/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.es-mx"
  }, {
    path: "/es-mx/consejos-para-alquiler-de-coche",
    component: _689a163c,
    name: "routes.car-hire-tips.es-mx"
  }, {
    path: "/es-mx/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.es-mx"
  }, {
    path: "/es-mx/liverpool",
    redirect: "/es-mx/lp/liverpool",
    name: "routes.liverpool.es-mx"
  }, {
    path: "/es-mx/tap-miles-and-go",
    component: _7f36259b,
    redirect: "/es-mx/",
    name: "routes.tapmilesandgo.es-mx"
  }, {
    path: "/es-mx/road-trip-america-latina",
    component: _ee21c76e,
    name: "routes.roadtripamericalatina.es-mx"
  }, {
    path: "/es-mx/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.es-mx"
  }, {
    path: "/es-mx/rewards",
    component: _18bd3d30,
    name: "routes.rewards.es-mx"
  }, {
    path: "/es-mx/cliente/mi-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.es-mx"
  }, {
    path: "/es-mx/mi-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.es-mx"
  }, {
    path: "/es-mx/cashback",
    component: _aec9bba8,
    name: "routes.cashback.es-mx"
  }, {
    path: "/es-mx/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.es-mx"
  }, {
    path: "/es-mx/alquiler-auto-electrico",
    component: _ad636294,
    name: "routes.electric-car-rental.es-mx"
  }, {
    path: "/es-mx/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.es-mx"
  }, {
    path: "/es-mx/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.es-mx"
  }, {
    path: "/es-mx/coupons",
    component: _39eb065c,
    name: "routes.coupons.es-mx"
  }, {
    path: "/es-mx/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/es-mx/', query: {}}),
    name: "routes.newsletter.confirmar.es-mx"
  }, {
    path: "/es-mx/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.es-mx"
  }, {
    path: "/it-it/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.it-it"
  }, {
    path: "/it-it/locations",
    component: _c115b93a,
    name: "routes.locations.it-it"
  }, {
    path: "/it-it/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.it-it"
  }, {
    path: "/it-it/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.it-it"
  }, {
    path: "/it-it/airports",
    component: _3a5f8279,
    name: "routes.airports.it-it"
  }, {
    path: "/it-it/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.it-it"
  }, {
    path: "/it-it/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.it-it"
  }, {
    path: "/it-it/companies",
    component: _759525d8,
    name: "routes.rentals.it-it"
  }, {
    path: "/it-it/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.it-it"
  }, {
    path: "/it-it/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.it-it"
  }, {
    path: "/it-it/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.it-it"
  }, {
    path: "/it-it/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.it-it"
  }, {
    path: "/it-it/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.it-it"
  }, {
    path: "/it-it/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.it-it"
  }, {
    path: "/it-it/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.it-it"
  }, {
    path: "/it-it/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.it-it"
  }, {
    path: "/it-it/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.it-it"
  }, {
    path: "/it-it/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.it-it"
  }, {
    path: "/it-it/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.it-it"
  }, {
    path: "/it-it/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.it-it"
  }, {
    path: "/it-it/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.it-it"
  }, {
    path: "/it-it/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.it-it"
  }, {
    path: "/it-it/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.it-it"
  }, {
    path: "/it-it/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.it-it"
  }, {
    path: "/it-it/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.it-it"
  }, {
    path: "/it-it/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.it-it"
  }, {
    path: "/it-it/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.it-it"
  }, {
    path: "/it-it/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.it-it"
  }, {
    path: "/it-it/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.it-it"
  }, {
    path: "/it-it/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.it-it"
  }, {
    path: "/it-it/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.it-it"
  }, {
    path: "/it-it/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.it-it"
  }, {
    path: "/it-it/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.it-it"
  }, {
    path: "/it-it/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.it-it"
  }, {
    path: "/it-it/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.it-it"
  }, {
    path: "/it-it/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.it-it"
  }, {
    path: "/it-it/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.it-it"
  }, {
    path: "/it-it/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.it-it"
  }, {
    path: "/it-it/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.it-it"
  }, {
    path: "/it-it/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.it-it"
  }, {
    path: "/it-it/customer",
    component: _25775634,
    name: "routes.customer.login.it-it"
  }, {
    path: "/it-it/customer/register",
    redirect: (route) => ({ path: '/it-it/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.it-it"
  }, {
    path: "/it-it/customer/password-recover",
    redirect: (route) => ({ path: '/it-it/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.it-it"
  }, {
    path: "/it-it/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.it-it"
  }, {
    path: "/it-it/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.it-it"
  }, {
    path: "/it-it/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.it-it"
  }, {
    path: "/it-it/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.it-it"
  }, {
    path: "/it-it/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.it-it"
  }, {
    path: "/it-it/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.it-it"
  }, {
    path: "/it-it/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.it-it"
  }, {
    path: "/it-it/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.it-it"
  }, {
    path: "/it-it/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.it-it"
  }, {
    path: "/it-it/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.it-it"
  }, {
    path: "/it-it/settings",
    component: _e971c858,
    name: "routes.settings.it-it"
  }, {
    path: "/it-it/info",
    component: _38580a71,
    name: "routes.info.it-it"
  }, {
    path: "/it-it/info/about",
    component: _3c0692da,
    name: "routes.info.about.it-it"
  }, {
    path: "/it-it/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.it-it"
  }, {
    path: "/it-it/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.it-it"
  }, {
    path: "/it-it/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.it-it"
  }, {
    path: "/it-it/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.it-it"
  }, {
    path: "/it-it/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.it-it"
  }, {
    path: "/it-it/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.it-it"
  }, {
    path: "/it-it/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.it-it"
  }, {
    path: "/it-it/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.it-it"
  }, {
    path: "/it-it/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.it-it"
  }, {
    path: "/it-it/app",
    component: _abd18f28,
    name: "routes.app-page.it-it"
  }, {
    path: "/it-it/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.it-it"
  }, {
    path: "/it-it/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.it-it"
  }, {
    path: "/it-it/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.it-it"
  }, {
    path: "/it-it/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.it-it"
  }, {
    path: "/it-it/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.it-it"
  }, {
    path: "/it-it/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.it-it"
  }, {
    path: "/it-it/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.it-it"
  }, {
    path: "/it-it/tap-miles-and-go",
    component: _7f36259b,
    redirect: "/it-it",
    name: "routes.tapmilesandgo.it-it"
  }, {
    path: "/it-it/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.it-it"
  }, {
    path: "/it-it/rewards",
    component: _18bd3d30,
    name: "routes.rewards.it-it"
  }, {
    path: "/it-it/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.it-it"
  }, {
    path: "/it-it/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.it-it"
  }, {
    path: "/it-it/cashback",
    component: _aec9bba8,
    name: "routes.cashback.it-it"
  }, {
    path: "/it-it/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.it-it"
  }, {
    path: "/it-it/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.it-it"
  }, {
    path: "/it-it/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.it-it"
  }, {
    path: "/it-it/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.it-it"
  }, {
    path: "/it-it/coupons",
    component: _39eb065c,
    name: "routes.coupons.it-it"
  }, {
    path: "/it-it/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/it-it/', query: {}}),
    name: "routes.newsletter.confirmar.it-it"
  }, {
    path: "/it-it/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.it-it"
  }, {
    path: "/pt-pt/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.pt-pt"
  }, {
    path: "/pt-pt/locations",
    component: _c115b93a,
    name: "routes.locations.pt-pt"
  }, {
    path: "/pt-pt/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.pt-pt"
  }, {
    path: "/pt-pt/locations/portugal/braga",
    component: _31590994,
    name: "routes.locations.locationV2Braga.pt-pt"
  }, {
    path: "/pt-pt/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.pt-pt"
  }, {
    path: "/pt-pt/airports",
    component: _3a5f8279,
    name: "routes.airports.pt-pt"
  }, {
    path: "/pt-pt/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.pt-pt"
  }, {
    path: "/pt-pt/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.pt-pt"
  }, {
    path: "/pt-pt/companies",
    component: _759525d8,
    name: "routes.rentals.pt-pt"
  }, {
    path: "/pt-pt/companies/brasil",
    component: _6c9354ae,
    name: "routes.rentals.countryV2.pt-pt"
  }, {
    path: "/pt-pt/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.pt-pt"
  }, {
    path: "/pt-pt/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.pt-pt"
  }, {
    path: "/pt-pt/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.pt-pt"
  }, {
    path: "/pt-pt/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.pt-pt"
  }, {
    path: "/pt-pt/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.pt-pt"
  }, {
    path: "/pt-pt/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.pt-pt"
  }, {
    path: "/pt-pt/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.pt-pt"
  }, {
    path: "/pt-pt/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.pt-pt"
  }, {
    path: "/pt-pt/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.pt-pt"
  }, {
    path: "/pt-pt/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.pt-pt"
  }, {
    path: "/pt-pt/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.pt-pt"
  }, {
    path: "/pt-pt/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.pt-pt"
  }, {
    path: "/pt-pt/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.pt-pt"
  }, {
    path: "/pt-pt/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.pt-pt"
  }, {
    path: "/pt-pt/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.pt-pt"
  }, {
    path: "/pt-pt/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.pt-pt"
  }, {
    path: "/pt-pt/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.pt-pt"
  }, {
    path: "/pt-pt/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.pt-pt"
  }, {
    path: "/pt-pt/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.pt-pt"
  }, {
    path: "/pt-pt/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.pt-pt"
  }, {
    path: "/pt-pt/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.pt-pt"
  }, {
    path: "/pt-pt/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.pt-pt"
  }, {
    path: "/pt-pt/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.pt-pt"
  }, {
    path: "/pt-pt/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.pt-pt"
  }, {
    path: "/pt-pt/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.pt-pt"
  }, {
    path: "/pt-pt/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.pt-pt"
  }, {
    path: "/pt-pt/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.pt-pt"
  }, {
    path: "/pt-pt/reserva/mapa/:params",
    component: _769d3826,
    name: "routes.booking.map.pt-pt"
  }, {
    path: "/pt-pt/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.pt-pt"
  }, {
    path: "/pt-pt/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.pt-pt"
  }, {
    path: "/pt-pt/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.pt-pt"
  }, {
    path: "/pt-pt/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.pt-pt"
  }, {
    path: "/pt-pt/customer",
    component: _25775634,
    name: "routes.customer.login.pt-pt"
  }, {
    path: "/pt-pt/customer/register",
    redirect: (route) => ({ path: '/pt-pt/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.pt-pt"
  }, {
    path: "/pt-pt/customer/password-recover",
    redirect: (route) => ({ path: '/pt-pt/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.pt-pt"
  }, {
    path: "/pt-pt/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.pt-pt"
  }, {
    path: "/pt-pt/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.pt-pt"
  }, {
    path: "/pt-pt/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.pt-pt"
  }, {
    path: "/pt-pt/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.pt-pt"
  }, {
    path: "/pt-pt/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.pt-pt"
  }, {
    path: "/pt-pt/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.pt-pt"
  }, {
    path: "/pt-pt/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.pt-pt"
  }, {
    path: "/pt-pt/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.pt-pt"
  }, {
    path: "/pt-pt/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.pt-pt"
  }, {
    path: "/pt-pt/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.pt-pt"
  }, {
    path: "/pt-pt/settings",
    component: _e971c858,
    name: "routes.settings.pt-pt"
  }, {
    path: "/pt-pt/info",
    component: _38580a71,
    name: "routes.info.pt-pt"
  }, {
    path: "/pt-pt/info/about",
    component: _3c0692da,
    name: "routes.info.about.pt-pt"
  }, {
    path: "/pt-pt/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.pt-pt"
  }, {
    path: "/pt-pt/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.pt-pt"
  }, {
    path: "/pt-pt/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.pt-pt"
  }, {
    path: "/pt-pt/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.pt-pt"
  }, {
    path: "/pt-pt/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.pt-pt"
  }, {
    path: "/pt-pt/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.pt-pt"
  }, {
    path: "/pt-pt/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.pt-pt"
  }, {
    path: "/pt-pt/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.pt-pt"
  }, {
    path: "/pt-pt/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.pt-pt"
  }, {
    path: "/pt-pt/app",
    component: _abd18f28,
    name: "routes.app-page.pt-pt"
  }, {
    path: "/pt-pt/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.pt-pt"
  }, {
    path: "/pt-pt/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.pt-pt"
  }, {
    path: "/pt-pt/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.pt-pt"
  }, {
    path: "/pt-pt/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.pt-pt"
  }, {
    path: "/pt-pt/offline",
    component: _35f9ca28,
    name: "routes.offline.pt-pt"
  }, {
    path: "/pt-pt/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.pt-pt"
  }, {
    path: "/pt-pt/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.pt-pt"
  }, {
    path: "/pt-pt/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.pt-pt"
  }, {
    path: "/pt-pt/tap-miles-and-go",
    component: _7f36259b,
    redirect: "/pt-pt/",
    name: "routes.tapmilesandgo.pt-pt"
  }, {
    path: "/pt-pt/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.pt-pt"
  }, {
    path: "/pt-pt/rewards",
    component: _18bd3d30,
    name: "routes.rewards.pt-pt"
  }, {
    path: "/pt-pt/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.pt-pt"
  }, {
    path: "/pt-pt/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.pt-pt"
  }, {
    path: "/pt-pt/cashback",
    component: _aec9bba8,
    name: "routes.cashback.pt-pt"
  }, {
    path: "/pt-pt/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.pt-pt"
  }, {
    path: "/pt-pt/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.pt-pt"
  }, {
    path: "/pt-pt/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.pt-pt"
  }, {
    path: "/pt-pt/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.pt-pt"
  }, {
    path: "/pt-pt/coupons",
    component: _39eb065c,
    name: "routes.coupons.pt-pt"
  }, {
    path: "/pt-pt/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/pt-pt/', query: {}}),
    name: "routes.newsletter.confirmar.pt-pt"
  }, {
    path: "/pt-pt/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.pt-pt"
  }, {
    path: "/en-gb/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.en-gb"
  }, {
    path: "/en-gb/locations",
    component: _c115b93a,
    name: "routes.locations.en-gb"
  }, {
    path: "/en-gb/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.en-gb"
  }, {
    path: "/en-gb/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.en-gb"
  }, {
    path: "/en-gb/airports",
    component: _3a5f8279,
    name: "routes.airports.en-gb"
  }, {
    path: "/en-gb/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.en-gb"
  }, {
    path: "/en-gb/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.en-gb"
  }, {
    path: "/en-gb/companies",
    component: _759525d8,
    name: "routes.rentals.en-gb"
  }, {
    path: "/en-gb/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.en-gb"
  }, {
    path: "/en-gb/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.en-gb"
  }, {
    path: "/en-gb/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.en-gb"
  }, {
    path: "/en-gb/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.en-gb"
  }, {
    path: "/en-gb/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.en-gb"
  }, {
    path: "/en-gb/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.en-gb"
  }, {
    path: "/en-gb/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.en-gb"
  }, {
    path: "/en-gb/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.en-gb"
  }, {
    path: "/en-gb/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.en-gb"
  }, {
    path: "/en-gb/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.en-gb"
  }, {
    path: "/en-gb/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.en-gb"
  }, {
    path: "/en-gb/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.en-gb"
  }, {
    path: "/en-gb/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.en-gb"
  }, {
    path: "/en-gb/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.en-gb"
  }, {
    path: "/en-gb/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.en-gb"
  }, {
    path: "/en-gb/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.en-gb"
  }, {
    path: "/en-gb/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.en-gb"
  }, {
    path: "/en-gb/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.en-gb"
  }, {
    path: "/en-gb/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.en-gb"
  }, {
    path: "/en-gb/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.en-gb"
  }, {
    path: "/en-gb/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.en-gb"
  }, {
    path: "/en-gb/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.en-gb"
  }, {
    path: "/en-gb/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.en-gb"
  }, {
    path: "/en-gb/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.en-gb"
  }, {
    path: "/en-gb/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.en-gb"
  }, {
    path: "/en-gb/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.en-gb"
  }, {
    path: "/en-gb/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.en-gb"
  }, {
    path: "/en-gb/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.en-gb"
  }, {
    path: "/en-gb/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.en-gb"
  }, {
    path: "/en-gb/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.en-gb"
  }, {
    path: "/en-gb/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.en-gb"
  }, {
    path: "/en-gb/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.en-gb"
  }, {
    path: "/en-gb/customer",
    component: _25775634,
    name: "routes.customer.login.en-gb"
  }, {
    path: "/en-gb/customer/register",
    redirect: (route) => ({ path: '/en-gb/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.en-gb"
  }, {
    path: "/en-gb/customer/password-recover",
    redirect: (route) => ({ path: '/en-gb/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.en-gb"
  }, {
    path: "/en-gb/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.en-gb"
  }, {
    path: "/en-gb/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.en-gb"
  }, {
    path: "/en-gb/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.en-gb"
  }, {
    path: "/en-gb/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.en-gb"
  }, {
    path: "/en-gb/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.en-gb"
  }, {
    path: "/en-gb/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.en-gb"
  }, {
    path: "/en-gb/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.en-gb"
  }, {
    path: "/en-gb/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.en-gb"
  }, {
    path: "/en-gb/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.en-gb"
  }, {
    path: "/en-gb/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.en-gb"
  }, {
    path: "/en-gb/settings",
    component: _e971c858,
    name: "routes.settings.en-gb"
  }, {
    path: "/en-gb/info",
    component: _38580a71,
    name: "routes.info.en-gb"
  }, {
    path: "/en-gb/info/about",
    component: _3c0692da,
    name: "routes.info.about.en-gb"
  }, {
    path: "/en-gb/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.en-gb"
  }, {
    path: "/en-gb/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.en-gb"
  }, {
    path: "/en-gb/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.en-gb"
  }, {
    path: "/en-gb/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.en-gb"
  }, {
    path: "/en-gb/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.en-gb"
  }, {
    path: "/en-gb/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.en-gb"
  }, {
    path: "/en-gb/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.en-gb"
  }, {
    path: "/en-gb/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.en-gb"
  }, {
    path: "/en-gb/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.en-gb"
  }, {
    path: "/en-gb/app",
    component: _abd18f28,
    name: "routes.app-page.en-gb"
  }, {
    path: "/en-gb/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.en-gb"
  }, {
    path: "/en-gb/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.en-gb"
  }, {
    path: "/en-gb/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.en-gb"
  }, {
    path: "/en-gb/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.en-gb"
  }, {
    path: "/en-gb/offline",
    component: _35f9ca28,
    name: "routes.offline.en-gb"
  }, {
    path: "/en-gb/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.en-gb"
  }, {
    path: "/en-gb/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.en-gb"
  }, {
    path: "/en-gb/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.en-gb"
  }, {
    path: "/en-gb/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.en-gb"
  }, {
    path: "/en-gb/rewards",
    component: _18bd3d30,
    name: "routes.rewards.en-gb"
  }, {
    path: "/en-gb/cliente/minha-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.en-gb"
  }, {
    path: "/en-gb/minha-carteira",
    component: _8db28168,
    name: "routes.customer.wallet.en-gb"
  }, {
    path: "/en-gb/cashback",
    component: _aec9bba8,
    name: "routes.cashback.en-gb"
  }, {
    path: "/en-gb/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.en-gb"
  }, {
    path: "/en-gb/electric-car-hire",
    component: _ad636294,
    name: "routes.electric-car-rental.en-gb"
  }, {
    path: "/en-gb/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.en-gb"
  }, {
    path: "/en-gb/travelclub",
    component: _7f36259b,
    name: "routes.travelclub.en-gb"
  }, {
    path: "/en-gb/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.en-gb"
  }, {
    path: "/en-gb/coupons",
    component: _39eb065c,
    name: "routes.coupons.en-gb"
  }, {
    path: "/en-gb/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/en-gb/', query: {}}),
    name: "routes.newsletter.confirmar.en-gb"
  }, {
    path: "/en-gb/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.en-gb"
  }, {
    path: "/nl-nl/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.nl-nl"
  }, {
    path: "/nl-nl/locations",
    component: _c115b93a,
    name: "routes.locations.nl-nl"
  }, {
    path: "/nl-nl/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.nl-nl"
  }, {
    path: "/nl-nl/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.nl-nl"
  }, {
    path: "/nl-nl/airports",
    component: _3a5f8279,
    name: "routes.airports.nl-nl"
  }, {
    path: "/nl-nl/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.nl-nl"
  }, {
    path: "/nl-nl/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.nl-nl"
  }, {
    path: "/nl-nl/companies",
    component: _759525d8,
    name: "routes.rentals.nl-nl"
  }, {
    path: "/nl-nl/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.nl-nl"
  }, {
    path: "/nl-nl/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.nl-nl"
  }, {
    path: "/nl-nl/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.nl-nl"
  }, {
    path: "/nl-nl/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.nl-nl"
  }, {
    path: "/nl-nl/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.nl-nl"
  }, {
    path: "/nl-nl/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.nl-nl"
  }, {
    path: "/nl-nl/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.nl-nl"
  }, {
    path: "/nl-nl/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.nl-nl"
  }, {
    path: "/nl-nl/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.nl-nl"
  }, {
    path: "/nl-nl/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.nl-nl"
  }, {
    path: "/nl-nl/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.nl-nl"
  }, {
    path: "/nl-nl/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.nl-nl"
  }, {
    path: "/nl-nl/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.nl-nl"
  }, {
    path: "/nl-nl/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.nl-nl"
  }, {
    path: "/nl-nl/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.nl-nl"
  }, {
    path: "/nl-nl/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.nl-nl"
  }, {
    path: "/nl-nl/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.nl-nl"
  }, {
    path: "/nl-nl/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.nl-nl"
  }, {
    path: "/nl-nl/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.nl-nl"
  }, {
    path: "/nl-nl/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.nl-nl"
  }, {
    path: "/nl-nl/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.nl-nl"
  }, {
    path: "/nl-nl/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.nl-nl"
  }, {
    path: "/nl-nl/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.nl-nl"
  }, {
    path: "/nl-nl/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.nl-nl"
  }, {
    path: "/nl-nl/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.nl-nl"
  }, {
    path: "/nl-nl/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.nl-nl"
  }, {
    path: "/nl-nl/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.nl-nl"
  }, {
    path: "/nl-nl/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.nl-nl"
  }, {
    path: "/nl-nl/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.nl-nl"
  }, {
    path: "/nl-nl/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.nl-nl"
  }, {
    path: "/nl-nl/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.nl-nl"
  }, {
    path: "/nl-nl/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.nl-nl"
  }, {
    path: "/nl-nl/customer",
    component: _25775634,
    name: "routes.customer.login.nl-nl"
  }, {
    path: "/nl-nl/customer/register",
    redirect: (route) => ({ path: '/nl-nl/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.nl-nl"
  }, {
    path: "/nl-nl/customer/password-recover",
    redirect: (route) => ({ path: '/nl-nl/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.nl-nl"
  }, {
    path: "/nl-nl/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.nl-nl"
  }, {
    path: "/nl-nl/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.nl-nl"
  }, {
    path: "/nl-nl/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.nl-nl"
  }, {
    path: "/nl-nl/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.nl-nl"
  }, {
    path: "/nl-nl/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.nl-nl"
  }, {
    path: "/nl-nl/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.nl-nl"
  }, {
    path: "/nl-nl/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.nl-nl"
  }, {
    path: "/nl-nl/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.nl-nl"
  }, {
    path: "/nl-nl/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.nl-nl"
  }, {
    path: "/nl-nl/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.nl-nl"
  }, {
    path: "/nl-nl/settings",
    component: _e971c858,
    name: "routes.settings.nl-nl"
  }, {
    path: "/nl-nl/info",
    component: _38580a71,
    name: "routes.info.nl-nl"
  }, {
    path: "/nl-nl/info/about",
    component: _3c0692da,
    name: "routes.info.about.nl-nl"
  }, {
    path: "/nl-nl/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.nl-nl"
  }, {
    path: "/nl-nl/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.nl-nl"
  }, {
    path: "/nl-nl/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.nl-nl"
  }, {
    path: "/nl-nl/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.nl-nl"
  }, {
    path: "/nl-nl/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.nl-nl"
  }, {
    path: "/nl-nl/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.nl-nl"
  }, {
    path: "/nl-nl/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.nl-nl"
  }, {
    path: "/nl-nl/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.nl-nl"
  }, {
    path: "/nl-nl/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.nl-nl"
  }, {
    path: "/nl-nl/app",
    component: _abd18f28,
    name: "routes.app-page.nl-nl"
  }, {
    path: "/nl-nl/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.nl-nl"
  }, {
    path: "/nl-nl/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.nl-nl"
  }, {
    path: "/nl-nl/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.nl-nl"
  }, {
    path: "/nl-nl/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.nl-nl"
  }, {
    path: "/nl-nl/offline",
    component: _35f9ca28,
    name: "routes.offline.nl-nl"
  }, {
    path: "/nl-nl/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.nl-nl"
  }, {
    path: "/nl-nl/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.nl-nl"
  }, {
    path: "/nl-nl/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.nl-nl"
  }, {
    path: "/nl-nl/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.nl-nl"
  }, {
    path: "/nl-nl/rewards",
    component: _18bd3d30,
    name: "routes.rewards.nl-nl"
  }, {
    path: "/nl-nl/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.nl-nl"
  }, {
    path: "/nl-nl/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.nl-nl"
  }, {
    path: "/nl-nl/cashback",
    component: _aec9bba8,
    name: "routes.cashback.nl-nl"
  }, {
    path: "/nl-nl/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.nl-nl"
  }, {
    path: "/nl-nl/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.nl-nl"
  }, {
    path: "/nl-nl/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.nl-nl"
  }, {
    path: "/nl-nl/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.nl-nl"
  }, {
    path: "/nl-nl/coupons",
    component: _39eb065c,
    name: "routes.coupons.nl-nl"
  }, {
    path: "/nl-nl/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/nl-nl/', query: {}}),
    name: "routes.newsletter.confirmar.nl-nl"
  }, {
    path: "/nl-nl/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.nl-nl"
  }, {
    path: "/de-de/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.de-de"
  }, {
    path: "/de-de/locations",
    component: _c115b93a,
    name: "routes.locations.de-de"
  }, {
    path: "/de-de/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.de-de"
  }, {
    path: "/de-de/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.de-de"
  }, {
    path: "/de-de/airports",
    component: _3a5f8279,
    name: "routes.airports.de-de"
  }, {
    path: "/de-de/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.de-de"
  }, {
    path: "/de-de/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.de-de"
  }, {
    path: "/de-de/companies",
    component: _759525d8,
    name: "routes.rentals.de-de"
  }, {
    path: "/de-de/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.de-de"
  }, {
    path: "/de-de/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.de-de"
  }, {
    path: "/de-de/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.de-de"
  }, {
    path: "/de-de/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.de-de"
  }, {
    path: "/de-de/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.de-de"
  }, {
    path: "/de-de/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.de-de"
  }, {
    path: "/de-de/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.de-de"
  }, {
    path: "/de-de/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.de-de"
  }, {
    path: "/de-de/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.de-de"
  }, {
    path: "/de-de/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.de-de"
  }, {
    path: "/de-de/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.de-de"
  }, {
    path: "/de-de/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.de-de"
  }, {
    path: "/de-de/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.de-de"
  }, {
    path: "/de-de/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.de-de"
  }, {
    path: "/de-de/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.de-de"
  }, {
    path: "/de-de/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.de-de"
  }, {
    path: "/de-de/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.de-de"
  }, {
    path: "/de-de/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.de-de"
  }, {
    path: "/de-de/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.de-de"
  }, {
    path: "/de-de/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.de-de"
  }, {
    path: "/de-de/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.de-de"
  }, {
    path: "/de-de/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.de-de"
  }, {
    path: "/de-de/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.de-de"
  }, {
    path: "/de-de/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.de-de"
  }, {
    path: "/de-de/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.de-de"
  }, {
    path: "/de-de/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.de-de"
  }, {
    path: "/de-de/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.de-de"
  }, {
    path: "/de-de/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.de-de"
  }, {
    path: "/de-de/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.de-de"
  }, {
    path: "/de-de/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.de-de"
  }, {
    path: "/de-de/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.de-de"
  }, {
    path: "/de-de/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.de-de"
  }, {
    path: "/de-de/customer",
    component: _25775634,
    name: "routes.customer.login.de-de"
  }, {
    path: "/de-de/customer/register",
    redirect: (route) => ({ path: '/de-de/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.de-de"
  }, {
    path: "/de-de/customer/password-recover",
    redirect: (route) => ({ path: '/de-de/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.de-de"
  }, {
    path: "/de/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.de"
  }, {
    path: "/de-de/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.de-de"
  }, {
    path: "/de-de/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.de-de"
  }, {
    path: "/de-de/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.de-de"
  }, {
    path: "/de-de/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.de-de"
  }, {
    path: "/de-de/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.de-de"
  }, {
    path: "/de-de/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.de-de"
  }, {
    path: "/de-de/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.de-de"
  }, {
    path: "/de-de/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.de-de"
  }, {
    path: "/de-de/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.de-de"
  }, {
    path: "/de-de/settings",
    component: _e971c858,
    name: "routes.settings.de-de"
  }, {
    path: "/de-de/info",
    component: _38580a71,
    name: "routes.info.de-de"
  }, {
    path: "/de-de/info/about",
    component: _3c0692da,
    name: "routes.info.about.de-de"
  }, {
    path: "/de-de/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.de-de"
  }, {
    path: "/de-de/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.de-de"
  }, {
    path: "/de-de/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.de-de"
  }, {
    path: "/de-de/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.de-de"
  }, {
    path: "/de-de/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.de-de"
  }, {
    path: "/de-de/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.de-de"
  }, {
    path: "/de-de/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.de-de"
  }, {
    path: "/de-de/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.de-de"
  }, {
    path: "/de-de/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.de-de"
  }, {
    path: "/de-de/app",
    component: _abd18f28,
    name: "routes.app-page.de-de"
  }, {
    path: "/de-de/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.de-de"
  }, {
    path: "/de-de/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.de-de"
  }, {
    path: "/de-de/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.de-de"
  }, {
    path: "/de-de/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.de-de"
  }, {
    path: "/de-de/offline",
    component: _35f9ca28,
    name: "routes.offline.de-de"
  }, {
    path: "/de-de/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.de-de"
  }, {
    path: "/de-de/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.de-de"
  }, {
    path: "/de-de/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.de-de"
  }, {
    path: "/de-de/tap-miles-and-go",
    component: _7f36259b,
    redirect: "/de-de/",
    name: "routes.tapmilesandgo.de-de"
  }, {
    path: "/de-de/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.de-de"
  }, {
    path: "/de-de/rewards",
    component: _18bd3d30,
    name: "routes.rewards.de-de"
  }, {
    path: "/de-de/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.de-de"
  }, {
    path: "/de-de/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.de-de"
  }, {
    path: "/de-de/cashback",
    component: _aec9bba8,
    name: "routes.cashback.de-de"
  }, {
    path: "/de-de/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.de-de"
  }, {
    path: "/de-de/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.de-de"
  }, {
    path: "/de-de/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.de-de"
  }, {
    path: "/de-de/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.de-de"
  }, {
    path: "/de-de/coupons",
    component: _39eb065c,
    name: "routes.coupons.de-de"
  }, {
    path: "/de-de/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/de-de/', query: {}}),
    name: "routes.newsletter.confirmar.de-de"
  }, {
    path: "/de-de/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.de-de"
  }, {
    path: "/fr-fr/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.fr-fr"
  }, {
    path: "/fr-fr/locations",
    component: _c115b93a,
    name: "routes.locations.fr-fr"
  }, {
    path: "/fr-fr/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.fr-fr"
  }, {
    path: "/fr-fr/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.fr-fr"
  }, {
    path: "/fr-fr/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.fr-fr"
  }, {
    path: "/fr-fr/airports",
    component: _3a5f8279,
    name: "routes.airports.fr-fr"
  }, {
    path: "/fr-fr/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.fr-fr"
  }, {
    path: "/fr-fr/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.fr-fr"
  }, {
    path: "/fr-fr/companies",
    component: _759525d8,
    name: "routes.rentals.fr-fr"
  }, {
    path: "/fr-fr/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.fr-fr"
  }, {
    path: "/fr-fr/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.fr-fr"
  }, {
    path: "/fr-fr/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.fr-fr"
  }, {
    path: "/fr-fr/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.fr-fr"
  }, {
    path: "/fr-fr/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.fr-fr"
  }, {
    path: "/fr-fr/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.fr-fr"
  }, {
    path: "/fr-fr/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.fr-fr"
  }, {
    path: "/fr-fr/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.fr-fr"
  }, {
    path: "/fr-fr/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.fr-fr"
  }, {
    path: "/fr-fr/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.fr-fr"
  }, {
    path: "/fr-fr/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.fr-fr"
  }, {
    path: "/fr-fr/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.fr-fr"
  }, {
    path: "/fr-fr/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.fr-fr"
  }, {
    path: "/fr-fr/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.fr-fr"
  }, {
    path: "/fr-fr/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.fr-fr"
  }, {
    path: "/fr-fr/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.fr-fr"
  }, {
    path: "/fr-fr/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.fr-fr"
  }, {
    path: "/fr-fr/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.fr-fr"
  }, {
    path: "/fr-fr/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.fr-fr"
  }, {
    path: "/fr-fr/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.fr-fr"
  }, {
    path: "/fr-fr/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.fr-fr"
  }, {
    path: "/fr-fr/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.fr-fr"
  }, {
    path: "/fr-fr/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.fr-fr"
  }, {
    path: "/fr-fr/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.fr-fr"
  }, {
    path: "/fr-fr/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.fr-fr"
  }, {
    path: "/fr-fr/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.fr-fr"
  }, {
    path: "/fr-fr/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.fr-fr"
  }, {
    path: "/fr-fr/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.fr-fr"
  }, {
    path: "/fr-fr/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.fr-fr"
  }, {
    path: "/fr-fr/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.fr-fr"
  }, {
    path: "/fr-fr/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.fr-fr"
  }, {
    path: "/fr-fr/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.fr-fr"
  }, {
    path: "/fr-fr/customer",
    component: _25775634,
    name: "routes.customer.login.fr-fr"
  }, {
    path: "/fr-fr/customer/register",
    redirect: (route) => ({ path: '/fr-fr/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.fr-fr"
  }, {
    path: "/fr-fr/customer/password-recover",
    redirect: (route) => ({ path: '/fr-fr/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.fr-fr"
  }, {
    path: "/fr-fr/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.fr-fr"
  }, {
    path: "/fr-fr/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.fr-fr"
  }, {
    path: "/fr-fr/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.fr-fr"
  }, {
    path: "/fr-fr/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.fr-fr"
  }, {
    path: "/fr-fr/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.fr-fr"
  }, {
    path: "/fr-fr/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.fr-fr"
  }, {
    path: "/fr-fr/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.fr-fr"
  }, {
    path: "/fr-fr/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.fr-fr"
  }, {
    path: "/fr-fr/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.fr-fr"
  }, {
    path: "/fr-fr/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.fr-fr"
  }, {
    path: "/fr-fr/settings",
    component: _e971c858,
    name: "routes.settings.fr-fr"
  }, {
    path: "/fr-fr/info",
    component: _38580a71,
    name: "routes.info.fr-fr"
  }, {
    path: "/fr-fr/info/about",
    component: _3c0692da,
    name: "routes.info.about.fr-fr"
  }, {
    path: "/fr-fr/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.fr-fr"
  }, {
    path: "/fr-fr/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.fr-fr"
  }, {
    path: "/fr-fr/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.fr-fr"
  }, {
    path: "/fr-fr/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.fr-fr"
  }, {
    path: "/fr-fr/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.fr-fr"
  }, {
    path: "/fr-fr/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.fr-fr"
  }, {
    path: "/fr-fr/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.fr-fr"
  }, {
    path: "/fr-fr/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.fr-fr"
  }, {
    path: "/fr-fr/app",
    component: _abd18f28,
    name: "routes.app-page.fr-fr"
  }, {
    path: "/fr-fr/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.fr-fr"
  }, {
    path: "/fr-fr/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.fr-fr"
  }, {
    path: "/fr-fr/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.fr-fr"
  }, {
    path: "/fr-fr/offline",
    component: _35f9ca28,
    name: "routes.offline.fr-fr"
  }, {
    path: "/fr-fr/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.fr-fr"
  }, {
    path: "/fr-fr/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.fr-fr"
  }, {
    path: "/fr-fr/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.fr-fr"
  }, {
    path: "/fr-fr/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.fr-fr"
  }, {
    path: "/fr-fr/tap-miles-and-go",
    component: _7f36259b,
    redirect: "/fr-fr/",
    name: "routes.tapmilesandgo.fr-fr"
  }, {
    path: "/fr-fr/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.fr-fr"
  }, {
    path: "/fr-fr/rewards",
    component: _18bd3d30,
    name: "routes.rewards.fr-fr"
  }, {
    path: "/fr-fr/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.fr-fr"
  }, {
    path: "/fr-fr/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.fr-fr"
  }, {
    path: "/fr-fr/cashback",
    component: _aec9bba8,
    name: "routes.cashback.fr-fr"
  }, {
    path: "/fr-fr/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.fr-fr"
  }, {
    path: "/fr-fr/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.fr-fr"
  }, {
    path: "/fr-fr/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.fr-fr"
  }, {
    path: "/fr-fr/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.fr-fr"
  }, {
    path: "/fr-fr/coupons",
    component: _39eb065c,
    name: "routes.coupons.fr-fr"
  }, {
    path: "/fr-fr/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/fr-fr/', query: {}}),
    name: "routes.newsletter.confirmar.fr-fr"
  }, {
    path: "/fr-fr/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.fr-fr"
  }, {
    path: "/fr-ca/",
    component: _655f96e0,
    pathToRegexpOptions: {"strict":true},
    name: "routes.home.fr-ca"
  }, {
    path: "/fr-ca/locations",
    component: _c115b93a,
    name: "routes.locations.fr-ca"
  }, {
    path: "/fr-ca/locations/:countrySlug",
    component: _2d97f5e7,
    name: "routes.locations.country.fr-ca"
  }, {
    path: "/fr-ca/locations/:countrySlug/:locationSlug",
    component: _31590994,
    name: "routes.locations.location.fr-ca"
  }, {
    path: "/fr-ca/airports",
    component: _3a5f8279,
    name: "routes.airports.fr-ca"
  }, {
    path: "/fr-ca/airports/:countrySlug",
    component: _91323106,
    name: "routes.airports.country.fr-ca"
  }, {
    path: "/fr-ca/airports/:countrySlug/:locationSlug",
    component: _47643d44,
    name: "routes.airports.location.fr-ca"
  }, {
    path: "/fr-ca/companies",
    component: _759525d8,
    name: "routes.rentals.fr-ca"
  }, {
    path: "/fr-ca/companies/:countrySlug",
    component: _3726d150,
    name: "routes.rentals.country.fr-ca"
  }, {
    path: "/fr-ca/company/:companySlug/:countrySlug/airports/:locationSlug?",
    component: _76088604,
    name: "routes.rental.airport.fr-ca"
  }, {
    path: "/fr-ca/company/:companySlug",
    component: _67ad25d6,
    name: "routes.rental.fr-ca"
  }, {
    path: "/fr-ca/company/:companySlug/:countrySlug",
    component: _033ec9ce,
    name: "routes.rental.country.fr-ca"
  }, {
    path: "/fr-ca/company/:companySlug/:countrySlug/:locationSlug",
    component: _748aa142,
    name: "routes.rental.location.fr-ca"
  }, {
    path: "/fr-ca/faq/:folder?/:question?",
    component: _2e18aeca,
    name: "routes.faq.fr-ca"
  }, {
    path: "/fr-ca/best-rates",
    component: _12c7984a,
    name: "routes.best-rates.fr-ca"
  }, {
    path: "/fr-ca/invite-friends",
    component: _699a0908,
    name: "routes.member-get-member.fr-ca"
  }, {
    path: "/fr-ca/c/:urlCode/:couponCode",
    component: _758f0a0f,
    name: "routes.invite.code.fr-ca"
  }, {
    path: "/fr-ca/booking/list/:params",
    component: _b121dd2c,
    name: "routes.search.list.fr-ca"
  }, {
    path: "/fr-ca/quote/:params/:plan/:currency/:residenceCode",
    component: _b178a5c6,
    name: "routes.save-quote.fr-ca"
  }, {
    path: "/fr-ca/booking/filter",
    component: _d20048f8,
    name: "routes.booking.filters.fr-ca"
  }, {
    path: "/fr-ca/booking/view/:params/:plan/:currency",
    component: _690dad1d,
    name: "routes.booking.detail.fr-ca"
  }, {
    path: "/fr-ca/booking/configuration/:params/:plan/:currency/:residenceCode?/:quote?",
    component: _24d111f0,
    name: "routes.booking.configure.fr-ca"
  }, {
    path: "/fr-ca/flight-information",
    component: _232fbe7c,
    name: "routes.configure.flight.fr-ca"
  }, {
    path: "/fr-ca/modify-rental-location/:type",
    component: _02f00008,
    name: "routes.configure.change-store.fr-ca"
  }, {
    path: "/fr-ca/change-plan",
    component: _85a4b320,
    name: "routes.configure.change-plan.fr-ca"
  }, {
    path: "/fr-ca/change-optionals",
    component: _40b1fc82,
    name: "routes.configure.change-optionals.fr-ca"
  }, {
    path: "/fr-ca/change-coverages",
    component: _2c95260c,
    name: "routes.configure.change-coverages.fr-ca"
  }, {
    path: "/fr-ca/change-search",
    component: _914ef9a2,
    name: "routes.search.change-search.fr-ca"
  }, {
    path: "/fr-ca/booking-duplicity",
    component: _4b00a306,
    name: "routes.search.booking-duplicity.fr-ca"
  }, {
    path: "/fr-ca/booking/:param?",
    component: _09b703b9,
    name: "routes.search.unavailable-vehicle.fr-ca"
  }, {
    path: "/fr-ca/booking/identification",
    component: _d3e9d40c,
    name: "routes.booking.identification.fr-ca"
  }, {
    path: "/fr-ca/booking/payment/:code",
    component: _487288d0,
    name: "routes.booking.payment.fr-ca"
  }, {
    path: "/fr-ca/booking/payment/insurance/:code",
    component: _487288d0,
    name: "routes.booking.payment.insurance.fr-ca"
  }, {
    path: "/fr-ca/booking/confirmation/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.fr-ca"
  }, {
    path: "/fr-ca/booking/confirmation/insurance/:code",
    component: _076cdb41,
    name: "routes.booking.confirmation.insurance.fr-ca"
  }, {
    path: "/fr-ca/reserva/map/:params",
    component: _769d3826,
    name: "routes.booking.map.fr-ca"
  }, {
    path: "/fr-ca/customer/my-reservations/review/:bookingCode",
    component: _96345828,
    name: "routes.customer.booking.evaluate.fr-ca"
  }, {
    path: "/fr-ca/book/:country/:locality",
    component: _b121dd2c,
    name: "routes.search.book-no-date.fr-ca"
  }, {
    path: "/fr-ca/book/:locality",
    component: _b121dd2c,
    name: "routes.search.old-book-no-date.fr-ca"
  }, {
    path: "/fr-ca/recent-researches",
    component: _2643ce5c,
    name: "routes.recentSearches.fr-ca"
  }, {
    path: "/fr-ca/customer",
    component: _25775634,
    name: "routes.customer.login.fr-ca"
  }, {
    path: "/fr-ca/customer/register",
    redirect: (route) => ({ path: '/fr-ca/', query: { ...route.query, auth: true }}),
    name: "routes.customer.signup.fr-ca"
  }, {
    path: "/fr-ca/customer/password-recover",
    redirect: (route) => ({ path: '/fr-ca/', query: { ...route.query, auth: true }}),
    name: "routes.customer.password-recover.fr-ca"
  }, {
    path: "/fr-ca/my-account/new_password",
    component: _3756a401,
    name: "routes.customer.password-new.fr-ca"
  }, {
    path: "/fr-ca/customer/my-account",
    component: _5c8eaf2c,
    name: "routes.customer.account.fr-ca"
  }, {
    path: "/fr-ca/customer/my-account/profile",
    component: _aea0ec34,
    name: "routes.customer.profile.fr-ca"
  }, {
    path: "/fr-ca/customer/my-account/access-information",
    component: _34108852,
    name: "routes.customer.account-access.fr-ca"
  }, {
    path: "/fr-ca/customer/my-reservations",
    component: _74ce6995,
    name: "routes.customer.bookings.fr-ca"
  }, {
    path: "/fr-ca/customer/my-reservations/old",
    component: _74ce6995,
    name: "routes.customer.bookings.old.fr-ca"
  }, {
    path: "/fr-ca/customer/my-reservations/booking/:bookingCode",
    component: _0b767f1b,
    name: "routes.customer.booking.details.fr-ca"
  }, {
    path: "/fr-ca/customer/my-reservations/booking/change/:bookingCode",
    component: _db72b00e,
    name: "routes.customer.booking.change.fr-ca"
  }, {
    path: "/fr-ca/customer/my-reservations/booking/cancel/:bookingCode",
    component: _fc29647a,
    name: "routes.customer.booking.cancel.fr-ca"
  }, {
    path: "/fr-ca/customer/my-reservations/reservation/voucher/:bookingCode/:locale?",
    component: _6e8d9296,
    name: "routes.customer.booking.voucher.fr-ca"
  }, {
    path: "/fr-ca/settings",
    component: _e971c858,
    name: "routes.settings.fr-ca"
  }, {
    path: "/fr-ca/info",
    component: _38580a71,
    name: "routes.info.fr-ca"
  }, {
    path: "/fr-ca/info/about",
    component: _3c0692da,
    name: "routes.info.about.fr-ca"
  }, {
    path: "/fr-ca/info/contact",
    component: _572a18ad,
    name: "routes.info.contact.fr-ca"
  }, {
    path: "/fr-ca/info/privacy",
    component: _a353b7d6,
    name: "routes.info.privacy.fr-ca"
  }, {
    path: "/fr-ca/info/cookies",
    component: _21dd30c8,
    name: "routes.info.cookies.fr-ca"
  }, {
    path: "/fr-ca/info/requirements-for-car-rental",
    component: _3a19935a,
    name: "routes.info.requirements.fr-ca"
  }, {
    path: "/fr-ca/info/payment-instructions",
    component: _0e2c78da,
    name: "routes.info.payment.fr-ca"
  }, {
    path: "/fr-ca/info/terms-and-conditions",
    component: _296bb7fe,
    name: "routes.info.terms-conditions.fr-ca"
  }, {
    path: "/fr-ca/info/regulamento-rent-protection",
    component: _34cd4ba8,
    name: "routes.info.regulamento-rent-protection.fr-ca"
  }, {
    path: "/fr-ca/info/code-conduct",
    component: _53157d27,
    name: "routes.info.code-conduct.fr-ca"
  }, {
    path: "/fr-ca/info/anti-discrimination-policy",
    component: _297ecbb0,
    name: "routes.info.anti-discrimination-policy.fr-ca"
  }, {
    path: "/fr-ca/app",
    component: _abd18f28,
    name: "routes.app-page.fr-ca"
  }, {
    path: "/fr-ca/best-deals",
    component: _64454e9e,
    name: "routes.best-deals.fr-ca"
  }, {
    path: "/fr-ca/blackfriday",
    component: _fac59888,
    name: "routes.blackfriday.fr-ca"
  }, {
    path: "/fr-ca/cybermonday",
    component: _406d0952,
    name: "routes.cybermonday.fr-ca"
  }, {
    path: "/fr-ca/traveltuesday",
    component: _040b3a66,
    name: "routes.traveltuesday.fr-ca"
  }, {
    path: "/fr-ca/offline",
    component: _35f9ca28,
    name: "routes.offline.fr-ca"
  }, {
    path: "/fr-ca/booking/quote/:params/:plan/:currency/:quoteCode",
    component: _690dad1d,
    name: "routes.booking.quote.fr-ca"
  }, {
    path: "/fr-ca/car-rental-tips",
    component: _689a163c,
    name: "routes.car-hire-tips.fr-ca"
  }, {
    path: "/fr-ca/lp/:slug",
    component: _0ccd2fb8,
    name: "routes.lpbuilder.fr-ca"
  }, {
    path: "/fr-ca/preferences/cookies",
    component: _4a29d23c,
    name: "routes.info.cookies-preferences.fr-ca"
  }, {
    path: "/fr-ca/rewards",
    component: _18bd3d30,
    name: "routes.rewards.fr-ca"
  }, {
    path: "/fr-ca/cliente/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.fr-ca"
  }, {
    path: "/fr-ca/my-wallet",
    component: _8db28168,
    name: "routes.customer.wallet.fr-ca"
  }, {
    path: "/fr-ca/cashback",
    component: _aec9bba8,
    name: "routes.cashback.fr-ca"
  }, {
    path: "/fr-ca/chat-status",
    component: _09148e4c,
    name: "routes.chat-status.fr-ca"
  }, {
    path: "/fr-ca/electric-car-rental",
    component: _ad636294,
    name: "routes.electric-car-rental.fr-ca"
  }, {
    path: "/fr-ca/one-way-car-rental",
    component: _67beef68,
    name: "routes.one-way.fr-ca"
  }, {
    path: "/fr-ca/yellow-week",
    component: _040b3a66,
    name: "routes.yellow-week.fr-ca"
  }, {
    path: "/fr-ca/coupons",
    component: _39eb065c,
    name: "routes.coupons.fr-ca"
  }, {
    path: "/fr-ca/cliente/newsletter/confirmar/:email/:token",
    redirect: () => ({ path: '/fr-ca/', query: {}}),
    name: "routes.newsletter.confirmar.fr-ca"
  }, {
    path: "/fr-ca/qatarairways",
    component: _7f36259b,
    name: "routes.qatarairways.fr-ca"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
