import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Moment from 'moment-timezone'
import VeeValidate from 'vee-validate'
import merge from 'lodash/merge'

Vue.use(VueI18n)

export default ({ app, store, route }, inject) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: store.getters.getLocale,
    fallbackLocale: 'en',
    silentTranslationWarn: process.env.NODE_ENV === 'production'
  })

  const loadedLanguages = {} // our default language that is preloaded

  function setI18nLanguage(lang) {
    app.i18n.locale = lang
    app.i18n.momentLocale = lang
    const languages = ['es-ar', 'es-co', 'es-cl', 'fr-fr', 'it-it', 'nl-nl', 'de-de', 'pt-pt']
    if (languages.includes(lang)) {
      app.i18n.momentLocale = lang.split('-')[0]
    }
    return lang
  }

  const loadLanguageAsync = function loadLanguageAsync(lang, currentRoute = null) {
    let importLocalesPath = null
    currentRoute = (currentRoute) || route.name

    const page = getPageType(lang, currentRoute)

    // If the language was already loaded
    if (lang in loadedLanguages && loadedLanguages[lang].indexOf(page) !== -1) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    if (page === 'home') {
      importLocalesPath = import(/* webpackChunkName: "lang2-[request]" */ `~/../locales/${lang}/home.js`)
    } else if (page === 'entry-pages') {
      importLocalesPath = import(/* webpackChunkName: "lang3-[request]" */ `~/../locales/${lang}/entry-pages.js`)
    } else {
      importLocalesPath = import(/* webpackChunkName: "lang-[request]" */ `~/../locales/${lang}/index.js`)
    }

    return importLocalesPath
      .then((messages) => {
        VeeValidate.Validator.localize(lang, messages.default.validator)

        Moment.locale(lang, {
          monthsShort: messages.default.pickadate.monthsShort
        })

        let newMessages = messages.default

        if (lang in app.i18n.messages) {
          newMessages = merge(newMessages, app.i18n.messages[lang])
        }

        app.i18n.setLocaleMessage(lang, newMessages)
        if (loadedLanguages[lang]) {
          loadedLanguages[lang].push(page)
        } else {
          loadedLanguages[lang] = [page]
        }

        return setI18nLanguage(lang)
      })
  }

  function getPageType (lang, currentRoute) {
    let page = 'default'

    const entryPagesRoutes = [
      'airports.locationV2',
      'airports.locationV2Confins',
      'airports.locationV2Guararapes',
      'locations.locationV2',
      'locations.locationV2SP',
      'locations.locationV2Cancun',
      'locations.locationV2Braga',
      'locations.countryV2',
      'locations.countryV2MX',
      'locations.countryV2PT',
      'airports.countryV2BR',
      'rentals.countryV2ES',
      'rental.locationV2AVIS',
      'rental.locationV2REC',
      'rental.locationV2MCO',
      'rental.countryV2MEX',
      'rental.rentalV2FOCO',
      'rental.rentalV2LOC',
      'rental.rentalV2DOL',
      'locations.location'
    ]

    if (currentRoute === `routes.home.${lang}`) {
      page = 'home'
    }

    if (entryPagesRoutes.some(route => currentRoute === `routes.${route}.${lang}`)) {
      page = 'entry-pages'
    }

    return page
  }

  inject('loadLanguageAsync', loadLanguageAsync)
}
