import insiderEnum from '~/services/Insider/InsiderEnum'

export default function cashbackToByPay(cashback, type = insiderEnum.CASHBACK_BR_VALUE) {
  if (!cashback) {
    return undefined
  }

  if (type === insiderEnum.CASHBACK_BR_VALUE) {
    return cashback.value
  }

  if (type === insiderEnum.CASHBACK_BR_FORMATTED_VALUE) {
    return cashback.formattedValue
  }

  if (type === insiderEnum.CASHBACK_TYPE_VERIFY) {
    return insiderEnum.CASHBACK
  }
}
