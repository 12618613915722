export const state = () => ({
  filterConfigurations: null,
  filterCategories: null,
  filterRentals: null,
  filterEvaluation: null,
  filterMultipleRates: false,
  filterMultiplePayments: false,
  filterPromotions: null,
  filterProtections: null,
  filterOptionals: null,
  appliedRate: [],
  appliedConfigurations: [],
  appliedProtections: [],
  appliedOptionals: [],
  appliedCategories: [],
  appliedRentals: [],
  appliedPayment: [],
  appliedEvaluation: [],
  appliedPromotions: false,
  appliedClear: false
})

export const actions = {
  SET_FILTER_CONFIGURATIONS({ commit }, config) {
    commit('SET_FILTER_CONFIGURATIONS', config)
  },
  SET_FILTER_CATEGORIES({ commit }, categories) {
    commit('SET_FILTER_CATEGORIES', categories)
  },
  SET_FILTER_RENTALS({ commit }, rentals) {
    commit('SET_FILTER_RENTALS', rentals)
  },
  SET_FILTER_MULTIPLE_RATES({ commit }, rates) {
    commit('SET_FILTER_MULTIPLE_RATES', rates)
  },
  SET_FILTER_EVALUATION({ commit }, evaluation) {
    commit('SET_FILTER_EVALUATION', evaluation)
  },
  SET_FILTER_MULTIPLE_PAYMENTS({ commit }, payments) {
    commit('SET_FILTER_MULTIPLE_PAYMENTS', payments)
  },
  SET_FILTER_PROMOTIONS({ commit }, promotions) {
    commit('SET_FILTER_PROMOTIONS', promotions)
  },
  SET_FILTER_PROTECTIONS({ commit }, protections) {
    commit('SET_FILTER_PROTECTIONS', protections)
  },
  SET_FILTER_OPTIONALS({ commit }, optionals) {
    commit('SET_FILTER_OPTIONALS', optionals)
  },
  SET_APPLIED_CONFIGURATIONS({ commit }, configurations) {
    commit('SET_APPLIED_CONFIGURATIONS', configurations)
  },
  SET_APPLIED_CATEGORIES({ commit }, categories) {
    commit('SET_APPLIED_CATEGORIES', categories)
  },
  SET_APPLIED_RENTALS({ commit }, rentals) {
    commit('SET_APPLIED_RENTALS', rentals)
  },
  SET_APPLIED_RATE({ commit }, rate) {
    commit('SET_APPLIED_RATE', rate)
  },
  SET_APPLIED_PAYMENT({ commit }, payment) {
    commit('SET_APPLIED_PAYMENT', payment)
  },
  SET_APPLIED_PROMOTIONS({ commit }, promotions) {
    commit('SET_APPLIED_PROMOTIONS', promotions)
  },
  SET_APPLIED_CLEAR({ commit }, clear) {
    commit('SET_APPLIED_CLEAR', clear)
  },
  SET_APPLIED_PROTECTIONS({ commit }, protections) {
    commit('SET_APPLIED_PROTECTIONS', protections)
  },
  SET_APPLIED_OPTIONALS({ commit }, optionals) {
    commit('SET_APPLIED_OPTIONALS', optionals)
  },
  SET_APPLIED_EVALUATION({ commit }, evaluation) {
    commit('SET_APPLIED_EVALUATION', evaluation)
  },
  CLEAR_APPLIED_FILTERS: ({ commit }) => {
    commit('CLEAR_APPLIED_FILTERS')
  }
}

export const mutations = {
  SET_FILTER_CONFIGURATIONS(state, config) {
    state.filterConfigurations = config
  },
  SET_FILTER_CATEGORIES(state, categories) {
    state.filterCategories = categories
  },
  SET_FILTER_RENTALS(state, rentals) {
    state.filterRentals = rentals
  },
  SET_FILTER_MULTIPLE_RATES(state, rates) {
    state.filterMultipleRates = rates
  },
  SET_FILTER_EVALUATION(state, evaluation) {
    state.filterEvaluation = evaluation
  },
  SET_FILTER_MULTIPLE_PAYMENTS(state, payments) {
    state.filterMultiplePayments = payments
  },
  SET_FILTER_PROMOTIONS(state, promotions) {
    state.filterPromotions = promotions
  },
  SET_FILTER_PROTECTIONS(state, protections) {
    state.filterProtections = protections
  },
  SET_FILTER_OPTIONALS(state, optionals) {
    state.filterOptionals = optionals
  },
  SET_APPLIED_CONFIGURATIONS(state, configurations) {
    state.appliedConfigurations = configurations
  },
  SET_APPLIED_OPTIONALS(state, optionals) {
    state.appliedOptionals = optionals
  },
  SET_APPLIED_CATEGORIES(state, categories) {
    state.appliedCategories = categories
  },
  SET_APPLIED_RENTALS(state, rentals) {
    state.appliedRentals = rentals
  },
  SET_APPLIED_RATE(state, rate) {
    state.appliedRate = rate
  },
  SET_APPLIED_PAYMENT(state, payment) {
    state.appliedPayment = payment
  },
  SET_APPLIED_PROMOTIONS(state, promotions) {
    state.appliedPromotions = promotions
  },
  SET_APPLIED_CLEAR(state, clear) {
    state.appliedClear = clear
  },
  SET_APPLIED_PROTECTIONS(state, protections) {
    state.appliedProtections = protections
  },
  SET_APPLIED_EVALUATION(state, evaluation) {
    state.appliedEvaluation = evaluation
  },
  CLEAR_APPLIED_FILTERS: (state) => {
    state.appliedConfigurations = []
    state.appliedCategories = []
    state.appliedRentals = []
    state.appliedRate = []
    state.appliedPayment = []
    state.appliedProtections = []
    state.appliedOptionals = []
    state.appliedEvaluation = []
    state.appliedPromotions = false
  }
}

export const getters = {
  /**
   * @desc returns Filter Configurations
   *
   * @param state
   * @return {null|Array}
   */
  getFilterConfigurations: state => state.filterConfigurations,

  /**
   * @desc returns Filter Rentals
   *
   * @param state
   * @return {null|Array}
   */
  getFilterRentals: state => state.filterRentals,

  /**
   * @desc returns Filter Categories
   *
   * @param state
   * @return {null|Array}
   */
  getFilterCategories: state => state.filterCategories,

  /**
   * @desc returns Filter Multiple Rates
   *
   * @param state
   * @return {Boolean}
   */
  getFilterMultipleRates: state => state.filterMultipleRates,

  /**
   * @desc returns Filter Multiple Payments
   *
   * @param state
   * @return {Boolean}
   */
  getFilterMultiplePayments: state => state.filterMultiplePayments,

  /**
   * @desc returns Filter Protections
   *
   * @param state
   * @return {null|Array}
   */
  getFilterProtections: state => state.filterProtections,

  /**
   * @desc returns Filter Optionals
   *
   * @param state
   * @return {null|Array}
   */
  getFilterOptionals: state => state.filterOptionals,

  /**
   * @desc returns Evaluations Optionals
   *
   * @param state
   * @return {null|Array}
   */
  getFilterEvaluations: state => state.filterEvaluation,

  /**
   * @desc returns Filter Promotions
   *
   * @param state
   * @return {null|Array}
   */
  getFilterPromotions: state => state.filterPromotions,

  /**
   * @desc returns Applied Rate
   *
   * @param state
   * @return {Array}
   */
  getAppliedRate: state => state.appliedRate,

  /**
   * @desc returns Applied Evaluation
   *
   * @param state
   * @return {Array}
   */
  getAppliedEvaluations: state => state.appliedEvaluation,

  /**
   * @desc returns Applied Configurations
   *
   * @param state
   * @return {Array}
   */
  getAppliedConfigurations: state => state.appliedConfigurations,

  /**
   * @desc returns Applied Protections
   *
   * @param state
   * @return {Array}
   */
  getAppliedProtections: state => state.appliedProtections,

  /**
   * @desc returns Applied Optionals
   *
   * @param state
   * @return {Array}
   */
  getAppliedOptionals: state => state.appliedOptionals,

  /**
   * @desc returns Applied Categories
   *
   * @param state
   * @return {Array}
   */
  getAppliedCategories: state => state.appliedCategories,

  /**
   * @desc returns Applied Rentals
   *
   * @param state
   * @return {Array}
   */
  getAppliedRentals: state => state.appliedRentals,

  /**
   * @desc returns Applied Payment
   *
   * @param state
   * @return {Array}
   */
  getAppliedPayment: state => state.appliedPayment,

  /**
   * @desc returns Applied Promotions
   *
   * @param state
   * @return {Boolean}
   */
  getAppliedPromotions: state => state.appliedPromotions,

  /**
   * @desc returns signal to clear filter elements
   *
   * @param state
   * @return {Boolean}
   */
  getAppliedClear: state => state.appliedClear,

  /**
   * @desc returns if has promotions filter
   *
   * @param state
   * @return {Boolean}
   */
  hasPromotionsFilter: state => state.filterPromotions
}
