import SearchRepository from 'repositories/SearchRepository'

const MAX_RESULTS = 7

export const actions = {
  APPEND_RECENT_LOCATION: async ({ commit, getters, rootGetters }, data) => {
    if (!rootGetters['gdpr/isPerformanceAllowed']) {
      return
    }

    const recent = await getters.getRecentLocations
    commit('APPEND_RECENT_LOCATION', { current: recent, new: data })
  }
}

export const mutations = {
  APPEND_RECENT_LOCATION: (state, data) => {
    const HAS_OPTION = data.current.some(obj => obj.Code === data.new.Code)

    if (data.current.length === MAX_RESULTS) {
      data.current.splice(0, 1)
    }

    if (HAS_OPTION === false) {
      data.current.push(data.new)
      data.current.reverse()
      SearchRepository.SetLastLocations(data.current, self.$nuxt.$store.getters.getLocale)
    }
  }
}

export const getters = {
  /**
   * @desc get all recent locations
   * @returns {Array}
   */
  async getRecentLocations(state, getters, rootState, rootGetters) {
    let recent = []

    try {
      recent = await SearchRepository.GetLastLocations(rootGetters.getLocale)

      if (recent == null) {
        return []
      }

      return recent
    } catch (e) {
      return []
    }
  }
}
