export const LOCATION_TYPE = {
  // CIDADE: 'icon-rc-city'
  CITY: '7cb933ac-65d4-4f8b-8a81-2f7c75ee4902',

  // AEROPORTO: 'icon-rc-airport'
  AIRPORT: 'fef1e7f9-ff3f-44c7-abee-f1b4c69d8fdd',

  // TREM/METRÔ: 'icon-rc-train-station'
  TRAIN: '8a4a9f3b-4025-44d0-9d96-bfe368f3a50b',
  SUBWAY: '63e8e3a3-4751-4d40-a114-dad574bb8e37',

  // RODOVIÁRIA: 'icon-rc-bus-station'
  BUS_STATION: '1aef6104-ceff-44ec-83e9-096e00f6d214',

  // BAIRRO/REGIÃO: 'icon-rc-neighborhood'
  DISTRICT: 'b5060068-df06-4316-914f-c62d8534fb8c',
  REGION: '34bf1e02-f270-4d2b-9151-8ead4d3d0d87',

  // LOCAL/REFERÊNCIA: 'icon-rc-location'
  LOCATION: '7c13c0e4-8be4-4a57-8e13-1f6193f2027c',

  // PORTO: 'icon-rc-harbor',
  HARBOR: 'eb564975-b524-4c5a-bb6a-d2aeb39b116f'
}
