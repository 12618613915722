import initIntercomScript from '@/mixins/helpers/intercom'
import IntercomAuthService from '@/services/IntercomAuthService'

export default {
  mixins: [initIntercomScript],

  data() {
    return {
      chatActive: false,
      intercomAuthService: new IntercomAuthService(this.$root)
    }
  },

  methods: {
    initIntercom () {
      this.setIntercomSettings()

      this.$nextTick(() => {
        this.loadIntercomWidget()
      })
    },

    async getHashUser(token) {
      return await this.intercomAuthService.getHashUser(token)
    },

    async setIntercomSettings () {
      const user = await this.$store.getters['user/getLoggedUser']
      const appId = this.getAppId()

      if (user) {
        const hash = await this.getHashUser(user.token)
        window.intercomSettings = {
          api_base: process.env.INTERCOM_URL,
          app_id: appId,
          language_override: this.getFormattedLanguage(),
          name: `${user.name} ${user.surname}`,
          user_id: user.id,
          user_hash: hash,
          email: user.email,
          phone: user.phone
        }
      } else {
        window.intercomSettings = {
          api_base: process.env.INTERCOM_URL,
          app_id: appId,
          language_override: this.getFormattedLanguage()
        }
      }
    },

    getFormattedLanguage () {
      try {
        const locale = this.$store.getters.getLocale
        const isBr = this.$store.getters.getLocale === 'pt-br' ? 'pt-BR' : undefined
        const language = locale.includes('-') ? locale.split('-')[0] : locale

        return isBr ?? language
      } catch (error) {
        this.$bugsnag.notify(error)
        return 'pt'
      }
    },

    getAppId () {
      return process.env.NODE_ENV === 'production' ? process.env.INTERCOM_APP_ID_PROD : process.env.INTERCOM_APP_ID_TEST
    },

    async getUser () {
      return await this.$store.getters['user/getLoggedUser']
    },

    async reloadIntercom () {
      const user = await this.getUser()
      const hash = await this.getHashUser(user.token)

      window.Intercom('boot', {
        app_id: this.getAppId(),
        email: user.email,
        name: `${user.name} ${user.surname}`,
        user_id: user.id,
        phone: user.phone,
        user_hash: hash
      })
    },

    shutdownIntercom () {
      window.Intercom('shutdown')
    },

    showIntercomMenu (preDefaultMessage) {
      if (preDefaultMessage) {
        window.Intercom('boot')
        window.Intercom('show')
        window.Intercom('showNewMessage', preDefaultMessage)
        return
      }
      window.Intercom('boot')
      window.Intercom('show')
    },

    openIntercomShowSpace (spaceName) {
      window.Intercom('boot')
      window.Intercom('showSpace', spaceName)
    },

    loadIntercomWidget () {
      this.initIntercomScript()
      this.chatActive = true
    },

    async inputConfigAlterationIntercom (alterationMotivation, description, bookingCode) {
      const user = await this.$store.getters['user/getLoggedUser']
      const appId = this.getAppId()
      const hash = await this.getHashUser(user.token)

      window.intercomSettings = {
        api_base: process.env.INTERCOM_URL,
        app_id: appId,
        user_id: user.id,
        user_hash: hash,
        alteration: true,
        alteration_motivation: alterationMotivation,
        alteration_description: description,
        alteration_booking: bookingCode
      }

      window.Intercom('boot')
    }
  }
}
