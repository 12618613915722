
export default {
  name: 'chat-status',

  props: {
    whiteVersion: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
