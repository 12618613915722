import RoutesMixin from './routes'

export default {
  mixins: [RoutesMixin],

  methods: {
    routesAllowedBf () {
      return this.isHome() ||
        this.isListPage() ||
        this.isConfigurePage() ||
        this.isConfirmationPage() ||
        this.isOldPaymentPage()
    },

    isBfOrBuenfinEvent () {
      const eventsRegex = /^(black_friday|buen_fin)(_.*)?$/

      return eventsRegex.test(this.$store.getters.getCustomEvent)
    },

    isPromotionalEventOnPages () {
      return this.isBfOrBuenfinEvent() && this.routesAllowedBf()
    },

    isBf () {
      const buenFin = /^(black_friday)(_.*)?$/

      return buenFin.test(this.$store.getters.getCustomEvent)
    },

    isBuenfin () {
      const buenFin = /^(buen_fin)(_.*)?$/

      return buenFin.test(this.$store.getters.getCustomEvent)
    },

    isYellowWeek () {
      const yellowWeek = /^(yellow_week)(_.*)?$/

      return yellowWeek.test(this.$store.getters.getCustomEvent)
    },

    isPromotionalEvent () {
      return this.isBf() || this.isBuenfin() || this.isYellowWeek()
    },

    getPromotionHeader () {
      if (this.isBf()) {
        return { img: 'images/blackfriday-2024/seal-promo-rentfriday.svg', alt: 'Black Friday' }
      }

      if (this.isBuenfin()) {
        return { img: 'images/blackfriday-2024/seal-promo-buenfin.svg', alt: 'Buen Fin' }
      }

      if (this.isYellowWeek()) {
        return { img: 'images/blackfriday-2024/seal-promo-yellowweek.svg', alt: 'Yellow Week' }
      }

      return { img: 'images/rentday/seal-rentday-list.svg', alt: 'Rent Day' }
    },

    getSealHome () {
      if (this.isBf()) {
        return { img: 'images/blackfriday-2024/seal-bf.svg', alt: 'Black Friday' }
      }

      if (this.isBuenfin()) {
        return { img: 'images/blackfriday-2024/seal-promo-buenfin.svg', alt: 'Buen Fin' }
      }

      return { img: 'images/blackfriday-2024/seal-yw.svg', alt: 'Yellow Week' }
    }
  }
}
