import insiderEnum from './InsiderEnum'

export default class SessionEventState {
  static instance = false

  eventSession

  constructor(config = null) {
    if ((!config) || (config !== 'start') || SessionEventState.instance) {
      throw new Error(insiderEnum.ERROR_CONFIG_MSG)
    }
  }

  static start() {
    if (!SessionEventState.instance) {
      SessionEventState.instance = new SessionEventState('start')
    }

    return SessionEventState.instance
  }

  set(eventSession) {
    this.eventSession = eventSession
  }

  get() {
    return this.eventSession
  }
}
