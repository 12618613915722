export default {
  methods: {
    getCookie (key) {
      return window.$nuxt.$cookie.get(key)
    },

    setCookie (key, value) {
      window.$nuxt.$cookie.set(key, value)
    }
  }
}
