const middleware = {}

middleware['auth'] = require('../../pwa/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['locale'] = require('../../pwa/middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['rental'] = require('../../pwa/middleware/rental.js')
middleware['rental'] = middleware['rental'].default || middleware['rental']

export default middleware
