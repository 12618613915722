import defineAttrCustom from './defineAttrCustom'

export default function switchDefaultCustom (insiderEvent) {
  const custom = {}
  const defaultGeneralStructure = {}

  for (const property in insiderEvent) {
    if (defineAttrCustom.listOfCustom.includes(property)) {
      custom[property] = insiderEvent[property]
    } else {
      defaultGeneralStructure[property] = insiderEvent[property]
    }
  }

  if (Object.keys(custom).length >= 0) {
    defaultGeneralStructure.custom = custom
  }

  return defaultGeneralStructure
}
