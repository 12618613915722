import sortBy from 'lodash/sortBy'
import removeAccents from 'remove-accents'

export const state = () => ({
  countries: [],
  states: []
})

export const actions = {
  SET_COUNTRIES({ commit }, countries) {
    commit('SET_COUNTRIES', countries)
  },

  SET_STATES({ commit }, states) {
    commit('SET_STATES', states)
  }
}

export const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },

  SET_STATES(state, states) {
    state.states = states
  }
}

export const getters = {
  /**
   * @desc returns a countries list
   * @returns {Array<Object>}
   */
  getCountries: state => locale => sortBy(state.countries, (value) => {
    const name = typeof value.name[locale] === typeof undefined ? value.name.en : value.name[locale]
    removeAccents(name)
  }),

  /**
   * @desc returns a states list
   * @returns {Array<Object>}
   */
  getStates: state => sortBy(state.states, value => removeAccents(value.name))
}
