export default {
  CREDIT_CARD: 'credit-card',
  DEBIT_CARD: 'debit-card',
  PURCHASE: 'purchase',
  APPLE_PAY: 'apple-pay',
  GOOGLE_PAY: 'google-pay',
  LOYALT: 'loyalt',
  PAY_AT_DESTINATION: 'pay-at-destination',
  PAYPAL: 'paypal',
  PIX: 'pix',
  THIS_IS_NULL: null,
  LISTING_PAGE_VIEW: 'search_result',
  VIEWED_NO_RESULTS: 'viewed_no_results',
  PRODUCT_DETAIL_PAGE_VIEW: 'product_detail_page_view',
  PRODUCT: 'product',
  VIEWED_PAYMENT_STEP: 'view_payment_step',
  VIEWED_LOGIN_STEP: 'viewed_login_step',
  SIGN_UP_CONFIRMATION: 'sign_up_confirmation',
  LOGGED_IN: 'logged_in',
  LOGGED_OUT: 'logged_out',
  USER: 'user',
  OXXO: 'oxxo',
  SESSION_START: 'session_start',
  REWARDS: 'rewards',
  PD: 'PD',
  PROMOTIONS_DISCOUNT: 'promotions and disccounts',
  EMPTY_STRING: '',
  HOME: 'home',
  RENTAL_COMPANY: 'rental_company',
  CAMPAIGN_LP: 'campaign_lp',
  PROMOTTIONS: 'promotions',
  LOCATIONS: 'locations',
  SEARCH: 'listing',
  TICKED: 'boleto',
  CHECKOUT: 'checkout',
  CONFIRMATION: 'confirmation',
  MOBILE: 'Mobile',
  INCLUDED: 'included',
  INCLUDED_PLAN: 'includedInPlan',
  ERROR_CONFIG_MSG: 'use start() static function to start this class',
  HOME_PAGE_VIEW_COOKIG: 'home_page_view_cookie',
  HOME_PAGE_VIEW: 'home_page_view',
  SESSION_PAGE_VIEW_COOKIG: 'session_page_view_cookie',
  SESSION_PAGE_VIEW: 'session_start',
  CURRENCY_PREPAID: 'currency-prepaid',
  CURRENCY_DESTINATIONPAID: 'currency-destionationpaid',
  VALUE_PREPAID: 'value-prepaid',
  VALUE_DESTINATIONPAID: 'value-destionationpaid',
  PP: 'PP',
  INSIDER_SET_DATA: 'insider/SET_DATA',
  INSIDER_GET_DATA: 'insider/GET_DATA',
  GET_TOTAL_FORMATTED_VALUE: 'get-total-formatted-value',
  GET_TOTAL_VALUE: 'get-total-value',
  GET_BR_TOTAL_VALUE: 'get-br-total-value',
  GET_CURRENCY: 'get-currency',
  GET_SYMBOL_CURRENCY: 'get-symbol-currency',
  GET_BR_CURRENCY: 'get-br-currency',
  TOTAL_VLW_WITHOUT_RENTALCOVER: 'total-vlw-without-rentalcover',
  QUANTITY_BOOKING: 1,
  CASHBACK_BR_VALUE: 'cashback-br-value',
  CASHBACK_BR_FORMATTED_VALUE: 'cashback-br-formatted-value',
  CASHBACK_TYPE_VERIFY: 'cashback-type-verify',
  CASHBACK: 'caskback',
  PREVIEW_CURRENCY_VALUE: 'preview-currency-value',
  UPDATE_USER_INFORMATION: 'update-user-information',
  MALE: 'm',
  FEMALE: 'f',
  SET_VIEWD_STEPS: 'setViewedSteps',
  BASKET: 'basket',
  TRANSACTION_OBJECT: 'transaction_object',
  SET_PURCHASE: 'setPurchase'
}
