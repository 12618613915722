export const state = () => ({
  urlList: null,
  isFlowAsyncConf: false,
  errorFlow: false,
  featureFlag: {},
  isAsyncVariant: null
})

export const mutations = {
  SET_LIST_URL: (state, urlList) => {
    state.urlList = urlList
  },

  SET_FLOW_ASYNC_CONF: (state, isFlowAsyncConf) => {
    state.isFlowAsyncConf = isFlowAsyncConf
  },

  SET_FEATURE_FLAG: (state, featureFlag) => {
    state.featureFlag = featureFlag
  },

  SET_ASYNC_FLOW: (state, isAsyncVariant) => {
    state.isAsyncVariant = isAsyncVariant
  }
}

export const actions = {
  SET_LIST_URL: ({ commit }, urlList) => {
    commit('SET_LIST_URL', urlList)
  },

  SET_FLOW_ASYNC_CONF: ({ commit }, isFlowAsyncConf) => {
    commit('SET_FLOW_ASYNC_CONF', isFlowAsyncConf)
  },

  SET_FEATURE_FLAG: ({ commit }, featureFlag) => {
    commit('SET_FEATURE_FLAG', featureFlag)
  },

  SET_ASYNC_FLOW: ({ commit }, isAsyncVariant) => {
    commit('SET_ASYNC_FLOW', isAsyncVariant)
  }
}

export const getters = {
  GET_LIST_URL: state => state.urlList,
  GET_FLOW_ASYNC_CONF: state => state.isFlowAsyncConf,
  GET_FEATURE_FLAG: state => state.featureFlag,
  GET_ASYNC_FLOW: state => state.isAsyncVariant
}
