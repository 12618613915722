import axios from 'axios'

export default ({ store: { getters } }, inject) => {
  const FAQ_CLIENT = axios.create({
    baseURL: process.env.FAQ_HOST,
    timeout: 60000
  })

  FAQ_CLIENT.interceptors.request.use((config) => {
    if (config.method.toLowerCase() === 'get') {
      config.params.locale = treatLocale(getters.getLocale)
    }

    return config
  })

  function treatLocale(locale = '') {
    if (locale === 'pt-br') {
      return 'pt-BR'
    } else if (locale === 'es') {
      return 'es-ES'
    } else if (locale === 'es-mx') {
      return 'es-MX'
    } else if (locale === 'es-cl') {
      return 'es-CL'
    } else if (locale === 'es-ar') {
      return 'es-AR'
    } else if (locale === 'es-co') {
      return 'es-CO'
    } else if (locale === 'en-gb') {
      return 'en-GB'
    } else if (locale === 'nl-nl') {
      return 'nl-NL'
    } else if (locale === 'it-it') {
      return 'it-IT'
    } else if (locale === 'fr-fr') {
      return 'fr-FR'
    } else if (locale === 'de-de') {
      return 'de-DE'
    } else if (locale === 'pt-pt') {
      return 'pt-PT'
    } else if (locale === 'fr-ca') {
      return 'fr-CA'
    }
    return 'en-US'
  }

  inject('faq_api', FAQ_CLIENT)
}
