import i18nConfig from 'config/i18n'

export default class {
  constructor(context) {
    if (context) this.context = context

    this.defaultCountry = i18nConfig.defaultCountry
    this.availableLocales = i18nConfig.availableLocales

    this.browserLocale = null
    this.geoip = null
  }

  setContext(context) {
    this.context = context
  }

  getBrowserLanguage(localesList) {
    if (!localesList) return false

    // Languages examples: pt-BR,pt;q=0.8,en-US;q=0.6,en;q=0.4
    const locales = localesList
      .replace(/(q=(0.[0-9]+|1),?)/g, '') // Replace all 'q=0.8' or 'q=1' from language quality header
      .replace(/\s/g, '') // Remove spaces
      .replace(',', ';') // Replace all ',' with ';' for multiple languages in array ex: 'pt-BR,pt'
      .replace(/;$/, '') // Remove last ';' from string
      .split(';') // Split languages

    let definedLocale = null

    locales.forEach((locale) => {
      if (definedLocale) return

      locale = locale.toLowerCase()
      if (i18nConfig.availableLocales.indexOf(locale) >= 0) {
        definedLocale = locale
      }
    })

    return definedLocale
  }
}
