import configI18n from '~/../config/i18n'
import { ROUTES_CANT_ACCESS_OFFLINE } from '~/../config/offline-pages'
import { ROUTES_CANT_ACCESS_OFFLINE as LP_REDIRECT_RULES } from '~/../config/landing-pages'

export default async function ({ isHMR, app, store, route, redirect, env }) {
  if (process.browser && !navigator.onLine && ROUTES_CANT_ACCESS_OFFLINE.some(r => route.name === app.$routeResolver(r))) {
    redirect(app.$routeResolver('routes.offline', store.getters.getLocale, true))
  } else if (process.browser && route.path in LP_REDIRECT_RULES) {
    if (route.path.match(/airports|aeroportos|aeropuertos/)) {
      return redirect(301, LP_REDIRECT_RULES[route.path])
    }
    return redirect(302, LP_REDIRECT_RULES[route.path])
  }

  // If middleware is called from hot module replacement, ignore it
  if (isHMR || route.name === 'catch-all') return

  // Get locale from params
  const LocaleInPath = route.fullPath.split('/')[1].trim()

  /**
   * TODO: Adicionar obtenção do locale baseado \o no GEOIP obtido.
   */
  let locale = null

  // AvailableLocaleExpr.test(LocaleInPath)
  if (LocaleInPath && configI18n.availableLocales.some(v => v === LocaleInPath)) {
    locale = LocaleInPath
  }

  if (!LocaleInPath) {
    locale = store.getters.getLocale
  }

  if (!locale) {
    locale = configI18n.defaultLocale
  }

  // eslint-disable-next-line prefer-regex-literals
  const LocalePathExpr = new RegExp(/^\/(pt-br|en|es|es-mx|en-gb|it-it|fr-fr|de-de|pt-pt|es-cl|es-ar|es-co|nl-nl|fr-ca)$/)
  // eslint-disable-next-line prefer-regex-literals
  const HomePathExpr = new RegExp(/^\/$/)
  store.dispatch('SET_LOCALE', locale)
  const currentRoute = route.name
  await app.$loadLanguageAsync(locale, currentRoute)

  if (route.query.hasOwnProperty('debug') && route.query.hasOwnProperty('secret')) {
    if (route.query.secret === env.DEBUG_SECRET) {
      store.dispatch('SET_DEBUG', true)
    }
  }

  if (LocalePathExpr.test(route.path) || HomePathExpr.test(route.path)) {
    if (route.query && route.query.hasOwnProperty('requestorid')) {
      return redirect(route.fullPath.replace(/.*$/, `/${locale}/?requestorid=${route.query.requestorid}`))
    } else if (route.query && route.query.hasOwnProperty('requestorId')) {
      return redirect(route.fullPath.replace(/.*$/, `/${locale}/?requestorid=${route.query.requestorId}`))
    } else if (Object.keys(route.query).length > 0) {
      return redirect(route.fullPath.replace(/.*$/, `/${locale}/?${Object.entries({ utm_source: 'pwa', utm_medium: 'direct' }).map(v => `${v[0]}=${v[1]}`).join('&')}`))
    }
    return redirect(301, route.fullPath.replace(/.*$/, `/${locale}/`))
  } else if (configI18n.availableLocales.indexOf(LocaleInPath) === -1) {
    return redirect(301, `/${locale}${route.fullPath}`)
  } else if (
    route.name !== app.$routeResolver('routes.home', store.getters.getLocale) &&
    route.path.slice(-1) === '/'
  ) {
    return redirect(301, (route.path.slice(0, -1)) + (Object.keys(route.query).length !== 0 ? '?' + Object.keys(route.query).map(key => key + '=' + route.query[key]).join('&') : ''))
  }
}
