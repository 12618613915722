export const state = () => ({
  whatsapp_cta: false,
  whatsapp_cta_mx: false,
  facebook_login: null,
  google_login: null,
  apple_login: null
})

export const actions = {
  UPDATE_FEATURE_FLAG({ commit }, data) {
    commit('UPDATE_FEATURE_FLAG', { name: data.name, value: data.value })
  }
}

export const mutations = {
  UPDATE_FEATURE_FLAG(state, { name, value }) {
    if (name in state) {
      state[name] = value
    }
  }
}

export const getters = {
  isWhatsappAllowed: state => state.whatsapp_cta_mx || state.whatsapp_cta,
  isFacebookLoginAllowed: state => state.facebook_login,
  isGoogleLoginAllowed: state => state.google_login,
  isAppleLoginAllowed: state => state.apple_login
}
